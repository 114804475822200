import { EmployerType } from "sharedTypes"

export interface StepContext {
  currentEmployer: { employerType: EmployerType }
  isOrderTransferred?: boolean
}

export enum StepId {
  PAYMENT_STEP = "payment",
  PRODUCTS_STEP = "products",
  DELIVERY_STEP = "delivery",
  CLINICAL_STEP = "clinical",
  SIGNATURE_STEP = "signature",
  REVIEW_STEP = "review",
}

export const PAYMENT_STEP = StepId.PAYMENT_STEP
export const PRODUCTS_STEP = StepId.PRODUCTS_STEP
export const DELIVERY_STEP = StepId.DELIVERY_STEP
export const CLINICAL_STEP = StepId.CLINICAL_STEP
export const SIGNATURE_STEP = StepId.SIGNATURE_STEP
export const REVIEW_STEP = StepId.REVIEW_STEP

export const stepIdToStepTitle = (stepId: StepId): string => {
  switch (stepId) {
    case PAYMENT_STEP:
      return "Payment"
    case PRODUCTS_STEP:
      return "Products & Services"
    case DELIVERY_STEP:
      return "Addresses & Contacts"
    case CLINICAL_STEP:
      return "Clinical Info"
    case SIGNATURE_STEP:
      return "Signing Clinician"
    case REVIEW_STEP:
      return "Review"
  }
}

export const ORDERED_STEPS: StepId[] = [
  PAYMENT_STEP,
  PRODUCTS_STEP,
  DELIVERY_STEP,
  CLINICAL_STEP,
  SIGNATURE_STEP,
  REVIEW_STEP,
]

export const isSimpleFacilitySteps = (context: StepContext) =>
  context.currentEmployer.employerType === EmployerType.ClinicalFacility

export const productsWarningDependents = (context: StepContext): StepId[] =>
  context.isOrderTransferred
    ? [REVIEW_STEP, CLINICAL_STEP]
    : [REVIEW_STEP, DELIVERY_STEP, CLINICAL_STEP, SIGNATURE_STEP]
