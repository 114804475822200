import React from "react"
import { Field } from "formik"
import classNames from "classnames"
import TextField from "components/TextArea"
import ErrorText from "components/form/ErrorText"
import { handleChange, handleBlur } from "./event-handlers"
import { getFieldError } from "./utilities"

type Props = {
  name: string
  label?: React.ReactNode
  disabled?: boolean
  onKeyDown?(event: React.KeyboardEvent): void
  onChange?(event: React.FormEvent): void
  onBlur?(event: React.FormEvent): void
  submitOnBlur?: boolean
  autoFocus?: boolean
  placeholder?: string
  rows?: number
  innerRef?(node: Element): void
  unwrapped?: boolean
  validate?(value): void
  alert?: React.ReactNode
}

function TextArea({
  label,
  name,
  disabled,
  placeholder,
  onKeyDown,
  onChange,
  onBlur,
  submitOnBlur,
  autoFocus,
  rows,
  innerRef,
  unwrapped,
  validate,
  alert,
}: Props) {
  const renderError = (error) => <ErrorText error={error} />
  return (
    <Field name={name} validate={validate}>
      {({ field, form }) => {
        const { errors, touched } = form
        const error = getFieldError(errors, touched, name)

        const textarea = (
          <TextField
            value={field.value}
            name={field.name}
            id={name}
            rows={rows}
            disabled={disabled}
            placeholder={placeholder}
            autoFocus={autoFocus}
            className={classNames("form-control", { "mb-0": error })}
            onKeyDown={onKeyDown}
            onChange={handleChange({ field, onChange })}
            onBlur={handleBlur({ form, field, onBlur, submitOnBlur })}
            innerRef={innerRef}
            alert={alert}
          />
        )

        const layout = (
          <>
            {label && <label htmlFor={name}>{label}</label>}
            {textarea}
            {renderError(error)}
          </>
        )

        return (
          <div
            className={classNames({
              "form-group": !unwrapped,
              "has-error": error,
            })}
          >
            {layout}
          </div>
        )
      }}
    </Field>
  )
}

TextArea.defaultProps = {
  rows: "1",
}

export default TextArea
