import React from "react"
import classNames from "classnames"
import { Icd10Code as Code } from "sharedTypes"
import Tooltip from "components/Tooltip"

type Props = {
  icd10Code: Code
  remove?(icd10Code: Code): void
}

function Icd10Code({ icd10Code, remove }: Props) {
  const deny = icd10Code.deny
  const allow = icd10Code.allow
  return (
    <Tooltip
      trigger={["hover"]}
      overlay={icd10Code.description}
      width={Tooltip.Width.Wide}
      placement={Tooltip.Placement.Top}
    >
      <div
        className={classNames("entry mb-2", {
          warning: deny,
          qualifying: allow,
        })}
        key={`relevant-codes-${icd10Code.code}`}
      >
        <span className="fa-wrapper">
          {allow && (
            <span className="fas fa-check-circle color-check-success" />
          )}
          {deny && <span className="fas fa-exclamation-circle color-danger" />}
        </span>
        <span className="title">{icd10Code.codeLabel}</span>
        <span className="description">{icd10Code.description}</span>
        {remove && (
          <span
            className="icon fas fa-times"
            onClick={() => remove(icd10Code)}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default Icd10Code
