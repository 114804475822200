import React, { useContext, useState } from "react"
import Modal from "components/Modal"
import { copyToClipboard } from "utilities/clipboard"
import Icon from "components/Icon"
import SupplierOrganizationContext from "../../SupplierOrganizationContext"
import { createViewAPI } from "../../view/api"

type Props = {
  view: { name: string; externalId: string }
  shareLink: string
}

export const ShareViewLink = ({ view, shareLink }: Props) => {
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)
  const openModal = () => setOpen(true)

  return (
    <>
      <a className="link mr-4" onClick={openModal}>
        <Icon type="share" prefix="fas" className="mr-2" />
        <small>Share View</small>
      </a>
      <Modal
        size="md"
        title={`Share '${view.name}'`}
        show={open}
        cancel={closeModal}
      >
        <Modal.Body>
          <p>Share this view with a team member using the following link</p>
          <div className="well well-borderless bg-lighter-gray">
            <div className="row">
              <div className="col-md-8">{shareLink}</div>
              <div className="col-md-4 text-right">
                <a className="link" onClick={() => copyToClipboard(shareLink)}>
                  Copy Link
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-brand" onClick={closeModal}>
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export const ShareViewLinkContainer = ({
  view,
}: {
  view: { name: string; externalId: string }
}) => {
  const { supplierOrganizationId } = useContext(SupplierOrganizationContext)
  const api = createViewAPI(supplierOrganizationId)
  return (
    <ShareViewLink view={view} shareLink={api.shareViewUrl(view.externalId)} />
  )
}
export default ShareViewLinkContainer

export { ShareViewLink as ShareViewLinkExportForSupplierDashboard }
