// @ts-strict-ignore
import React, { useContext } from "react"
import { CarrierAuthorization } from "sharedTypes"
import Table from "./Table"
import { Filters } from "./sharedTypes"
import TableOverview from "components/TableOverview"
import BackToTop from "components/BackToTop"
import LoadMoreLink from "components/LoadMoreLink"
import SelectFilter, { Record } from "components/SelectFilter"
import { isAnyFilterPresent } from "utilities/dashboard"
import { ApiContext } from "./apiContext"

type Props = {
  authorizations: CarrierAuthorization[]
  loading?: boolean
  filters: Filters
  counts: { [attr: string]: number }
  getRecords(values, options): Promise<void>
  statuses: Record[]
}

const FILTER_TITLES = {
  status: "Status",
  updated_at: "New/Changed",
}

const InternalCarrierAuthorizationDashboard = ({
  authorizations,
  loading,
  filters,
  getRecords,
  statuses,
  counts,
}: Props) => {
  const { getMembers, getCarrierAuthorizationNumbers } = useContext(ApiContext)
  const getRecordsWithParams = (values) => {
    return getRecords({ ...filters, ...values, page: 1 }, { append: false })
  }

  const handleLoadMore = () => {
    const currentPage = filters.page || 1
    return getRecords({ ...filters, page: currentPage + 1 }, { append: true })
  }

  const clearFilters = () =>
    getRecords(
      { sort: filters.sort, direction: filters.direction, clear: true },
      { append: false }
    )

  return (
    <div {...(loading ? { "data-status": "loading" } : {})}>
      <SelectFilter
        name="authorizationNumberQuery"
        label="Authorization Number"
        initialValues={filters.authorizationNumberQuery || []}
        fetchOptions={getCarrierAuthorizationNumbers}
        onChange={getRecordsWithParams}
      />
      <SelectFilter
        minLength={2}
        name="memberNumberQuery"
        label="Member"
        initialValues={filters.memberNumberQuery || []}
        fetchOptions={getMembers}
        onChange={getRecordsWithParams}
      />
      <SelectFilter
        name="statuses"
        label="Statuses"
        initialValues={filters.statuses}
        options={statuses}
        onChange={getRecordsWithParams}
      />
      {isAnyFilterPresent(filters) && (
        <a
          onClick={clearFilters}
          className="ml-3 link link-sm link-understated nowrap"
        >
          <small>Clear All Filters</small>
        </a>
      )}
      <TableOverview
        loading={loading}
        currentCount={counts.current}
        totalCount={counts.total}
        sortingBy={filters.sort && FILTER_TITLES[filters.sort]}
        suffix="authorizations"
      />
      <Table
        authorizations={authorizations}
        sortColumn={filters.sort}
        sortDirection={filters.direction}
        loading={loading}
        onSortChange={getRecordsWithParams}
        statuses={statuses}
      />
      <BackToTop />
      <LoadMoreLink
        loading={loading}
        count={authorizations.length}
        totalCount={counts.current}
        onClick={handleLoadMore}
      />
    </div>
  )
}

export default InternalCarrierAuthorizationDashboard
