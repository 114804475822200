// @team @clinical-intelligence
import classNames from "classnames"
import SeverityIcon from "components/SeverityIcon"
import React from "react"
import { Severity } from "sharedTypes"
import { ExternalQuestionnaireSatisfyStatus } from "../sharedTypes"

const ExternalQuestionnaireStatusPill = ({ satisfyStatus }) => {
  const statusToSeverity = {
    [ExternalQuestionnaireSatisfyStatus.Recommended]: Severity.Success,
    [ExternalQuestionnaireSatisfyStatus.NotRecommended]: Severity.Warning,
    [ExternalQuestionnaireSatisfyStatus.NotStarted]: Severity.Warning,
    [ExternalQuestionnaireSatisfyStatus.Incomplete]: Severity.Warning,
  }

  const statusToText = {
    [ExternalQuestionnaireSatisfyStatus.Recommended]: "Recommended",
    [ExternalQuestionnaireSatisfyStatus.NotRecommended]: "Not Recommended",
    [ExternalQuestionnaireSatisfyStatus.NotStarted]: "Not Started",
    [ExternalQuestionnaireSatisfyStatus.Incomplete]: "Incomplete",
  }

  const status = statusToText[satisfyStatus]
  const severity = statusToSeverity[satisfyStatus]

  return (
    <span className={classNames("pill nowrap", `pill-${severity}`)}>
      <SeverityIcon className="mr-1" type={severity} />
      {status}
    </span>
  )
}

export default ExternalQuestionnaireStatusPill
