import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"

interface Props {
  href: string
}

const PreferencesLinkButton: React.FC<Props> = ({ href }) => {
  return (
    <CanopyButton
      accessibleText="User Preferences"
      data-analytics="gear_preferences_select"
      data-analytics-context="Workflow Navigation"
      data-analytics-replay-event
      href={href}
      iconStart="gear"
      size="small"
      variant="tertiary"
    />
  )
}

export default PreferencesLinkButton
