// @team @facilitators

export const allowSearchByProduct = (
  userActivationProductFirstSearchFlag: boolean,
  userActivationProductFirstSearchIncludeEnterpriseFlag: boolean,
  usesEnterpriseFeatures: boolean
): boolean => {
  if (!userActivationProductFirstSearchFlag) return false

  if (userActivationProductFirstSearchIncludeEnterpriseFlag) return true

  // Search by Product is true and include enterprise is false,
  // so we only enable search by product if the facility is not using enterprise features
  return !usesEnterpriseFeatures
}
