import React, { useState } from "react"
import {
  SidebarListDoctor,
  SidebarListFacility,
} from "components/ClinicalDashboard/sharedTypes"
import { FeatureFlagInput } from "../../sharedTypes"
import LeftSidebar from "../../components/ClinicalDashboard/LeftSidebar"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import { withApollo } from "hocs/withApollo"
import IncomingOrdersTable from "./components/IncomingOrdersTable"
import {
  GetClinicalFacilities,
  GetDoctors,
  GetIncomingOrders,
  GetIncomingOrdersFollowers,
  GetProductSubcategories,
  GetSuppliers,
} from "./graphql/incomingOrdersQuery"
import { lastAccessedFacilityEmployerUrl } from "./urls"
import Filters from "./components/Filters"
import {
  Category,
  ClinicalFacility,
  Doctor,
  Follower,
  IncomingDmeOrderConnection,
  IncomingOrdersSortOptionEnum,
  SortDirectionEnum,
  Supplier,
} from "graphql/__generated__/graphql"
import { IncomingOrdersUserUpdate } from "./graphql/incomingOrdersUserUpdateMutation"
import { GridSortDirection } from "@mui/x-data-grid-pro"

interface InitialFilterValues {
  clinicalFacilityIds: string[]
  productSubcategoryIds: string[]
  doctorIds: string[]
  supplierIds: string[]
  followerIds: string[]
  deliveryDateStartAt: string
  deliveryDateEndAt: string
}

type Props = {
  featureFlags: FeatureFlagInput
  sidebarInformation: {
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
    facilities?: SidebarListFacility[]
    lastAccessedDoctorEmployerId?: string
  }
  initialFilterValues: InitialFilterValues
  clinicalFacilities: ClinicalFacility[]
  productSubcategories: Category[]
  doctors: Doctor[]
  suppliers: Supplier[]
  followers: Follower[]
}

const IncomingOrders = ({
  featureFlags,
  sidebarInformation,
  initialFilterValues,
  clinicalFacilities,
  productSubcategories,
  doctors,
  suppliers,
  followers,
}: Props) => {
  const [initialClinicalFacilityIds, setInitialClinicalFacilityIds] = useState<
    string[]
  >(initialFilterValues.clinicalFacilityIds)
  const [
    initialProductSubcategoryIds,
    setInitialProductSubcategoryIds,
  ] = useState<string[]>(initialFilterValues.productSubcategoryIds)
  const [initialFollowerIds, setInitialFollowerIds] = useState<string[]>(
    initialFilterValues.followerIds
  )
  const [initialSupplierIds, setInitialSupplierIds] = useState<string[]>(
    initialFilterValues.supplierIds
  )
  const [initialDeliveryDateStart, setInitialDeliveryDateStart] = useState(
    initialFilterValues.deliveryDateStartAt
  )
  const [initialDeliveryDateEnd, setInitialDeliveryDateEnd] = useState(
    initialFilterValues.deliveryDateEndAt
  )
  const [initialQuery, setInitialQuery] = useState("")
  const [initialDoctorIds, setInitialDoctorIds] = useState<string[]>(
    initialFilterValues.doctorIds
  )

  const { data, loading, fetchMore, refetch, error, variables } = useQuery(
    GetIncomingOrders,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        clinicalFacilityIds: initialClinicalFacilityIds,
        productSubcategoryIds: initialProductSubcategoryIds,
        doctorIds: initialDoctorIds,
        supplierIds: initialSupplierIds,
        followerIds: initialFollowerIds,
        deliveryDateStartAt: initialDeliveryDateStart,
        deliveryDateEndAt: initialDeliveryDateEnd,
        patientSearchTerm: initialQuery,
        sortDirection: SortDirectionEnum.Asc,
        sort: IncomingOrdersSortOptionEnum.DeliveryDate,
      },
    }
  )

  const initialDataGridOptions = {
    sort: [
      {
        field: variables?.sort as IncomingOrdersSortOptionEnum,
        sort: variables?.sortDirection?.toLowerCase() as GridSortDirection,
      },
    ],
  }

  const incomingOrderData = data?.incomingOrders as IncomingDmeOrderConnection

  const lastAccessedFacilityDashboardUrl = lastAccessedFacilityEmployerUrl(
    sidebarInformation.lastAccessedFacilityEmployerId as string
  )

  const client = useApolloClient()

  const [updateUserUiPreferences] = useMutation(IncomingOrdersUserUpdate, {
    refetchQueries: [GetIncomingOrders],
  })

  return (
    <>
      <LeftSidebar
        active={LeftSidebar.Options.IncomingOrders}
        sidebarInformation={sidebarInformation}
        featureFlags={{
          leftSidebarSignatureRequestsSignup: !!featureFlags.leftSidebarSignatureRequestsSignup,
          facilityIncomingOrders: !!featureFlags.facilityIncomingOrders,
        }}
        expandLeftSidebar
      />
      <div className="row mt-n3">
        <div className="col-12 canopy-mbs-12x canopy-pb-12x">
          <h1 className="canopy-typography-heading-2xlarge">Incoming Orders</h1>
          <h3>Orders sent from suppliers that require your attention.</h3>
          <Filters
            refetchOrders={refetch}
            loading={loading}
            initialQuery={initialQuery}
            setInitialQuery={setInitialQuery}
            followers={followers}
            clinicalFacilities={clinicalFacilities}
            productSubcategories={productSubcategories}
            suppliers={suppliers}
            doctors={doctors}
            initialFollowerIds={initialFollowerIds}
            setInitialFollowerIds={setInitialFollowerIds}
            initialDeliveryDateStart={initialDeliveryDateStart}
            setInitialDeliveryDateStart={setInitialDeliveryDateStart}
            initialDeliveryDateEnd={initialDeliveryDateEnd}
            setInitialDeliveryDateEnd={setInitialDeliveryDateEnd}
            initialClinicalFacilityIds={initialClinicalFacilityIds}
            setInitialClinicalFacilityIds={setInitialClinicalFacilityIds}
            initialProductSubcategoryIds={initialProductSubcategoryIds}
            setInitialProductSubcategoryIds={setInitialProductSubcategoryIds}
            initialSupplierIds={initialSupplierIds}
            setInitialSupplierIds={setInitialSupplierIds}
            initialDoctorIds={initialDoctorIds}
            setInitialDoctorIds={setInitialDoctorIds}
            searchFollowers={(searchTerm) =>
              client.query({
                query: GetIncomingOrdersFollowers,
                variables: { searchTerm },
              })
            }
            searchSuppliers={(searchTerm) =>
              client.query({ query: GetSuppliers, variables: { searchTerm } })
            }
            searchClinicalFacilities={(searchTerm) =>
              client.query({
                query: GetClinicalFacilities,
                variables: { searchTerm },
              })
            }
            searchProductSubcategories={(searchTerm) =>
              client.query({
                query: GetProductSubcategories,
                variables: { searchTerm },
              })
            }
            searchDoctors={(searchTerm) =>
              client.query({
                query: GetDoctors,
                variables: { searchTerm },
              })
            }
            updateUserUiPreferences={updateUserUiPreferences}
          />
          <IncomingOrdersTable
            loading={loading}
            error={error}
            incomingOrderData={incomingOrderData}
            paginate={fetchMore}
            refetch={refetch}
            lastAccessedFacilityDashboardUrl={lastAccessedFacilityDashboardUrl}
            initialDataGridOptions={initialDataGridOptions}
          />
        </div>
      </div>
    </>
  )
}

export default withApollo(IncomingOrders)
