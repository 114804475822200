import React from "react"
import ReviewCard from "."
import SignatureSectionContent from "../SectionContent/SignatureSectionContent"
import { Severity, DmeOrder } from "sharedTypes"
import { Permissions, SignatureRequest } from "../../sharedTypes"
import { fullName } from "utilities/person"

type Props = {
  status?: Severity
  expanded?: boolean
  badge?: ({ open }) => React.ReactNode
  permissions: Permissions
  sendSignatureRequest(params: {}): Promise<void>
  signatureRequests: SignatureRequest[]
  dmeOrder: DmeOrder
}

const SignatureReviewCard = (props: Props) => {
  const {
    status,
    expanded,
    badge,
    permissions,
    sendSignatureRequest,
    signatureRequests,
    dmeOrder,
  } = props

  const signatureRequest = () => (
    <ReviewCard
      status={status}
      title="Signing Clinician"
      summary={dmeOrder.doctor && fullName(dmeOrder.doctor)}
      expanded={expanded}
      badge={badge}
    >
      <SignatureSectionContent
        dmeOrder={dmeOrder}
        permissions={permissions}
        sendSignatureRequest={sendSignatureRequest}
        signatureRequests={signatureRequests}
      />
    </ReviewCard>
  )

  return signatureRequest()
}

export default SignatureReviewCard
