// @team @archers
import { datadogRum } from "@datadog/browser-rum"

import loadGlassboxScript from "./loadGlassboxScript"
import { FeatureFlagName, ParachuteWindow } from "sharedTypes"

export const isPermittedPath = (location: Location): boolean =>
  window.prohibitedUserMonitoringPaths.every(
    (unpermittedPath) => !location.pathname.match(unpermittedPath)
  )

export const isInstrumentationEnabled = (): boolean => {
  if (window.parachute?.currentUserId) {
    return true
  } else {
    return isPermittedPath(window.location)
  }
}

export const glassboxInitialize = async (): Promise<void> => {
  if (!window.glassboxJsUrl || !isInstrumentationEnabled()) {
    return Promise.resolve()
  }

  await loadGlassboxScript(window.glassboxJsUrl)
  if (!window._detector) {
    return Promise.reject("Glassbox failed to load, no detector found")
  }

  const {
    currentUserId,
    currentUserEmail,
  } = window.parachute as ParachuteWindow

  if (currentUserId) {
    window._detector.triggerCustomEvent("email", currentUserEmail!)
    window._detector.triggerCustomEvent("userId", currentUserId)
  }

  return await Promise.resolve()
}

export const initializeDatadogRUM = (): void => {
  if (!isInstrumentationEnabled()) {
    return
  }

  const { datadogConfiguration, clientsideInstrumentation } = window

  datadogRum.init({
    applicationId: datadogConfiguration.applicationId,
    clientToken: datadogConfiguration.clientToken,
    site: datadogConfiguration.site,
    service: datadogConfiguration.serviceName,
    env: clientsideInstrumentation.releaseStage,
    version: clientsideInstrumentation.commitSHA,
    sessionSampleRate: datadogConfiguration.realUserMonitoring.sampleRate,
    sessionReplaySampleRate:
      datadogConfiguration.realUserMonitoring.sessionReplaySampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    actionNameAttribute: "data-user-target-name",
    defaultPrivacyLevel: "allow",
    enableExperimentalFeatures: ["feature_flags"],
    allowedTracingUrls: [isAllowedTracingURL],
  })
}

export const instrumentDatadogUser = (): void => {
  datadogRum.setUser({
    id: window.parachute?.currentUserId,
    email: window.parachute?.currentUserEmail,
  })
}

export const initUserInstruments = async (): Promise<void> => {
  initializeDatadogRUM()
  instrumentDatadogUser()

  await glassboxInitialize()
}

export const isAllowedTracingURL = (url: string): boolean =>
  !!url.match(
    /^(?:https:\/\/)(?:(?:app-workflow\.(?:master|production)\.internal)|(?:dme\.parachutehealth\.com)).*$/i
  )

export const instrumentFeatureFlagEvaluation = (
  flagName: FeatureFlagName,
  flagValue: boolean | undefined
): void => datadogRum.addFeatureFlagEvaluation(flagName, flagValue)
