import React from "react"
import { Form, TextField } from "components/form"
import Button from "components/form/Button"
import ErrorText from "components/form/ErrorText"
import { navigate } from "utilities/navigation"

interface Props {
  login(params): Promise<{ redirectTo?: string }>
  redirectUrl?: string
}

function InternalTwoFactorAuthentication({ login, redirectUrl }: Props) {
  const onSubmit = (values, { setErrors }) => {
    return login(values)
      .then(({ redirectTo }) => {
        navigate(redirectTo || redirectUrl)
      })
      .catch(({ errors }) => {
        setErrors(errors)
      })
  }

  return (
    <>
      <h1 className="mb-5">Log In to Parachute Health</h1>
      <Form onSubmit={onSubmit} initialValues={{ otpAttempt: "" }}>
        {({ isSubmitting, errors }) => (
          <>
            {errors && errors.base && <ErrorText error={errors.base} />}
            <TextField
              label="Two-factor verification code"
              name="otpAttempt"
              autoFocus
              autoComplete="off"
              subtext="A text message with a 6-digit verification code was sent to your
              mobile device."
            />
            <Button className="btn-brand" type="submit" disabled={isSubmitting}>
              Verify code
            </Button>
          </>
        )}
      </Form>
    </>
  )
}

export default InternalTwoFactorAuthentication
