// @ts-strict-ignore
import React from "react"
import ToggleFilter from "components/ToggleFilter"
import SelectFilter from "components/SelectFilter"
import { isAnyFilterPresent } from "utilities/dashboard"
import { formatPhone } from "utilities/phone"
import { InternalStatus } from "sharedTypes"
interface Assignee {
  name: string
  id: string
}

interface ClinicalFacility {
  name: string
  id: string
}

interface DocumentSource {
  source: string
  sourceType: string
}

interface Filters {
  assigned?: boolean
  assignees?: string[]
  liaison?: boolean
  internalStatuses?: string[]
  clinicalFacilities?: string[]
  documentSources?: string[]
  receivingFaxNumbers?: string[]
  worked?: boolean
}

interface Props {
  fromSources?: { source: string }[]
  assignees?: Assignee[]
  filters?: Filters
  onSubmit?: (values) => Promise<void>
  internalStatuses?: InternalStatus[]
  clinicalFacilities?: ClinicalFacility[]
  documentSources?: DocumentSource[]
  receivingFaxNumbers?: string[]
  getClinicalFacilities?: (term: string) => Promise<ClinicalFacility[]>
  getDocumentSources?: (term: string) => Promise<DocumentSource[]>
  getReceivingFaxNumbers?: (term: string) => Promise<string[]>
  getAssignees?: (term: string) => Promise<Assignee[]>
  onReset?: () => void
}

const FilterForm: React.FC<Props> = (props) => {
  const defaultFilters: Filters = {
    assigned: false,
    assignees: [],
    clinicalFacilities: [],
    documentSources: [],
    receivingFaxNumbers: [],
    internalStatuses: [],
    liaison: false,
  }

  const {
    assignees = [],
    clinicalFacilities = [],
    documentSources = [],
    receivingFaxNumbers = [],
    filters = defaultFilters,
    getDocumentSources,
    getClinicalFacilities,
    getReceivingFaxNumbers,
    getAssignees,
    internalStatuses = [],
    onReset,
    onSubmit,
  } = props

  const formatDocumentSources = (source: {
    source: string
    sourceType: string
  }) => ({
    label:
      source.sourceType === "email"
        ? source.source
        : formatPhone(source.source),
    value: source.source,
  })

  const getSourcesWithFormatting = async (term: string) => {
    const sources = await getDocumentSources(term)
    return sources.map(formatDocumentSources)
  }

  const buildFaxNumberOption = (faxNumber: string) => {
    if (faxNumber === "unknown") {
      return {
        label: "Unknown",
        value: "unknown",
      }
    }
    return {
      label: formatPhone(faxNumber),
      value: faxNumber,
    }
  }

  const getReceivingFaxNumberOptions = async (term: string) => {
    let optionValues = []
    if (!term || term.match(/^\s+$/)) {
      optionValues.push("unknown")
    }
    const faxNumbers = await getReceivingFaxNumbers(term)
    optionValues = optionValues.concat(faxNumbers)

    return optionValues.map(buildFaxNumberOption)
  }

  return (
    <div>
      <ToggleFilter
        name="worked"
        options={[
          {
            label: "New",
            value: false,
          },
          {
            label: "Worked",
            value: true,
          },
        ]}
        initialValue={filters.worked}
        onChange={onSubmit}
      />
      <SelectFilter
        name="assignees"
        label="Assignee"
        options={assignees.map((option) => ({
          label: option.name,
          value: option.id,
        }))}
        initialValues={filters.assignees}
        fetchOptions={getAssignees}
        onChange={onSubmit}
      />
      <SelectFilter
        name="internalStatuses"
        label="Internal Status"
        options={internalStatuses.map(({ status, id }) => ({
          label: status,
          value: id.toString(),
        }))}
        initialValues={filters.internalStatuses}
        onChange={onSubmit}
      />
      <SelectFilter
        name="clinicalFacilities"
        label="Facility"
        initialValues={filters.clinicalFacilities}
        options={clinicalFacilities.map(({ id, name }) => ({
          label: name,
          value: id.toString(),
        }))}
        fetchOptions={getClinicalFacilities}
        onChange={onSubmit}
      />
      <SelectFilter
        name="documentSources"
        label="From"
        initialValues={filters.documentSources}
        options={documentSources.map(formatDocumentSources)}
        fetchOptions={getSourcesWithFormatting}
        onChange={onSubmit}
        singular
      />
      <SelectFilter
        name="receivingFaxNumbers"
        label="To"
        initialValues={filters.receivingFaxNumbers}
        options={receivingFaxNumbers.map(buildFaxNumberOption)}
        fetchOptions={getReceivingFaxNumberOptions}
        onChange={onSubmit}
        minLength={0}
        singular
      />
      <ToggleFilter
        name="assigned"
        options={[
          {
            label: "Unassigned",
            value: false,
          },
          {
            label: "Assigned",
            value: true,
          },
        ]}
        initialValue={filters.assigned}
        onChange={onSubmit}
      />
      <ToggleFilter
        name="liaison"
        options={[
          {
            label: "Liaison",
            value: true,
          },
          {
            label: "Non-liaison",
            value: false,
          },
        ]}
        initialValue={filters.liaison}
        onChange={onSubmit}
      />
      {isAnyFilterPresent(filters) && (
        <a
          onClick={onReset}
          className="ml-3 link link-sm link-understated nowrap"
        >
          <small>Clear All Filters</small>
        </a>
      )}
    </div>
  )
}

export default FilterForm
