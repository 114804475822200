import React from "react"
import emailSuccessIllustration from "email-success-illustration.png"
interface Props {
  onClick(): void
}
import { CanopyButton } from "@parachutehealth/canopy-button"

function ForgotPasswordEmailSent({ onClick }: Props) {
  return (
    <div className="text-center">
      <img src={emailSuccessIllustration} width="460" />
      <h2 className="mb-5">
        If you have a Parachute Health account, you will receive an email with
        instructions to reset your password.
      </h2>
      <CanopyButton onClick={onClick}>Back to Login</CanopyButton>
    </div>
  )
}

export default ForgotPasswordEmailSent
