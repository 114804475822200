import React from "react"
import * as styles from "./index.module.scss"

const countWithLimit = (count: number, limit: number) => {
  if (count > limit) {
    return `${limit}+`
  }
  return count
}

function DashboardHeader({ title, count, limit }) {
  return (
    <div className={styles.container}>
      <h3 className={styles.header}>{title}</h3>
      <div className="badge mb-0">{countWithLimit(count, limit)}</div>
    </div>
  )
}

export default DashboardHeader
