import React from "react"
import { Address } from "sharedTypes"
import Icon from "components/Icon"

type Props = {
  deliveryAddress: Address
  editAddress(address: Address): void
}

function EditDeliveryAddressButton({ deliveryAddress, editAddress }: Props) {
  return (
    <a
      className="link link-primary ml-1 nowrap"
      onClick={() => editAddress(deliveryAddress)}
    >
      Edit <Icon type="pencil-alt" className="ml-1" />
    </a>
  )
}

export default EditDeliveryAddressButton
