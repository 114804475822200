// @ts-strict-ignore
import React from "react"
import {
  ChartNote,
  DocumentationRequirement,
  DocumentationRequirementSatisfyMethod,
  DocumentationRequirementPermissions,
} from "sharedTypes"
import DocumentationRequirementRow from "./DocumentationRequirementRow"
import cx from "classnames"
import { isChartNoteRelatedToDocumentationRequirement } from "./../utilities"
import { useFeatureFlags } from "components/FeatureFlagContext"

interface Props {
  chartNotes: ChartNote[]
  documentationRequirements: DocumentationRequirement[]
  permissions: DocumentationRequirementPermissions
  missingChartNotesPrerequisites?: boolean
  updateSatisfyMethod?(
    documentationRequirement: DocumentationRequirement,
    satisfyMethod: DocumentationRequirementSatisfyMethod
  ): Promise<void>
  detailed?: boolean
  dmeOrderId: string
  bounceToSection: {
    shouldBounce: boolean
    triggerBounce: () => void
  }
}

const DocumentationRequirementRows = ({
  chartNotes,
  documentationRequirements,
  permissions,
  missingChartNotesPrerequisites,
  updateSatisfyMethod,
  detailed,
  dmeOrderId,
  bounceToSection,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const contextDrivenDocs = isFeatureEnabled("contextDrivenDocs")
  return (
    <div className={cx("well p-3", "wide-fixed-row")}>
      {documentationRequirements.map((documentationRequirement, index) => (
        <div key={documentationRequirement.externalId}>
          {index !== 0 && <hr />}
          <DocumentationRequirementRow
            chartNote={chartNotes.find((cn) =>
              isChartNoteRelatedToDocumentationRequirement(
                documentationRequirement,
                cn,
                contextDrivenDocs
              )
            )}
            dmeOrderId={dmeOrderId}
            documentationRequirement={documentationRequirement}
            permissions={permissions}
            missingChartNotesPrerequisites={missingChartNotesPrerequisites}
            updateSatisfyMethod={updateSatisfyMethod}
            detailed={detailed}
            bounceToSection={bounceToSection}
          />
        </div>
      ))}
    </div>
  )
}
export default DocumentationRequirementRows
