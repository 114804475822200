// @ts-strict-ignore
import React, { useCallback, useState, useEffect } from "react"
import LeftSidebar from "components/SupplierOrganizationDashboard/LeftSidebar"
import { fetchSupplierPermissions } from "./fetchSupplierPermissions"
import { employerPrefix } from "utilities/url"
import { SupplierSelector, Supplier } from "./components/SupplierSelector"
import {
  SupplierSettingsList,
  SupplierPermissions,
} from "./components/SupplierSettingsList"
import { useHistory, Route, useRouteMatch } from "react-router-dom"
import { withBrowserRouter } from "routers/BrowserRouter"

export interface Props {
  supplierOrganizationId: string
  supplierOrganizationPermissions: {
    manageUsers: boolean
  }
  suppliers: Supplier[]
  initialData?: {
    supplierId: string
    supplierPermissions: SupplierPermissions
  }
}

const SupplierOrganizationSettings = ({
  supplierOrganizationId,
  supplierOrganizationPermissions,
  suppliers,
  initialData,
}: Props) => {
  const [supplierPermissions, setSupplierPermissions] = useState<
    SupplierPermissions
  >(initialData?.supplierPermissions)

  const settingsUrlBase = `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/settings`

  const history = useHistory()
  const routeMatch = useRouteMatch(`${settingsUrlBase}/:supplierId?`)

  const usersPath =
    employerPrefix("SupplierOrganization", supplierOrganizationId) + "/users"

  const onSupplierSelected = useCallback(
    ({ supplierId }) => {
      history.push(`${settingsUrlBase}/${supplierId}`)
      setSupplierPermissions(null)
      fetchSupplierPermissions(supplierId).then((response) => {
        setSupplierPermissions(response)
      })
    },
    [history, settingsUrlBase]
  )
  useEffect(() => {
    if (suppliers.length === 1) {
      onSupplierSelected({ supplierId: suppliers[0].id })
    } else if (routeMatch?.params.supplierId) {
      onSupplierSelected({ supplierId: routeMatch?.params.supplierId })
    }
    // Ignoring deps warning because this only should run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Route
      path={`${settingsUrlBase}/:supplierId?`}
      render={({ match }) => {
        return (
          <div className="position-relative">
            <LeftSidebar active={null} />
            <div className="mt-n3 p-4">
              <h1>Settings</h1>
              {supplierOrganizationPermissions.manageUsers && (
                <>
                  <h2>Administration</h2>
                  <ul className="list-unstyled ml-3">
                    <li className="py-2">
                      <a href={usersPath}>Users</a>
                    </li>
                  </ul>
                </>
              )}
              {suppliers.length === 1 && (
                <>
                  <h2>Supplier Settings: {suppliers[0].name}</h2>
                  <SupplierSelector
                    supplierId={match.params.supplierId}
                    suppliers={suppliers}
                    onSupplierSelected={onSupplierSelected}
                  />
                  {match.params.supplierId && supplierPermissions && (
                    <SupplierSettingsList
                      supplierId={match.params.supplierId}
                      supplierPermissions={supplierPermissions}
                    />
                  )}
                </>
              )}
              {suppliers.length > 1 && (
                <>
                  <h2>Supplier Settings: </h2>
                  <SupplierSelector
                    supplierId={match.params.supplierId}
                    suppliers={suppliers}
                    onSupplierSelected={onSupplierSelected}
                  />
                  {match.params.supplierId && supplierPermissions && (
                    <SupplierSettingsList
                      supplierId={match.params.supplierId}
                      supplierPermissions={supplierPermissions}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )
      }}
    ></Route>
  )
}

export default withBrowserRouter(SupplierOrganizationSettings, {})
