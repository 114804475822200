// @ts-strict-ignore
import React, { useState } from "react"
import Confirm from "components/Confirm"
import Icon from "components/Icon"

type Props = {
  archive(): void
}

function ArchiveButton(props: Props) {
  const [disabled, setDisabled] = useState(false)
  const archive = () => {
    props.archive()
    setDisabled(true)
  }

  return (
    <Confirm
      title="Are you sure?"
      confirmText="Yes, remove"
      cancelText="No, cancel"
      render={(confirm) => {
        return (
          <button
            className="btn btn-secondary btn-xs ml-2 color-danger"
            disabled={disabled}
            onClick={confirm(archive)}
          >
            <Icon type="trash" />{" "}
          </button>
        )
      }}
    />
  )
}

export default ArchiveButton
