import React from "react"
import ParachuteMuiDataGridPro from "components/ParachuteMuiDataGrid/ParachuteMuiDataGrid"
import { canopyColorPrimitivesGray97 } from "@parachutehealth/canopy-tokens-color"
import { isTest } from "utilities/environment"
import { GridRowData } from "@mui/x-data-grid-pro"
import { useGraphqDataGrid } from "hooks/useGraphqlDataGrid/useGraphqlDataGrid"
import {
  GetIncomingOrdersQuery,
  IncomingDmeOrderConnection,
} from "graphql/__generated__/graphql"
import {
  ApolloError,
  ApolloQueryResult,
  FetchMoreOptions,
} from "@apollo/client"
import { makeStyles } from "@material-ui/styles"
import { navigate } from "utilities/navigation"
import { incomingOrderColumnDefinitions } from "./tableColumnDefinitions"
import { dmeOrderUrl } from "../urls"
import NoOrders from "./NoOrders"
import { DataGridOptions } from "sharedTypes"

interface Props {
  incomingOrderData: IncomingDmeOrderConnection | undefined
  loading: boolean
  error: ApolloError | undefined
  paginate: (args: FetchMoreOptions) => void
  refetch: (variables?) => Promise<ApolloQueryResult<GetIncomingOrdersQuery>>
  lastAccessedFacilityDashboardUrl: string
  initialDataGridOptions?: Partial<DataGridOptions>
}

const useStyles = makeStyles({
  rowHover: {
    "&:hover": {
      backgroundColor: `${canopyColorPrimitivesGray97} !important`,
      cursor: "pointer",
    },
  },
})

const IncomingOrdersTable: React.FC<Props> = ({
  incomingOrderData,
  loading,
  error,
  paginate,
  refetch,
  lastAccessedFacilityDashboardUrl,
  initialDataGridOptions,
}) => {
  const classes = useStyles()

  const { nodes, pageInfo, totalCount, pageSize, unfilteredCount } =
    incomingOrderData || {}
  const dmeOrders: Readonly<GridRowData[]> = nodes
    ? (nodes as GridRowData[])
    : []

  const { handlePagination, handleSorting, gridOptions } = useGraphqDataGrid(
    refetch,
    paginate,
    pageInfo,
    pageSize,
    initialDataGridOptions
  )

  const noOrdersToReview = !loading && unfilteredCount === 0

  if (noOrdersToReview) {
    return (
      <NoOrders
        lastAccessedFacilityDashboardUrl={lastAccessedFacilityDashboardUrl}
      />
    )
  }

  return (
    <ParachuteMuiDataGridPro
      autoHeight
      columns={incomingOrderColumnDefinitions()}
      disableVirtualization={isTest()}
      disableMultipleSelection
      error={error && "An error occurred. Please try again."}
      getRowClassName={() => classes.rowHover}
      onRowClick={(params) => {
        navigate(dmeOrderUrl(params.row.clinicalFacility.id, params.row.id))
      }}
      getRowId={(row) => row.id}
      loading={loading}
      onPageChange={async (page) => await handlePagination(page)}
      page={gridOptions.page}
      pageSize={pageSize}
      pagination
      paginationMode="server"
      rowCount={totalCount}
      rows={dmeOrders}
      rowsPerPageOptions={[25]}
      sortingMode="server"
      sortModel={gridOptions.sort}
      onSortModelChange={async (sortModel) => await handleSorting(sortModel)}
    />
  )
}

export default IncomingOrdersTable
