import React, { useState } from "react"
import classNames from "classnames"
import Tooltip from "components/Tooltip"
import { SvgIcon } from "@material-ui/core"
import Link from "components/Link"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import SidebarOption from "./components/SidebarOption"
import * as styles from "./index.module.scss"
import { SidebarSuboption } from "components/LeftSidebar/components/SidebarSubOption"
import { SidebarSuboptionCta } from "components/LeftSidebar/components/SidebarSubOptionCta"

export interface Option {
  icon: React.FC<React.ComponentProps<typeof SvgIcon>>
  text: string
  active?: boolean
  href?: string
  tooltip?: string
  count?: number
  badgeEnabled?: boolean
  badgeColor?: string
  pill?: {
    icon?: React.ReactNode
    text: string
    className?: string
  }
  subOptions?: SidebarSuboption[]
  subOptionsExpanded?: boolean
  subOptionCta?: SidebarSuboptionCta
  onClick?: () => void
  multilineText?: boolean
}

interface Props {
  options: Option[]
  fixed?: boolean
  renderExpanded?: boolean
  onToggleExpand?: () => void
  feedbackButton?: React.ReactNode
}

function LeftSidebar({
  options,
  fixed,
  renderExpanded,
  onToggleExpand,
  feedbackButton,
}: Props) {
  const [expanded, setExpanded] = useState(!!renderExpanded)
  const toggleExpanded = () => {
    setExpanded((expanded) => !expanded)
    onToggleExpand && onToggleExpand()
  }
  const sideBarAction = expanded ? "Collapse Sidebar" : "Expand Sidebar"
  return (
    <div
      className={classNames("left-sidebar", "extra-width", {
        expanded,
        "position-fixed": fixed,
      })}
    >
      <div className={styles.flexContainer}>
        <div>
          <Tooltip
            trigger="hover"
            placement={Tooltip.Placement.Right}
            overlay={sideBarAction}
          >
            <div className={styles.menuIconContainer}>
              <Link
                className={styles.menuIcon}
                onClick={toggleExpanded}
                aria-label={sideBarAction}
              >
                {expanded ? (
                  <CanopyIcon name="chevron-left" title="Collapse Sidebar" />
                ) : (
                  <CanopyIcon name="chevron-right" title="Expand Sidebar" />
                )}
              </Link>
            </div>
          </Tooltip>
          {options.map((option: Option) => (
            <SidebarOption key={option.text} expanded={expanded} {...option} />
          ))}
        </div>
        {expanded && feedbackButton}
      </div>
    </div>
  )
}

export default LeftSidebar
