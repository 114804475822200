import React from "react"
import classNames from "classnames"
import * as styles from "./SidebarSubOptionCta.module.scss"
import Link from "components/Link"

export interface SidebarSuboptionCta {
  text: string
  href: string
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void
}

const SidebarSubOptionCta = ({ text, href, onClick }: SidebarSuboptionCta) => {
  return (
    <div className="text-center canopy-pl-1x canopy-mt-2x">
      <Link
        href={href}
        onClick={onClick}
        className={classNames(
          styles.subOptionCta,
          "d-flex align-items-center canopy-ml-4x canopy-pl-4x"
        )}
      >
        <div>{text}</div>
      </Link>
    </div>
  )
}

export default SidebarSubOptionCta
