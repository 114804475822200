import React, { useState } from "react"
import { Button, EmailField, Form, TextField } from "components/form"
import Link from "components/Link"
import Icon from "../../../components/Icon"
import * as styles from "../index.module.scss"
import { isEmailValid } from "utilities/email"
import { ReviewerParams } from "../api"
import { isEmpty } from "utilities/object"
import { FormikErrors } from "formik"

export type Props = {
  description: string
  login: (params: ReviewerParams) => void
  parachuteUserLoginPath: string
  onUserSignInLink?: () => void
  handleBack?: () => void
  reviewerEmail?: string
  reviewerFirstName?: string
  reviewerLastName?: string
}

interface FormValues {
  email?: string
  firstName?: string
  lastName?: string
}

const formErrors = {
  emailMissing: "Email must be filled.",
  emailInvalid: "Email is invalid.",
  firstNameMissing: "First name must be filled.",
  lastNameMissing: "Last name must be filled.",
}

const ReviewerIdentificationForm: React.FC<Props> = ({
  description,
  login,
  parachuteUserLoginPath,
  onUserSignInLink,
  handleBack,
  reviewerEmail,
  reviewerFirstName,
  reviewerLastName,
}: Props) => {
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const validate = ({ email, firstName, lastName }) => {
    const errors: FormikErrors<FormValues> = {}

    if (!hasSubmitted) {
      return errors
    }

    if (!email) {
      errors.email = formErrors.emailMissing
    } else if (!isEmailValid(email)) {
      errors.email = formErrors.emailInvalid
    }
    if (!firstName) {
      errors.firstName = formErrors.firstNameMissing
    }
    if (!lastName) {
      errors.lastName = formErrors.lastNameMissing
    }
    return errors
  }

  const submit = (
    values: {
      email: string
      firstName: string
      lastName: string
    },
    { validateForm }
  ) => {
    if (!hasSubmitted) {
      setHasSubmitted(true)
    }

    return validateForm().then((errors) => {
      if (isEmpty(errors)) {
        return login({
          reviewer: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
          },
        })
      }
    })
  }

  return (
    <>
      {handleBack && (
        <div className="back-button">
          <Link onClick={handleBack}>
            <Icon className="mr-2" type="chevron-left" /> {` Back`}
          </Link>
        </div>
      )}
      <div className={styles.signatureLoginIntro}>
        <h1 className="text-center mb-3">Welcome to Parachute Health</h1>
        <p className="text-center mb-3">{description}</p>
      </div>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={{
          email: reviewerEmail || "",
          firstName: reviewerFirstName || "",
          lastName: reviewerLastName || "",
        }}
      >
        {({ isSubmitting }) => (
          <>
            <EmailField
              name="email"
              label="Email"
              placeholder="Email"
              autoFocus
              disabled={!!reviewerEmail}
            />
            <div className="row">
              <div className="col-md-6">
                <TextField
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                />
              </div>
              <div className="col-md-6">
                <TextField
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <Button
              type="submit"
              className="btn-brand btn-block"
              disabled={isSubmitting}
            >
              View and Sign Documents
            </Button>
          </>
        )}
      </Form>
      <div className="text-center mt-5">
        <span className="mr-1">Already a Parachute Health User?</span>
        <Link
          className="link link-secondary"
          href={parachuteUserLoginPath}
          onClick={() => onUserSignInLink && onUserSignInLink()}
        >
          Sign In
        </Link>
      </div>
    </>
  )
}

export default ReviewerIdentificationForm
