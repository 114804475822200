import React from "react"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import Pages from "../Pages"
import { Page } from "sharedTypes"
import * as styles from "./index.module.scss"

type Props = {
  pages: Page[]
  patientLink: React.ReactElement
  nextPagePath: string
  buttons: React.ReactElement[]
}

function LeftNavigation({ pages, patientLink, nextPagePath, buttons }: Props) {
  const activePage = pages.find((page) => page.active)
  const isPageChecked = activePage && activePage.checked

  return (
    <>
      <div className={styles.nav}>
        {patientLink}
        <Pages pages={pages} removeBottomBorder={true} />
      </div>
      <div className="canopy-mb-8x canopy-ml-8x canopy-mr-8x">
        {nextPagePath && (
          <ContinueButton
            block
            isButtonOutlined={!isPageChecked}
            path={nextPagePath}
          />
        )}
      </div>
      {buttons.map((button, index) => (
        <div
          className="canopy-mb-8x canopy-ml-8x canopy-mr-8x"
          key={`button-${button.props.className}-${index}`}
        >
          {button}
        </div>
      ))}
    </>
  )
}

export default LeftNavigation
