// @ts-strict-ignore
import React from "react"
import { EmailField, TextField, Form, Select } from "components/form"
import Button from "components/form/Button"
import {
  Supplier,
  ReferralFields,
  SalesRep,
  SupplierLegalEntity,
  Branch,
} from "../../sharedTypes"
import { validateReferralForm } from "./utilities"

interface Props {
  suppliers: Supplier[]
  onSubmit(values: ReferralFields): Promise<void>
  onSupplierIdChange(supplierId: string): void
  getSalesReps(term: string): Promise<SalesRep[]>
  getSupplierLegalEntities(term: string): Promise<SupplierLegalEntity[]>
  getBranches(term: string): Promise<Branch[]>
}

const ReferralForm = ({
  suppliers,
  onSubmit,
  onSupplierIdChange,
  getSalesReps,
  getSupplierLegalEntities,
  getBranches,
}: Props) => {
  const supplierOptions = suppliers.map(({ externalId, name }) => ({
    value: externalId,
    label: name,
  }))

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    supplierId: "",
    branchId: "",
    salesRepId: "",
    supplierLegalEntityId: "",
  }

  const submitForm = (values: ReferralFields, { setErrors }) =>
    onSubmit(values).catch(({ errors }) => {
      setErrors(errors)
    })

  const shouldShowBranches = (supplierId: string): boolean => {
    return (
      supplierId &&
      suppliers.find(({ externalId }) => externalId === supplierId)
        ?.acceptanceStrategy === "brightree"
    )
  }

  const validate = (values) => validateReferralForm(values)

  return (
    <>
      <h1 className="mb-5">Invite a referral</h1>
      <Form
        initialValues={initialValues}
        onSubmit={submitForm}
        validate={validate}
      >
        {({ setFieldValue, values, isSubmitting }) => {
          const clearSupplierIdDependentFields = () => {
            setFieldValue("supplierLegalEntityId", "")
            setFieldValue("branchId", "")
            setFieldValue("salesRepId", "")
          }

          return (
            <>
              <EmailField label="Referral email" name="email" autoFocus />
              <div className="row">
                <div className="col-md-6">
                  <TextField label="Referral first name" name="firstName" />
                </div>
                <div className="col-md-6">
                  <TextField label="Referral last name" name="lastName" />
                </div>
              </div>
              <Select
                label="Referring supplier"
                name="supplierId"
                onChange={(supplierId: string) => {
                  onSupplierIdChange(supplierId)
                  clearSupplierIdDependentFields()
                }}
                options={supplierOptions}
              />
              {values.supplierId && (
                <Select
                  key={`supplierLegalEntityId-${values.supplierId}`}
                  label="Legal entity"
                  name="supplierLegalEntityId"
                  fetchOptions={(term: string) =>
                    getSupplierLegalEntities(term).then(
                      (supplierLegalEntities) =>
                        supplierLegalEntities.map(({ name, externalId }) => ({
                          label: name,
                          value: externalId,
                        }))
                    )
                  }
                  minLength={0}
                  isSearchable
                  isClearable
                  openMenuOnFocus
                />
              )}
              {shouldShowBranches(values.supplierId) && (
                <Select
                  key={`branchId-${values.supplierId}`}
                  label="Branch"
                  name="branchId"
                  fetchOptions={(term: string) =>
                    getBranches(term).then((branches) =>
                      branches.map(({ name, id }) => ({
                        label: name,
                        value: id,
                      }))
                    )
                  }
                  minLength={0}
                  isSearchable
                  isClearable
                  openMenuOnFocus
                />
              )}
              {values.supplierId && (
                <Select
                  key={`salesRepId-${values.supplierId}`}
                  label="Territory sales rep"
                  name="salesRepId"
                  fetchOptions={(term: string) =>
                    getSalesReps(term).then((users) =>
                      users.map(({ name, externalId }) => ({
                        label: name,
                        value: externalId,
                      }))
                    )
                  }
                  minLength={0}
                  isSearchable
                  isClearable
                  openMenuOnFocus
                />
              )}
              <Button
                disabled={isSubmitting}
                className="btn-brand btn-block"
                type="submit"
              >
                Submit
              </Button>
            </>
          )
        }}
      </Form>
    </>
  )
}
export default ReferralForm
