import { employerPrefix } from "utilities/url"
import {
  SupplierSettingConfiguration,
  supplierSettingConfigurations,
} from "./supplierSettingConfiguration"
import React from "react"

export type SupplierPermissions = {
  createReports?: boolean
  manageBrightreeAcceptanceStatusMappings?: boolean
  manageCachedO2Recerts?: boolean
  manageDeliveryStatusMappings?: boolean
  manageFulfillmentAgreements?: boolean
  manageInsurance?: boolean
  manageInternalStatuses?: boolean
  manageLegalEntities?: boolean
  manageTeams?: boolean
  manageSalesRepTerritories?: boolean
  managePatientPortalMessageTemplates?: boolean
}

export const SupplierSettingsList = ({
  supplierId,
  supplierPermissions,
}: {
  supplierId: string
  supplierPermissions: SupplierPermissions
}) => {
  const supplierUrlPrefix = supplierId && employerPrefix("Supplier", supplierId)

  return (
    <ul className="list-unstyled ml-3">
      <li key={`${supplierId}-notification-settings`} className="py-2">
        <a href={`${supplierUrlPrefix}/user_preferences/edit`}>
          Notification Settings
        </a>
      </li>
      {supplierSettingConfigurations.map(
        (config: SupplierSettingConfiguration) => {
          if (!supplierPermissions[config.permissionKey]) return null

          return (
            <li key={`${supplierId}-${config.label}`} className="py-2">
              <a href={`${supplierUrlPrefix}/${config.supplierPath}`}>
                {config.label}
              </a>
            </li>
          )
        }
      )}
    </ul>
  )
}
