// @ts-strict-ignore
import React, { useState } from "react"
import Icon from "components/Icon"
import { DmeOrder } from "sharedTypes"
import PatientInfoModal from "./PatientInfoModal"
import {
  isDeliveryInfoComplete,
  isPatientRequiredBillingInfoComplete,
} from "../utilities/validation"
import { fullName } from "utilities/person"
import { formatPhone } from "utilities/phone"
import { formatAddress } from "utilities/address"
import { CanopyButton } from "@parachutehealth/canopy-button"
import cx from "classnames"

type Props = {
  dmeOrder: DmeOrder
  updatePatientInfo(values): Promise<void>
}

function PatientContactsForm({ dmeOrder, updatePatientInfo }: Props) {
  const billingAddress = dmeOrder.addresses.find(
    ({ id }) => id === dmeOrder.billingAddressId
  )
  const isBillingAddressComplete = isDeliveryInfoComplete(
    billingAddress,
    dmeOrder.billingPhoneNumber
  )
  const isPatientInfoComplete =
    isPatientRequiredBillingInfoComplete(dmeOrder) && isBillingAddressComplete
  const [showPatientInfoModal, setShowPatientInfoModal] = useState(false)

  return (
    <div className={cx("wide-fixed-row", "well")}>
      {isPatientInfoComplete ? (
        <>
          <h4>
            {`${fullName(dmeOrder.patient)} (Patient)`}
            <a
              className="link link-primary float-right"
              onClick={() => {
                setShowPatientInfoModal(true)
              }}
            >
              Edit
              <Icon className="ml-2" type="pencil-alt" />
            </a>
          </h4>
          <p>
            {formatAddress(billingAddress)}
            <br /> {formatPhone(dmeOrder.billingPhoneNumber)}
            <br /> {formatPhone(billingAddress?.alternatePhoneNumber)}
            {dmeOrder.patientSnapshot.email && (
              <>
                <br />
                {dmeOrder.patientSnapshot.email}
              </>
            )}
            {dmeOrder.patientSnapshot.preferredLanguage && (
              <>
                <br />
                {dmeOrder.patientSnapshot.preferredLanguage} (Preferred
                Language)
              </>
            )}
          </p>
        </>
      ) : (
        <>
          <h3>Patient Contact Info</h3>
          <label className="col-form-label">Home/Billing Address</label>
          <CanopyButton
            fullWidth
            onClick={() => setShowPatientInfoModal(true)}
            iconStart={
              isBillingAddressComplete ? "house" : "triangle-exclamation"
            }
            variant={isBillingAddressComplete ? "primary" : "danger"}
          >
            {isBillingAddressComplete
              ? "Add Patient's Home/Billing Information"
              : "Incomplete Patient Billing/Contact Information"}
          </CanopyButton>
        </>
      )}
      <PatientInfoModal
        show={showPatientInfoModal}
        onClose={() => setShowPatientInfoModal(false)}
        dmeOrder={dmeOrder}
        update={updatePatientInfo}
        billingAddress={billingAddress}
      />
    </div>
  )
}

export default PatientContactsForm
