// @ts-strict-ignore
const MENTION_TAG = "@"
const LINK_TAG = "^"
const OPEN_BRACKET = "["
const CLOSING_BRACKET = "]"
const OPEN_PARENS = "("
const CLOSING_PARENS = ")"
const TEXT_TYPE = "text"
const MENTION_TYPE = "mention"
const LINK_TYPE = "link"

export function tokenize(input) {
  const tokens = []
  const length = input.length
  let index = 0
  let character = input[0]
  let collect = true
  function advance() {
    index += 1
    character = input[index]
    return character
  }
  function current(tag) {
    return character === tag
  }
  function next(tag) {
    return input[index + 1] === tag
  }
  function push(type, value) {
    if (value) tokens.push({ type, value })
  }
  let type = TEXT_TYPE
  let value = ""
  let parsing = false
  while (index < length) {
    if (current(MENTION_TAG) && next(OPEN_BRACKET)) {
      if (value) {
        push(type, value)
        value = ""
      }
      type = MENTION_TYPE
      collect = false
      advance()
    } else if (current(LINK_TAG) && next(OPEN_BRACKET)) {
      if (value) {
        push(type, value)
        value = ""
      }
      type = LINK_TYPE
      collect = false
      advance()
      collect = true
      advance()
    } else if (current(CLOSING_BRACKET) && next(OPEN_PARENS)) {
      parsing = true
      if (type === LINK_TYPE) {
        value += "|"
      }
      advance()
    } else if (current(OPEN_PARENS) && parsing) {
      collect = true
      advance()
    } else if (current(CLOSING_PARENS) && parsing) {
      push(type, value)
      advance()
      value = ""
      type = TEXT_TYPE
      parsing = false
    } else {
      if (collect) {
        value += character
      }
      advance()
    }
  }
  push(type, value)
  return tokens
}
