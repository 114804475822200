import React, { useEffect, useState } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { RouteComponentProps } from "react-router"
import { getDmeOrder, getWarnings } from "./api"
import { Context, DmeOrder, DmeOrderWarning, EmployerType } from "sharedTypes"
import { withBrowserRouter } from "routers/BrowserRouter"
import WorkflowPage from "./components/WorkflowPage"
import Payment from "./containers/Payment"
import Product from "./containers/Product"
import Delivery from "./containers/Delivery"
import ClinicalFacilityClinical from "./containers/ClinicalFacilityClinical"
import SupplierClinical from "./containers/SupplierClinical"
import ChartNotes from "./containers/ChartNotes"
import QuickOrder from "./containers/QuickOrder"
import Signature from "./containers/Signature"
import Review from "./containers/Review"
import Snapshot from "./containers/Snapshot"
import GlobalContext from "context/Global"
import WebSocketContext from "context/WebSocket"
import { scrollTop } from "utilities/scroll"
import { blur } from "utilities/blur"
import emitter from "utilities/eventEmitter"
import * as navigation from "./utilities/navigation"
import * as routes from "./routes"
import { workflowsUrl } from "./urls"
import WebSocketClient from "services/WebSocketClient"
import { initUpscope } from "services/upscope"
import { SidebarTab } from "./containers/Navigation/sharedTypes"
import { ToastProvider } from "components/Toaster"
import {
  DELIVERY_STEP,
  PAYMENT_STEP,
  PRODUCTS_STEP,
  CLINICAL_STEP,
  SIGNATURE_STEP,
} from "./utilities/steps"
import { FeatureFlagProvider } from "components/FeatureFlagContext"

type Props = {
  context: Context
  dmeOrder: DmeOrder
  warnings: DmeOrderWarning[]
  sidebarTab: SidebarTab
} & RouteComponentProps

const initCobrowsing = (supplierCobrowseKey, currentEmployer) => {
  if (currentEmployer.employerType === EmployerType.ClinicalFacility) {
    initUpscope(supplierCobrowseKey)
  }
}

const Workflow = ({
  dmeOrder: initialDmeOrder,
  warnings: initialWarnings,
  location,
  context,
  history,
  sidebarTab,
}: Props) => {
  const [dmeOrder, setOnlyDmeOrder] = useState(initialDmeOrder)
  const [warnings, setWarnings] = useState(initialWarnings)
  const [currentPath, setCurrentPath] = useState(location.pathname)

  useEffect(() => {
    return history.listen(({ pathname }) => {
      blur()
      if (
        !(pathname + currentPath).includes("patient_preference_configuration")
      ) {
        scrollTop()
      }
      emitter.emit("route:change")
      setCurrentPath(pathname)
    })
  }, [history, currentPath])

  useEffect(() => {
    initCobrowsing(dmeOrder.supplierCobrowseKey, context.currentEmployer)
  }, [dmeOrder.supplierCobrowseKey, context.currentEmployer])

  const refreshSteps = async () => {
    const warnings = await getWarnings()
    setWarnings(warnings)
    return warnings
  }

  const setDmeOrderAndRefreshSteps = (dmeOrder) => {
    setOnlyDmeOrder(dmeOrder)
    initCobrowsing(dmeOrder.supplierCobrowseKey, context.currentEmployer)
    return refreshSteps()
  }

  const refreshDmeOrder = () =>
    getDmeOrder().then((dmeOrder) => setDmeOrderAndRefreshSteps(dmeOrder))

  const refreshDmeOrderOnly = () =>
    getDmeOrder().then((dmeOrder) => setOnlyDmeOrder(dmeOrder))

  const goToNextPage = async () => {
    const warnings = await refreshSteps()
    const nextPagePath = navigation.nextPagePath(
      dmeOrder,
      context,
      warnings,
      location
    )
    await refreshDmeOrderOnly()
    nextPagePath && history.push(nextPagePath)
  }

  const { featureFlags } = context
  const { permissions } = dmeOrder
  const pages = navigation.getPages(dmeOrder, context, warnings, location)
  const nextPagePath = navigation.nextPagePath(
    dmeOrder,
    context,
    warnings,
    location
  )
  const activePage = pages.find((page) => page.active)
  const isPageChecked = activePage && activePage.checked
  const workflowPageProps = {
    nextPagePath,
    permissions,
    pages,
    dmeOrder,
    history,
    sidebarTab,
    location,
    refreshDmeOrder,
    setDmeOrder: setDmeOrderAndRefreshSteps,
    warnings,
  }

  const isOptumFlow = !!(
    featureFlags.stubbedClinicalGuidelines && dmeOrder.optum
  )

  const steps = pages.map((page) => page.id)
  const template = (
    <Switch>
      {steps.includes(PAYMENT_STEP) && (
        <Route
          path={routes.paymentPath.matcher}
          render={(innerProps) => (
            <WorkflowPage
              title="Payment Information"
              subtitle="Patient insurance information will be used to help determine eligibility for the order."
              {...workflowPageProps}
            >
              <Payment
                {...innerProps}
                refreshDmeOrder={refreshDmeOrder}
                dmeOrder={dmeOrder}
                nextPagePath={nextPagePath}
              />
            </WorkflowPage>
          )}
        />
      )}
      {steps.includes(PRODUCTS_STEP) && (
        <Route
          path={routes.productsPath.matcher}
          render={(innerProps) => (
            <WorkflowPage {...workflowPageProps}>
              <Product
                {...innerProps}
                dmeOrder={dmeOrder}
                refreshDmeOrder={refreshDmeOrder}
                currentEmployer={context.currentEmployer}
                goToNextPage={goToNextPage}
              />
            </WorkflowPage>
          )}
        />
      )}
      {steps.includes(DELIVERY_STEP) && (
        <Route
          path={routes.deliveryPath.matcher}
          render={(innerProps) => (
            <WorkflowPage
              title="Addresses &amp; Contacts"
              {...workflowPageProps}
            >
              <Delivery
                {...innerProps}
                dmeOrder={dmeOrder}
                setDmeOrder={setDmeOrderAndRefreshSteps}
                nextPagePath={nextPagePath}
                isPageChecked={isPageChecked}
              />
            </WorkflowPage>
          )}
        />
      )}
      {steps.includes(CLINICAL_STEP) && (
        <Route
          path={routes.chartNotePath.matcher}
          render={(innerProps) => (
            <WorkflowPage {...workflowPageProps}>
              <ChartNotes
                {...innerProps}
                key={innerProps.match.params.faceToFaceId}
                faceToFaceId={innerProps.match.params.faceToFaceId}
                refreshDmeOrder={refreshDmeOrder}
                setDmeOrder={setDmeOrderAndRefreshSteps}
                nextPagePath={nextPagePath}
              />
            </WorkflowPage>
          )}
        />
      )}
      {steps.includes(CLINICAL_STEP) && (
        <Route
          path={routes.clinicalPath.matcher}
          render={(innerProps) =>
            context.currentEmployer.employerType ===
            EmployerType.ClinicalFacility ? (
              <WorkflowPage {...workflowPageProps}>
                <ClinicalFacilityClinical
                  {...innerProps}
                  dmeOrder={dmeOrder}
                  isOptumFlow={isOptumFlow}
                  refreshDmeOrder={refreshDmeOrder}
                  nextPagePath={nextPagePath}
                  isPageChecked={isPageChecked}
                  permissions={permissions}
                  setDmeOrder={setDmeOrderAndRefreshSteps}
                  warnings={warnings}
                />
              </WorkflowPage>
            ) : (
              <WorkflowPage title="Clinical Info" {...workflowPageProps}>
                <SupplierClinical
                  {...innerProps}
                  dmeOrder={dmeOrder}
                  refreshDmeOrder={refreshDmeOrder}
                  nextPagePath={nextPagePath}
                  isPageChecked={isPageChecked}
                  setDmeOrder={setDmeOrderAndRefreshSteps}
                />
              </WorkflowPage>
            )
          }
        />
      )}
      {steps.includes(SIGNATURE_STEP) && (
        <Route
          path={routes.signaturePath.matcher}
          render={(innerProps) => (
            <WorkflowPage title="Signing Clinician" {...workflowPageProps}>
              <Signature
                {...innerProps}
                dmeOrder={dmeOrder}
                nextPagePath={nextPagePath}
                isPageChecked={isPageChecked}
                setDmeOrder={setDmeOrderAndRefreshSteps}
              />
            </WorkflowPage>
          )}
        />
      )}
      <Route
        path={routes.reviewPath.matcher}
        render={(innerProps) => {
          if (dmeOrder.useSnapshot) {
            return (
              <WorkflowPage {...workflowPageProps}>
                <Snapshot />
              </WorkflowPage>
            )
          }
          return (
            <WorkflowPage title="Order Details" {...workflowPageProps}>
              <Review
                {...innerProps}
                dmeOrder={dmeOrder}
                currentEmployer={context.currentEmployer}
                featureFlags={featureFlags}
                requiresTwoStepDownload={context.requiresTwoStepDownload}
                dmeOrderSpecificUser={context.dmeOrderSpecific}
                refreshDmeOrder={refreshDmeOrder}
                warnings={warnings}
                setDmeOrder={setDmeOrderAndRefreshSteps}
                emrSession={context.emrSession}
                isOptumFlow={isOptumFlow}
              />
            </WorkflowPage>
          )
        }}
      />
      <Route
        path={routes.quickOrderPath.matcher}
        render={(innerProps) => {
          if (!dmeOrder.permissions.sendDocuments) {
            return <Redirect to={routes.reviewPath()} />
          }
          return (
            <WorkflowPage
              title="How will you send the documentation?"
              subtitle="To finish up the supplier will need a copy of the order documentation. Choose how you'll send it below."
              {...workflowPageProps}
            >
              <QuickOrder {...innerProps} dmeOrder={dmeOrder} />
            </WorkflowPage>
          )
        }}
      />
      <Redirect
        to={navigation.defaultRedirectPath(
          dmeOrder,
          context,
          warnings,
          location
        )}
      />
    </Switch>
  )

  return (
    <GlobalContext.Provider value={context}>
      <FeatureFlagProvider flags={context.featureFlags}>
        <WebSocketContext.Provider
          value={{ client: new WebSocketClient(context.currentEmployer) }}
        >
          <ToastProvider>{template}</ToastProvider>
        </WebSocketContext.Provider>
      </FeatureFlagProvider>
    </GlobalContext.Provider>
  )
}

export default withBrowserRouter(Workflow, {
  basename: workflowsUrl(),
})
