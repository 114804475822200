import React, { useState } from "react"
import { Form, TextArea } from "components/form"
import Button from "components/form/Button"
import Alert from "components/Alert"
import { Nullable } from "sharedTypes"
import { isCtrlEnter } from "utilities/browser/event"
import { SupplierChatTemplate } from "../types"
import ChatTemplates from "./ChatTemplates"
import autosize from "autosize"

interface Props {
  onSubmit(values: Comment, { resetForm }): void
  chatId: string
  chatTemplates: SupplierChatTemplate[]
  patientMessagingConsentedAt: Nullable<string>
}

const alertContent = (consentedAt): React.ReactNode =>
  consentedAt
    ? "Patient will receive a text message notification."
    : "Patient will receive a text message within 24 hours."

const MessageForm: React.FC<Props> = ({
  onSubmit,
  chatId,
  chatTemplates,
  patientMessagingConsentedAt,
}) => {
  const [textareaEl, setTextareaEl] = useState<Element>()
  return (
    <Form initialValues={{ content: "", chatId: chatId }} onSubmit={onSubmit}>
      {({ isSubmitting, submitForm, values, setFieldValue }) => (
        <>
          <ChatTemplates
            name="portal-chat-templates"
            templates={chatTemplates}
            onClick={(template) => {
              setFieldValue("portal-chat-templates", template.id)
              setFieldValue("content", template.template).then(() => {
                autosize.update(textareaEl)
              })
            }}
          />
          <TextArea
            name="content"
            placeholder="Send a message to the patient"
            autoFocus
            unwrapped
            alert={
              <Alert leftIcon="fa-mobile-alt" status="warning">
                {alertContent(patientMessagingConsentedAt)} Do not post credit
                card info.
              </Alert>
            }
            onKeyDown={(event) => isCtrlEnter(event) && submitForm()}
            innerRef={(node) => setTextareaEl(node)}
          />
          <input type="hidden" name="chatId" value={chatId} />
          <div className="clearfix">
            <Button
              type="submit"
              className="btn-brand px-3 float-right"
              disabled={isSubmitting || !values.content}
            >
              Send
            </Button>
          </div>
          <p className="text-right mt-2 color-dark-gray font-notice">
            or press 'Ctrl+Enter' to post
          </p>
        </>
      )}
    </Form>
  )
}

export default MessageForm
