// @ts-strict-ignore
import React from "react"
import {
  ChartNote,
  DmeOrderPermissions,
  DmeOrderWarning,
  DocumentationRequirement,
  DocumentationRequirementSatisfyMethod,
  PackageConfigurationWarningMetadata,
} from "sharedTypes"
import GenerateOptumButton from "./GenerateOptumButton"
import DocumentationRequirementRow from "./../DocumentationRequirements/DocumentationRequirementRow"
import Alert from "components/Alert"
import { productsPackageConfigurationPath } from "applications/Workflow/routes"
import { Link } from "react-router-dom"
import { Survey } from "components/cms/sharedTypes"
import ClinicalInformationRow from "../DocumentationRequirements/ClinicalInformationRow"
import { isChartNoteRelatedToDocumentationRequirement } from "./../utilities"
import { useFeatureFlags } from "components/FeatureFlagContext"

interface Props {
  chartNotes: ChartNote[]
  className: string
  dmeOrderId: string
  documentationRequirements: DocumentationRequirement[]
  permissions: DmeOrderPermissions
  surveys?: Survey[]
  updateSatisfyMethod(
    documentationRequirement: DocumentationRequirement,
    satisfyMethod: DocumentationRequirementSatisfyMethod
  ): Promise<void>
  warnings: DmeOrderWarning[]
}

const OptumDocumentationRequirements = ({
  chartNotes,
  className,
  dmeOrderId,
  documentationRequirements,
  permissions,
  surveys,
  updateSatisfyMethod,
  warnings,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const contextDrivenDocs = isFeatureEnabled("contextDrivenDocs")
  const demoClinicalGuidelines = isFeatureEnabled("demoClinicalGuidelines") // dmeOrder.optum check not required because OptumDocumentationRequirements is only used for optum orders

  if (!documentationRequirements.length) {
    return <></>
  }

  const packageConfigurationWarning = warnings.find(
    (warning) =>
      warning.key === "rx_details" || warning.key === "product_requirements"
  )

  const renderActionButton = () => {
    return (
      <div className="mt-4">
        <div className="mb-2">
          <GenerateOptumButton chartNotes={chartNotes} />
        </div>
      </div>
    )
  }

  const requirementRows = () => {
    return (
      <>
        <div className="pt-2">
          <p className="color-dark-gray">
            Validate the health plan's medical necessity criteria and
            automatically request their authorization
          </p>
        </div>
        <div className="well p-3">
          {documentationRequirements.map((documentationRequirement, index) => (
            <div key={documentationRequirement.externalId}>
              {index !== 0 && <hr />}
              <DocumentationRequirementRow
                dmeOrderId={dmeOrderId}
                chartNote={chartNotes.find((cn) =>
                  isChartNoteRelatedToDocumentationRequirement(
                    documentationRequirement,
                    cn,
                    contextDrivenDocs
                  )
                )}
                documentationRequirement={documentationRequirement}
                permissions={permissions}
                missingChartNotesPrerequisites={!!packageConfigurationWarning}
                updateSatisfyMethod={updateSatisfyMethod}
                bounceToSection={{
                  shouldBounce: false,
                  triggerBounce: () => {},
                }}
                detailed
              />
            </div>
          ))}
          {!demoClinicalGuidelines &&
            surveys.map((survey) => (
              <div key={survey.externalId}>
                <hr />
                <ClinicalInformationRow
                  showDetails={false}
                  title={survey.name}
                  isDocumentParachuteGenerated={true}
                />
              </div>
            ))}
        </div>
        {renderActionButton()}
      </>
    )
  }

  return (
    <div className={className}>
      <div className="well">
        <div className="row px-3">
          <span className="h3 col-12">
            Get approved by your patient's health plan
          </span>
        </div>
        {packageConfigurationWarning && (
          <Alert status="danger" leftIcon>
            The documentation requirements are disabled because of missing
            details for your product(s).
            <Link
              className="pl-1"
              to={productsPackageConfigurationPath(
                null,
                (packageConfigurationWarning.metadata as PackageConfigurationWarningMetadata)
                  .packageConfigurationId,
                true
              )}
            >
              Click here to add product details
            </Link>
          </Alert>
        )}
        {requirementRows()}
      </div>
    </div>
  )
}

export default OptumDocumentationRequirements
