import React from "react"
import { Button, DatePickerField, Select, Form } from "components/form"
import { isDateInFuture, isDateAfter } from "utilities/date"
import { CumulativeInvoiceFormFields, Supplier } from "../../sharedTypes"
import { ClinicalFacility } from "sharedTypes"

type Props = {
  emailCumulativeReport(values: CumulativeInvoiceFormFields): Promise<void>
  getFacilities(term: string): Promise<ClinicalFacility[]>
  getSuppliers(clinicalFacilityId: string, term: string): Promise<Supplier[]>
}

export default function ClinicalOrganizationCumulativeReport({
  emailCumulativeReport,
  getFacilities,
  getSuppliers,
}: Props) {
  const initialValues = {
    startDate: "",
    endDate: "",
    facilityId: "",
    supplierId: "",
  } as CumulativeInvoiceFormFields
  const validate = ({
    startDate,
    endDate,
    facilityId,
    supplierId,
  }: CumulativeInvoiceFormFields) => {
    const errors: {
      startDate?: string
      endDate?: string
      facilityId?: string
      supplierId?: string
    } = {}
    if (!startDate) {
      errors.startDate = "Start date is missing"
    }
    if (isDateInFuture(startDate)) {
      errors.startDate = "Start date cannot be in the future"
    }
    if (!endDate) {
      errors.endDate = "End date is missing"
    }
    if (isDateInFuture(endDate)) {
      errors.endDate = "End date cannot be in the future"
    }
    if (isDateAfter(startDate, endDate)) {
      errors.endDate = "End date cannot be before start date"
    }
    if (!facilityId) {
      errors.facilityId = "Facility is missing"
    }
    if (!supplierId) {
      errors.supplierId = "Supplier is missing"
    }
    return errors
  }
  return (
    <>
      <p className="mt-4 mb-5">
        The cumulative report will include all invoiced items from the selected
        date range, facility, and supplier.
      </p>
      <Form
        initialValues={initialValues}
        onSubmit={(values: CumulativeInvoiceFormFields) =>
          emailCumulativeReport(values)
        }
        validate={validate}
      >
        {({ setFieldValue, values }) => (
          <>
            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <DatePickerField
                    name="startDate"
                    label="Select Invoice Start Date"
                    unwrapped
                  />
                </div>
                <div className="col-6">
                  <DatePickerField
                    name="endDate"
                    label="Select Invoice End Date"
                    unwrapped
                  />
                </div>
              </div>
            </div>
            <Select
              isSearchable
              name="facilityId"
              label="Select Facility"
              placeholder="Select..."
              isClearable
              openMenuOnFocus
              fetchOptions={(term: string) =>
                getFacilities(term).then((facilities) => {
                  return facilities.map((facility) => ({
                    label: facility.name,
                    value: facility.externalId,
                  }))
                })
              }
              onChange={() => setFieldValue("supplierId", "")}
            />
            {values.facilityId && (
              <Select
                isSearchable
                key={`supplier-select-${values.facilityId}`}
                name="supplierId"
                label="Select Supplier"
                placeholder="Select..."
                isClearable
                openMenuOnFocus
                fetchOptions={(term) =>
                  getSuppliers(values.facilityId, term).then((suppliers) => {
                    return suppliers.map((supplier) => ({
                      label: supplier.name,
                      value: supplier.externalId,
                    }))
                  })
                }
              />
            )}
            <Button type="submit" className="btn-brand">
              Email Cumulative Report
            </Button>
          </>
        )}
      </Form>
    </>
  )
}
