import React, { useState } from "react"
import InternalFollowers from "./components/InternalFollowers"
import SpinIcon from "components/Icon/SpinIcon"
import { getFollowers } from "./api"
import { DmeOrder } from "sharedTypes"
import { UpdateFollowersParams } from "../../sharedTypes"
import { convertToGroupOption } from "./components/utilities/followers"
import { GroupedOptions } from "components/form/Select"

type Props = {
  dmeOrder: DmeOrder
  editable: boolean
  updateFollowers(params: UpdateFollowersParams): Promise<void>
  onChange(): void
}

const Followers: React.FC<Props> = ({
  dmeOrder,
  editable,
  updateFollowers,
  onChange,
}) => {
  const [loading, setLoading] = useState(false)

  const updateFollowersHandler = (params: UpdateFollowersParams) => {
    setLoading(true)
    return updateFollowers(params).then(() => {
      onChange()
      setLoading(false)
    })
  }

  const searchFollowers = (term: string): Promise<GroupedOptions[]> => {
    return getFollowers(term).then((response) =>
      response.map(convertToGroupOption)
    )
  }

  if (loading) {
    return (
      <div className="text-center">
        <SpinIcon />
      </div>
    )
  }

  return (
    <InternalFollowers
      editable={editable}
      dmeOrder={dmeOrder}
      getFollowers={searchFollowers}
      updateFollowers={updateFollowersHandler}
    />
  )
}

export default Followers
