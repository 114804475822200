import React from "react"
import Icon from "components/Icon"

function ResetButton({ onClick }) {
  return (
    <a className="link link-understated font-xs ml-3" onClick={onClick}>
      <Icon className="mr-1" type="redo-alt" flip="horizontal" />
      Reset View
    </a>
  )
}

export default ResetButton
