import React from "react"
import InternalTwoFactorAuthentication from "./components/InternalTwoFactorAuthentication"
import * as api from "./api"

interface Props {
  redirectUrl?: string
}

const TwoFactorAuthentication = ({ redirectUrl }: Props) => {
  return (
    <InternalTwoFactorAuthentication
      login={api.login}
      redirectUrl={redirectUrl}
    />
  )
}

export default TwoFactorAuthentication
