import React from "react"
import { Checkboxes, TextArea } from "components/form"
import { titleWithCategory } from "../../utilities/documentationRequirements"
import { DocumentationRequirement } from "sharedTypes"

interface Props {
  requirements: DocumentationRequirement[]
}

const OTHER_VALUE = "other"

const ManualRequirementsSection = ({ requirements }: Props) => {
  const options = [
    ...(requirements || []).map((requirement) => ({
      label: titleWithCategory(requirement),
      value: requirement.externalId,
    })),
    {
      label: "Other",
      value: OTHER_VALUE,
      addOnPosition: "inside",
      addOn: ({ field }) => {
        if (!field.value.includes(OTHER_VALUE)) {
          return
        }
        return (
          <div className="mt-2">
            <TextArea
              autoFocus
              name="otherAnswer"
              placeholder="Enter document name"
              unwrapped
            />
          </div>
        )
      },
    },
  ]

  return (
    <>
      <div className="row mb-3">
        <div className="col-1">
          <i className="far fa-file-alt color-dark-gray" />
        </div>
        <div className="col-11 pl-0">
          <span className="color-dark-gray">EMR Documents</span>
        </div>
      </div>
      <div className="row">
        <div className="offset-1 col-11 pl-0">
          <Checkboxes
            unframed
            outdented
            id="documentationRequirementIds"
            name="documentationRequirementIds"
            options={options}
          />
        </div>
      </div>
    </>
  )
}

ManualRequirementsSection.OTHER = OTHER_VALUE

export default ManualRequirementsSection
