import { DocumentationRequirement } from "sharedTypes"

export const requestedDocumentationRequirements = (
  documentationRequirements: DocumentationRequirement[]
) => documentationRequirements.filter((dr) => dr.requested)

export const titleWithCategory = (documentationRequirement: {
  categoryName?: string | null
  usageContextTitle?: string | null
  title: string
}): string => {
  const parenthetical =
    documentationRequirement.usageContextTitle ||
    documentationRequirement.categoryName

  if (!parenthetical) {
    return documentationRequirement.title
  }

  return `${documentationRequirement.title} (${parenthetical})`
}
