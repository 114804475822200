import React from "react"
import { Form } from "components/form"
import Button from "components/form/Button"
import letter from "letter-warning.png"

interface Props {
  resendInvitation(): Promise<void>
}

function ExpiredToken({ resendInvitation }: Props) {
  return (
    <div className="text-center">
      <div className="my-5">
        <img src={letter} width="220" />
      </div>
      <div className="text-xs-left">
        <h1>Invitation link expired</h1>
        <p>
          Your account creation invitation link has expired. Resend the
          invitation link to receive a new email and complete your account
          creation.
        </p>
      </div>
      <Form onSubmit={resendInvitation}>
        {({ isSubmitting }) => (
          <Button
            className="btn-brand btn-block mt-5"
            type="submit"
            disabled={isSubmitting}
          >
            Resend Invitation Link
          </Button>
        )}
      </Form>
    </div>
  )
}

export default ExpiredToken
