// @ts-strict-ignore
import React from "react"
import {
  DmeOrderWarning,
  Employer,
  NotAddablePackageConfigurationWarningMetadata,
  PackageConfigurationWarningMetadata,
  PatientPreferenceWarningMetadata,
  RejectedSupplierWarningMetadata,
  InsuranceWarningMetadata,
  InsuranceRank,
} from "sharedTypes"
import Warning from "./Warning"
import SignatureWarning from "./SignatureWarning"
import BasicSupplierWarning from "applications/Workflow/components/BasicSupplierWarning"
import { Permissions } from "../../sharedTypes"
import { reportError } from "utilities/error"
import * as routes from "applications/Workflow/routes"
import {
  stepPath,
  defaultPath,
} from "applications/Workflow/utilities/navigation"
import WarningTracker from "./WarningTracker"
import { getWarningText } from "applications/Workflow/utilities/warningText"
import cx from "classnames"

interface Props {
  warnings: DmeOrderWarning[]
  permissions: Permissions
  currentEmployer: Employer
  trackEvents: boolean
  submissionErrorMessage?: string
}

interface RendererProps {
  warning: DmeOrderWarning
  permissions: Permissions
  currentEmployer: Employer
  trackEvents: boolean
}

const genericWarning = (
  warning: DmeOrderWarning,
  permissions: { update: boolean },
  trackEvents: boolean
) => (
  <Warning
    editable={permissions.update}
    warningType={warning.key}
    severity={warning.severity}
    text={warning.text}
    to={stepPath(warning.step) || defaultPath}
    trackEvents={trackEvents}
  />
)

const incompletePreauthorization = (warning, permissions, trackEvents) => {
  return (
    <Warning
      warningType={warning.key}
      editable={permissions.update}
      severity={warning.severity}
      text={warning.text}
      to={routes.paymentPath(null, "preauthorization")}
      trackEvents={trackEvents}
    />
  )
}

const insurancePolicyWarningHandling = (
  rank: InsuranceRank,
  warning,
  permissions,
  trackEvents
) => {
  const { errorType } = warning.metadata as InsuranceWarningMetadata
  return (
    <Warning
      warningType={warning.key}
      editable={permissions.update}
      severity={warning.severity}
      text={warning.text}
      to={routes.paymentPath(rank, errorType)}
      trackEvents={trackEvents}
    />
  )
}

const renderers = {
  product_requirements({ warning, permissions, trackEvents }: RendererProps) {
    const {
      packageConfigurationId,
    } = warning.metadata as PackageConfigurationWarningMetadata
    return (
      <Warning
        editable={permissions.update}
        warningType={warning.key}
        severity={warning.severity}
        text={warning.text}
        to={routes.productsPackageConfigurationPath(
          null,
          packageConfigurationId,
          true
        )}
        trackEvents={trackEvents}
      />
    )
  },
  rx_details({ warning, permissions, trackEvents }: RendererProps) {
    const {
      packageConfigurationId,
    } = warning.metadata as PackageConfigurationWarningMetadata
    return (
      <Warning
        editable={permissions.update}
        warningType={warning.key}
        severity={warning.severity}
        text={warning.text}
        to={routes.productsPackageConfigurationPath(
          null,
          packageConfigurationId,
          true
        )}
        trackEvents={trackEvents}
      />
    )
  },
  not_addable_package_configuration({
    warning,
    permissions,
    trackEvents,
  }: RendererProps) {
    const {
      packageConfigurationId,
    } = warning.metadata as NotAddablePackageConfigurationWarningMetadata
    return (
      <Warning
        editable={permissions.update}
        warningType={warning.key}
        severity={warning.severity}
        text={warning.text}
        to={routes.productsPackageConfigurationPath(
          null,
          packageConfigurationId,
          false
        )}
        trackEvents={trackEvents}
      />
    )
  },
  supplier_signature({ warning, trackEvents }: RendererProps) {
    return <SignatureWarning warning={warning} trackEvents={trackEvents} />
  },
  manager_approval({ warning, trackEvents }: RendererProps) {
    return (
      <Warning
        warningType={warning.key}
        severity={warning.severity}
        text={warning.text}
        title="Manager Approval Required"
        trackEvents={trackEvents}
      />
    )
  },
  basic_supplier({ warning, trackEvents }: RendererProps) {
    return (
      <WarningTracker warningType={warning.key} trackEvents={trackEvents}>
        <BasicSupplierWarning warning={warning} />
      </WarningTracker>
    )
  },
  patient_preference({ warning, permissions, trackEvents }: RendererProps) {
    const {
      lineItemGroupId,
    } = warning.metadata as PatientPreferenceWarningMetadata
    return (
      <Warning
        editable={permissions.update}
        warningType={warning.key}
        severity={warning.severity}
        text={warning.text}
        to={routes.patientPreferenceConfigurationPath(lineItemGroupId)}
        trackEvents={trackEvents}
      />
    )
  },
  pecos_certification({ warning, trackEvents }: RendererProps) {
    return (
      <Warning
        warningType={warning.key}
        severity={warning.severity}
        text={warning.text}
        trackEvents={trackEvents}
      />
    )
  },
  proof_of_delivery({ warning, trackEvents }: RendererProps) {
    return (
      <Warning
        warningType={warning.key}
        severity={warning.severity}
        title="Consignment reminder"
        text={warning.text}
        trackEvents={trackEvents}
      />
    )
  },
  rejected_supplier({ warning, permissions, trackEvents }: RendererProps) {
    const {
      packageConfigurationId,
    } = warning.metadata as RejectedSupplierWarningMetadata
    return (
      <Warning
        editable={permissions.update}
        warningType={warning.key}
        severity={warning.severity}
        text={warning.text}
        to={routes.productsPackageConfigurationPath(
          null,
          packageConfigurationId,
          false
        )}
        trackEvents={trackEvents}
      />
    )
  },
  medicare_signing_ability_unknown({ warning, trackEvents }: RendererProps) {
    return (
      <Warning
        warningType={warning.key}
        severity={warning.severity}
        text={warning.text}
        trackEvents={trackEvents}
      />
    )
  },
  supplier_system_clinical_facility_id({
    warning,
    permissions,
    trackEvents,
  }: RendererProps) {
    return (
      <Warning
        warningType={warning.key}
        editable={permissions.update}
        severity={warning.severity}
        text={warning.text}
        to={routes.supplierSystemClinicalFacilityExternalSystemIdModalPath()}
        trackEvents={trackEvents}
      />
    )
  },
  carrier_authorization_number({
    warning,
    permissions,
    trackEvents,
  }: RendererProps) {
    return incompletePreauthorization(warning, permissions, trackEvents)
  },
  primary_insurance_policy({
    warning,
    permissions,
    trackEvents,
  }: RendererProps) {
    return insurancePolicyWarningHandling(
      "primary",
      warning,
      permissions,
      trackEvents
    )
  },
  secondary_insurance_policy({
    warning,
    permissions,
    trackEvents,
  }: RendererProps) {
    return insurancePolicyWarningHandling(
      "secondary",
      warning,
      permissions,
      trackEvents
    )
  },
  tertiary_insurance_policy({
    warning,
    permissions,
    trackEvents,
  }: RendererProps) {
    return insurancePolicyWarningHandling(
      "tertiary",
      warning,
      permissions,
      trackEvents
    )
  },
}

const InternalWarning = ({
  warning,
  permissions,
  currentEmployer,
  trackEvents,
}: RendererProps) => {
  const render = renderers[warning.key]

  if (render) {
    return render({
      warning,
      permissions,
      currentEmployer,
      trackEvents,
    })
  }

  if (!warning.text) {
    reportError(new Error(`Unknown DmeOrderWarning type: ${warning.key}`))

    return (
      <Warning
        editable={false}
        warningType={warning.key}
        severity={warning.severity}
        text={`Error with this order: ${warning.key}`}
        to={stepPath(warning.step) || defaultPath}
        trackEvents={trackEvents}
      />
    )
  }

  return genericWarning(warning, permissions, trackEvents)
}

export default ({
  warnings,
  permissions,
  currentEmployer,
  trackEvents,
  submissionErrorMessage,
}: Props) => {
  return (
    <>
      {warnings.length > 0 && (
        <div className={cx("wide-fixed-row")}>
          {warnings.map((warning) => {
            const warningWithText = {
              ...warning,
              text: getWarningText(warning, currentEmployer),
            }
            return (
              <InternalWarning
                key={warning.key}
                warning={warningWithText}
                permissions={permissions}
                currentEmployer={currentEmployer}
                trackEvents={trackEvents}
              />
            )
          })}
        </div>
      )}
      {submissionErrorMessage && (
        <Warning
          severity="error"
          title="Error submitting"
          text={submissionErrorMessage}
          warningType="submission_error"
          trackEvents={trackEvents}
        />
      )}
    </>
  )
}
