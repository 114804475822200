import React from "react"
import SelectFilter from "components/SelectFilter"

export const PatientActivityFilter = (props: {
  patientActivity: string[] | undefined
  onChange: (params: { patientActivity: string[] }) => void
}) => {
  return (
    <SelectFilter
      onChange={(values) => {
        props.onChange(values)
      }}
      options={[
        { label: "Not Contacted", value: "Not Contacted" },
        { label: "Patient Contacted", value: "Patient Contacted" },
        { label: "Clinician Confirmed", value: "Clinician Confirmed" },
        { label: "Wrong Clinician", value: "Wrong Clinician" },
      ]}
      name="patientActivity"
      label="Patient Activity"
      singular={true}
      initialValues={props.patientActivity}
    />
  )
}
