import React, { useState } from "react"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import Icd10Codes from "applications/Workflow/containers/Icd10Codes"
import { DiagnosisHook } from "applications/Workflow/containers/Icd10Codes/hooks/useDiagnosis"
import DocumentationRequirements from "./DocumentationRequirements"
import {
  DmeOrder,
  DmeOrderPermissions,
  DmeOrderWarning,
  DocumentationRequirement,
  DocumentationRequirementSatisfyMethod,
  DocumentationRequirementSatisfyStatus,
} from "sharedTypes"
import { LEGAL_WARNING } from "../utilities"
import { handleError } from "utilities/error"
import { scrollTo } from "utilities/scroll"
import { requestedDocumentationRequirements } from "applications/Workflow/utilities/documentationRequirements"
import OptumDocumentationRequirements from "./OptumDocumentationRequirements"
import {
  hasError,
  hasWarning,
} from "../../Icd10Codes/utilities/warningSeverity"
import cx from "classnames"
import WorkflowHeader from "applications/Workflow/components/WorkflowHeader"
import { useFeatureFlags } from "../../../../../components/FeatureFlagContext"

interface Props {
  dmeOrder: DmeOrder
  diagnosisHook: DiagnosisHook
  permissions: DmeOrderPermissions
  nextPagePath: string | undefined
  isPageChecked?: boolean
  updateSatisfyMethod(
    documentationRequirement: DocumentationRequirement,
    satisfyMethod: DocumentationRequirementSatisfyMethod
  ): Promise<void>
  uploadDocuments(
    documentationRequirementIds: string[],
    files: File[],
    signed: boolean,
    otherAttachmentTitle: string
  ): Promise<void>
  updateDocumentationRequirementsSatisfyStatus(values: {
    manualSatisfyStatus: DocumentationRequirementSatisfyStatus
    documentationRequirementIds: string[]
  }): Promise<void>
  warnings: DmeOrderWarning[]
  isOptumFlow: boolean
}

const InternalClinical = (props: Props) => {
  const {
    dmeOrder,
    permissions,
    updateSatisfyMethod,
    diagnosisHook,
    nextPagePath,
    isPageChecked,
    warnings,
    uploadDocuments,
    updateDocumentationRequirementsSatisfyStatus,
    isOptumFlow,
  } = props

  const uploadDocumentsCallback = (
    documentationRequirementIds,
    files,
    signed,
    otherAttachmentTitle
  ) => {
    return uploadDocuments(
      documentationRequirementIds,
      files,
      signed,
      otherAttachmentTitle
    ).catch((payload) => {
      handleError(payload)
    })
  }

  const { isFeatureEnabled } = useFeatureFlags()
  const demoClinicalGuidelines =
    isFeatureEnabled("demoClinicalGuidelines") && dmeOrder.optum

  const [triggerIcd10SelectionAlert, setTriggerIcd10SelectionAlert] = useState<
    boolean
  >(false)
  const { diagnosis: { warnings: diagnosisWarnings = [] } = {} } = diagnosisHook

  const renderDocumentationRequirements = () => {
    if (
      dmeOrder.documentationRequirements.length > 0 &&
      !isOptumFlow &&
      !demoClinicalGuidelines
    ) {
      const pendingWarnings = diagnosisWarnings
        ?.map((diagnosisWarnings) => diagnosisWarnings.warnings)
        ?.filter((w) => hasError(w) || hasWarning(w))
      const bounceToIcd10SelectionSection = {
        shouldBounce: Boolean(pendingWarnings.length),
        triggerBounce: () => {
          const diagnosisSection = document.querySelector(
            ".icd10-selection-section"
          )
          scrollTo(diagnosisSection, -100)
          setTriggerIcd10SelectionAlert(true)
        },
      }
      return (
        <DocumentationRequirements
          chartNotes={dmeOrder.chartNotes}
          className={cx("mt-3", "wide-fixed-row")}
          dmeOrderId={dmeOrder.id}
          documentationRequirements={requestedDocumentationRequirements(
            dmeOrder.documentationRequirements
          )}
          hasSigningClinician={!!dmeOrder.doctor}
          permissions={permissions}
          supplierFaxNumber={dmeOrder.supplierFaxNumber}
          updateSatisfyMethod={updateSatisfyMethod}
          uploadDocuments={uploadDocumentsCallback}
          warnings={warnings}
          updateDocumentationRequirementsSatisfyStatus={
            updateDocumentationRequirementsSatisfyStatus
          }
          bounceToSection={bounceToIcd10SelectionSection}
        />
      )
    } else {
      return (
        <OptumDocumentationRequirements
          chartNotes={dmeOrder.chartNotes}
          className={cx("mt-3", "wide-fixed-row")}
          dmeOrderId={dmeOrder.id}
          documentationRequirements={requestedDocumentationRequirements(
            dmeOrder.documentationRequirements
          )}
          permissions={permissions}
          surveys={dmeOrder.qualificationSurveys}
          updateSatisfyMethod={updateSatisfyMethod}
          warnings={warnings}
        />
      )
    }
  }

  return (
    <>
      <WorkflowHeader
        title="Clinical Info"
        subtitle={
          isOptumFlow || demoClinicalGuidelines
            ? "Enter relevant clinical details to support payor authorization."
            : "Select relevant diagnoses and complete the corresponding required documents."
        }
      />
      <Icd10Codes
        className={cx("well icd10-selection-section", "wide-fixed-row")}
        diagnosisHook={diagnosisHook}
        label="Diagnosis Codes"
        triggerDiagnosisSelectionAlert={{
          showAlert: triggerIcd10SelectionAlert,
          setShowAlert: (triggerAlert) =>
            setTriggerIcd10SelectionAlert(triggerAlert),
        }}
      />
      {renderDocumentationRequirements()}
      {permissions.updateChartNotes && (
        <p
          className={cx(
            "font-notice",
            "color-dark-gray",
            "wide-fixed-row",
            "pl-0"
          )}
        >
          {LEGAL_WARNING}
        </p>
      )}
      {nextPagePath && isPageChecked && (
        <div className={cx("wide-fixed-row", "text-right")}>
          <ContinueButton path={nextPagePath} />
        </div>
      )}
    </>
  )
}

export default InternalClinical
