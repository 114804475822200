import React from "react"
import Alert from "components/Alert"
import LearnMore from "./LearnMore"
import PatientLoginStatus from "./PatientLoginStatus"
import LinkComponent from "components/Link"
import * as routes from "applications/Workflow/routes"
import { Link } from "react-router-dom"
import { Nullable } from "sharedTypes"
import { formatPhone, normalizePhone } from "utilities/phone"
import cx from "classnames"

type Props = {
  phoneNumber: string
  phoneCanReceiveSms: boolean
  latestMessageScheduledAt: Nullable<Date>
  globalSupplierEnabled: boolean
  globalFacilityEnabled: boolean
  facilityEnabled: boolean
  supplierControlPanelUrl: string
  orderStatus: string
  lastPatientLoginAt: Nullable<Date>
  lastFailedLoginAt: Nullable<Date>
  lastAccessEventType: Nullable<string>
  phoneNumberRejectedAt: Nullable<string>
  optedOutAt: Nullable<string>
}

const SupplierPatientPortal: React.FC<Props> = ({
  phoneNumber,
  phoneCanReceiveSms,
  latestMessageScheduledAt,
  globalSupplierEnabled,
  globalFacilityEnabled,
  facilityEnabled,
  supplierControlPanelUrl,
  orderStatus,
  lastPatientLoginAt,
  lastFailedLoginAt,
  lastAccessEventType,
  phoneNumberRejectedAt,
  optedOutAt,
}) => {
  const controlPanelLink = (
    <LinkComponent
      href={supplierControlPanelUrl}
      target="_blank"
      className="color-brand mr-4"
    >
      View Control Panel
    </LinkComponent>
  )

  if (latestMessageScheduledAt) {
    const invited = latestMessageScheduledAt < new Date()
    if (globalSupplierEnabled) {
      return (
        <div className={cx("wide-fixed-row", "pb-3")}>
          <div className="card">
            <div className="flex">
              <h3 className="d-inline">
                Order tracker {invited ? "has been" : "will be"} shared with the
                patient
              </h3>
            </div>
            <div className="mt-3">
              <p>
                An SMS with a link to the order tracker{" "}
                {invited ? "has been" : "will be"} sent to{" "}
                <strong>{formatPhone(normalizePhone(phoneNumber))}</strong>{" "}
              </p>
              {invited && (
                <PatientLoginStatus
                  lastPatientLoginAt={lastPatientLoginAt}
                  lastFailedLoginAt={lastFailedLoginAt}
                  lastAccessEventType={lastAccessEventType}
                  phoneNumberRejectedAt={phoneNumberRejectedAt}
                  optedOutAt={optedOutAt}
                />
              )}
              {globalSupplierEnabled && controlPanelLink}
              <LearnMore />
            </div>
          </div>
        </div>
      )
    } else if (globalFacilityEnabled) {
      return (
        <div className={cx("wide-fixed-row", "pb-3")}>
          <div className="card">
            <div className="flex">
              <h3 className="d-inline">
                Order tracker {invited ? "has been" : "will be"} shared with the
                patient
              </h3>
            </div>
            <div className="mt-3">
              <p>
                The facility has elected to keep the patient in the loop by
                enabling order tracking. An SMS with a link to the order tracker{" "}
                {invited ? "has been" : "will be"} sent to{" "}
                <strong>{formatPhone(normalizePhone(phoneNumber))}</strong>. The
                patient can use the link to view their order details, and once
                accepted, supplier name.
              </p>
              {invited && (
                <PatientLoginStatus
                  lastPatientLoginAt={lastPatientLoginAt}
                  lastFailedLoginAt={lastFailedLoginAt}
                  lastAccessEventType={lastAccessEventType}
                  phoneNumberRejectedAt={phoneNumberRejectedAt}
                  optedOutAt={optedOutAt}
                />
              )}
              {globalSupplierEnabled && controlPanelLink}
              <LearnMore />
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  } else if (globalFacilityEnabled && !facilityEnabled) {
    return (
      <div className={cx("wide-fixed-row", "pb-3")}>
        <div className="card">
          <div className="flex">
            The facility has disabled the Order Tracker for this order.
          </div>
        </div>
      </div>
    )
  } else if (globalSupplierEnabled) {
    return (
      <div className={cx("wide-fixed-row", "pb-3")}>
        <div className="card">
          <div className="flex">
            <h3 className="d-inline">Share Order Tracker with the Patient</h3>
          </div>
          <div className="mt-3">
            <p>
              Keep your patient in the loop with the Parachute Order Tracker.
              Use the link below to preview or share with your patient.
            </p>
            {controlPanelLink}
          </div>
          {!phoneCanReceiveSms && (
            <Alert leftIcon bordered status="info">
              <strong>Action Recommended!</strong> To enable this feature the
              patient&#39;s primary phone number should be mobile.{" "}
              {orderStatus === "supplier_review" && (
                <>
                  <Link to={routes.deliveryPath()}>Add a mobile number</Link> as
                  the patient&#39;s primary phone number.
                </>
              )}
            </Alert>
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default SupplierPatientPortal
