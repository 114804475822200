import React, { useState } from "react"
import { Button, Form, TextField } from "components/form"
import { optOutOfEmailNotifications } from "./api"
import { handleError } from "utilities/error"
import Alert from "components/Alert"

interface Props {
  reviewerId: string
  manageNotificationPreferencesPath: string
}

const ReviewerManageNotificationsForm = (props: Props) => {
  const [showOptOutConfirm, setShowOptOutConfirm] = useState(false)
  const [
    showKeepNotificationsConfirm,
    setShowKeepNotificationsConfirm,
  ] = useState(false)
  const onOptOut = ({ reason }) => {
    optOutOfEmailNotifications(props.reviewerId, false, reason)
      .then(() => setShowOptOutConfirm(true))
      .catch(() => handleError())
  }

  const initialValues = {
    reason: "",
  }

  const renderOptOutWarning = () => (
    <>
      <Alert className="mt-4" status="warning">
        If you are the only reviewer for a doctor, future orders will be sent to
        that doctor via fax instead of email.
      </Alert>
    </>
  )

  const renderSupportParagraph = () => (
    <>
      <small>
        If you would like to continue receiving signature requests, but not
        receive notifications, please contact us at{" "}
        <a className="underline" href="mailto:support@parachutehealth.com">
          support@parachutehealth.com
        </a>
        .
      </small>
    </>
  )

  const renderForm = () => (
    <Form initialValues={initialValues} onSubmit={onOptOut}>
      <div className="text-center">
        <h1> Opt-out of email notifications </h1>
      </div>
      {renderOptOutWarning()}
      <div className="mt-3">
        <TextField label="Opt-out Reason" subtext="Optional" name="reason" />
      </div>
      <Button
        className="btn-brand-o w-100-p"
        onClick={() => setShowKeepNotificationsConfirm(true)}
      >
        Keep receiving emails for signature requests
      </Button>
      <Button type="submit" className="mt-3 btn-brand w-100-p">
        Opt out of emails for signature requests
      </Button>
      <div className="mt-5">{renderSupportParagraph()}</div>
    </Form>
  )

  const renderOptOutConfirm = () => (
    <Form>
      <>
        <div className="text-center">
          <h1> Thanks for your submission! </h1>
          <Alert status="success" className="mt-5 text-left">
            <p>
              <strong>Opt-out successful!</strong> You will no longer receive
              email notifications for signature requests. Navigate to your user
              preferences{" "}
              <a
                className="link"
                href={props.manageNotificationPreferencesPath}
              >
                here
              </a>{" "}
              if you need to make changes.
            </p>
          </Alert>
        </div>
      </>
    </Form>
  )

  const renderKeepNotificationsConfirm = () => (
    <Form>
      <>
        <div className="text-center">
          <h1> Thanks for your submission! </h1>
          <Alert status="success" className="mt-5 text-left">
            <p>
              <strong>Thanks!</strong> You will continue to receive email
              notifications for signature requests. Navigate to your user
              preferences{" "}
              <a
                className="link"
                href={props.manageNotificationPreferencesPath}
              >
                here
              </a>{" "}
              if you need to make changes.
            </p>
          </Alert>
        </div>
      </>
    </Form>
  )

  if (showOptOutConfirm) return renderOptOutConfirm()
  else if (showKeepNotificationsConfirm) return renderKeepNotificationsConfirm()
  else return renderForm()
}

export default ReviewerManageNotificationsForm
