import React from "react"
import classNames from "classnames"

type Props = {
  checked: boolean
  enabled: boolean
  toggle(): void
  name: string
  nowrap?: boolean
}

const Checkbox: React.FunctionComponent<Props> = ({
  checked,
  enabled,
  toggle,
  name,
  nowrap,
}) => {
  if (enabled) {
    const id = `cb-${name.replace(/\s+/g, "-")}`
    return (
      <div className="checkbox primary block mt-0">
        <input type="checkbox" id={id} checked={checked} onChange={toggle} />
        <label className={classNames({ nowrap })} htmlFor={id}>
          {name}
        </label>
      </div>
    )
  }
  return null
}

export default Checkbox
