import React from "react"
import { DmeOrderFulfillment } from "../../../../../../../graphql/__generated__/graphql"
import formatDate from "date-fns/format"
import { cityLine } from "../../../../../../../utilities/address"
import { formatPhone } from "../../../../../../../utilities/phone"

interface DeliveryDetailsProps {
  deliveryFulfillment: DmeOrderFulfillment
}

export const DeliveryDetails = ({
  deliveryFulfillment,
}: DeliveryDetailsProps) => {
  const {
    deliveryAddress,
    requestedDeliveryDate,
    deliveryStatus,
  } = deliveryFulfillment

  return (
    <div>
      <p>
        {formatDate(requestedDeliveryDate, "MM/DD/YYYY")} (
        {deliveryStatus || "Requested"})
      </p>
      {deliveryAddress && (
        <address className="canopy-mt-10x">
          {[
            deliveryAddress.line1,
            deliveryAddress.line2,
            cityLine(deliveryAddress),
            deliveryAddress.phone ? formatPhone(deliveryAddress.phone) : "",
          ]
            .filter((l) => !!l)
            .map((l) => (
              <React.Fragment key={l}>
                {l}
                <br />
              </React.Fragment>
            ))}
        </address>
      )}
    </div>
  )
}
