import React from "react"
import { CarrierAuthorization, Context } from "sharedTypes"
import Section from "components/Section"
import { fullName } from "utilities/person"
import { capitalize } from "utilities/string"
import { Form, TextArea } from "components/form"
import MemberSectionContent from "./MemberSectionContent"
import LineItemAuthorizationSectionContent from "./LineItemAuthorizationSectionContent"
import CoverageSectionContent from "./CoverageSectionContent"
import DiagnosesSectionContent from "./DiagnosesSectionContent"
import ProviderSectionContent from "./ProviderSectionContent"
import LinkedOrdersSectionContent from "./LinkedOrdersSectionContent"
import { carrierAuthorizationDashboardUrl } from "../urls"

type Props = {
  carrierAuthorization: CarrierAuthorization
  context: Context
  updateCarrierAuthorization({ internalNotes }: { internalNotes: string }): void
}

function InternalCarrierAuthorization({
  carrierAuthorization,
  context,
  updateCarrierAuthorization,
}: Props) {
  const {
    parentAuthorizationNumber,
    carrierMembership,
    dmeOrders,
  } = carrierAuthorization
  const isSupplier = context.currentEmployer.employerType === "supplier"

  return (
    <div className="mt-3">
      {isSupplier && (
        <a
          className="color-secondary bold"
          href={carrierAuthorizationDashboardUrl()}
        >
          Back to Dashboard
        </a>
      )}
      <div className="row">
        <div className="col-md-8">
          <h1 className="mt-3">
            Authorization #{parentAuthorizationNumber} for{" "}
            {fullName(carrierMembership)}
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          {carrierAuthorization.terminated && (
            <div className="well well-expand well-error mb-2">
              <h4>
                Authorization Has Been{" "}
                {capitalize(carrierAuthorization.authStatus)}
              </h4>
              <p className="font-xxs">
                Please update your internal records and change the status to
                &quot;Cancelled&quot; on the dashboard.
              </p>
            </div>
          )}
          <div className="well well-expand well-info mb-2">
            <h4>Authorization Has Been Updated</h4>
            <p className="font-xxs">
              Please compare the data below to your internal records.
            </p>
          </div>
          <Section fullWidth hideToggle initialExpanded title="Patient">
            <MemberSectionContent carrierMembership={carrierMembership} />
          </Section>
          <Section fullWidth hideToggle initialExpanded title="Coverage">
            <CoverageSectionContent
              carrierAuthorization={carrierAuthorization}
            />
          </Section>
          <Section fullWidth hideToggle initialExpanded title="Providers">
            <ProviderSectionContent
              carrierAuthorization={carrierAuthorization}
            />
          </Section>
          <Section fullWidth hideToggle initialExpanded title="Authorization">
            <LineItemAuthorizationSectionContent
              carrierAuthorization={carrierAuthorization}
            />
          </Section>
          <Section fullWidth hideToggle initialExpanded title="Diagnoses">
            <DiagnosesSectionContent
              carrierAuthorization={carrierAuthorization}
            />
          </Section>
        </div>
        <div className="col-md-4">
          {isSupplier && (
            <Form
              initialValues={{
                internalNotes: carrierAuthorization.internalNotes,
              }}
              onSubmit={(values) => updateCarrierAuthorization(values)}
              submitOnChange
            >
              <TextArea
                label="Internal Notes"
                name="internalNotes"
                rows={6}
                placeholder="Any notes here will be saved automatically"
              />
            </Form>
          )}
          <Section fullWidth hideToggle initialExpanded title="Orders">
            <LinkedOrdersSectionContent dmeOrders={dmeOrders} />
          </Section>
        </div>
      </div>
    </div>
  )
}

export default InternalCarrierAuthorization
