import React from "react"
import { Address } from "sharedTypes"
import { formatPhone } from "utilities/phone"
import { cityLine } from "utilities/address"
import withInitialData from "components/withInitialData"
import { getSupplierDetails } from "./api"
import * as styles from "./index.module.scss"

interface ContactDetails {
  address: Address
  phoneNumber: string
  name: string
}

type Props = {
  initialData: ContactDetails[]
}

function formatAddress(address: Address) {
  const street = [address.line1, address.line2].filter(Boolean).join(" ")
  return (
    <>
      {street && (
        <>
          {street}
          <br />
        </>
      )}
      {cityLine(address)}
    </>
  )
}

function SupplierDetails({ initialData }: Props) {
  const suppliers = initialData
  if (suppliers.length === 1) {
    const { address, phoneNumber } = suppliers[0]
    return (
      <p className={styles.text}>
        {formatAddress(address)}
        <br />
        {formatPhone(phoneNumber)}
      </p>
    )
  }
  return (
    <>
      {suppliers.map(({ name, address, phoneNumber }, index) => (
        <p key={`${name}-${index}`} className={styles.text}>
          <strong>{name}</strong>
          <br />
          {formatAddress(address)}
          <br />
          {formatPhone(phoneNumber)}
        </p>
      ))}
    </>
  )
}

const Placeholder = () => <p className="mb-0">Loading...</p>
export default withInitialData(getSupplierDetails, Placeholder)(SupplierDetails)
