import React from "react"
import { DocumentationRequirement } from "sharedTypes"
import Alert from "components/Alert"
import { Field } from "formik"
import { titleWithCategory } from "applications/Workflow/utilities/documentationRequirements"
import { SendBackFormKeys } from "./sharedTypes"

interface Props {
  documentationRequirements: DocumentationRequirement[]
}

const DocumentationRequirementsSelection = ({
  documentationRequirements,
}: Props) => {
  if (documentationRequirements.length < 1) {
    return <></>
  }

  const renderCheckbox = (docReq: DocumentationRequirement) => (
    <div className="pb-1" key={docReq.externalId}>
      <Field name={SendBackFormKeys.requestedDocumentationRequirementIds}>
        {({ form, field }) => (
          <label className="checkbox checkbox-brand basic">
            {titleWithCategory(docReq)}
            <input
              type="checkbox"
              name="requestedDocumentationRequirementIds"
              value={docReq.externalId}
              checked={field.value.includes(docReq.externalId)}
              onChange={() => {
                if (field.value.includes(docReq.externalId)) {
                  const nextValue = field.value.filter(
                    (value) => value !== docReq.externalId
                  )
                  form.setFieldValue(
                    "requestedDocumentationRequirementIds",
                    nextValue
                  )
                } else {
                  const nextValue = field.value.concat(docReq.externalId)
                  form.setFieldValue(
                    "requestedDocumentationRequirementIds",
                    nextValue
                  )
                }
              }}
            />
            <span className="icon" />
          </label>
        )}
      </Field>
    </div>
  )

  return (
    <>
      <label>
        Required Documents
        <p className="font-subparagraph regular color-dark-gray canopy-mb-2x">
          Select any required documents still needed to fulfill this order.
        </p>
      </label>
      <div className="mb-4">
        {documentationRequirements.map((docReq) => renderCheckbox(docReq))}
      </div>
      {!documentationRequirements.find(
        (requirement) => requirement.requested
      ) && (
        <Alert leftIcon status="warning">
          <strong>No documents have been selected</strong>
          <div>
            Please select which documents you'd like to request from the
            facility to generate on Parachute.
          </div>
        </Alert>
      )}
    </>
  )
}

export default DocumentationRequirementsSelection
