import React from "react"
import EmailForm from "../EmailForm"
import image from "sign_up/order-updates.png"
import * as styles from "./index.module.scss"

type Props = {
  updateEmail(email: string): Promise<void>
  initialEmail: string
  setSubmittedEmail(string): void
}

const PushbackSignUpBody = ({
  updateEmail,
  initialEmail,
  setSubmittedEmail,
}: Props) => {
  const submit = (email: string) => {
    return updateEmail(email)
  }

  return (
    <div className={styles.container}>
      <img className="mb-3" src={image} />
      <h1>Place orders without the run-around</h1>
      <p className="mb-4">
        Less phone tag. Faster fulfillment. No fax required.
      </p>
      <EmailForm
        initialEmail={initialEmail}
        onSubmit={submit}
        setSubmittedEmail={setSubmittedEmail}
      />
      <a
        className="link d-block mt-5"
        href="https://www.parachutehealth.com"
        target="_blank"
        rel="noreferrer"
      >
        <small>Learn more about Parachute Health</small>
      </a>
    </div>
  )
}

export default PushbackSignUpBody
