// @ts-strict-ignore
import "custom-event-polyfill"
import ReactSignaturePad from "react-signature-pad-wrapper"
import SignaturePadType from "signature_pad"
import Button from "../form/Button"
import React, { useRef, useState, useEffect, useCallback } from "react"
import SubText from "../SubText"
import classNames from "classnames"

type Props = {
  height: number
  onChange?(base64DataUrl?: string): void
  fieldError?: React.ReactElement
}

const SignaturePad = ({ height, onChange, fieldError }: Props) => {
  const ref = useRef(null)
  const [isEmpty, setIsEmpty] = useState(true)

  const getSignaturePad = (): SignaturePadType => ref.current.signaturePad
  const onSignatureChange = useCallback(() => {
    const signaturePad = getSignaturePad()
    const signaturePadEmpty = !signaturePad || signaturePad.isEmpty()
    setIsEmpty(signaturePadEmpty)
    if (onChange) {
      onChange(signaturePadEmpty ? null : signaturePad.toDataURL())
    }
  }, [onChange])
  const clearSignaturePad = () => {
    const signaturePad = getSignaturePad()
    if (signaturePad) {
      getSignaturePad().clear()
      onSignatureChange()
    }
  }

  useEffect(() => {
    const current = ref?.current
    if (current) {
      current.instance.addEventListener("endStroke", onSignatureChange)
    }
    return () => {
      if (current) {
        current.instance.removeEventListener("endStroke", onSignatureChange)
      }
    }
  }, [ref, onSignatureChange])

  return (
    <>
      <div>
        <label className="col-form-label">Signature</label>
      </div>
      <div
        className={classNames("form-control", "form-group", "signature-pad", {
          "has-error": fieldError,
        })}
        style={{ height }}
        data-testid="signature-pad-wrapper"
      >
        <ReactSignaturePad
          canvasProps={{ style: { height: "100%" } }}
          redrawOnResize
          ref={ref}
        />
      </div>
      <div className="row mb-4">
        <div className="col-md-6 col-12">
          {fieldError}
          <SubText content="Use your mouse or finger to sign above" />
        </div>
        <div className="col-md-6 col-12 text-xs-left text-right">
          <Button
            className="btn-secondary btn-sm"
            disabled={isEmpty}
            onClick={clearSignaturePad}
          >
            Clear Signature
          </Button>
        </div>
      </div>
    </>
  )
}

export default SignaturePad

SignaturePad.defaultProps = {
  height: 300,
}
