import React, { useState } from "react"
import {
  Form,
  TextField,
  EmailField,
  PhoneField,
  Button,
} from "components/form"
import { updateContactInformation } from "../../api"
import { handleError } from "utilities/error"
import { isPhoneValid } from "utilities/phone"
import Icon from "components/Icon"
import Tooltip from "components/Tooltip"

type Props = {
  email?: string
  mobileNumber?: string
  phoneNumber?: string
  phoneExtension?: string
  onSuccess: () => void
}

type FormValues = {
  mobileNumber?: string
  phoneNumber?: string
  phoneExtension?: string
}

const ContactDetailsForm = ({
  email,
  mobileNumber,
  phoneNumber,
  phoneExtension,
  onSuccess,
}: Props) => {
  const [initialValues, setInitialValues] = useState({
    email: email || "",
    mobileNumber: mobileNumber || "",
    phoneNumber: phoneNumber || "",
    phoneExtension: phoneExtension || "",
  })

  const validate = ({
    mobileNumber,
    phoneNumber,
    phoneExtension,
  }: FormValues) => {
    const errors = {} as FormValues
    if (!isPhoneValid(mobileNumber, true)) {
      errors.mobileNumber = "Enter a valid 10-digit phone number"
    }
    if (!isPhoneValid(phoneNumber, true)) {
      errors.phoneNumber = "Enter a valid 10-digit phone number"
    }
    if (phoneExtension && phoneExtension.length > 12) {
      errors.phoneExtension = "Must be 12 characters or less"
    }
    return errors
  }

  const onSubmit = (values) =>
    updateContactInformation(values)
      .then(() => {
        onSuccess()
        setInitialValues(values)
      })
      .catch(handleError)

  return (
    <div className="row mt-5">
      <div className="col-sm-2">
        <h3>Contact Details</h3>
      </div>
      <div className="col-sm-6">
        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ dirty, isValid, isSubmitting }) => (
            <>
              <div className="row">
                <div className="col-sm-12">
                  <EmailField
                    name="email"
                    label={
                      <>
                        <strong className="color-black">Email </strong>
                        <Tooltip
                          trigger={["click", "hover"]}
                          triggerElementClassName="d-inline-block"
                          placement={Tooltip.Placement.Top}
                          width={Tooltip.Width.Unlimited}
                          overlay="If you'd like to change your email, please use the Help
                        Center."
                        >
                          <>
                            <Icon
                              className="color-dark-gray"
                              type="info-circle"
                            />
                          </>
                        </Tooltip>
                      </>
                    }
                    disabled
                    unwrapped
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <PhoneField name="mobileNumber" label="Mobile Number" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <PhoneField name="phoneNumber" label="Work Number" />
                </div>
                <div className="col-sm-6">
                  <TextField name="phoneExtension" label="Work Extension" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <Button
                    className="btn-brand"
                    type="submit"
                    disabled={!dirty || !isValid || isSubmitting}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  )
}

export default ContactDetailsForm
