import React from "react"

import { trackEvent } from "../utilities/analytics"

export interface AnalyticsContextInterface {
  trackSearchEvent(eventCategory: string, eventType: string): void
  trackFacilityTapped(): void
  viewParachuteGeneratedDocument(): void
  trackRequestSignatureTapped(): void
}

export const AnalyticsContext = React.createContext<AnalyticsContextInterface>({
  trackFacilityTapped() {
    const eventCategory = "ssr-app"
    const eventType = "facility-tapped"
    trackEvent([eventCategory, eventType])
  },
  trackSearchEvent(eventCategory: string, eventType: string) {
    trackEvent([eventCategory, eventType])
  },
  viewParachuteGeneratedDocument() {
    const eventCategory = "ssr-app"
    const eventType = "view-parachute-generated-document"
    trackEvent([eventCategory, eventType])
  },
  trackRequestSignatureTapped() {
    const eventCategory = "ssr-app"
    const eventType = "request-signature-button"
    trackEvent([eventCategory, eventType])
  },
})
