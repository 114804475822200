import React, { useState } from "react"
import ViewsDropdown from "./ViewsDropdown"
import SaveButton from "./SaveButton"
import SaveModal from "./SaveModal"
import ResetButton from "./ResetButton"
import SaveChangesButton from "./SaveChangesButton"
import { DashboardViewListing } from "../../sharedTypes"

interface Props {
  views: DashboardViewListing[]
  currentView: DashboardViewListing
  fetchViews(): Promise<DashboardViewListing[]>
  createView(viewName: string): Promise<void>
  changeView(id: string): Promise<void>
  resetView(): void
  saveView(): Promise<void>
  anyUnsavedChanges?: boolean
}

function ViewEditor({
  currentView,
  fetchViews,
  createView,
  changeView,
  resetView,
  saveView,
  views,
  anyUnsavedChanges,
}: Props) {
  const [isModalOpen, showModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const openModal = () => showModal(true)
  const closeModal = () => showModal(false)
  const isDefaultView = currentView.isDefault
  const isCustomView = !isDefaultView

  const submit = ({ viewName }) => {
    return createView(viewName).then((response) => {
      closeModal()
      return response
    })
  }

  const handleChange = (id) => {
    setLoading(true)
    return changeView(id).then((response) => {
      setLoading(false)
      return response
    })
  }

  const handleSaveChanges = () => {
    setLoading(true)
    return saveView().then(() => {
      setLoading(false)
    })
  }

  const isSaveButtonVisible =
    isDefaultView || (isCustomView && anyUnsavedChanges)
  const isSaveChangesButtonVisible = isCustomView && anyUnsavedChanges
  const isResetButtonVisible = isCustomView && anyUnsavedChanges

  return (
    <div className="mb-2">
      <ViewsDropdown
        currentView={currentView}
        fetchViews={fetchViews}
        views={views}
        onChange={handleChange}
      />
      {anyUnsavedChanges && isCustomView && !loading && (
        <span className="font-xs color-dark-gray ml-2">
          Filters no longer reflect original state*
        </span>
      )}
      {isResetButtonVisible && !loading && <ResetButton onClick={resetView} />}
      {isSaveChangesButtonVisible && !loading && (
        <SaveChangesButton onClick={handleSaveChanges} />
      )}
      {isSaveButtonVisible && !loading && (
        <SaveButton onClick={openModal} isDefaultView={isDefaultView} />
      )}
      <SaveModal show={isModalOpen} cancel={closeModal} submit={submit} />
    </div>
  )
}

export default ViewEditor
