import React, { useEffect } from "react"
import ErrorMessage from "../ErrorMessage"
import { DmeOrderInvitation } from "../../sharedTypes"
import { Field } from "formik"
import { EMAIL_PLACEHOLDER } from "utilities/placeholder"
import { isEmailValid } from "utilities/email"
import { Form } from "components/form"
import BigInput from "components/BigInput"
import Icon from "components/Icon"
import { toSentence } from "utilities/array"
import Cobranding from "components/Cobranding"
import Legalese from "../Legalese"
import { trackInvitationEvent } from "../../utilities"

type Props = {
  invitation: DmeOrderInvitation
  onSubmit({ email }: { email: string }): Promise<void>
  skipEmail: () => Promise<void>
}

const InvitationEmailForm = ({ invitation, onSubmit, skipEmail }: Props) => {
  const { patientName, packageNames } = invitation
  useEffect(() => {
    trackInvitationEvent("email-form-viewed")
  }, [])

  const initialValues = {
    email: "",
  }
  const validate = ({ email }) => {
    const errors: {
      email?: string
    } = {}

    if (!email) {
      errors.email = "Please enter your email address"
    } else if (!isEmailValid(email)) {
      errors.email = "Enter a valid email address"
    }

    return errors
  }

  return (
    <>
      <Cobranding
        supplierName={invitation.supplierName}
        supplierLogoUrl={invitation.supplierLogoUrl}
      />
      <div className="onboarding-intro">
        <Icon type="comment-dots" />
        <p className="px-3">
          <strong className="mb-2 d-block">
            Your order needs some additional information
          </strong>
          {toSentence(packageNames)} for {patientName}
        </p>
        <h1 className="my-5">What is your email address?</h1>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
        >
          {({ errors, isSubmitting }) => (
            <>
              <Field name="email">
                {({ field }) => (
                  <BigInput
                    id={`${invitation.dmeOrderId}-email`}
                    label="Email Address"
                    placeholder={EMAIL_PLACEHOLDER}
                    type="email"
                    autoFocus
                    {...field}
                  />
                )}
              </Field>
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
              <div className="text-center pt-5 pb-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-brand px-4"
                >
                  Continue
                </button>
              </div>
            </>
          )}
        </Form>
        <button onClick={skipEmail} className="btn btn-sm btn-gray">
          Skip
        </button>
      </div>
      <Legalese entityName={invitation.clinicalFacilityName} />
    </>
  )
}
export default InvitationEmailForm
