import React from "react"
import Card from "./Card"
import { EventCategory, trackEvent } from "utilities/tracking"

interface Props {
  onCreateAccountClick(): void
  onInviteReferralClick(): void
}

function MethodSelector({
  onCreateAccountClick,
  onInviteReferralClick,
}: Props) {
  return (
    <>
      <h1 className="mb-5">Invite referral or create account</h1>
      <div className="sign-up-form">
        <Card
          icon="letter"
          onClick={() => {
            trackEvent(
              EventCategory.SupplierInfoCenterMethodSelector,
              "invite-referral-clicked"
            )
            onInviteReferralClick()
          }}
        >
          <p className="mb-1">
            <strong>Invite a referral</strong>
          </p>
          <p className="font-subparagraph color-dark-gray">
            For sales reps who want to invite their referrals to create a
            Parachute Health account
          </p>
        </Card>
        <Card
          icon="user"
          onClick={() => {
            trackEvent(
              EventCategory.SupplierInfoCenterMethodSelector,
              "create-account-clicked"
            )
            onCreateAccountClick()
          }}
        >
          <p className="mb-1">
            <strong>Create an account</strong>
          </p>
          <p className="font-subparagraph color-dark-gray">
            For healthcare facility employees who want to create their own
            Parachute Health account
          </p>
        </Card>
      </div>
    </>
  )
}

export default MethodSelector
