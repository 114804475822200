import Button from "components/form/Button"
import React, { useState } from "react"
import { signUpForFacility } from "./api"
import { navigate } from "utilities/navigation"
import { EventCategory, trackEvent } from "utilities/tracking"
import VerifyEmailModal from "components/VerifyEmailModal"
import { useDoctorContext } from "../../hooks/useDoctorContext"

interface Props {
  currentUserEmail: string
}

function SignUpModal({ currentUserEmail }: Props) {
  const { doctor } = useDoctorContext()
  const [showModal, setShowModal] = useState(false)

  const onButtonClick = () => {
    const emailSubmittedEvent = "promo_submitted_resupply"
    void trackEvent(EventCategory.ResupplySignUp, emailSubmittedEvent)
    signUpForFacility(doctor.id).then((data) => {
      if (data.redirectUrl) {
        navigate(data.redirectUrl)
      } else {
        setShowModal(true)
      }
    })
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <Button className="btn btn-brand mb-4" onClick={onButtonClick}>
        Start your first order!
      </Button>
      <VerifyEmailModal
        email={currentUserEmail}
        dismiss={closeModal}
        show={showModal}
      />
    </>
  )
}

export default SignUpModal
