import React from "react"
import {
  Form,
  TextField,
  PhoneField,
  ZipField,
  NpiField,
  StateSelect,
} from "components/form"
import Button from "components/form/Button"
import FacilityTypeSelect from "components/FacilityTypeSelect"
import BackButton from "./BackButton"
import { isPhoneValid } from "utilities/phone"
import { Facility, FacilityCreateFormFields } from "../../sharedTypes"

interface Props {
  onBackButtonClick(): void
  onSubmit(values: FacilityCreateFormFields): void
  facility: Facility
}

export default function FacilityCreateForm({
  onBackButtonClick,
  facility,
  onSubmit,
}: Props) {
  const validate = (facility: FacilityCreateFormFields) => {
    const { name, line1, state, phoneNumber, city } = facility

    const errors: {
      name?: string
      line1?: string
      state?: string
      phoneNumber?: string
      city?: string
    } = {}

    if (!name) {
      errors.name = "Enter healthcare facility name"
    }
    if (!line1) {
      errors.line1 = "Enter address"
    }
    if (!state) {
      errors.state = "Select state"
    }
    if (!isPhoneValid(phoneNumber)) {
      errors.phoneNumber = "Enter a valid 10-digit phone number"
    }
    if (!city) {
      errors.city = "Enter City"
    }
    return errors
  }

  const initialValues = {
    supplierSystemId: facility.supplierSystemId || "",
    name: facility.name || "",
    npi: facility.npi || "",
    phoneNumber: facility.phoneNumber || "",
    facilityType: "",
    line1: facility.line1 || "",
    line2: facility.line2 || "",
    city: facility.city || "",
    state: facility.state || "",
    zip: facility.zip || "",
  }

  return (
    <>
      <BackButton onClick={onBackButtonClick} />
      <div className="mb-5">
        <h1>Review details and send invite</h1>
        <p className="text-center text-xs-left mb-0">
          Review and confirm details to invite your referral to join this
          facility.
        </p>
      </div>
      <Form
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <>
            <TextField
              label="External facility ID (Optional)"
              name="supplierSystemId"
              placeholder="Enter external facility ID"
            />
            <TextField
              label="Healthcare facility name"
              name="name"
              placeholder="Ex. East Medical Center - Emergency"
            />
            <NpiField
              label="NPI (Optional)"
              name="npi"
              optional
              validateField
            />
            <PhoneField label="Facility phone number" name="phoneNumber" />
            <TextField label="Address line 1" name="line1" />
            <TextField label="Address line 2 (Optional)" name="line2" />
            <div className="row gutter-10">
              <div className="col-md-6">
                <TextField label="City" name="city" />
              </div>
              <div className="col-md-6">
                <StateSelect name="state" />
              </div>
            </div>
            <ZipField name="zip" validateField />
            <FacilityTypeSelect name="facilityType" />
            <Button
              className="btn-brand btn-block"
              type="submit"
              disabled={isSubmitting}
            >
              Send Invite
            </Button>
          </>
        )}
      </Form>
    </>
  )
}
