import React from "react"
import { ErrorType } from "../sharedTypes"
import { newConfirmationUrl } from "../urls"
import Alert from "components/Alert"
import { navigate } from "utilities/navigation"

interface Props {
  error: string
  type: ErrorType
}

const ErrorText = ({ error, type }: Props) => {
  switch (type) {
    case ErrorType.Invalid:
      return <></>
    case ErrorType.Unconfirmed:
      return (
        <Alert
          status="danger"
          leftIcon
          rightIcon="fa-chevron-right pointer"
          onClick={() => navigate(newConfirmationUrl())}
        >
          <strong>Email confirmation is required to log in. </strong>
          Click here to confirm your email address.
        </Alert>
      )
    default:
      return <>{error}</>
  }
}

const LoginAlert = ({ error, type }: Props) => {
  return (
    <div className="color-danger font-xs text-left mt-1">
      <ErrorText error={error} type={type} />
    </div>
  )
}

export default LoginAlert
