import React from "react"
import Header from "./Header"
import Content from "./Content"
import { Event, Notice, Nullable } from "sharedTypes"
import classNames from "classnames"
import { eventClassName } from "./utilities/classNames"
import Icon from "../Icon"
import SidebarLinks from "./SidebarLinks"

type Props = {
  activeUser?: boolean
  currentUserId?: string
  event: Event
  notice?: Nullable<Notice>
  portalMessagingEnabled?: boolean
}

const EventComponent: React.FC<Props> = ({
  activeUser,
  currentUserId,
  event,
  notice,
  portalMessagingEnabled,
}) => {
  const className = eventClassName(event)
  const hasSidebarLinks = event.sidebarLinks && event.sidebarLinks.length > 0

  return (
    <div
      className={classNames("event-component", className, {
        internal: !!notice,
      })}
    >
      <Header
        activeUser={activeUser}
        event={event}
        currentUserId={currentUserId}
      />
      <Content event={event} notice={notice}>
        {hasSidebarLinks && (
          <SidebarLinks
            links={event.sidebarLinks}
            portalMessagingEnabled={!!portalMessagingEnabled}
          />
        )}
      </Content>
      {event.skipNotifications && (
        <div className="mt-2 ml-1 font-subparagraph">
          <Icon type="bell-slash" className="mr-2" />
          Internal note was posted with notifications turned off
        </div>
      )}
    </div>
  )
}

export default EventComponent
