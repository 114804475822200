import React from "react"
import SkuQuickAdd from "../SkuQuickAdd"
import { PackageSku } from "../../sharedTypes"
import QuickListButton from "../QuickListButton"
import * as styles from "./index.module.scss"
import { blurOnEnter } from "utilities/browser/event"
import SidebarAwareContainer from "../SideBarAwareContainer"
import CatalogSearchTabs from "../CatalogSearchTabs"
import { CatalogSearchTab, CatalogSearchType } from "sharedTypes"

interface Props {
  canFilterByFormulary: boolean
  catalogSearchType: CatalogSearchType
  filterQuery: string
  formularyPriceEnabled: boolean
  supplierIdParam: string
  searchPackagesWithSku(query: string): Promise<{ data: PackageSku[] }>
  selectPackageWithSku(s: PackageSku): void
  setSelectedTab(tab: CatalogSearchTab): void
  selectedTab: CatalogSearchTab
  updateFilterQuery(query: string): void
}

const HybridSearch: React.FC<Props> = ({
  canFilterByFormulary,
  catalogSearchType,
  filterQuery,
  formularyPriceEnabled,
  searchPackagesWithSku,
  selectPackageWithSku,
  supplierIdParam,
  updateFilterQuery,
  setSelectedTab,
  selectedTab,
}) => {
  return (
    <SidebarAwareContainer role="hybridSearch">
      <CatalogSearchTabs
        catalogSearchType={catalogSearchType}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
      {selectedTab === CatalogSearchTab.SkuQuickAdd ? (
        <div className={styles.hybridSearchContainer}>
          <SkuQuickAdd
            formularyPriceEnabled={formularyPriceEnabled}
            hideIcon
            placeholder="Search by specific SKUs"
            searchPackagesWithSku={searchPackagesWithSku}
            selectPackageWithSku={selectPackageWithSku}
            isInHybridSearch={true}
          />
        </div>
      ) : undefined}
      {selectedTab === CatalogSearchTab.PackageFilter ? (
        <div className={styles.hybridSearchContainer}>
          <input
            className="package-search form-control mb-4"
            value={filterQuery}
            onChange={(event) => updateFilterQuery(event.target.value)}
            placeholder="Search by packages/products"
            onKeyUp={blurOnEnter}
          />
        </div>
      ) : undefined}
      <QuickListButton
        canFilterByFormulary={canFilterByFormulary}
        supplierIdParam={supplierIdParam}
      />
    </SidebarAwareContainer>
  )
}

export default HybridSearch
