import React from "react"
import { Form, TextField, Button } from "components/form"
import {
  createPatientPortalMessageTemplate,
  updatePatientPortalMessageTemplate,
} from "./api"
import { patientPortalMessageTemplatesUrl } from "./urls"
import { navigate } from "utilities/navigation"
import { Field } from "formik"
import { MentionsInput, Mention } from "react-mentions"
import * as styles from "./index.module.scss"
import { canopyColorPrimitivesPurple94 } from "@parachutehealth/canopy-tokens-color"

interface Props {
  messageTemplate: { id: string; name: string; template: string }
}

const PatientPortalMessageTemplateForm: React.FC<Props> = ({
  messageTemplate,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const createOrUpdate = messageTemplate.id
    ? updatePatientPortalMessageTemplate(messageTemplate.id)
    : createPatientPortalMessageTemplate
  const onSubmit = (values, { setErrors }) => {
    createOrUpdate(values)
      .then(() => navigate(patientPortalMessageTemplatesUrl()))
      .catch(({ errors }) => setErrors(errors))
  }

  const validateName = (value: string) => {
    if (!value) {
      return "Required"
    } else if (value.length > 30) {
      return "Must be 30 characters or less"
    }
  }

  const variables = ["Patient First Name", "Patient Full Name", "Supplier Name"]

  return (
    <Form
      initialValues={{
        name: messageTemplate.name || "",
        template: messageTemplate.template || "",
      }}
      onSubmit={onSubmit}
    >
      {({ errors, isSubmitting, setFieldValue, values }) => (
        <>
          <TextField
            label="Template Name"
            name="name"
            placeholder="Enter template name"
            validate={validateName}
          />
          <Field name="template">
            {({ field, form }) => (
              <div className="form-group">
                <label htmlFor="template">Message</label>
                <div className="mb-2">
                  Add Customization
                  {variables.map((v) => (
                    <button
                      key={v}
                      type="button"
                      className={styles.addVariableButton}
                      onClick={() => {
                        const cursorPosition =
                          inputRef.current!.selectionStart ||
                          values["template"].length
                        const start = values["template"].slice(
                          0,
                          cursorPosition
                        )
                        const end = values["template"].slice(cursorPosition)
                        setFieldValue("template", start + `{${v}}` + end)

                        inputRef.current!.focus()
                        setImmediate(() => {
                          inputRef.current!.setSelectionRange(
                            cursorPosition + v.length + 2,
                            cursorPosition + v.length + 2
                          )
                        })
                      }}
                    >
                      {v}
                    </button>
                  ))}
                </div>
                <MentionsInput
                  value={field.value}
                  onChange={(
                    _event,
                    newValue,
                    _newPlainTextValue,
                    _mentions
                  ) => {
                    form.setFieldValue(field.name, newValue)
                  }}
                  className="text-editor form-control"
                  inputRef={inputRef}
                >
                  <Mention
                    markup="{__id__}"
                    trigger=""
                    data={[]}
                    appendSpaceOnAdd
                    style={{
                      backgroundColor: canopyColorPrimitivesPurple94,
                      marginLeft: "2px",
                    }}
                    displayTransform={(_id, display) => ` ${display} `}
                  />
                </MentionsInput>
              </div>
            )}
          </Field>
          <Button
            type="submit"
            className="btn-primary"
            disabled={!!errors.name || isSubmitting}
          >
            Save
          </Button>
        </>
      )}
    </Form>
  )
}

export default PatientPortalMessageTemplateForm
