import React, { useEffect, useState } from "react"
import { camelizeKeys } from "humps"
import withInitialData from "components/withInitialData"
import InternalChartNotes from "./components/InternalChartNotes"
import { answerQuestion, startChartNote, confirmPrefilledAnswers } from "./api"
import {
  DmeOrder,
  History,
  SurveyQuestion,
  SurveyAnswerType,
} from "sharedTypes"
import { ChartNote, ChartNoteHelperType } from "./sharedTypes"
import { handleError } from "utilities/error"

type Props = {
  history: History
  refreshDmeOrder(): Promise<void>
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
  initialData: {
    dmeOrder: DmeOrder
    hasDefaultChoices: boolean
    chartNoteHelperType: ChartNoteHelperType
    progress: {
      faceToFace: ChartNote
      questions: SurveyQuestion[]
      requiresConfirmation: boolean
      disqualifiedMessage?: string
      qualificationStatus: string
      percentageComplete: number
    }
  }
  nextPagePath?: string
  faceToFaceId: string
}

const ChartNotes: React.FC<Props> = ({
  initialData,
  refreshDmeOrder,
  setDmeOrder,
  nextPagePath,
}) => {
  const { dmeOrder, progress } = initialData
  useEffect(() => {
    setDmeOrder(dmeOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [faceToFace, setFaceToFace] = useState<ChartNote>(progress.faceToFace)
  const [questions, setQuestions] = useState(progress.questions)
  const [requiresConfirmation, setRequiresConfirmation] = useState(
    progress.requiresConfirmation
  )
  const [disqualifiedMessage, setDisqualifiedMessage] = useState(
    progress.disqualifiedMessage
  )
  const [qualificationStatus, setQualificationStatus] = useState(
    progress.qualificationStatus
  )
  const [percentageComplete, setPercentageComplete] = useState(
    progress.percentageComplete
  )

  const updateState = (response) => {
    if (response.data.faceToFace) {
      setFaceToFace(response.data.faceToFace)
      setQuestions(response.data.questions)
      setRequiresConfirmation(response.data.requiresConfirmation)
      setDisqualifiedMessage(response.data.disqualifiedMessage)
      setQualificationStatus(response.data.qualificationStatus)
      setPercentageComplete(response.data.percentageComplete)
    }
    return response
  }

  const answerQuestionWrapper = (
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue
  ) => {
    return answerQuestion(faceToFace.id, question, answerType, answerValue)
      .then((response) => {
        refreshDmeOrder()
        return updateState(response)
      })
      .catch(handleError)
  }

  const confirmPrefilledAnswersWrapper = () => {
    return confirmPrefilledAnswers(faceToFace.id)
      .then((response) => {
        refreshDmeOrder()
        return updateState(response)
      })
      .catch(handleError)
  }

  const { hasDefaultChoices, chartNoteHelperType } = initialData

  return (
    <InternalChartNotes
      answerQuestion={answerQuestionWrapper}
      confirmPrefilledAnswers={confirmPrefilledAnswersWrapper}
      faceToFace={camelizeKeys(faceToFace)}
      questions={questions}
      requiresConfirmation={requiresConfirmation}
      disqualifiedMessage={disqualifiedMessage}
      qualificationStatus={qualificationStatus}
      percentageComplete={percentageComplete}
      hasDefaultChoices={hasDefaultChoices}
      chartNoteHelperType={chartNoteHelperType}
      nextPagePath={nextPagePath}
    />
  )
}

const fetchInitialData = ({ faceToFaceId }) => startChartNote(faceToFaceId)
export default withInitialData(fetchInitialData)(ChartNotes)
