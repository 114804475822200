import React from "react"
import {
  ListItemIcon,
  Menu,
  MenuItem,
  MenuProps,
  Tooltip,
} from "@material-ui/core"
import classNames from "classnames"
import uuid from "uuid/v4"
import * as styles from "./index.module.scss"
import { CanopyButton, CanopyButtonProps } from "@parachutehealth/canopy-button"
import { isEmpty, isFunction } from "lodash"
import { CanopyIconNames } from "@parachutehealth/canopy-icons/build/canopyIconGlyphNames"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import FileUploadMenuItem from "../FileUploadMenuItem"
import { isNullOrUndefined } from "../../../../utilities/isNullOrUndefined"

const DEFAULT_DISABLED_MESSAGE =
  "You do not have the necessary permissions to perform this action"

export type DropdownMenuItem = {
  /**
   * The visible label of the menu item (that also serves as its accessible name).
   */
  label: string
  /**
   * If specified, the menu item will have this prepended to it.
   */
  icon?: CanopyIconNames
  /**
   * Allows specifying contextual variants for menu items that are visually distinguished.
   */
  variant?: "danger"
  /**
   * Determine if this particular menu item should be disabled. If it evaluates to true, the disabled message will be used.
   */
  disabled?: boolean | (() => boolean)
  /**
   * If this evaluates to a truthy value, the menu item will be disabled and show the value in a tooltip.
   */
  disabledMessage?: string
  /**
   * If specified, the menu item will only be rendered if it evaluates to true.
   */
  ifTrue?: boolean | (() => boolean)
  /**
   * @deprecated Only the PackagesPage needs this, and it will cause a different item to be rendered that does not have all fancy bits.
   */
  fileUploadProps?: {
    url: string | URL
    redirectUrl?: string | URL
    parameterName?: string
  }
} & Omit<React.HTMLProps<HTMLAnchorElement>, "disabled">

export type DropdownMenuProps = {
  label: string
  id?: string
  children: DropdownMenuItem[]
  buttonProps?: CanopyButtonProps<"button">
  menuProps?: MenuProps
} & React.HTMLProps<HTMLSpanElement>

/**
 * For item props that allow booleans or boolean-returning functions or undefined.
 * If undefined, return `true`, since undefined means "inapplicable" in this context;
 * otherwise evaluate the incoming value as indicated.
 */
const evaluateTruthiness = (input?: boolean | (() => boolean)): boolean => {
  if (isNullOrUndefined(input)) {
    return true
  } else if (isFunction(input)) {
    return input()
  } else {
    return Boolean(input)
  }
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  children,
  id = uuid(),
  label,
  menuProps,
  buttonProps = {},
  ...other
}): React.JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const definitionToMenuItem = (
    item: DropdownMenuItem
  ): React.JSX.Element | undefined => {
    const {
      label,
      fileUploadProps,
      disabled = false,
      disabledMessage = DEFAULT_DISABLED_MESSAGE,
      className,
      variant,
      ifTrue,
      ...rest
    } = item

    if (!evaluateTruthiness(ifTrue)) {
      return undefined
    }

    // special case for handling file-upload triggers
    if (fileUploadProps) {
      return (
        <FileUploadMenuItem label={label} key={label} {...fileUploadProps} />
      )
    }

    const itemDisabled: boolean = evaluateTruthiness(disabled)

    const menuItem = (
      <MenuItem
        className={classNames(variant, className)}
        // the rest operator makes this unhappy w/r/t Typescript, so we're ignoring it for now
        // @ts-ignore
        component="a"
        disabled={itemDisabled}
        label={label}
        key={label}
        {...rest}
      >
        {item.icon && (
          <ListItemIcon>
            <CanopyIcon name={item.icon} />
          </ListItemIcon>
        )}
        {item.label}
      </MenuItem>
    )

    if (itemDisabled) {
      return (
        <Tooltip arrow key={label} title={disabledMessage}>
          <span data-disabled="disabled">{menuItem}</span>
        </Tooltip>
      )
    } else {
      return menuItem
    }
  }

  const childrenToMenuItems = (
    input: DropdownMenuItem[]
  ): React.JSX.Element[] => {
    const filteredItems: React.JSX.Element[] = []
    return input.reduce((accumulator, item) => {
      const element = definitionToMenuItem(item)
      if (!element) {
        return accumulator
      }

      accumulator.push(element)

      return accumulator
    }, filteredItems)
  }

  const menuOpen = (): boolean => Boolean(anchorEl)

  const filteredChildren = childrenToMenuItems(children)

  // will attach to the button so we can programmatically get its width
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  if (isEmpty(filteredChildren)) {
    return <></>
  }

  const { className: buttonClassName, ...otherButtonProps } = buttonProps
  const generatedId = `${id}-button`

  return (
    <span {...other}>
      <CanopyButton
        ref={buttonRef}
        id={generatedId}
        size="small"
        variant="secondary"
        aria-controls={`${id}-menu`}
        aria-haspopup="true"
        onClick={handleClick}
        iconEnd="caret-down"
        className={classNames(buttonClassName, { [styles.open]: menuOpen() })}
        {...otherButtonProps}
      >
        {label}
      </CanopyButton>

      <Menu
        data-testid={`test-${id}`}
        className={classNames(styles.menu)}
        MenuListProps={{
          // make the menu _at least_ as wide as the button itself
          style: { minWidth: buttonRef?.current?.offsetWidth || "100px" },
          "aria-labelledby": generatedId,
        }}
        id={`${id}-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen()}
        onClose={handleClose}
        onClick={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...menuProps}
      >
        {filteredChildren}
      </Menu>
    </span>
  )
}

export default DropdownMenu
