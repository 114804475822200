// @ts-strict-ignore
import React from "react"
import ReviewCard from "."
import {
  DmeOrder,
  DmeOrderWarning,
  Employer,
  EmployerType,
  DocumentationRequirementSatisfyStatus,
  Severity,
} from "sharedTypes"
import { toList } from "utilities/array"
import {
  requestedDocumentationRequirements,
  titleWithCategory,
} from "applications/Workflow/utilities/documentationRequirements"
import DocumentationRequirementRows from "../../../ClinicalFacilityClinical/components/DocumentationRequirements/DocumentationRequirementRows"
import { Link } from "react-router-dom"
import { clinicalPath } from "applications/Workflow/routes"

type Props = {
  currentEmployer: Employer
  dmeOrder: DmeOrder
  expanded: boolean
  warnings: DmeOrderWarning[]
}

const statusSeverity = {
  [DocumentationRequirementSatisfyStatus.Complete]: 2,
  [DocumentationRequirementSatisfyStatus.Incomplete]: 1,
  [DocumentationRequirementSatisfyStatus.Unstarted]: 1,
  [DocumentationRequirementSatisfyStatus.Invalid]: 0,
}

const statusToSeverity = (status) => {
  switch (status) {
    case DocumentationRequirementSatisfyStatus.Complete:
      return Severity.Success
    case DocumentationRequirementSatisfyStatus.Incomplete:
    case DocumentationRequirementSatisfyStatus.Unstarted:
      return Severity.Warning
    case DocumentationRequirementSatisfyStatus.Invalid:
      return Severity.Danger
  }
}

function DocumentationRequirementsReviewCard({
  currentEmployer,
  dmeOrder,
  expanded,
  warnings,
}: Props) {
  const { chartNotes } = dmeOrder
  const visibleDocumentationRequirements = requestedDocumentationRequirements(
    dmeOrder.documentationRequirements
  )

  const productConfigurationWarning = warnings.find(
    (warning) =>
      warning.key === "rx_details" || warning.key === "product_requirements"
  )
  const summary = toList(
    visibleDocumentationRequirements.map((d) => titleWithCategory(d)),
    3
  )
  const incomplete = !!visibleDocumentationRequirements.find(
    (d) => d.satisfyStatus !== DocumentationRequirementSatisfyStatus.Complete
  )
  const showLink =
    incomplete &&
    dmeOrder.permissions.update &&
    currentEmployer.employerType === EmployerType.ClinicalFacility

  const mostSevereStatus = visibleDocumentationRequirements
    .map((d) => d.satisfyStatus)
    .sort(
      (statusA, statusB) => statusSeverity[statusA] - statusSeverity[statusB]
    )[0]
  const severity = statusToSeverity(mostSevereStatus)

  const expandedContent = () => {
    if (visibleDocumentationRequirements.length === 0) return

    return (
      <div>
        <DocumentationRequirementRows
          dmeOrderId={dmeOrder.id}
          chartNotes={chartNotes}
          documentationRequirements={visibleDocumentationRequirements}
          permissions={{
            updateChartNotes: false,
            updateManualDocumentation: false,
          }}
          missingChartNotesPrerequisites={!!productConfigurationWarning}
          bounceToSection={{ shouldBounce: false, triggerBounce: () => {} }}
        />
        {showLink && (
          <Link className="link" to={clinicalPath()}>
            <small>Go to Clinical Info</small>
          </Link>
        )}
      </div>
    )
  }

  return (
    <ReviewCard
      status={severity}
      expanded={expanded}
      summary={summary}
      title="Documentation Requirements"
    >
      {expandedContent()}
    </ReviewCard>
  )
}

export default DocumentationRequirementsReviewCard
