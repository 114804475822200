import React, { useState } from "react"
import { Doctor, SupplierInformation } from "sharedTypes"
import { Form, RadioButtons, Button } from "components/form"
import Legalese from "applications/AccessCodeLogin/components/Legalese"
import { doctorBasicInfo } from "applications/AccessCodeLogin/utilities"
import * as styles from "../index.module.scss"
import { useHistory } from "react-router-dom"
import Link from "components/Link"
import { DoctorParams } from "../api"
import Cobranding from "components/Cobranding"
import Logo from "components/Logo"

enum AccessOptions {
  Doctor,
  WorkWithDoctor,
  RoutingError,
}
interface FormValues {
  role: AccessOptions
}

type Props = {
  doctor: Doctor
  login(params: DoctorParams): void
  parachuteUserLoginPath: string
  signOut(): void
  onUserSignInLink(): void
  reviewerIdentificationFormPath: string
  routingErrorPath: string
  supplierInformation?: SupplierInformation
}

const SigningSignatureRequestsAccess: React.FC<Props> = ({
  doctor,
  login,
  parachuteUserLoginPath,
  onUserSignInLink,
  reviewerIdentificationFormPath,
  routingErrorPath,
  supplierInformation,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState(false)

  const history = useHistory()

  const formOptions = () => [
    {
      label: `I am ${doctor.firstName} ${doctor.lastName}`,
      value: AccessOptions.Doctor,
    },
    {
      label: `I work with ${doctor.firstName} ${doctor.lastName}`,
      value: AccessOptions.WorkWithDoctor,
    },
    {
      label: `I should not have received this order`,
      value: AccessOptions.RoutingError,
    },
  ]

  const SupplierInfo = () => {
    if (!supplierInformation) return null

    const supplierInfo = supplierInformation.logoUrl ? (
      <div className="d-flex justify-content-center align-items-center mb-3">
        <Cobranding
          supplierName={supplierInformation.name}
          supplierLogoUrl={supplierInformation.logoUrl}
        />
      </div>
    ) : (
      <div className="cobranding d-flex justify-content-center align-items-center mb-3">
        <h3 className="mb-0">{supplierInformation.name}</h3>
        <span className="cobranding-plus">+</span>
        <Logo />
      </div>
    )

    return supplierInfo
  }

  const handleSubmit = (values: FormValues) => {
    switch (values.role) {
      case AccessOptions.Doctor:
        login({ doctorId: doctor.doctorId })
        break
      case AccessOptions.WorkWithDoctor:
        history.push(reviewerIdentificationFormPath)
        break
      case AccessOptions.RoutingError:
        history.push(routingErrorPath)
        break
    }
  }

  return (
    <>
      <div className={styles.signatureLoginIntro}>
        <h1 className="text-center mb-3">Welcome to Parachute Health</h1>
        <p className="text-center mb-3">
          A patient&apos;s order needs to be signed by Dr. {doctor.firstName}{" "}
          {doctor.lastName}, NPI {doctor.npi}
        </p>
      </div>
      <SupplierInfo />
      <Form onSubmit={handleSubmit}>
        <RadioButtons
          name="role"
          className="primary mb-3"
          block
          onChange={(event) => {
            setSelectedOption(!!event.currentTarget.value)
          }}
          options={formOptions()}
        />
        <Button
          type="submit"
          className="btn-brand btn-block"
          disabled={!selectedOption}
        >
          Continue
        </Button>
        <div className={styles.legalese}>
          <Legalese entityName={doctorBasicInfo(doctor)} />
        </div>
      </Form>
      <div className="text-center">
        <span className="mr-1">Already a Parachute Health User?</span>
        <Link
          className="link link-secondary"
          href={parachuteUserLoginPath}
          onClick={onUserSignInLink}
        >
          Sign In
        </Link>
      </div>
    </>
  )
}

export default SigningSignatureRequestsAccess
