// @team @clinical-intelligence
import React from "react"
import ClinicalInformationRow from "applications/Workflow/containers/ClinicalFacilityClinical/components/DocumentationRequirements/ClinicalInformationRow"
import ExternalQuestionnaireModalButton from "./ExternalQuestionnaireModalButton"

interface Props {
  title: string
  questionnaire: object
  recommendation: string
}

const MedicalNecessityRow = (props: Props) => {
  return (
    <ClinicalInformationRow
      title={props.title}
      showDetails={false}
      isDocumentParachuteGenerated={true}
      status={
        <ExternalQuestionnaireModalButton
          title={props.title}
          questionnaire={props.questionnaire}
        />
      }
      recommendation={props.recommendation}
    />
  )
}

export default MedicalNecessityRow
