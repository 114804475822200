// @team @facilitators
// @ts-strict-ignore
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { datadogRum } from "@datadog/browser-rum"
import {
  initialCatalogDataForProductFirstSearch,
  productFirstSearch,
  initialCatalogDataForAllSuppliers,
  initialCatalogDataForSupplier,
  searchCatalog,
  searchPackagesWithSkus,
  searchPackagesWithSkuCompatibleWithProductFirstSearch,
  selectDefaultConsignmentCloset as apiSelectDefaultConsignmentCloset,
  selectPackage as apiSelectPackage,
  selectPackageV2,
  selectPackageWithSku as apiSelectPackageWithSku,
} from "./api"
import { updateUiPreferences } from "applications/Workflow/api"
import Overlay from "components/Overlay"
import withInitialData from "components/withInitialData"
import Header from "./components/Header"
import Grid from "./components/Grid"
import Pagination from "./components/Pagination"
import ConsignmentClosetNudgeModal from "./components/ConsignmentClosetNudgeModal"
import * as routes from "applications/Workflow/routes"
import {
  CatalogSearchTab,
  CatalogSearchType,
  ConsignmentCloset,
  Context,
  DmeOrder,
  NetworkCoverage,
  CatalogBrowseTab,
  SearchWorkflow,
  Supplier,
} from "sharedTypes"
import {
  Package,
  PackageSku,
  PackageWithCartData,
  SupplierContactDetails,
} from "./sharedTypes"
import { ALL_SUPPLIERS } from "applications/Workflow/containers/Product"
import GlobalContext from "context/Global"
import { scrollTop } from "utilities/scroll"
import { handleError } from "utilities/error"
import SidebarAwareContainer from "./components/SideBarAwareContainer"
import ProductFilters from "./components/ProductFilters"
import ProductSearch from "./components/ProductSearch"
import { useFeatureFlags } from "components/FeatureFlagContext"
import { allowSearchByProduct } from "../../utilities/searchByProduct"
import { AxiosPromise, AxiosResponse } from "axios"
import debounce from "awesome-debounce-promise"

const noResults = (
  <div className="alert alert-default text-center">
    <h1>No packages match your filters</h1>
  </div>
)

type Category = {
  id: number
  name: string
}

type CategoryWithSubcategories = Category & {
  subcategories: Category[]
}

type CatalogResult = {
  packages: Package[]
  availableCategories: CategoryWithSubcategories[]
  currentPage: number
  totalPages: number
  categories?: CategoryWithSubcategories[]
}

type CatalogFilter = {
  supplierId?: number
  consignmentClosetOnly: boolean
  formularyOnly: boolean
  yourOrganizationsSuppliersOnly: boolean
}

type SearchParams = {
  loading?: boolean
  categoryId?: number
  supplierId?: number
  query?: string
  consignmentClosetOnly?: boolean
  formularyOnly?: boolean
  yourOrganizationsSuppliersOnly?: boolean
  page: number
}

type Props = {
  initialData: {
    categories: CategoryWithSubcategories[]
    canFilterByFormulary: boolean
    consignmentClosets: ConsignmentCloset[]
    defaultConsignmentClosetId: string
    initialCatalogResult: CatalogResult
    initialFilter: CatalogFilter
    networkCoverage: NetworkCoverage | undefined
    supplier: Supplier | undefined
    supplierContactDetails: SupplierContactDetails
  }
  dmeOrder: DmeOrder
  supplierId?: string
  currentCatalogBrowseTab: CatalogBrowseTab
  yourOrganizationsSuppliersOnly: boolean
  toggleYourOrganizationsSuppliersOnly: () => void
}

const search = async (
  params: SearchParams,
  catalogSearch: (params: any) => AxiosPromise
) => {
  return catalogSearch(params)
}

const debouncedSearch = debounce(search, 250)

const Browsing: React.FC<Props> = ({
  initialData,
  dmeOrder,
  supplierId: supplierIdProp,
  currentCatalogBrowseTab,
  yourOrganizationsSuppliersOnly: initialYourOrganizationsSuppliersOnly,
  toggleYourOrganizationsSuppliersOnly,
}: Props) => {
  const context = useContext(GlobalContext)
  const history = useHistory()
  const { isFeatureEnabled } = useFeatureFlags()
  const { consignmentClosets } = initialData

  const initialSelectedTab =
    dmeOrder.clinicalFacility.catalogSearchType ===
    CatalogSearchType.PackageFilter
      ? CatalogSearchTab.PackageFilter
      : CatalogSearchTab.SkuQuickAdd

  const initialConsignmentClosetId =
    consignmentClosets.length === 1
      ? consignmentClosets[0].externalId
      : initialData.defaultConsignmentClosetId
  const PER_PAGE = 12

  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [categoryId, setCategoryId] = useState(null)
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab)
  const [defaultConsignmentClosetId, setDefaultConsignmentClosetId] = useState(
    initialConsignmentClosetId
  )
  const [data, setData] = useState(initialData.initialCatalogResult)
  const [categories, setCategories] = useState(initialData.categories)
  const [catalogPackageToSelect, setCatalogPackageToSelect] = useState(null)
  const [
    yourOrganizationsSuppliersOnly,
    setYourOrganizationsSuppliersOnly,
  ] = useState(initialYourOrganizationsSuppliersOnly)
  const [consignmentClosetOnly, setConsignmentClosetOnly] = useState(
    initialData.initialFilter.consignmentClosetOnly
  )
  const [formularyOnly, setFormularyOnly] = useState(
    initialData.initialFilter.formularyOnly
  )
  const [supplierId, setSupplierId] = useState(
    initialData.initialFilter.supplierId
  )
  const [searchText, setSearchText] = useState("")
  const [initialLoad, setInitialLoad] = useState(true)

  const supplierIdParam = () => supplierIdProp || ALL_SUPPLIERS

  const isConsignmentClosetSelectionSkipped = () => {
    return context.uiPreferences.skippedConsignmentClosetSelections?.find(
      (skipped) => {
        return (
          skipped.supplierId === initialData.supplier.externalId &&
          skipped.clinicalFacilityId === dmeOrder.clinicalFacility.externalId
        )
      }
    )
  }

  const viewPackageConfig = (
    packageConfigurationId: string,
    selecting = false
  ): Promise<void> => {
    history.push(
      routes.productsPackageConfigurationPath(
        supplierIdParam(),
        packageConfigurationId,
        selecting
      )
    )
    return Promise.resolve()
  }

  const selectPackage = (
    catalogPackage: PackageWithCartData
  ): Promise<void> => {
    if (catalogPackage.packageConfigurationId) {
      return viewPackageConfig(catalogPackage.packageConfigurationId)
    }

    const defaultCloset = consignmentClosets.find(
      ({ externalId }) => externalId === defaultConsignmentClosetId
    )

    if (
      consignmentClosets.length > 1 &&
      !defaultCloset &&
      !isConsignmentClosetSelectionSkipped()
    ) {
      setCatalogPackageToSelect(catalogPackage)
      return Promise.resolve()
    } else {
      return actuallySelectPackage(catalogPackage)
    }
  }

  const markAsSkipped = () => {
    const { uiPreferences } = context as Context
    uiPreferences.skippedConsignmentClosetSelections =
      uiPreferences.skippedConsignmentClosetSelections || []
    if (!isConsignmentClosetSelectionSkipped()) {
      uiPreferences.skippedConsignmentClosetSelections.push({
        supplierId: initialData.supplier.externalId,
        clinicalFacilityId: dmeOrder.clinicalFacility.externalId,
      })
      updateUiPreferences(uiPreferences)
    }
  }

  const cancelConsignmentClosetNudgeModal = () => {
    markAsSkipped()
    actuallySelectPackage()
  }

  const selectDefaultConsignmentCloset = (consignmentClosetId) => {
    return apiSelectDefaultConsignmentCloset({ consignmentClosetId }).then(
      () => {
        setDefaultConsignmentClosetId(consignmentClosetId)
        updateParams({ page: 1 }, consignmentClosetId)
        if (catalogPackageToSelect) {
          actuallySelectPackage(null, consignmentClosetId)
        }
      }
    )
  }

  const actuallySelectPackage = (
    catalogPackage?: PackageWithCartData,
    consignmentClosetId?: string
  ): Promise<void> => {
    const body = {
      dmeOrderPackageConfiguration: {
        catalogPackageId: (catalogPackage || catalogPackageToSelect).id,
        formularyOnly,
        consignmentClosetOnly,
        consignmentClosetId: consignmentClosetId || defaultConsignmentClosetId,
        supplierId,
        searchWorkflow: searchWorkflow(),
      },
    }

    const selectPackageAPI = isFeatureEnabled("marketplacePackageConfiguration")
      ? selectPackageV2
      : apiSelectPackage
    setLoading(true)
    return selectPackageAPI(body).then(({ data }) => {
      setLoading(false)
      setCatalogPackageToSelect(null)
      viewPackageConfig(data.id, true)
    }, handleError)
  }

  const apiSearchPackagesWithSku = (searchQuery: string) => {
    const params = {
      consignmentClosetOnly,
      formularyOnly,
      supplierId,
      query: searchQuery,
    }
    return searchPackagesWithSkus(params)
  }

  const apiSearchPackagesWithSkuCompatibleWithProductFirstSearch = (
    searchQuery: string
  ) => {
    const params = {
      consignmentClosetOnly,
      formularyOnly,
      yourOrganizationsSuppliersOnly,
      supplierId,
      query: searchQuery,
    }
    return searchPackagesWithSkuCompatibleWithProductFirstSearch(params)
  }

  const apiSearchBySku = () => {
    return isProductFirstSearch()
      ? apiSearchPackagesWithSkuCompatibleWithProductFirstSearch
      : apiSearchPackagesWithSku
  }

  const selectPackageWithSku = (packageSku: PackageSku) => {
    const { catalogPackageId, catalogProductVariationId } = packageSku

    const body = {
      dmeOrderPackageConfiguration: {
        catalogPackageId,
        catalogProductVariationId,
        formularyOnly,
        consignmentClosetOnly,
        supplierId,
        searchWorkflow: searchWorkflow(),
      },
    }
    apiSelectPackageWithSku(body).then(
      ({ data }) => viewPackageConfig(data.id),
      handleError
    )
  }

  const getParams = useCallback(
    (params?: SearchParams, consignmentClosetId?: string) => {
      const consignmentClosetIdParam =
        consignmentClosetId || defaultConsignmentClosetId

      return {
        page,
        perPage: PER_PAGE,
        consignmentClosetOnly,
        consignmentClosetIds: consignmentClosetIdParam
          ? [consignmentClosetIdParam]
          : [],
        formularyOnly,
        yourOrganizationsSuppliersOnly,
        searchText,
        supplierId,
        categoryId,
        ...params,
      }
    },
    [
      categoryId,
      consignmentClosetOnly,
      defaultConsignmentClosetId,
      formularyOnly,
      page,
      searchText,
      supplierId,
      yourOrganizationsSuppliersOnly,
    ]
  )

  const goToPage = (page: number) => {
    scrollTop()
    updateParams({ page })
  }

  const toggleConsignmentClosetFilter = async () => {
    updateParams({
      consignmentClosetOnly: !consignmentClosetOnly,
      page: 1,
    })
  }

  const toggleFormularyFilter = () => {
    updateParams({ formularyOnly: !formularyOnly, page: 1 })
  }

  const toggleYourOrganizationsSuppliersFilter = () => {
    updateParams({
      yourOrganizationsSuppliersOnly: !yourOrganizationsSuppliersOnly,
      page: 1,
    })
    toggleYourOrganizationsSuppliersOnly()
  }

  const selectCategory = async (categoryId: number) => {
    await updateParams({ categoryId, page: 1 })
  }

  const isProductFirstSearch = useCallback(
    () =>
      allowSearchByProduct(
        isFeatureEnabled("userActivationProductFirstSearch"),
        isFeatureEnabled("userActivationProductFirstSearchIncludeEnterprise"),
        dmeOrder.clinicalFacility.usesEnterpriseFeatures
      ) && currentCatalogBrowseTab === CatalogBrowseTab.Product,
    [
      currentCatalogBrowseTab,
      dmeOrder.clinicalFacility.usesEnterpriseFeatures,
      isFeatureEnabled,
    ]
  )

  const searchWorkflow = () => {
    switch (currentCatalogBrowseTab) {
      case CatalogBrowseTab.Product:
        return SearchWorkflow.ProductFirstSearch
      case CatalogBrowseTab.Supplier:
        return SearchWorkflow.SupplierFirstSearch
    }
  }

  const updateData = (response: AxiosResponse) => {
    const { data } = response
    const { categories } = data
    if (categories) {
      setCategories(categories)
    }
    setData(data)
    setLoading(false)
  }

  const updateParams = async (
    params: SearchParams,
    consignmentClosetId?: string
  ) => {
    const newParams = {
      ...getParams(params, consignmentClosetId),
      query: searchText,
    }
    setLoading(true)

    if (params) {
      if ("supplierId" in params) {
        setSupplierId(params.supplierId)
      }
      if ("categoryId" in params) {
        setCategoryId(params.categoryId)
      }
      if ("consignmentClosetOnly" in params) {
        setConsignmentClosetOnly(params.consignmentClosetOnly)
      }
      if ("formularyOnly" in params) {
        setFormularyOnly(params.formularyOnly)
      }
      if ("yourOrganizationsSuppliersOnly" in params) {
        setYourOrganizationsSuppliersOnly(params.yourOrganizationsSuppliersOnly)
      }
      if ("page" in params) {
        setPage(params.page)
      }
    }

    const catalogSearch = isProductFirstSearch()
      ? productFirstSearch
      : searchCatalog

    try {
      const response = await search(newParams, catalogSearch)
      updateData(response)
    } catch (e) {
      setLoading(false)
      handleError(e)
    }
  }

  const updateQuery = useCallback(
    async (consignmentClosetId?) => {
      datadogRum.addAction("user triggers search", { searchText })

      const params = {
        ...getParams(null, consignmentClosetId),
        query: searchText,
      }
      const catalogSearch = isProductFirstSearch()
        ? productFirstSearch
        : searchCatalog

      return debouncedSearch(params, catalogSearch)
    },
    [getParams, isProductFirstSearch, searchText]
  )

  useEffect(() => {
    async function fetchData() {
      try {
        if (!initialLoad) {
          setLoading(true)
        } else {
          setInitialLoad(false)
        }
        const response = await updateQuery()
        updateData(response)
      } catch (e) {
        setLoading(false)
        handleError(e)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // Without this eslint-disable-next-line react-hooks/exhaustive-deps, the linter will complain about initialLoad not
    //   being included in the dependency array. If it is added the useEffect will run an extra time after the initial
    //   load causing the loading spinner to show for a second after the initial load.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, setSearchText, updateQuery])

  const categoryOptions = (
    availableCategories: CategoryWithSubcategories[]
  ) => {
    const findCategory = (
      categories: CategoryWithSubcategories[],
      categoryId: number
    ) => categories.find((otherCategory) => otherCategory.id === categoryId)
    const findSubCategory = (categories: Category[], categoryId: number) =>
      categories.find((otherCategory) => otherCategory.id === categoryId)
    const isDisabled = (categoryId: number) =>
      !findCategory(availableCategories, categoryId)
    const isSubDisabled = (parentCategoryId: number, categoryId: number) => {
      const parentCategory = findCategory(availableCategories, parentCategoryId)
      return (
        !parentCategory ||
        !findSubCategory(parentCategory.subcategories, categoryId)
      )
    }
    const categoryToOption = ({ name, id, subcategories }) => ({
      label: name,
      value: id,
      disabled: isDisabled(id),
      suboptions:
        subcategories.length <= 1
          ? []
          : subcategories.map((subcategory) => ({
              label: subcategory.name,
              value: subcategory.id,
              disabled: isSubDisabled(id, subcategory.id),
            })),
    })
    return [{ label: "All Categories", value: null, disabled: false }].concat(
      categories.map(categoryToOption)
    )
  }

  const packagesWithCartData = useCallback(() => {
    const groupsById = dmeOrder.lineItemGroups.reduce(
      (accum, lig) => ({
        ...accum,
        [lig.packageId]: lig,
      }),
      {}
    )

    return data.packages.map((pkg) => {
      const lineItemGroup = groupsById[pkg.externalId]
      return {
        ...pkg,
        packageConfigurationId:
          lineItemGroup && lineItemGroup.packageConfigurationId,
        numberInCart: (lineItemGroup && lineItemGroup.quantity) || 0,
      }
    })
  }, [data, dmeOrder])

  const [packages, setPackages] = useState(packagesWithCartData())
  useEffect(() => {
    const packages = packagesWithCartData()
    setPackages(packages)
  }, [data, setData, dmeOrder, packagesWithCartData])

  const {
    canFilterByFormulary,
    supplier,
    networkCoverage,
    supplierContactDetails,
  } = initialData
  const combinedCatalogSearch = isFeatureEnabled("combinedCatalogSearch")
  const showProductGrid =
    !combinedCatalogSearch ||
    selectedTab === CatalogSearchTab.PackageFilter ||
    currentCatalogBrowseTab === CatalogBrowseTab.Product
  const hideHeader = isProductFirstSearch()
  const { oneTimeOrderEnabled, catalogSearchType } = dmeOrder.clinicalFacility
  const canFilterByYourOrganizationsSuppliers =
    isProductFirstSearch() && oneTimeOrderEnabled

  return (
    <>
      {hideHeader ? null : (
        <Header
          dmeOrder={dmeOrder}
          networkCoverage={networkCoverage}
          supplier={supplier}
          supplierContactDetails={supplierContactDetails}
        />
      )}

      <div className="row">
        <ProductFilters
          dmeOrder={dmeOrder}
          consignmentClosetOnly={consignmentClosetOnly}
          toggleConsignmentClosetFilter={toggleConsignmentClosetFilter}
          defaultConsignmentClosetId={defaultConsignmentClosetId}
          consignmentClosets={consignmentClosets}
          selectDefaultConsignmentCloset={selectDefaultConsignmentCloset}
          formularyOnly={formularyOnly}
          canFilterByFormulary={canFilterByFormulary}
          toggleFormularyFilter={toggleFormularyFilter}
          canFilterByYourOrganizationsSuppliers={
            canFilterByYourOrganizationsSuppliers
          }
          yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
          toggleYourOrganizationsSuppliersFilter={
            toggleYourOrganizationsSuppliersFilter
          }
          categoryOptions={categoryOptions(data.availableCategories)}
          selectedCategory={categoryId}
          selectCategory={selectCategory}
          currentCatalogBrowseTab={currentCatalogBrowseTab}
          combinedCatalogSearch={combinedCatalogSearch}
        ></ProductFilters>
        <SidebarAwareContainer role="productGrid">
          <ProductSearch
            canFilterByFormulary={canFilterByFormulary}
            catalogSearchType={catalogSearchType}
            combinedCatalogSearch={combinedCatalogSearch}
            filterQuery={searchText}
            formularyPriceEnabled={
              dmeOrder.clinicalFacility.formularyPriceEnabled
            }
            searchPackagesWithSku={apiSearchBySku()}
            selectPackageWithSku={selectPackageWithSku}
            supplierIdParam={supplierIdParam()}
            updateFilterQuery={setSearchText}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            currentCatalogBrowseTab={currentCatalogBrowseTab}
          />
          {showProductGrid ? (
            <Overlay showSpinner active={loading}>
              <Grid
                currentCatalogBrowseTab={currentCatalogBrowseTab}
                dmeOrder={dmeOrder}
                onSelect={selectPackage}
                packages={packages}
                yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
              />
              {packages.length === 0 && noResults}
            </Overlay>
          ) : undefined}
        </SidebarAwareContainer>
      </div>
      <div className="row">
        <SidebarAwareContainer role="paginationLink">
          {packages.length > 0 && showProductGrid && (
            <Overlay active={loading} showSpinner={false}>
              <Pagination
                goToPage={goToPage}
                currentPage={data.currentPage}
                totalPages={data.totalPages}
              />
            </Overlay>
          )}
        </SidebarAwareContainer>
      </div>
      <ConsignmentClosetNudgeModal
        selectDefaultCloset={selectDefaultConsignmentCloset}
        show={!!catalogPackageToSelect}
        cancel={cancelConsignmentClosetNudgeModal}
        closets={consignmentClosets}
      />
    </>
  )
}

const fetchInitialData = ({
  supplierId,
  currentCatalogBrowseTab,
  yourOrganizationsSuppliersOnly,
}: Omit<Props, "initialData">): Promise<Props["initialData"]> => {
  if (supplierId && supplierId !== ALL_SUPPLIERS) {
    return initialCatalogDataForSupplier(supplierId).then(({ data }) => data)
  }

  if (currentCatalogBrowseTab === CatalogBrowseTab.Product) {
    return initialCatalogDataForProductFirstSearch(
      yourOrganizationsSuppliersOnly
    ).then(({ data }) => data)
  }

  return initialCatalogDataForAllSuppliers().then(({ data }) => data)
}

export default withInitialData(fetchInitialData)(Browsing)
