import React from "react"
import Icon from "components/Icon"
import { sanitizeUrl } from "@braintree/sanitize-url"

type LinkProps = {
  title: string
  url: string
}
export const Link = ({ title, url }: LinkProps) => (
  <a href={sanitizeUrl(url)} target="_blank" rel="noopener noreferrer">
    {title}
    <Icon type="external-link-alt" size="xs" className="pl-1" />
  </a>
)
