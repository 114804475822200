import React, { useState } from "react"
import {
  Context,
  EmrCachedPatients,
  HospicePatient,
  MasterPatient,
  RecentOrder,
} from "sharedTypes"
import { capitalize } from "utilities/string"
import IntegratedData from "./IntegratedData"
import CreateOrderButton from "./CreateOrderButton"
import RecentOrdersSection from "./RecentOrdersSection"
import NewOrderForm from "applications/NewOrderForm"
import HospiceDetail from "./HospiceDetail"
import { FormData } from "./UpdatePatientHospiceForm"
import EmrResetPasswordAlert from "./Alerts/EmrResetPasswordAlert"
import PendingOrderAlert from "./Alerts/PendingOrderAlert"
import { EmailResponse } from "../sharedTypes"

type Props = {
  context: Context
  masterPatient: MasterPatient
  emrPatient: boolean
  emrCachedPatients: EmrCachedPatients
  hospicePatient: HospicePatient
  hospiceEnabled: boolean
  createDmeOrder(patientId?: string, cachedEncounterId?: string): Promise<void>
  reorderDmeOrder(dmeOrderId: string): Promise<void>
  recentOrders: RecentOrder[]
  showDmeOrderUrl(dmeOrderId: string): string
  showSuppliers: boolean
  permissions: {
    updateHospice: boolean
    createOrder: boolean
  }
  sendEmrResetPasswordEmail(): Promise<EmailResponse>
  supplierSystemPatientLinkages: { supplierSystemPatientId: string }[]
  updateHospiceDates(
    values: FormData
  ): Promise<{ hospicePatient: HospicePatient }>
}

const InternalPatientProfile: React.FC<Props> = ({
  context,
  masterPatient,
  emrPatient,
  emrCachedPatients,
  recentOrders,
  createDmeOrder,
  reorderDmeOrder,
  showDmeOrderUrl,
  hospicePatient,
  hospiceEnabled,
  showSuppliers,
  permissions,
  updateHospiceDates,
  sendEmrResetPasswordEmail,
  supplierSystemPatientLinkages,
}) => {
  const [hasPendingOrders, setHasPendingOrders] = useState(false)

  const demographics = () => (
    <p className="mb-2">
      <small className="pr-3">
        <strong>DOB: </strong>
        {masterPatient.dateOfBirth}
      </small>
      <br className="d-block d-md-none" />
      <small className="pr-3">
        <strong>Sex: </strong>
        {capitalize(masterPatient.sex)}
      </small>
      <br className="d-block d-md-none" />
      {supplierSystemPatientLinkages.length > 0 && (
        <small className="pr-3">
          <strong>
            Supplier System Patient ID
            {supplierSystemPatientLinkages.length > 1 ? "s: " : ": "}
          </strong>
          {supplierSystemPatientLinkages
            .map((l) => l.supplierSystemPatientId)
            .join(", ")}
        </small>
      )}
    </p>
  )

  const hospiceSection = () => {
    if (hospiceEnabled) {
      return (
        <HospiceDetail
          hospicePatient={hospicePatient}
          updateHospiceDates={updateHospiceDates}
          updateHospicePermission={permissions.updateHospice}
        />
      )
    }
  }

  const newOrderButton = () => {
    if (!permissions.createOrder) {
      return
    }

    if (
      ["supplier", "supplier_organization"].includes(
        context.currentEmployer.employerType
      )
    ) {
      return (
        <div className="float-right">
          <NewOrderForm
            employer={context.currentEmployer}
            masterPatient={masterPatient}
          />
        </div>
      )
    }

    if (!emrPatient) {
      return (
        <div>
          <CreateOrderButton
            createDmeOrder={() => createDmeOrder()}
            isPrimary
            isBrand={false}
          />
        </div>
      )
    }
  }

  const renderHeader = () => {
    return (
      <div className="pb-2">
        <h1>
          {masterPatient.firstName} {masterPatient.lastName}
        </h1>
        <div className="row">
          <div className="col-md-8 pt-3">
            {demographics()}
            {hospiceSection()}
          </div>
          <div className="col-md-4 text-right">{newOrderButton()}</div>
        </div>
        <hr className="my-2" />
      </div>
    )
  }

  return (
    <div className="container">
      {context.showEmrResetPasswordBanner && (
        <EmrResetPasswordAlert
          sendEmrResetPasswordEmail={() => sendEmrResetPasswordEmail()}
        />
      )}

      {renderHeader()}
      {context.featureFlags.referralApiPendingOrderEnabled &&
        emrCachedPatients?.map((emrCachedPatient) => (
          <PendingOrderAlert
            key={emrCachedPatient.externalId}
            patientExternalId={emrCachedPatient.externalId}
            showDmeOrderUrl={showDmeOrderUrl}
            onQueryCompleted={({ pendingOrder }) =>
              setHasPendingOrders(hasPendingOrders || pendingOrder !== null)
            }
          />
        ))}
      {emrPatient && emrCachedPatients.length > 0 && (
        <IntegratedData
          emrCachedPatients={emrCachedPatients}
          createDmeOrder={createDmeOrder}
          canCreateOrder={permissions.createOrder}
          hasPrimaryAction={!hasPendingOrders}
        />
      )}
      <RecentOrdersSection
        recentOrders={recentOrders}
        showDmeOrderUrl={showDmeOrderUrl}
        showSuppliers={showSuppliers}
        reorderDmeOrder={reorderDmeOrder}
        currentEmployer={context.currentEmployer}
      />
    </div>
  )
}

export default InternalPatientProfile
