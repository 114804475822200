// @ts-strict-ignore
import { get, put, post, del, uploadDocumentToUrl } from "services/api"
import * as urls from "applications/Workflow/urls"
import { handleError } from "utilities/error"
import {
  UiPreferences,
  DocumentationRequirementSatisfyStatus,
  Supplier,
} from "sharedTypes"
import {
  updateDocumentationRequirementsSatisfyStatusUrl,
  documentationUploadUrl,
  createAttachmentGroupUrl,
} from "applications/Workflow/urls"
import { PackageConfiguration } from "./containers/PackageConfiguration/sharedTypes"
import { AxiosPromise } from "axios"

export function getPackageConfiguration(
  packageConfigurationId,
  searchWorkflow
): AxiosPromise<PackageConfiguration> {
  return get(
    `${urls.packageConfigurationUrl(
      packageConfigurationId
    )}?searchWorkflow=${searchWorkflow}`
  ) as AxiosPromise<PackageConfiguration>
}

export function updatePackageConfiguration(
  packageConfigurationId,
  params,
  searchWorkflow
): AxiosPromise<PackageConfiguration> {
  return put(
    `${urls.packageConfigurationUrl(
      packageConfigurationId
    )}?searchWorkflow=${searchWorkflow}`,
    params
  ) as AxiosPromise<PackageConfiguration>
}

export function deletePackageConfiguration(packageConfigurationId) {
  return del(`${urls.packageConfigurationUrl(packageConfigurationId)}`)
}

export const getPackageConfigurationV2 = (
  packageConfigurationId
): AxiosPromise<PackageConfiguration> => {
  return get(
    `${urls.packageConfigurationV2Url(packageConfigurationId)}`
  ) as AxiosPromise<PackageConfiguration>
}
export const createPackageConfigurationErrorNotification = (
  packageConfigurationId
) => {
  return post(
    `${urls.packageConfigurationErrorNotificationUrl(packageConfigurationId)}`
  )
}

export function getPreferredSuppliers(
  insuranceProductId: string,
  params
): Promise<Supplier[]> {
  return get(urls.getPreferredSuppliersUrl(insuranceProductId), params).then(
    ({ data }) => data
  )
}

export function getDmeOrder() {
  return get(urls.dmeOrder()).then(({ data }) => data)
}

export function getWarnings() {
  return put(urls.dmeOrderWarningsUrl()).then(({ data }) => data)
}

export const updateProductLineItemGroup = (id, params) =>
  put(urls.updateLineItemGroupUrl(id), params)

export function getInitialCartData() {
  return get(urls.initialCartData())
}

export function updateUiPreferences(uiPreferences: UiPreferences) {
  return put(urls.updateUiPreferencesUrl(), { uiPreferences })
}

export const updateDocumentationRequirementsSatisfyStatus = (values) => {
  return put(updateDocumentationRequirementsSatisfyStatusUrl(), values).then(
    ({ data }) => data
  )
}

const uploadDocumentation = (
  file: File,
  signed: boolean,
  attachmentGroupId: string
) => {
  return uploadDocumentToUrl(
    documentationUploadUrl(signed, attachmentGroupId),
    file,
    null
  ).then(() => {})
}

const createAttachmentGroup = (values: {
  documentationRequirementIds: string[]
  expectedAttachmentCount: number
  signed: boolean
  otherAttachmentTitle: string
}) => post(createAttachmentGroupUrl(), values).then(({ data }) => data)

export const uploadDocuments = (
  documentationRequirementIds: string[],
  files: File[],
  signed: boolean,
  otherAttachmentTitle: string
) =>
  createAttachmentGroup({
    documentationRequirementIds,
    expectedAttachmentCount: files.length,
    signed,
    otherAttachmentTitle,
  }).then(({ externalId }) =>
    Promise.all(
      files.map((file) => uploadDocumentation(file, signed, externalId))
    )
      .then(() =>
        updateDocumentationRequirementsSatisfyStatus({
          manualSatisfyStatus: DocumentationRequirementSatisfyStatus.Complete,
          documentationRequirementIds: documentationRequirementIds,
        })
      )
      .catch((error) => {
        handleError(
          error,
          "Could not upload documents. Please confirm the documents are valid PDFs and try again."
        )
        throw error
      })
  )
