import React from "react"
import Logo from "components/Logo"

export const groupSearchResults = (facilities) => {
  return [
    {
      label: (
        <>
          <Logo className="mr-1" width="16" />
          FACILITIES ON PARACHUTE
        </>
      ),
      options: facilities.filter((facility) => !!facility.externalId),
    },
    {
      label: "OTHER FACILITIES",
      options: facilities.filter((facility) => !facility.externalId),
    },
  ].filter((group) => group.options.length > 0)
}
