import React, { useState, useEffect } from "react"
import Flatpickr from "react-flatpickr"
import {
  DATE_FORMAT,
  format,
  isDateValid,
  BUTTON_DATE_FORMAT,
} from "utilities/date"
import TextInput from "../TextInput"
import { DATE_MASK } from "../MaskedInput"
import { english } from "flatpickr/dist/l10n/default.js"
import Button from "../form/Button"
import Popover, { ContentLocation } from "../Popover"
import classNames from "classnames"
import Icon from "../Icon"

type Props = {
  name: string
  title: string
  onChange?(values: { [key: string]: string }): void
  popoverContentLocation?: ContentLocation
  minDateLabel?: string
  maxDateLabel?: string
  minDate?: string
  maxDate?: string
  dirty?: boolean
  initialStartDate?: string
  initialEndDate?: string
  isStartOptional: boolean
  isEndOptional: boolean
  enableReinitialize?: boolean
  fullWidthPill?: boolean
}

const DATEPICKER_DATE_FORMAT = "m/d/Y"
const WEEKDAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]

function formatForButton(date) {
  if (!date) {
    return
  }
  return format(new Date(date), BUTTON_DATE_FORMAT)
}

function formatDate(date) {
  if (!date) {
    return ""
  }
  if (!isDateValid(date)) {
    return ""
  }
  return format(date)
}

const DateRangeFilter = ({
  name,
  title,
  onChange,
  minDateLabel,
  maxDateLabel,
  initialStartDate,
  initialEndDate,
  dirty,
  minDate,
  maxDate,
  isStartOptional,
  isEndOptional,
  popoverContentLocation,
  enableReinitialize = false,
  fullWidthPill,
}: Props) => {
  const [open, setOpen] = useState(false)
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)

  useEffect(() => {
    if (enableReinitialize) {
      setStartDate(initialStartDate)
      setEndDate(initialEndDate)
    }
  }, [enableReinitialize, initialStartDate, initialEndDate])

  const clear = () => {
    setStartDate("")
    setEndDate("")
  }

  const handleChange = (startDate, endDate) => {
    const newStartDate = isDateValid(startDate) ? startDate : null
    const newEndDate = isDateValid(endDate) ? endDate : null
    const payload = {
      [`${name}StartAt`]: newStartDate,
      [`${name}EndAt`]: newEndDate,
    }
    if (onChange) {
      onChange(payload)
    }
    setOpen(false)
  }

  const dropFilter = (event) => {
    event.stopPropagation()
    clear()
    handleChange("", "")
  }

  const isActive = () => {
    return [initialStartDate, initialEndDate].filter(Boolean).length > 0
  }

  const getLabel = () => {
    let result = title
    if (isActive()) {
      const dateStr = [initialStartDate, initialEndDate]
        .map((el) => formatForButton(el))
        .join(" - ")
      if (dateStr) {
        result += ": " + dateStr
      }
    }
    return result
  }
  return (
    <Popover
      className="d-inline-block v-align-top mb-3"
      open={open}
      size="date-selector"
      onClick={() => setOpen((open) => !open)}
      onHide={() => handleChange(startDate, endDate)}
      contentLocation={popoverContentLocation}
      label={
        <Button
          className={classNames("btn-pill btn-sm mr-2", {
            active: isActive(),
            warning: dirty,
            "has-icon": isActive(),
            "btn-pill-full-width": fullWidthPill,
          })}
        >
          {getLabel()}
          {isActive() && <Icon type="times" clickable onClick={dropFilter} />}
        </Button>
      }
    >
      <Flatpickr
        options={{
          mode: "range",
          defaultDate: [startDate, endDate],
          minDate,
          maxDate,
          disable: [],
          onChange: (dates) => {
            setStartDate(formatDate(dates[0]))
            setEndDate(formatDate(dates[1]))
          },
          inline: true,
          allowInput: true,
          clickOpens: false,
          dateFormat: DATEPICKER_DATE_FORMAT,
          locale: {
            ...english,
            weekdays: {
              shorthand: WEEKDAYS,
            },
          },
        }}
        render={(props, ref) => {
          return (
            <div className="p1">
              <div className="d-flex">
                <div className="flex-1 text-center">
                  <label htmlFor="start">
                    <small className="bold">
                      {" "}
                      {minDateLabel} {isStartOptional && "(Optional)"}
                    </small>
                  </label>
                </div>
                <div className="flex-1 text-center">
                  <label htmlFor="end">
                    <small className="bold">
                      {" "}
                      {maxDateLabel} {isEndOptional && "(Optional)"}
                    </small>
                  </label>
                </div>
              </div>
              <div className="d-flex">
                <div className="flex-1">
                  <TextInput
                    className="input-date mx-auto"
                    onChange={(event) => setStartDate(event.target.value)}
                    autoComplete="off"
                    value={startDate}
                    mask={DATE_MASK}
                    placeholder={DATE_FORMAT}
                    id="start"
                  />
                </div>
                <div className="flex-1">
                  <TextInput
                    className="input-date mx-auto"
                    onChange={(event) => setEndDate(event.target.value)}
                    autoComplete="off"
                    value={endDate}
                    mask={DATE_MASK}
                    placeholder={DATE_FORMAT}
                    id="end"
                  />
                </div>
              </div>
              <div ref={ref} />
              <div className="float-right mt-1">
                <Button className="btn-sm btn-link" onClick={clear}>
                  Clear
                </Button>
                <Button
                  className="btn-sm btn-brand"
                  onClick={() => handleChange(startDate, endDate)}
                >
                  Done
                </Button>
              </div>
            </div>
          )
        }}
      />
    </Popover>
  )
}

DateRangeFilter.defaultProps = {
  minDateLabel: "Date 1",
  maxDateLabel: "Date 2",
  isStartOptional: true,
  isEndOptional: true,
}

export default DateRangeFilter
