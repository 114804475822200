import React, { useState, useContext } from "react"
import { Document } from "components/DocumentsBrowser/sharedTypes"
import {
  DocumentationRequirement,
  DocumentationRequirementSatisfyStatus,
} from "sharedTypes"
import DownloadLink from "components/DownloadLink"
import Icon from "components/Icon"
import AttachManualDocumentationModal from "applications/Workflow/components/AttachManualDocumentationModal"
import GlobalContext from "context/Global"

export type Permissions = {
  createDocuments: boolean
  manuallySatisfyGeneratedDocs: boolean
}

type Props = {
  dmeOrderId: string
  permissions: Permissions
  documents: Document[]
  documentationRequirements: DocumentationRequirement[]
  updateDocumentationRequirementsSatisfyStatus(values: {
    manualSatisfyStatus: DocumentationRequirementSatisfyStatus
    documentationRequirementIds: string[]
  }): Promise<void>
  uploadDocuments(
    documentationRequirementIds: string[],
    files: File[],
    signed: boolean,
    otherAttachmentTitle: string
  ): Promise<any>
  supplierFaxNumber: string
  hasSigningClinician: boolean
}

const DOCUMENT_KEY = "all_documents_download"

const DownloadUploadButtons = ({
  dmeOrderId,
  permissions,
  documents,
  documentationRequirements,
  updateDocumentationRequirementsSatisfyStatus,
  uploadDocuments,
  supplierFaxNumber,
  hasSigningClinician,
}: Props) => {
  const { requiresTwoStepDownload } = useContext(GlobalContext)
  const [showModal, setShowModal] = useState(false)
  const showDownloadLink = documents.length > 0
  const renderAttachDocuments = () => {
    return (
      <>
        <AttachManualDocumentationModal
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          documentationRequirements={documentationRequirements}
          updateDocumentationRequirementsSatisfyStatus={
            updateDocumentationRequirementsSatisfyStatus
          }
          uploadDocuments={uploadDocuments}
          dmeOrderId={dmeOrderId}
          supplierFaxNumber={supplierFaxNumber}
          hasSigningClinician={hasSigningClinician}
          manuallySatisfyGeneratedDocs={
            permissions.manuallySatisfyGeneratedDocs
          }
        />

        <button className="link small mr-2" onClick={() => setShowModal(true)}>
          <Icon type="paperclip" className="mr-2" />
          Upload or Fax Documents
        </button>
      </>
    )
  }

  return (
    <div className="horizontal-divider">
      <div className="mt-2">
        {showDownloadLink && (
          <DownloadLink
            orderId={dmeOrderId}
            documentKey={DOCUMENT_KEY}
            label="All Documents"
            className="link small mr-2"
            requiresTwoStepDownload={requiresTwoStepDownload}
          />
        )}
        {permissions.createDocuments && renderAttachDocuments()}
      </div>
    </div>
  )
}

export default DownloadUploadButtons
