import uniq from "lodash/uniq"
import startCase from "lodash/startCase"

const EQUIPMENT_STATUS_MESSAGES = {
  confirmation_in_progress: "Confirmation in Progress",
  completed: "Completed",
  ready_for_delivery: "Ready for Delivery",
  held_for_documentation: "Held: Documentation Changes Required",
  held_for_copay: "Held: Patient Copay Required",
  held_for_documentation_and_copay: "Held: Documentation & Copay Required",
  third_party_delivery: "Delivered by Third Party",
  delivery_successful: "Delivery Successful",
  canceled: "Canceled",
  delivery_unsuccessful: "Attempted - will be rescheduled",
  pending_authorization: "Pending Authorization",
  pending_delivery_ticket: "Pending Delivery Ticket",
  pending_further_review: "Pending Further Review",
  scheduling_delivery: "Scheduling Delivery",
  backordered: "Backordered",
  partially_shipped: "Partially Shipped",
  patient_refused: "Patient Refused",
  return_in_progress: "Return in Progress",
  return_complete: "Return Complete",
  contacting_patient: "Contacting Patient",
  cannot_dispense: "Cannot Dispense",
  submitted_to_basic_service_tier_supplier: "eFaxed to Supplier",
}

const EQUIPMENT_STATUS_LEVEL = {
  third_party_delivery: "success",
  delivery_successful: "success",
  completed: "success",
  confirmation_in_progress: "default",
  contacting_patient: "default",
  partially_shipped: "default",
  pending_delivery_ticket: "default",
  pending_further_review: "default",
  processing: "default",
  ready_for_delivery: "default",
  return_complete: "default",
  scheduling_delivery: "default",
  submitted_to_basic_service_tier_supplier: "default",
  held_for_copay: "warning",
  held_for_documentation: "warning",
  held_for_documentation_and_copay: "warning",
  pending_authorization: "warning",
  return_in_progress: "warning",
  backordered: "danger",
  canceled: "danger",
  cannot_dispense: "danger",
  delivery_unsuccessful: "danger",
  patient_refused: "danger",
}

const SERVICE_STATUS_MESSAGES = {
  accepted: "Accepted",
  accepted_pt_already_on_service: "Accepted: Pt Already on Service",
  pending_review: "Pending Review",
  pending_patient_contact: "Pending Patient Contact",
  pending_patient_discharge: "Pending Patient Discharge",
  pending_patient_acceptance: "Pending Patient Acceptance",
  pending_documentation: "Pending Documentation",
  unable_to_accept_pt_refused: "Unable to Accept: Pt Refused",
  unable_to_accept_out_of_area: "Unable to Accept: Out of Area",
  unable_to_accept_no_skilled_need: "Unable to Accept: No Skilled Need",
  unable_to_accept_other_agency: "Unable to Accept: Other Agency",
  unable_to_accept_no_capacity: "Unable to Accept: No Capacity",
  unable_to_accept_other: "Unable to Accept: Other",
  unable_to_accept_insurance: "Unable to Accept: Insurance",
  unable_to_accept_pt_transferred: "Unable to Accept: Pt Transferred",
  previous_vnsny_episode_ffe: "Previous VNSNY Episode: FFE",
  previous_vnsny_episode_485: "Previous VNSNY Episode: 485",
}

const SERVICE_STATUS_LEVEL = {
  accepted: "success",
  accepted_pt_already_on_service: "success",
  pending_review: "warning",
  processing: "default",
  pending_patient_contact: "warning",
  pending_patient_discharge: "warning",
  pending_patient_acceptance: "warning",
  pending_documentation: "warning",
  unable_to_accept_pt_refused: "danger",
  unable_to_accept_out_of_area: "danger",
  unable_to_accept_no_skilled_need: "danger",
  unable_to_accept_other_agency: "danger",
  unable_to_accept_no_capacity: "danger",
  unable_to_accept_other: "danger",
  unable_to_accept_insurance: "danger",
  unable_to_accept_pt_transferred: "danger",
  previous_vnsny_episode_ffe: "danger",
  previous_vnsny_episode_485: "danger",
}

const O2_RECERT_STATUS_MESSAGES = {
  completed: "Completed",
  canceled: "Canceled",
  missing_documentation: "Missing Documentation",
  needs_face_to_face: "Needs Face To Face",
}

const O2_RECERT_STATUS_LEVEL = {
  completed: "success",
  canceled: "danger",
  missing_documentation: "warning",
  needs_face_to_face: "warning",
}

export const formatDeliveryStatuses = (fulfillments) => {
  const deliveryStatuses = fulfillments
    .map((fulfillment) => formatDeliveryStatus(fulfillment.deliveryStatus))
    .filter(Boolean)
    .sort()

  return uniq(deliveryStatuses).join(", ")
}

export const formatDeliveryStatus = (deliveryStatus) => {
  return (
    EQUIPMENT_STATUS_MESSAGES[deliveryStatus] ||
    SERVICE_STATUS_MESSAGES[deliveryStatus] ||
    O2_RECERT_STATUS_MESSAGES[deliveryStatus] ||
    startCase(deliveryStatus)
  )
}

export const getDeliveryStatusSeverity = (deliveryStatus) => {
  return (
    EQUIPMENT_STATUS_LEVEL[deliveryStatus] ||
    SERVICE_STATUS_LEVEL[deliveryStatus] ||
    O2_RECERT_STATUS_LEVEL[deliveryStatus] ||
    "default"
  )
}
