import React from "react"
import { asForm, NumberField } from "components/form"
import { Employer, EmployerType } from "sharedTypes"

type HeightSectionProps = {
  useFeetForHeight: boolean
  changeHeightUnit(): void
  currentEmployer: Employer
}

function HeightSection({
  useFeetForHeight,
  changeHeightUnit,
  currentEmployer,
}: HeightSectionProps) {
  return (
    <div className="row">
      <div className="col-md-12">
        <label htmlFor="heightInches" className="d-block">
          Patient height
          {currentEmployer.employerType === "clinical_facility" && (
            <a className="link ml-3" onClick={changeHeightUnit}>
              {useFeetForHeight ? "Use inches" : "Use feet & inches"}
            </a>
          )}
        </label>
      </div>

      {useFeetForHeight && (
        <div className="col-md-3">
          <NumberField
            name="heightFeet"
            suffix="ft"
            min={0}
            autoComplete="off"
          />
        </div>
      )}

      <div className="col-md-3">
        <NumberField
          name="heightInches"
          suffix="in"
          min={0}
          autoComplete="off"
        />
      </div>
    </div>
  )
}

function WeightSection() {
  return (
    <div className="row">
      <div className="col-md-3">
        <NumberField
          label="Patient weight"
          name="weightLbs"
          suffix="lb"
          min={0}
          autoComplete="off"
        />
      </div>
    </div>
  )
}

function WoundSection() {
  return (
    <div className="row">
      <div className="col-md-3">
        <NumberField
          label="Wound count"
          name="numberOfWounds"
          suffix="#"
          min={0}
          autoComplete="off"
        />
      </div>
    </div>
  )
}

type Props = {
  isValid: boolean
  requiresHeight: boolean
  requiresWeight: boolean
  requiresWoundCount: boolean
  useFeetForHeight: boolean
  changeHeightUnit(): void
  currentEmployer: Employer
  onSkip(): void
}

function RequirementsForm({
  isValid,
  currentEmployer,
  useFeetForHeight,
  changeHeightUnit,
  onSkip,
  requiresHeight,
  requiresWeight,
  requiresWoundCount,
}: Props) {
  return (
    <>
      {requiresHeight && (
        <HeightSection
          useFeetForHeight={useFeetForHeight}
          changeHeightUnit={changeHeightUnit}
          currentEmployer={currentEmployer}
        />
      )}
      {requiresWeight && <WeightSection />}
      {requiresWoundCount && <WoundSection />}
      <div className="float-right">
        {currentEmployer.employerType === EmployerType.Supplier && (
          <button type="button" className="btn btn-brand-o" onClick={onSkip}>
            Skip
          </button>
        )}
        <button
          type="submit"
          className="ml-2 btn btn-brand"
          disabled={!isValid}
        >
          Continue
        </button>
      </div>
    </>
  )
}

export default asForm(RequirementsForm)
