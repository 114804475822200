import { CircularProgress, MenuItem } from "@material-ui/core"
import React, { forwardRef, useRef, useState } from "react"
import { meta } from "utilities/meta"
import classNames from "classnames"
import * as styles from "./index.module.scss"

type Props = {
  label: string | HTMLElement
  url: string | URL
  redirectUrl?: string | URL
  parameterName?: string
  styles?: string
}

/**
 * This shared component satisfies a _very_ specific use case within Catalog index pages,
 * namely, where dropdowns are used as file uploads, and the upload occurs immediately upon file
 * selection; it's not a very good design pattern, but it works, and has feature parity with the legacy version.
 *
 * It's wrapped in `React.forwardRef` in order for it to play nicely with ThemeProviders (see https://stackoverflow.com/a/62648323)
 * @deprecated
 */
const FileUploadMenuItem = forwardRef<HTMLElement, Props>(
  (props: Props, _ref) => {
    const formRef = useRef<HTMLFormElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [submitting, setSubmitting] = useState<boolean>(false)

    return (
      <MenuItem
        onClick={() => inputRef.current?.click()}
        key={props.url.toString()}
        className={classNames(styles.fileUploadMenuItem, props.styles)}
      >
        {submitting && <CircularProgress className="mr-1" size={20} />}
        <form
          ref={formRef}
          encType="multipart/form-data"
          method="post"
          action={props.url.toString()}
        >
          {props.redirectUrl && (
            <input
              type="hidden"
              name="redirect_url"
              value={props.redirectUrl.toString()}
            />
          )}
          <input
            type="hidden"
            name="authenticity_token"
            value={meta("csrf-token")}
          />
          <input
            accept="*/csv"
            type="file"
            ref={inputRef}
            name={props.parameterName || "file"}
            onChange={() => {
              setSubmitting(true)
              formRef.current?.submit()
            }}
          />
          {props.label}
        </form>
      </MenuItem>
    )
  }
)

export default FileUploadMenuItem
