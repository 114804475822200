import React from "react"
import Modal from "components/Modal"
import { Form, NativeSelect as Select, TextField } from "components/form"
import Overlay from "components/Overlay"
import { states } from "utilities/address"

type Props = {
  onClose(name?): void
  requestNewFacility(values): Promise<any>
}

const RequestFacilityModal = ({ onClose, requestNewFacility }: Props) => {
  const stateOptions = states.map((state) => ({
    label: state[0],
    value: state[1],
  }))
  const initialValues = {
    name: "",
    npi: "",
    line1: "",
    line2: "",
    city: "",
    state: "NY",
    phoneNumber: "",
    faxNumber: "",
    zip: "",
  }
  const onSubmit = (values, { setErrors, setSubmitting }) => {
    requestNewFacility(values)
      .then(() => {
        onClose(values.name)
      })
      .catch((errors) => {
        setErrors(errors)
        setSubmitting(false)
      })
  }

  return (
    <Modal
      size="md"
      title="Request new clinical facility"
      show
      cancel={onClose}
    >
      <Modal.Body>
        <Form initialValues={initialValues} onSubmit={onSubmit}>
          {({ isSubmitting }) => {
            return (
              <Overlay active={isSubmitting} showSpinner>
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextField label="Name" name="name" />
                    </div>
                    <div className="col-md-6">
                      <TextField label="NPI" name="npi" />
                    </div>
                  </div>
                  <hr />
                  <h5 className="mb-4">
                    <strong>(Optional) Contact Info</strong>
                  </h5>
                  <div className="row">
                    <div className="col-md-6">
                      <TextField label="Phone Number" name="phoneNumber" />
                    </div>
                    <div className="col-md-6">
                      <TextField label="Fax Number" name="faxNumber" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextField label="City" name="city" />
                    </div>
                    <div className="col-md-3">
                      <Select
                        label="State"
                        name="state"
                        options={stateOptions}
                        unwrapped
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField label="ZIP" name="zip" numeric unwrapped />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextField label="Street - Line 1" name="line1" />
                    </div>
                    <div className="col-md-6">
                      <TextField label="Street - Line 2" name="line2" />
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-link color-danger"
                    onClick={() => onClose(null)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </Overlay>
            )
          }}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default RequestFacilityModal
