import React from "react"
import Button from "components/form/Button"

interface Props {
  onAddSupplierClick: () => void
}

const Header: React.FC<Props> = ({ onAddSupplierClick }) => {
  return (
    <>
      <div className="clearfix">
        <div className="float-left">
          <h1>Manage Suppliers</h1>
        </div>
        <div className="float-right">
          <Button className="btn-brand btn-sm" onClick={onAddSupplierClick}>
            + Add Supplier
          </Button>
        </div>
      </div>
      <p className="color-dark-gray font-paragraph mb-3">
        Manage your organization's suppliers.
      </p>
    </>
  )
}

export default Header
