import React from "react"
import { DmeOrder } from "sharedTypes"

type Props = {
  dmeOrder: DmeOrder
}
const DiagnosesSectionContent = ({ dmeOrder }: Props) => {
  return (
    <ul>
      {dmeOrder.icd10Codes.map((icd10Code) => (
        <li className="mb-2" key={icd10Code.code}>
          <span className="bold color-black">{icd10Code.codeLabel}</span> -{" "}
          {icd10Code.description}
        </li>
      ))}
    </ul>
  )
}

export default DiagnosesSectionContent
