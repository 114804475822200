import React from "react"
import letter from "letter-success.png"
import eventEmitter from "utilities/eventEmitter"

type Props = {
  email: string
  dismissParentModal?(): void
}

function VerifyEmailMemo({ email, dismissParentModal }: Props) {
  return (
    <div className="text-center pt-5 pb-2">
      <div className="my-2">
        <img src={letter} width="220" />
      </div>
      <h1>Verify your email address</h1>
      <p>
        Follow the link in the email sent to {email} to verify your email and
        set up your Parachute Health account.
      </p>
      <p className="py-4 mt-5">
        Need to change your email?{" "}
        <a
          className="link link-primary"
          onClick={() => {
            if (dismissParentModal) {
              dismissParentModal()
            }
            eventEmitter.emit("chat-link:clicked")
          }}
        >
          Chat with us.
        </a>
      </p>
    </div>
  )
}

export default VerifyEmailMemo
