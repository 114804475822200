import React from "react"

interface Props {
  signUpUrl: string
}

function SignUpLink({ signUpUrl }: Props) {
  return (
    <div className="text-center mt-3">
      Don’t have an account?
      <a href={signUpUrl} className="ml-1 link">
        Sign up
      </a>
    </div>
  )
}

export default SignUpLink
