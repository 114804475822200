import React, { useEffect, useState, useCallback } from "react"
import orderIllustration from "order-illustration.svg"
import { navigate } from "utilities/navigation"
import Icon from "components/Icon"
import { useFeatureFlags } from "components/FeatureFlagContext"

interface Props {
  poll(): Promise<{ successPath?: string; dmeOrderPath?: string }>
}

function EmrDataLoader({ poll }: Props) {
  const [failed, setFailed] = useState(false)
  const [errors, setErrors] = useState("")

  const pollForStatus = useCallback(
    () =>
      poll()
        .then(({ successPath, dmeOrderPath }) =>
          navigate(successPath || dmeOrderPath)
        )
        .catch((data?: { errors: string[] }) => {
          setFailed(true)
          setErrors(data && data.errors ? data.errors.join(" ") : "")
        }),
    [poll]
  )

  useEffect(() => {
    pollForStatus()
  }, [pollForStatus])

  const { isFeatureEnabled } = useFeatureFlags()
  const newPatientLoadingForm = isFeatureEnabled("newPatientLoadingForm")

  const v1_header = failed ? (
    <h3>
      An error occurred while loading patient data. Contact your IT team for
      assistance. {errors}
    </h3>
  ) : (
    <h3>
      <Icon className="mr-3" type="spinner" spin />
      Loading data from EMR...
    </h3>
  )

  const v2_header = failed ? (
    <>
      An error occurred while loading patient data. Contact your IT team for
      assistance. {errors}
    </>
  ) : (
    <>
      <span className="canopy-mr-8x">
        <Icon
          className="canopy-typography-heading-2xlarge"
          type="spinner"
          spin
        />
      </span>
      Loading data from EMR...
    </>
  )

  if (newPatientLoadingForm) {
    return (
      <div className="text-center canopy-mb-48x">
        <h3 className="canopy-typography-body-large canopy-mt-24x">
          {v2_header}
        </h3>
        <img
          src={orderIllustration}
          alt="Order illustration"
          className="canopy-my-24x"
        />
      </div>
    )
  } else {
    return (
      <div className="text-center mt-3">
        {v1_header}
        <img src={orderIllustration} alt="Order illustration" width="320" />
      </div>
    )
  }
}

export default EmrDataLoader
