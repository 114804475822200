import React from "react"
import { Fulfillment, Address, OfferingType } from "sharedTypes"
import { Form, NativeSelect as Select } from "components/form"
import DatePickerField from "components/form/DatePickerField"
import { isDateValid } from "utilities/date"
import { formatAddress } from "utilities/address"

export type FormData = {
  deliveryStatus?: string
  expectedDeliveryDate?: string
  actualDeliveryDate?: string
}

type Props = {
  fulfillment: Fulfillment
  deliveryAddress: Address | null
  updateFulfillment(values: FormData): Promise<void>
}

const EQUIPMENT_STATUSES = [
  { value: "", label: "Processing" },
  { value: "backordered", label: "Backordered" },
  { value: "canceled", label: "Canceled" },
  { value: "cannot_dispense", label: "Cannot Dispense" },
  { value: "completed", label: "Completed" },
  { value: "confirmation_in_progress", label: "Confirmation In Progress" },
  { value: "contacting_patient", label: "Contacting Patient" },
  { value: "delivery_successful", label: "Delivery Successful" },
  { value: "delivery_unsuccessful", label: "Delivery Unsuccessful" },
  { value: "held_for_copay", label: "Held For Copay" },
  { value: "held_for_documentation", label: "Held For Documentation" },
  {
    value: "held_for_documentation_and_copay",
    label: "Held For Documentation And Copay",
  },
  { value: "partially_shipped", label: "Partially Shipped" },
  { value: "patient_refused", label: "Patient Refused" },
  { value: "pending_authorization", label: "Pending Authorization" },
  { value: "pending_delivery_ticket", label: "Pending Delivery Ticket" },
  { value: "pending_further_review", label: "Pending Further Review" },
  { value: "ready_for_delivery", label: "Ready For Delivery" },
  { value: "return_complete", label: "Return Complete" },
  { value: "return_in_progress", label: "Return In Progress" },
  { value: "scheduling_delivery", label: "Scheduling Delivery" },
  { value: "third_party_delivery", label: "Third Party Delivery" },
]

const SERVICE_STATUSES = [
  { value: "", label: "Processing" },
  { value: "accepted", label: "Accepted" },
  {
    value: "accepted_pt_already_on_service",
    label: "Accepted: Pt Already on Service",
  },
  { value: "pending_review", label: "Pending Review" },
  { value: "pending_patient_contact", label: "Pending Patient Contact" },
  { value: "pending_patient_discharge", label: "Pending Patient Discharge" },
  { value: "pending_patient_acceptance", label: "Pending Patient Acceptance" },
  { value: "pending_documentation", label: "Pending Documentation" },
  {
    value: "unable_to_accept_pt_refused",
    label: "Unable to Accept: Pt Refused",
  },
  {
    value: "unable_to_accept_out_of_area",
    label: "Unable to Accept: Out of Area",
  },
  {
    value: "unable_to_accept_no_skilled_need",
    label: "Unable to Accept: No Skilled Need",
  },
  {
    value: "unable_to_accept_other_agency",
    label: "Unable to Accept: Other Agency",
  },
  {
    value: "unable_to_accept_no_capacity",
    label: "Unable to Accept: No Capacity",
  },
  { value: "unable_to_accept_other", label: "Unable to Accept: Other" },
  { value: "unable_to_accept_insurance", label: "Unable to Accept: Insurance" },
  {
    value: "unable_to_accept_pt_transferred",
    label: "Unable to Accept: Pt Transferred",
  },
  { value: "previous_vnsny_episode_ffe", label: "Previous VNSNY Episode: FFE" },
  { value: "previous_vnsny_episode_485", label: "Previous VNSNY Episode: 485" },
]

const O2_RECERT_STATUSES = [
  { value: "", label: "Processing" },
  { value: "completed", label: "Completed" },
  { value: "canceled", label: "Canceled" },
  { value: "missing_documentation", label: "Missing Documentation" },
  { value: "needs_face_to_face", label: "Needs Face To Face" },
]

export default (props: Props) => {
  const { fulfillment, updateFulfillment, deliveryAddress } = props
  const onSubmit = (values, { setErrors }) => {
    return updateFulfillment(values).catch((response) => {
      if (response.errors) {
        setErrors(response.errors)
      }
    })
  }
  const statusOptions = () => {
    switch (fulfillment.offeringType) {
      case OfferingType.Equipment:
        return EQUIPMENT_STATUSES
      case OfferingType.Service:
        return SERVICE_STATUSES
      case OfferingType.O2Recert:
        return O2_RECERT_STATUSES
      default:
        return []
    }
  }

  return (
    <tr>
      <td className="mw-200">{formatAddress(deliveryAddress)}</td>
      <td>
        <Form
          onSubmit={onSubmit}
          initialValues={{ deliveryStatus: fulfillment.deliveryStatus }}
          submitOnChange
        >
          <Select name="deliveryStatus" options={statusOptions()} />
        </Form>
      </td>
      <td className="line-height-3">{fulfillment.deliveryDate}</td>
      <td className="dme_order_fulfillment_expected_delivery_date">
        {(fulfillment.isReadyForDelivery || fulfillment.hasDeliveredStatus) && (
          <Form
            onSubmit={onSubmit}
            initialValues={{
              expectedDeliveryDate: fulfillment.expectedDeliveryDate,
            }}
            validate={({ expectedDeliveryDate }) =>
              isDateValid(expectedDeliveryDate)
                ? {}
                : { expectedDeliveryDate: "Invalid date" }
            }
            submitOnChange
          >
            <DatePickerField
              name="expectedDeliveryDate"
              disabled={!fulfillment.isReadyForDelivery}
            />
          </Form>
        )}
      </td>
      <td className="dme_order_fulfillment_actual_delivery_date">
        {fulfillment.hasDeliveredStatus && (
          <Form
            onSubmit={onSubmit}
            initialValues={{
              actualDeliveryDate: fulfillment.actualDeliveryDate,
            }}
            validate={({ actualDeliveryDate }) =>
              isDateValid(actualDeliveryDate)
                ? {}
                : { actualDeliveryDate: "Invalid date" }
            }
            submitOnChange
          >
            <DatePickerField name="actualDeliveryDate" autoFocus />
          </Form>
        )}
      </td>
    </tr>
  )
}
