import React from "react"

interface Props {
  stateName: string
}

const ServiceAreaWarning: React.FC<Props> = ({ stateName }) => (
  <div className="d-flex align-items-center mb-3 mt-2">
    <span className="icon fas fa-exclamation-triangle color-warning mr-1" />
    <span className="font-subparagraph color-dark-gray">
      This supplier may not service {stateName}
    </span>
  </div>
)

export default ServiceAreaWarning
