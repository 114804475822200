import React, { useState } from "react"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { cancelOrder } from "./api"
import { navigate } from "utilities/navigation"
import { employerHomeUrl } from "applications/Workflow/urls"
import { handleError } from "utilities/error"
import { canopyColorTextDanger } from "@parachutehealth/canopy-tokens-color"

type Props = {
  className: string
}

const CancelButton = ({ className }: Props) => {
  const [cancelling, setCancelling] = useState(false)
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onClick = () => setOpen(true)
  const activatorElementRef = React.useRef<HTMLButtonElement>(null)

  const onCancel = () =>
    cancelOrder()
      .then(() => {
        navigate(employerHomeUrl())
      })
      .catch(handleError)

  const handleCancel = () => {
    setCancelling(true)
    onCancel()
      .then(() => {
        onClose()
        setCancelling(false)
      })
      .catch(() => {
        setCancelling(false)
      })
  }

  return (
    <>
      <CanopyButton
        fullWidth
        onClick={onClick}
        type="button"
        style={{ color: canopyColorTextDanger }}
        className={className}
        variant="tertiary"
        loading={cancelling}
        ref={activatorElementRef}
      >
        Cancel Order
      </CanopyButton>
      <CanopyDialogModal
        activatorElementRef={activatorElementRef}
        onClose={onClose}
        open={open}
        header="Cancel Order"
        primaryFooterButton={
          <CanopyButton onClick={handleCancel} variant="danger">
            Confirm order cancellation
          </CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton onClick={onClose} variant="secondary">
            No, take me back
          </CanopyButton>
        }
        size="small"
      >
        Are you sure you want to cancel this order?
      </CanopyDialogModal>
    </>
  )
}

export default CancelButton
