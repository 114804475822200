import * as React from "react"
import pluralize from "pluralize"
import { SalesRepAssignment } from "../ReassignSalesRepsTable"

type Props = {
  salesRepAssignments: SalesRepAssignment[]
  givingSalesRepName: string
  recipientSalesRepName: string
}
const TransferSalesRepsEditMessage: React.FC<Props> = ({
  salesRepAssignments,
  givingSalesRepName,
  recipientSalesRepName,
}: Props) => {
  return (
    <>
      {salesRepAssignments && givingSalesRepName && recipientSalesRepName && (
        <div className="well well-info">
          <span>
            {salesRepAssignments.length}{" "}
            {pluralize("facility", salesRepAssignments.length)} will be
            transferred from {givingSalesRepName} to {recipientSalesRepName}.
          </span>
        </div>
      )}
    </>
  )
}

export default TransferSalesRepsEditMessage
