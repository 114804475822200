import React, { useLayoutEffect, useState } from "react"
import Button from "components/form/Button"
import Icon from "components/Icon"
import { scrollTop, getWindowOffsetTop } from "utilities/scroll"

const MINIMUM_REQUIRED_OFFSET = 150

const BackToTop = () => {
  const [topOffset, setTopOffset] = useState(0)
  const moveToTop = () => {
    scrollTop()
  }

  useLayoutEffect(() => {
    const onScroll = () => {
      setTopOffset(getWindowOffsetTop())
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  if (topOffset <= MINIMUM_REQUIRED_OFFSET) return null

  return (
    <Button
      className="ml-1 btn-sm btn-primary-o btn-rounded btn-bottom-left-fixed"
      onClick={moveToTop}
    >
      <Icon type="arrow-up" className="mr-2" />
      Back to top
    </Button>
  )
}

export default BackToTop
