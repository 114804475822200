import React from "react"
import Cobranding from "../../components/Cobranding"
import * as styles from "./index.module.scss"

interface Props {
  packageNames: string[]
  patient: {
    firstName: string
    lastName: string
  }
  supplier?: {
    name: string
    logoUrl?: string
  }
}

const Header: React.FC<Props> = ({ packageNames, patient, supplier }) => {
  const patientName = patient.firstName + "\u00A0" + patient.lastName

  return (
    <div className="mb-4">
      <div className={supplier?.logoUrl ? "pb-4" : "pb-2"}>
        <Cobranding
          supplierName={supplier?.name || ""}
          supplierLogoUrl={supplier?.logoUrl}
        />
      </div>
      <h1 className={styles.header}>
        Let's finish the order for {patientName}!
      </h1>
      <h3 className={styles.subhead}>{packageNames.join(", ")}</h3>
    </div>
  )
}

export default Header
