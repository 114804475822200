import React from "react"
import { Doctor, SignatureRequest } from "sharedTypes"
import Warning from "./Warning"
import { summarizeSignatureRequests } from "../../utilities/signature"
import { fullName } from "utilities/person"

const displaySignaturePendingWarning = (
  doctor,
  signatureRequests: SignatureRequest[],
  nextExpectedDate: string | undefined
) => {
  const nextExpectedText = `A signature reminder is scheduled to be sent on ${nextExpectedDate} at 12 pm EST.`

  if (!(signatureRequests.length && doctor)) {
    return (
      <div>
        {nextExpectedDate
          ? nextExpectedText
          : "Signature request is being sent."}
      </div>
    )
  }

  const {
    originalRequestsSummary,
    reminderRequestsSummary,
  } = summarizeSignatureRequests(signatureRequests)

  return (
    <div>
      <div>
        Requested from Dr. {fullName(doctor)} {originalRequestsSummary}.
        {nextExpectedDate && " " + nextExpectedText}
      </div>
      {reminderRequestsSummary && <div>Reminded {reminderRequestsSummary}</div>}
    </div>
  )
}

type Props = {
  doctor: Doctor
  signatureRequests: SignatureRequest[]
  trackEvents: boolean
  warningType: string
  nextExpectedDate: string | undefined
}
function SignaturePendingWarning({
  doctor,
  signatureRequests,
  trackEvents,
  warningType,
  nextExpectedDate,
}: Props) {
  return (
    <Warning
      severity="warning"
      title="Signature Pending"
      warningType={warningType}
      trackEvents={trackEvents}
    >
      {displaySignaturePendingWarning(
        doctor,
        signatureRequests,
        nextExpectedDate
      )}
    </Warning>
  )
}

export default SignaturePendingWarning
