// @ts-strict-ignore
import React, { useState } from "react"
import { Form, NativeSelect as Select } from "components/form"
import { InternalStatus } from "sharedTypes"
import Popover from "components/Popover"
import Icon from "components/Icon"
import { handleError } from "utilities/error"
import { updateStatus } from "./api"

type Props = {
  internalStatus: InternalStatus
  internalStatuses: InternalStatus[]
  readOnly?: boolean
  updateUrl: string
  noneLabel?: string
  useLinkClassName?: boolean
}

const NONE_LABEL = "None"

const InternalStatusEditor = ({
  internalStatuses,
  internalStatus,
  readOnly,
  updateUrl,
  noneLabel = NONE_LABEL,
  useLinkClassName = false,
}: Props) => {
  const [currentStatus, setCurrentStatus] = useState(internalStatus)
  const [isSubmitting, setSubmitting] = useState(false)
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen((open) => !open)
  const hide = () => setOpen(false)
  const close = () => {
    hide()
    setSubmitting(false)
  }

  const getStatusLabel = (status) => {
    if (status && currentStatus.status) {
      let result = status.status
      if (status.archivedAt) {
        result += " *"
      }
      return result
    } else {
      return noneLabel
    }
  }

  if (internalStatuses.length === 0) {
    return getStatusLabel(currentStatus)
  }

  const getOptions = (statuses) => {
    const defaultOptions = [{ label: NONE_LABEL, value: null }]
    return defaultOptions.concat(
      statuses.map((status) => ({
        label: status.status,
        value: status.id,
      }))
    )
  }

  const onChange = (values) => {
    setSubmitting(true)
    updateStatus(
      updateUrl,
      values.internalStatusId === NONE_LABEL ? null : values.internalStatusId
    )
      .then((response) => {
        setCurrentStatus(response)
        close()
      })
      .catch((response) => {
        handleError(response)
        close()
      })
  }

  if (readOnly) {
    return <span>{getStatusLabel(currentStatus)}</span>
  }

  return (
    <Popover
      open={open}
      onHide={hide}
      onClick={toggle}
      label={
        <a
          title="Show popover"
          className={useLinkClassName ? "link link-teal" : null}
        >
          {getStatusLabel(currentStatus)}
          <Icon type="chevron-down" className="ml-1 position-absolute" />
        </a>
      }
    >
      <Form
        initialValues={{
          internalStatusId: currentStatus ? currentStatus.id : "",
        }}
        onSubmit={onChange}
        submitOnChange
      >
        {currentStatus && currentStatus.archivedAt && (
          <div className="alert alert-warning alert-rounded alert-borderless mb-3">
            <span className="alert-icon mr-1" />
            This status has been archived.
          </div>
        )}
        <Select
          disabled={isSubmitting}
          name="internalStatusId"
          options={getOptions(internalStatuses)}
          autoFocus
        />
      </Form>
    </Popover>
  )
}

export default InternalStatusEditor
