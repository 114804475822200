import React from "react"
import { ClinicalFacility, EmployerType } from "sharedTypes"
import { DashboardDmeOrder } from "../../../sharedTypes"
import classNames from "classnames"
import ProgressDonut from "components/ProgressDonut"
import DeliveryStatus from "components/DeliveryStatus"
import SignatureStatus from "components/SignatureStatus"
import DeliveryDates from "components/DeliveryDates"
import { workflowsUrl } from "applications/Workflow/urls"
import { fullName } from "utilities/person"
import { getDeliveryStatusSeverity } from "utilities/deliveryStatus"
import { getSignatureStatusSeverity } from "utilities/signatureStatus"
import { getSeverity } from "utilities/swatch"
import LastComment from "./LastComment"
import MissingDocument from "components/MissingDocument"

type Props = {
  dmeOrder: DashboardDmeOrder
  clinicalFacility: ClinicalFacility
  unreadEventCount?: number
  showProgress?: boolean
  showSwatch?: boolean
  showMissingDocument?: boolean
}

function OrderCard({
  clinicalFacility,
  dmeOrder,
  unreadEventCount,
  showProgress,
  showSwatch,
  showMissingDocument,
}: Props) {
  const { fulfillments, createdAt } = dmeOrder
  const deliveryStatuses = fulfillments.map(
    (fulfillment) => fulfillment.deliveryStatus
  )
  const deliveryStatusSeverity = getSeverity(
    deliveryStatuses.map(getDeliveryStatusSeverity)
  )
  const severity = getSeverity([
    getSignatureStatusSeverity(dmeOrder.signatureStatus),
    deliveryStatusSeverity,
  ])

  return (
    <a
      className={classNames("card m-0 mb-3", showSwatch && `card-${severity}`)}
      href={workflowsUrl(dmeOrder.externalId)}
    >
      {unreadEventCount && <div className="card-badge">{unreadEventCount}</div>}
      <div className="clearfix">
        <div className="float-left">
          <h4 className="bold">{fullName(dmeOrder.patient)}</h4>
        </div>
        <div className="float-right">
          {showProgress && (
            <ProgressDonut
              className="progress-circle-s v-align-top ml-1"
              percentage={dmeOrder.progress}
            />
          )}
        </div>
      </div>
      <DeliveryStatus
        statusLevel={deliveryStatusSeverity}
        statusText={dmeOrder.status}
      />
      <SignatureStatus
        signatureStatus={dmeOrder.signatureStatus}
        orderStatus={dmeOrder.orderStatus}
        employerType={EmployerType.ClinicalFacility}
      />
      {showMissingDocument && (
        <MissingDocument
          missingDocument={dmeOrder.unsatisfiedManualDocumentation}
        />
      )}
      {dmeOrder.followerNames.length > 0 && (
        <div className="font-subparagraph mb-1">
          <span className="color-dark-gray mr-1">Followers:</span>
          {dmeOrder.followerNames.join(" | ")}
        </div>
      )}
      <div className="font-subparagraph mb-1">
        <span className="color-dark-gray mr-1">Delivery:</span>
        <DeliveryDates fulfillments={fulfillments} createdAt={createdAt} />
      </div>
      {clinicalFacility.multiSupplier && dmeOrder.supplier && (
        <div className="font-subparagraph mb-1">
          <span className="color-dark-gray mr-1">Supplier:</span>
          {dmeOrder.supplier.name}
        </div>
      )}
      {dmeOrder.categories.length > 0 && (
        <div className="font-subparagraph mb-1">
          <span className="color-dark-gray mr-1">Categories:</span>
          {dmeOrder.categories.join(", ")}
        </div>
      )}
      {dmeOrder.lastComment && (
        <LastComment lastComment={dmeOrder.lastComment} />
      )}
    </a>
  )
}

export default OrderCard
