import React from "react"
import { Form, TextField, DateField } from "components/form"
import { PatientFormParams } from "../sharedTypes"
import SexSelect from "components/form/Select/SexSelect"

type Props = {
  patientFormParams: PatientFormParams
  updatePatient(params: PatientFormParams): Promise<void>
  demographicsEditable: boolean
  supplierPatientIdVisible: boolean
  supplierPatientIdEditable: boolean
  mrnEditable: boolean
}

const PatientForm = ({
  patientFormParams,
  updatePatient,
  demographicsEditable,
  mrnEditable,
  supplierPatientIdVisible,
  supplierPatientIdEditable,
}: Props) => {
  const { firstName, lastName, dateOfBirth, sex, mrn } = patientFormParams
  const [submitting, setSubmitting] = React.useState(false)
  const onSubmit = (params, { setErrors }) => {
    setSubmitting(true)
    return updatePatient(params)
      .then((response) => {
        setSubmitting(false)
        return response
      })
      .catch(({ response }) => {
        setErrors(response.data.errors)
        setSubmitting(false)
      })
  }

  const supplierSystemPatientId = supplierPatientIdVisible
    ? patientFormParams.supplierSystemPatientId
    : null

  const initialValues = {
    firstName,
    lastName,
    dateOfBirth,
    sex,
    mrn,
    supplierSystemPatientId,
  }

  return (
    <div id="patientFormParams-form">
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <TextField
              label="First Name"
              name="firstName"
              disabled={!demographicsEditable}
            />
          </div>
          <div className="col-lg-6">
            <TextField
              label="Last Name"
              name="lastName"
              disabled={!demographicsEditable}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <DateField
              label="Date of Birth"
              name="dateOfBirth"
              disabled={!demographicsEditable}
            />
          </div>
          <div className="col-lg-6">
            <SexSelect isDisabled={!demographicsEditable} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <TextField
              label="Clinical MRN"
              name="mrn"
              disabled={!mrnEditable}
            />
          </div>
          {supplierPatientIdVisible && (
            <div className="col-lg-6">
              <TextField
                label="Supplier System Patient ID"
                name="supplierSystemPatientId"
                disabled={!supplierPatientIdEditable}
              />
            </div>
          )}
        </div>

        <div className="clearfix mt-2">
          <button
            type="submit"
            className="btn btn-brand float-right"
            disabled={submitting}
          >
            Continue
          </button>
        </div>
      </Form>
    </div>
  )
}

export default PatientForm
