// @ts-strict-ignore
import React, { useEffect } from "react"
import Modal from "components/Modal"
import Stepper from "components/Stepper"
import image1 from "welcome-illustration.png"
import image2 from "create-illustration.png"
import image3 from "track-illustration.png"
import image4 from "collaborate-illustration.png"

interface Props {
  dismiss(): void
  onComplete(): void
  onStepChange(step): void
  onMount(): void
  show?: boolean
}

const InternalWelcomeModal = ({
  dismiss,
  onComplete,
  onStepChange,
  onMount,
  show,
}: Props): JSX.Element => {
  useEffect(() => {
    onMount()
  }, [onMount])

  return (
    <Modal closeable verticalCenter show={show} cancel={dismiss}>
      <Modal.Body>
        <button
          className="close icon clickable position-absolute-right mr-4"
          type="button"
          onClick={dismiss}
        >
          ×
        </button>
        <Stepper
          pages={[
            <Step
              key="step1"
              image={image1}
              title="Welcome to Parachute Health!"
              description="Say goodbye to faxing orders and hello to Digital Ordering."
            />,
            <Step
              key="step2"
              image={image2}
              title="Ordering Made Simple"
              description="Order medical equipment and supplies with our intuitive digital workflow."
            />,
            <Step
              key="step3"
              image={image3}
              title="Track Orders With Confidence"
              description="Track the status of your orders to ensure safe and timely delivery."
            />,
            <Step
              key="step4"
              image={image4}
              title="Collaborate in Context"
              description="Communicate with colleagues and suppliers to ship orders faster."
            />,
          ]}
          completionText="Start Ordering"
          onComplete={onComplete}
          onStepChange={onStepChange}
        />
      </Modal.Body>
    </Modal>
  )
}

interface StepProps {
  image: string
  title: string
  description: string
}
const Step = ({ image, title, description }: StepProps) => {
  return (
    <>
      <img
        src={image}
        className="my-4 responsive"
        style={{ maxHeight: "270px" }}
        alt={title}
      />
      <h1>{title}</h1>
      <p>{description}</p>
    </>
  )
}

export default InternalWelcomeModal
