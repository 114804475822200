import React from "react"
import ActionsTitleSection, {
  Props as ActionTitleProps,
} from "../ActionsTitleSection"
import OrderAlerts, { Props as orderAlertsProps } from "./OrderAlerts"
import { Permissions } from "../../sharedTypes"
import ReorderForm from "../ReorderForm"
import { DmeOrder, Employer, SignatureStatus } from "sharedTypes"
import classNames from "classnames"
import cx from "classnames"
import SubmitButton from "../SubmitButton"
import { Link } from "react-router-dom"
import {
  reviewSendBackPath,
  supplierTransferPath,
  reviewSendBackFacilityWarningPath,
} from "applications/Workflow/routes"
import { EventCategory, trackEvent } from "utilities/tracking"
import SupplierEditButton from "../SupplierEditButton"
import GenerateInviteLinkButton from "../GenerateInviteLinkButton"
import SendSignatureRequestButton from "../SendSignatureRequestButton"
import ActionButton from "../ActionButton"
import SendSignatureReminderButton from "../SendSignatureReminderButton"

type Props = {
  dmeOrder: DmeOrder
  currentEmployer: Employer
  actionTitleProps: ActionTitleProps
  orderAlertsProps: orderAlertsProps
  showReorder: boolean
  showSubmit: boolean
  canSubmit: boolean
  permissions: Permissions
  sendSignatureRequest(params?: {}): Promise<void>
  updateSignatureStatus(): Promise<void>
  supplierEdit(): Promise<void>
  supplierUnaccept(): Promise<void>
  submit(): Promise<void>
  submissionPending: boolean
}

const SupplierActions = (props: Props) => {
  const {
    dmeOrder,
    currentEmployer,
    actionTitleProps: actionTitleProps,
    orderAlertsProps,
    showReorder,
    showSubmit,
    canSubmit,
    permissions,
    sendSignatureRequest,
    updateSignatureStatus,
    supplierEdit,
    supplierUnaccept,
    submit,
    submissionPending,
  } = props

  const warnUserBeforeSendBack =
    dmeOrder.createdVia === "supplier_api" &&
    dmeOrder.clinicalFacility.tempFacilityOrDoctorOffice

  const sendBackButtonLinkPath = warnUserBeforeSendBack
    ? reviewSendBackFacilityWarningPath()
    : reviewSendBackPath()

  return (
    <div className={cx("wide-fixed-row", "pb-3")}>
      <div className="card">
        <div className="horizontal-divider">
          <ActionsTitleSection {...actionTitleProps} />
        </div>
        <div className="horizontal-divider">
          <OrderAlerts {...orderAlertsProps} />
        </div>
        <div>
          {showReorder && (
            <div className="well-wide">
              <ReorderForm dmeOrderId={dmeOrder.id} />
            </div>
          )}
          {showSubmit && (
            <SubmitButton
              submissionPending={submissionPending}
              currentEmployer={currentEmployer}
              submit={submit}
              disabled={!canSubmit}
              supplierGeneratedPriorToPushBack={
                dmeOrder.supplierGeneratedPriorToPushBack
              }
            />
          )}
          {permissions.supplierSendBack && (
            <Link
              className={classNames("btn btn-block", {
                "btn-brand-o": !dmeOrder.supplierGeneratedPriorToPushBack,
                "btn-brand": dmeOrder.supplierGeneratedPriorToPushBack,
                disabled: submissionPending,
              })}
              to={sendBackButtonLinkPath}
            >
              Push Order to Facility
            </Link>
          )}
          {permissions.showSupplierTransfer && (
            <Link
              className={classNames("btn btn-block", {
                "btn-brand-o":
                  !dmeOrder.supplierGeneratedPriorToPushBack &&
                  permissions.enableSupplierTransfer,
                "btn-brand":
                  dmeOrder.supplierGeneratedPriorToPushBack &&
                  permissions.enableSupplierTransfer,
                disabled:
                  !permissions.enableSupplierTransfer || submissionPending,
              })}
              to={supplierTransferPath()}
              onClick={() =>
                trackEvent(
                  EventCategory.SupplierTransfer,
                  "supplier-transfer-modal-opened"
                )
              }
            >
              Transfer to Another Supplier
            </Link>
          )}
          {permissions.supplierEdit && (
            <SupplierEditButton onClick={supplierEdit} />
          )}
          {permissions.supplierGenerateInvite && <GenerateInviteLinkButton />}
          {permissions.sendSignatureRequest &&
            dmeOrder.signatureStatus !== SignatureStatus.Pending &&
            dmeOrder.signatureStatus !== SignatureStatus.Completed && (
              <SendSignatureRequestButton
                className="btn btn-brand-o btn-block"
                dmeOrder={dmeOrder}
                onClick={updateSignatureStatus}
              />
            )}
          {permissions.sendSignatureReminder && (
            <SendSignatureReminderButton
              className="btn btn-brand-o btn-block"
              sendSignatureRequest={sendSignatureRequest}
              disabled={dmeOrder.transferredOrderLocked}
            />
          )}
          {permissions.supplierUnaccept && (
            <ActionButton
              className="btn btn-brand-o btn-block"
              onClick={supplierUnaccept}
              type="button"
            >
              Send Back to Review
            </ActionButton>
          )}
        </div>
      </div>
    </div>
  )
}

export default SupplierActions
