import React from "react"
import { Form, TextField, Select, EmailField } from "components/form"
import Button from "components/form/Button"
import { isEmailValid } from "utilities/email"
import { Supplier } from "../../../../shared/sharedTypes"
import * as styles from "./index.module.scss"

interface Props {
  createUser(params): Promise<void>

  getSuppliers(term: string): Promise<Supplier[]>

  supplier?: Supplier
}

const CreateAccountForm = ({ createUser, getSuppliers, supplier }: Props) => {
  const onSubmit = (params, { setErrors }) => {
    const { firstName, lastName, email, supplierId, salesRepEmail } = params
    return createUser({
      firstName,
      lastName,
      email,
      supplierId,
      salesRepEmail,
    }).catch(({ errors }) => {
      if (errors.user) {
        setErrors(errors.user)
      }
    })
  }

  const validate = ({ email, firstName, lastName, salesRepEmail }) => {
    const errors: {
      email?: string
      firstName?: string
      lastName?: string
      supplierId?: string
      salesRepEmail?: string
    } = {}

    if (!isEmailValid(email)) {
      errors.email = "Enter email"
    }

    if (!firstName) {
      errors.firstName = "Enter first name"
    }
    if (!lastName) {
      errors.lastName = "Enter last name"
    }

    if (salesRepEmail && !isEmailValid(salesRepEmail)) {
      errors.salesRepEmail = "Enter email"
    }
    return errors
  }

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    supplierId: supplier?.externalId || "",
    salesRepEmail: "",
  }

  const referringSupplierLabel = () => {
    const optionalLabel = (
      <>
        <span>Referring supplier </span>
        <span className={styles.optionalLabel}>(optional)</span>
      </>
    )

    return optionalLabel
  }

  const referringSalesRepEmailLabel = () => (
    <>
      <span>Referring sales rep email </span>
      <span className={styles.optionalLabel}>(optional)</span>
    </>
  )

  return (
    <>
      <h1 className="mb-5">Create an account</h1>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <>
            <EmailField label="Email address" name="email" autoFocus />
            <div className="row">
              <div className="col-md-6">
                <TextField label="First name" name="firstName" />
              </div>
              <div className="col-md-6">
                <TextField label="Last name" name="lastName" />
              </div>
            </div>
            <Select
              label={referringSupplierLabel()}
              placeholder="Select..."
              name="supplierId"
              isSearchable
              isClearable
              minLength={2}
              options={
                supplier
                  ? [{ label: supplier.name, value: supplier.externalId }]
                  : []
              }
              fetchOptions={(term: string) =>
                getSuppliers(term).then((suppliers) => {
                  return suppliers.map((supplier) => ({
                    value: supplier.externalId,
                    label: supplier.name,
                  }))
                })
              }
            />
            <EmailField
              label={referringSalesRepEmailLabel()}
              name="salesRepEmail"
            />
            <Button
              disabled={isSubmitting}
              className="btn-brand btn-block"
              type="submit"
            >
              Submit
            </Button>
          </>
        )}
      </Form>
    </>
  )
}

export default CreateAccountForm
