// @ts-strict-ignore
import React, { useEffect, useRef, useState } from "react"
import Popover, { ContentLocation } from "components/Popover"
import Tooltip from "components/Tooltip"
import Icon from "components/Icon"
import Button from "components/form/Button"
import { components } from "react-select"
import { Form, Select } from "components/form"
import { toArray, unique } from "utilities/array"
import { pluralize } from "utilities/string"
import classNames from "classnames"

export type Record = {
  label: string
  value: string
}

type Props = {
  name: string
  label: string
  initialValues?: string | string[]
  dirty?: boolean
  options?: Record[]
  fetchOptions?(term: string): Promise<any>
  onChange(values: any): void
  onToggle?(open: boolean): void
  singular?: boolean
  popoverContentLocation?: ContentLocation
  minLength?: number
  enableReinitialize?: boolean
}

const Menu = ({ children, ...props }) => (
  <div
    key="react-select-menu"
    className="react-select__menu--container--is-relative"
  >
    <components.Menu {...props}>{children}</components.Menu>
  </div>
)

function SelectFilter({
  name,
  label,
  options,
  fetchOptions,
  initialValues,
  dirty,
  onChange,
  onToggle,
  singular,
  minLength,
  popoverContentLocation,
  enableReinitialize = false,
}: Props) {
  const [currentValues, setCurrentValues] = useState(toArray(initialValues))
  const [searchOptions, setSearchOptions] = useState(options)
  const [open, setIsOpen] = useState(false)
  const setOpen = (value: boolean) => {
    setIsOpen(value)
    onToggle && onToggle(value)
  }
  const selectedOptions = currentValues.map(
    (id) =>
      searchOptions.find(({ value }) => value.toString() === id.toString()) || {
        name: name,
        value: id,
        label: "<Option deleted>",
      }
  )
  const isAsync = !!fetchOptions
  const getLabel = () => {
    const baseLabel = singular
      ? label
      : pluralize(label, selectedOptions.length)

    switch (selectedOptions.length) {
      case 0:
        return baseLabel
      case 1:
        const record = selectedOptions[0]
        if (!record) {
          return `${baseLabel} (${selectedOptions.length})`
        }
        return `${label}: ${record.label}`
      default:
        return `${baseLabel} (${selectedOptions.length})`
    }
  }
  const onSubmit = (values) => {
    setOpen(false)
    onChange(values)
  }
  const fetch = (term) => {
    if (term.length === 0 && minLength !== 0) {
      return Promise.resolve([])
    }
    return fetchOptions(term).then((response) => {
      setSearchOptions((searchOptions) =>
        unique([...searchOptions, ...response])
      )
      return response
    })
  }
  const tooltip = selectedOptions
    .filter(Boolean)
    .map(({ label }) => label)
    .join(", ")

  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    setSearchOptions((searchOptions) => unique([...searchOptions, ...options]))
    setCurrentValues(toArray(initialValues))
    return () => {
      isMounted.current = false
    }
  }, [options, initialValues])

  return (
    <Form
      className="d-inline-block v-align-top mb-3"
      initialValues={{ [name]: currentValues }}
      enableReinitialize={enableReinitialize}
      onSubmit={onSubmit}
    >
      {isMounted.current &&
        (({ setFieldValue, submitForm }) => {
          const reset = () => {
            setFieldValue(name, [])
          }
          const onClick = () => setOpen(!open)
          const clear = () => {
            setOpen(false)
            reset()
            submitForm()
          }
          const active = currentValues.length > 0
          return (
            <Popover
              open={open}
              onClick={onClick}
              onHide={submitForm}
              contentLocation={popoverContentLocation}
              label={
                <Tooltip
                  trigger={currentValues.length > 1 && "hover"}
                  overlay={tooltip || "Click to open"}
                >
                  <div
                    role="button"
                    className={classNames("btn btn-pill btn-sm mr-2", {
                      active,
                      "has-icon": active,
                      warning: dirty,
                    })}
                    tabIndex={0}
                  >
                    {getLabel()}
                    {active && (
                      <Icon className="icon" type="times" onClick={clear} />
                    )}
                  </div>
                </Tooltip>
              }
            >
              <Select
                autoFocus
                placeholder={`Search ${pluralize(label, singular ? 1 : 2)}...`}
                label={`Filter by ${pluralize(label, singular ? 1 : 2)}`}
                name={name}
                options={isAsync ? selectedOptions : options}
                fetchOptions={isAsync ? fetch : null}
                isMulti
                isSearchable
                menuIsOpen
                unwrapped
                minLength={minLength}
                renderOption={({ label }, { isSelected }) => (
                  <label className="checkbox basic">
                    {" "}
                    {label}
                    <input
                      onChange={() => {}}
                      type="checkbox"
                      checked={isSelected}
                    />
                    <span className="icon" />
                  </label>
                )}
                renderMenu={Menu}
                hasDropdownIndicator={false}
                hideSelectedOptions={false}
              />
              <hr className="my-3" />
              <div className="float-right">
                <Button className="btn-sm btn-link" onClick={reset}>
                  Clear
                </Button>
                <Button className="btn-sm btn-brand" onClick={submitForm}>
                  Done
                </Button>
              </div>
            </Popover>
          )
        })}
    </Form>
  )
}

SelectFilter.defaultProps = {
  options: [],
}

export default SelectFilter
