import React from "react"
import Logo from "components/Logo"

export const groupFacilities = (facilities) => {
  const registeredFacilities = facilities.filter(
    (facility) => !!facility.facilityId
  )
  const unregisteredFacilities = facilities.filter(
    (facility) => !facility.facilityId
  )
  return [
    {
      label: (
        <>
          <Logo className="mr-1" width="16" />
          <span className="uppercase">Facilities on Parachute</span>
        </>
      ),
      options: registeredFacilities,
    },
    {
      label: <span className="uppercase">Other Facilities</span>,
      options: unregisteredFacilities,
    },
  ].filter((group) => group.options.length > 0)
}
