// @ts-strict-ignore
import React, { useContext, useState, useEffect, useRef } from "react"
import { reload } from "utilities/navigation"
import DownloadUploadButtons, {
  Permissions,
} from "./components/DownloadUploadButtons"
import withInitialData from "components/withInitialData"
import { getDocumentsData, transformImage, archiveAttachment } from "./api"
import { handleError } from "utilities/error"
import { DmeOrder, DocumentationRequirement } from "sharedTypes"
import * as workflowApi from "applications/Workflow/api"
import WorkflowPage from "context/WorkflowPage"
import Icon from "components/Icon"
import { requestedDocumentationRequirements } from "applications/Workflow/utilities/documentationRequirements"
import { sidebarDocumentsDownloadurl } from "applications/Workflow/urls"
import { Document } from "components/DocumentsBrowser/sharedTypes"
import DocumentsBrowser from "components/DocumentsBrowser"

type AttachedDocument = Document & { attachmentId: string }

type InitialData = {
  documents: AttachedDocument[]
  permissions: Permissions
  processing: boolean
}

type Props = {
  initialData: InitialData
  refreshDmeOrder(): void
  dmeOrderId: string
  documentationRequirements: DocumentationRequirement[]
  supplierFaxNumber: string
  hasSigningClinician: boolean
  setDmeOrder(dmeOrder: DmeOrder): void
}

enum Status {
  Inactive = "inactive",
  Polling = "polling",
}

function Documents({
  initialData,
  refreshDmeOrder,
  setDmeOrder,
  dmeOrderId,
  documentationRequirements,
  supplierFaxNumber,
  hasSigningClinician,
}: Props) {
  const { permissions, processing } = initialData
  const [documents, setDocuments] = useState(initialData.documents)

  const [status, setStatus] = useState<Status>(
    processing ? Status.Polling : Status.Inactive
  )

  const { registerPollForDocuments, unregisterPollForDocuments } = useContext(
    WorkflowPage
  )

  const pollTimeout = useRef(null)

  const pollForAllDocuments = () => {
    getDocumentsData().then(({ data }) => {
      if (!data.processing) {
        setDocuments(data.documents)
        setStatus(Status.Inactive)
        refreshDmeOrder()

        return
      }
      pollTimeout.current = setTimeout(pollForAllDocuments, 1000)
    })
  }

  useEffect(() => {
    if (processing) {
      pollForAllDocuments()
    }
    const callback = () => {
      if (status === Status.Inactive) {
        setStatus(Status.Polling)
        pollForAllDocuments()
      }
    }
    registerPollForDocuments(callback)

    return () => {
      clearTimeout(pollTimeout.current)
      unregisterPollForDocuments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const archive = (document: AttachedDocument) => {
    archiveAttachment(document.attachmentId)
      .then(() => {
        reload()
      })
      .catch(handleError)
  }

  const rotate = (
    documentId: string,
    imageIndex: number,
    rotationDegrees: number
  ) => {
    setDocuments(
      documents.map((document) => {
        if (document.id === documentId) {
          return {
            ...document,
            files: document.files.map((file, fileIndex) => {
              if (fileIndex === imageIndex) {
                transformImage(document.id, {
                  document_file_transform: {
                    file_name: file.name,
                    rotation_degrees: rotationDegrees,
                  },
                })
                return {
                  ...file,
                  rotation: (file.rotation + rotationDegrees) % 360,
                }
              }
              return file
            }),
          }
        }
        return document
      })
    )
  }

  const updateDocumentationRequirementsSatisfyStatus = (values: {
    documentationRequirementIds: string[]
  }) => {
    return workflowApi
      .updateDocumentationRequirementsSatisfyStatus(values)
      .then((dmeOrder) => setDmeOrder(dmeOrder))
      .catch((error) => {
        handleError(error, "Unable to update manual documentation requirement")
      })
  }

  const uploadDocuments = (
    documentationRequirementIds,
    files,
    signed,
    otherAttachmentTitle
  ) =>
    workflowApi.uploadDocuments(
      documentationRequirementIds,
      files,
      signed,
      otherAttachmentTitle
    )

  return (
    <div className="pt-3" data-status={status}>
      <DownloadUploadButtons
        dmeOrderId={dmeOrderId}
        permissions={permissions}
        documents={documents}
        documentationRequirements={requestedDocumentationRequirements(
          documentationRequirements
        )}
        updateDocumentationRequirementsSatisfyStatus={
          updateDocumentationRequirementsSatisfyStatus
        }
        uploadDocuments={uploadDocuments}
        supplierFaxNumber={supplierFaxNumber}
        hasSigningClinician={hasSigningClinician}
      />
      {status === Status.Polling && <PollingForDocuments />}
      {status !== Status.Polling && (
        <DocumentsBrowser
          documents={documents.map((doc) => ({
            ...doc,
            downloadUrl: sidebarDocumentsDownloadurl(doc.id),
          }))}
          handleImageRotated={rotate}
          handleArchive={archive}
        />
      )}
    </div>
  )
}

const PollingForDocuments = () => (
  <div
    id="sidebar-documents"
    className="d-flex justify-content-center align-items-center"
  >
    <div className="text-center w-60-p">
      <Icon type="spinner" spin className="font-xl mb-3 color-black" />
      <h2 className="color-black">Processing documents</h2>
      <p className="color-dark-gray">
        Documents will appear here when processing is complete.
      </p>
    </div>
  </div>
)

const fetch = () => getDocumentsData().then(({ data }) => data)

export default withInitialData(fetch)(Documents)
