import React, { useContext, useState } from "react"
import Button from "components/form/Button"
import { handleError } from "utilities/error"
import { convertFollowerToOption } from "./utilities/followers"
import { initials } from "utilities/person"
import { UpdateFollowersParams } from "../../../sharedTypes"
import Form from "components/form/Form"
import Select, { GroupedOptions } from "components/form/Select"
import Suggestion from "components/form/Select/Suggestion"
import { DmeOrder, EmployerType, FollowerEmployerType } from "sharedTypes"
import GlobalContext from "context/Global"
import ReadOnlyFollowers from "./ReadOnlyFollowers"

type Props = {
  dmeOrder: DmeOrder
  editable: boolean
  updateFollowers(params: UpdateFollowersParams): Promise<void>
  getFollowers(term: string): Promise<GroupedOptions[]>
}

const InternalFollowers = ({
  dmeOrder,
  editable,
  updateFollowers,
  getFollowers,
}: Props) => {
  const [saving, setSaving] = useState(false)
  const { currentEmployer } = useContext(GlobalContext)
  const isFacility =
    currentEmployer.employerType === EmployerType.ClinicalFacility
  const allFollowers = dmeOrder.followers
  const supplierFollowers = allFollowers.filter(
    (follower) => follower.employerType === FollowerEmployerType.Supplier
  )

  const onSubmit = ({ followerIds }) => {
    setSaving(true)
    const readonlyFollowerIds = isFacility
      ? supplierFollowers.map((follower) => follower.id)
      : []
    updateFollowers({
      followerIds: followerIds.concat(readonlyFollowerIds),
    }).catch((e) => {
      setSaving(false)
      handleError(e)
    })
  }
  const isAvailableToRemove = (record) =>
    !(record.employerType === FollowerEmployerType.Supplier && isFacility)
  const followers = dmeOrder.followers.filter(isAvailableToRemove)
  const followerIds = followers.map((follower) => follower.id)
  const initialOptions = followers.map(convertFollowerToOption)

  if (!editable) {
    if (allFollowers.length === 0) {
      return <div>This order does not have any followers yet</div>
    }
    return (
      <ul className="list-unstyled">
        {allFollowers.map((follower) => {
          return (
            <li className="mb-3" key={`${follower.name}-${follower.id}`}>
              <div className="gray-o square d-inline-block">
                {initials(follower.name)}
              </div>
              <strong className="ml-3 font-primary font-xs d-inline-block">
                {follower.name}
              </strong>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <Form onSubmit={onSubmit} initialValues={{ followerIds }}>
      <div className="form-group">
        <label className="d-block" htmlFor="followerIds">
          Followers
        </label>

        <Select
          name="followerIds"
          placeholder="Search"
          isSearchable
          closeMenuOnSelect={false}
          isMulti
          minLength={1}
          options={initialOptions}
          fetchOptions={getFollowers}
          renderOption={(option, { isFocused }) => (
            <Suggestion isHighlighted={isFocused}>{option.label}</Suggestion>
          )}
        />
        {isFacility && supplierFollowers.length > 0 && (
          <ReadOnlyFollowers followers={supplierFollowers} />
        )}
      </div>
      <div className="text-right">
        <Button
          className="btn-brand"
          type="submit"
          disabled={saving}
          loading={saving}
        >
          Done
        </Button>
      </div>
    </Form>
  )
}

export default InternalFollowers
