// @team @facilitators
import React from "react"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { RadioButtons } from "components/form"
import * as styles from "./YourOrganizationsSuppliers.module.scss"
import LinkComponent from "components/Link"
import { fulfillmentAgreementsUrl } from "../../../SupplierList/InternalSupplierList/Header/urls"
import { Supplier, ServiceTier } from "sharedTypes"
import SupplierLabel from "../SupplierSelector/SupplierLabel"

type Props = {
  manageFulfillmentAgreements: boolean
  onSupplierSelect: (event: any) => void
  yourOrganizationsSuppliers: Supplier[]
  showServiceTier: boolean
  preferredSupplierIds: string[]
  hasPreferredSuppliers: boolean
}

const YourOrganizationsSuppliers: React.FC<Props> = ({
  manageFulfillmentAgreements,
  onSupplierSelect,
  yourOrganizationsSuppliers,
  showServiceTier,
  preferredSupplierIds,
  hasPreferredSuppliers,
}) => {
  const showBadges = yourOrganizationsSuppliers.some(
    (s) => s.serviceTier === ServiceTier.Basic
  )
    ? showServiceTier
    : false
  const supplierOption = (supplier) => {
    return {
      label: (
        <SupplierLabel
          showServiceTier={showBadges}
          isPreferredSupplier={preferredSupplierIds.includes(
            supplier.externalId
          )}
          supplier={supplier}
          hasPreferredSuppliers={hasPreferredSuppliers}
        />
      ),
      value: supplier.externalId,
    }
  }

  const yourOrganizationsSuppliersOptions = yourOrganizationsSuppliers.map(
    (supplier) => supplierOption(supplier)
  )

  return (
    <>
      <div className={styles.header}>
        <div>
          <h2>Select Supplier</h2>
        </div>
        <div className="m-1 text-right">
          <strong>Sorting by: </strong>
          Order volume
        </div>
      </div>
      <div className={styles.subheader}>
        Suppliers that may not accept the patient’s insurance are not shown.
      </div>
      <div className={styles.supplierGroup}>
        <div className={styles.yourOrganizationsSuppliersHeader}>
          <h3>
            Your Organization's Suppliers ({yourOrganizationsSuppliers.length})
          </h3>
          {manageFulfillmentAgreements && (
            <div className={styles.manageSuppliersContainer}>
              <LinkComponent href={fulfillmentAgreementsUrl()} target="_blank">
                <CanopyIcon className="mr-1" name="gear" />
                <span>Manage Suppliers</span>
              </LinkComponent>
            </div>
          )}
        </div>

        <RadioButtons
          name="supplierId"
          options={yourOrganizationsSuppliersOptions}
          onChange={onSupplierSelect}
        />
      </div>
    </>
  )
}

export default YourOrganizationsSuppliers
