import React from "react"
import Icon from "components/Icon"

interface Props {
  onClick(): void
}

export default function BackButton({ onClick }: Props) {
  return (
    <div className="back-button">
      <a className="link" onClick={onClick}>
        <Icon type="chevron-left" className="mr-2" />
        Back to facility search
      </a>
    </div>
  )
}
