import React from "react"
import SeverityIcon from "../../../../../../../components/SeverityIcon"
import {
  DeliveryStatus,
  OrderStatus,
  Severity,
  SignatureStatus,
} from "../../../../../../../sharedTypes"
import {
  formatDeliveryStatus,
  getDeliveryStatusSeverity,
} from "../../../../../../../utilities/deliveryStatus"
import startCase from "lodash/startCase"
import { getSignatureStatusSeverity } from "../../../../../../../utilities/signatureStatus"

interface OrderStatusProps {
  orderStatus: OrderStatus | null
  deliveryStatus: DeliveryStatus | null
  signatureStatus: SignatureStatus | null
  missingDocuments: boolean
}

const OrderDeliveryStatusPill = ({ status }: { status: string }) => {
  const severity = getDeliveryStatusSeverity(status)
  const statusText = formatDeliveryStatus(status)
  return (
    <div className={`pill pill-${severity || "default"}`}>
      <SeverityIcon className="mr-1" type={severity as Severity} />
      {statusText}
    </div>
  )
}

const SignatureStatusPill = ({
  signatureStatus,
}: {
  signatureStatus: SignatureStatus
}) => {
  const severity = getSignatureStatusSeverity(signatureStatus)

  return (
    <div
      className={`pill pill-${severity} ${
        severity === Severity.Default && "pill-basic"
      }`}
    >
      {severity !== "default" && (
        <SeverityIcon className="mr-1" type={severity} />
      )}
      Signature {startCase(signatureStatus)}
    </div>
  )
}

const MissingDocumentsPill = () => {
  return (
    <div className="pill pill-warning">
      <SeverityIcon className="mr-1" type={Severity.Warning} />
      Missing Documents
    </div>
  )
}

export const OrderStatuses: React.FC<OrderStatusProps> = ({
  orderStatus,
  deliveryStatus,
  signatureStatus,
  missingDocuments,
}) => {
  const statusUnknown =
    !signatureStatus && !missingDocuments && !orderStatus && !deliveryStatus

  if (statusUnknown) {
    return <OrderDeliveryStatusPill status={DeliveryStatus.Processing} />
  }

  let orderDeliveryStatusText = !!orderStatus
    ? orderStatus
    : DeliveryStatus.Processing

  if (deliveryStatus) {
    orderDeliveryStatusText = deliveryStatus
  }

  return (
    <div className="canopy-mt-2x">
      {!!signatureStatus && (
        <SignatureStatusPill signatureStatus={signatureStatus} />
      )}
      {!!missingDocuments && <MissingDocumentsPill />}

      <OrderDeliveryStatusPill status={orderDeliveryStatusText} />
    </div>
  )
}
