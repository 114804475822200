// @ts-strict-ignore
import React from "react"
import {
  CsrInboxDocument,
  CsrInboxDocumentState,
  InternalStatus,
  SortDirection,
} from "sharedTypes"
import Tooltip from "components/Tooltip"
import { EVENT_DATE_TIME_FORMAT, format } from "utilities/date"
import { employerPrefix, EmployerType } from "utilities/url"
import SortableTableHeaders from "components/SortableTableHeaders"
import { formatPhone } from "utilities/phone"
import InternalStatusEditor from "applications/InternalStatusEditor"
import { getCsrInboxStateInternalStatusesUrl } from "./api"
import CsrInboxStateAssigneeEditor from "applications/CsrInboxStateAssigneeEditor"
import Overlay from "components/Overlay"
import { initials } from "utilities/person"
import { titleize } from "utilities/string"
import { get } from "services/api"
import { FilePreviewer } from "./FilePreviewer"

type Props = {
  csrInboxDocuments: CsrInboxDocument[]
  sortColumn?: string
  sortDirection?: SortDirection
  onSortChange(values: { [key: string]: string }): Promise<void>
  internalStatuses: InternalStatus[]
  onChange(document: CsrInboxDocument): void
  loading?: boolean
  patientColumnEnabled: boolean
  supplierId: string
}
export const fetchUrlForFirstPageImage = (
  csrInboxDocumentId: string,
  supplierId: string
) => {
  const url =
    employerPrefix("Supplier", supplierId) +
    "/inbox/" +
    csrInboxDocumentId +
    "/files"

  return get(url).then((d) => {
    const getFirstPage = (min, curr) => {
      return curr.pageNumber &&
        (!min.pageNumber || curr.pageNumber < min.pageNumber)
        ? curr
        : min
    }
    return d.data.data.reduce(getFirstPage).url
  })
}

const Table = ({
  csrInboxDocuments,
  sortColumn,
  sortDirection,
  onSortChange,
  internalStatuses,
  onChange,
  loading,
  supplierId,
  patientColumnEnabled,
}: Props) => {
  const employerType: EmployerType = "Supplier"

  const tableColumns = [
    { title: "Document", className: "col-w250" },
    ...(patientColumnEnabled ? [{ title: "Patient" }] : []),
    { title: "Status" },
    { title: "Facility", value: "facility_name" },
    { title: "From", value: "from" },
    { title: "To" },
    { title: "Received", value: "created_at" },
    { title: "Pages", value: "page_count", className: "col-w80" },
    { title: "Internal Status", value: "internal_status" },
    { title: "Assigned To", value: "assignee" },
  ]
  const getDocumentPath = ({ id }) =>
    `${employerPrefix(employerType, supplierId)}/inbox/${id}`
  const fromFax = (document) => document.sourceType === "fax"

  const isNewDocument = (document: CsrInboxDocument) =>
    document.state === CsrInboxDocumentState.New

  const renderDocumentRow = (document: CsrInboxDocument) => {
    const fetchFile = () => fetchUrlForFirstPageImage(document.id, supplierId)
    return (
      <tr key={document.id} data-csr-inbox-state-id={document.id}>
        <td className="col-w250">
          <a href={getDocumentPath(document)} className="link pr-2">
            {document.id}
          </a>
          <FilePreviewer fetchFileUrl={fetchFile} />
          {document.lastAccessUserName && (
            <Tooltip
              placement={Tooltip.Placement.Right}
              trigger={["click", "hover"]}
              overlay={document.lastAccessUserName}
            >
              <div className="circle green-o ml-2">
                {initials(document.lastAccessUserName)}
              </div>
            </Tooltip>
          )}
        </td>
        {patientColumnEnabled ? <td>{document.patientName}</td> : null}
        <td>{titleize(document.state)}</td>
        <td>{document.clinicalFacilityName}</td>
        <td>
          {fromFax(document) ? formatPhone(document.source) : document.source}
        </td>
        <td>{formatPhone(document.receivingFaxNumber)}</td>
        <td>{format(document.createdAt, EVENT_DATE_TIME_FORMAT)}</td>
        <td className="col-w80">{document.pageCount}</td>
        <td>
          <InternalStatusEditor
            updateUrl={getCsrInboxStateInternalStatusesUrl(
              document.id,
              employerType,
              supplierId
            )}
            internalStatus={document.internalStatus}
            internalStatuses={internalStatuses}
            readOnly={!isNewDocument(document)}
          />
        </td>
        <td className="assignee">
          <CsrInboxStateAssigneeEditor
            document={document}
            onChange={onChange}
            readOnly={!isNewDocument(document)}
            supplierId={supplierId}
          />
        </td>
      </tr>
    )
  }

  return (
    <Overlay active={loading} showSpinner position="top">
      <div
        className="table-container"
        style={{ maxHeight: "calc(100vh - 300px)" }}
      >
        <table className="table table-striped table-hover table-sortable table-expanded table-dashboard table-flex">
          <SortableTableHeaders
            onSortChange={onSortChange}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            columns={tableColumns}
          />
          <tbody>{csrInboxDocuments.map(renderDocumentRow)}</tbody>
        </table>
      </div>
    </Overlay>
  )
}

export default Table
