import React from "react"
import CarrierAuthorizationDashboard from "components/CarrierAuthorizationDashboard/index"
import LeftSidebar from "components/SupplierDashboard/LeftSidebar"

const SupplierCarrierAuthorizationDashboard = (props) => {
  return (
    <div className="position-relative">
      <LeftSidebar
        active={LeftSidebar.Options.Authorizations}
        counts={{
          orders: props.ordersCount,
          documents: props.counts.inboxTotal,
          authorizations: props.authorizationsCount,
        }}
        authorizationEnabled
      />
      <div className="row mt-n3">
        <div className="mt-4 col-12">
          <h3>
            <span className="bold">Authorizations</span>
          </h3>
          <div className="mt-3">
            <CarrierAuthorizationDashboard {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupplierCarrierAuthorizationDashboard
