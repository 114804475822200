// @ts-strict-ignore
import React, { useState } from "react"
import Tabs from "./Tabs"
import { IconType } from "components/Icon"
import GlobalCommentForm from "./GlobalCommentForm"
import InternalCommentForm from "./InternalCommentForm"
import { CommentType, DmeOrder, EmploymentMention } from "sharedTypes"
import { Label } from "../utilities/filters"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import SupplierToSupplierCommentForm from "./SupplierToSupplierCommentForm"

type Props = {
  createComment: (values: { content: string }) => Promise<void>
  getEmploymentMentions(
    term: string,
    commentType: CommentType
  ): Promise<EmploymentMention[]>
  createInternalComment: (values: { content: string }) => Promise<void>
  createSupplierComment: (values: { content: string }) => Promise<void>
  warningMessages: string[]
  viewFilter: string
  dmeOrder: DmeOrder
}

const CLOSED_TAB = "closed"
const GLOBAL_TAB = "global"
const INTERNAL_TAB = "internal"
const SUPPLIER_TAB = "supplier"

const SupplierCommentForm = ({
  createComment,
  getEmploymentMentions,
  createInternalComment,
  createSupplierComment,
  viewFilter,
  dmeOrder,
}: Props) => {
  const closedTab = {
    id: CLOSED_TAB,
  }
  const globalTab = {
    id: GLOBAL_TAB,
    label: "Global Comment",
    icon: "clinic-medical" as IconType,
  }
  const internalTab = {
    id: INTERNAL_TAB,
    label: "Internal Comment",
    icon: "book" as IconType,
  }
  const supplierTab = {
    id: SUPPLIER_TAB,
    label: "Supplier Comment",
    icon: "shipping-fast" as IconType,
  }

  const [currentTab, setCurrentTab] = useState(closedTab)
  const close = () => setCurrentTab(closedTab)

  const getTabs = () => {
    const tabs = [globalTab]
    if (dmeOrder.permissions.internalComment) tabs.push(internalTab)
    if (dmeOrder.permissions.supplierComment) tabs.push(supplierTab)
    return tabs
  }

  return (
    <div className="pt-3">
      <Tabs
        tabs={getTabs()}
        onChange={(tab) => setCurrentTab(tab)}
        currentTab={currentTab}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition
            in
            key={currentTab.id}
            classNames="comment-form"
            timeout={100}
            mountOnEnter
            unmountOnExit
            exit
          >
            <>
              {currentTab.id === GLOBAL_TAB && (
                <GlobalCommentForm
                  onSubmit={createComment}
                  getEmploymentMentions={getEmploymentMentions}
                  onCancel={close}
                  disabled={viewFilter === Label.InternalComments}
                />
              )}
              {currentTab.id === INTERNAL_TAB &&
                dmeOrder.permissions.internalComment && (
                  <InternalCommentForm
                    onSubmit={createInternalComment}
                    getEmploymentMentions={getEmploymentMentions}
                    onCancel={close}
                    disabled={viewFilter === Label.GlobalComments}
                  />
                )}
              {currentTab.id === SUPPLIER_TAB &&
                dmeOrder.permissions.supplierComment && (
                  <SupplierToSupplierCommentForm
                    onSubmit={createSupplierComment}
                    getEmploymentMentions={getEmploymentMentions}
                    onCancel={close}
                    disabled={false}
                  />
                )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </Tabs>
    </div>
  )
}

export default SupplierCommentForm
