import React, { useState } from "react"

type Props = {
  sendSignatureRequest(params: {}): Promise<void>
  className: string
  disabled: boolean
}

function SendSignatureReminderButton({
  className,
  sendSignatureRequest,
  disabled,
}: Props) {
  const [sending, setSending] = useState(false)
  const onClick = function () {
    setSending(true)
    return sendSignatureRequest({ sendImmediately: true })
      .then(() => setSending(false))
      .catch(() => setSending(false))
  }

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={sending || disabled}
    >
      {sending ? "Sending..." : "Send Signature Reminder Now"}
    </button>
  )
}

export default SendSignatureReminderButton
