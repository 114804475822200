import { employerPrefix } from "utilities/url"

export function salesRepFulfillmentAgreementUrl(
  salesRepId: string | null
): string {
  return `${employerPrefix()}/sales_rep_assignments/${salesRepId}`
}

export function salesRepTransferAssignmentsUrl(): string {
  return `${employerPrefix()}/sales_rep_assignments/transfer`
}

export function cmsSalesRepTerritoryUrl(supplierId, territoryId): string {
  return `/cms/suppliers/${supplierId}/sales_rep_territories/${territoryId}`
}
