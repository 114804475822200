import { post, put } from "services/api"
import {
  patientPortalMessageTemplatesUrl,
  patientPortalMessageTemplateUrl,
} from "./urls"

export const createPatientPortalMessageTemplate = (messageTemplate) =>
  post(patientPortalMessageTemplatesUrl(), { messageTemplate })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))

export const updatePatientPortalMessageTemplate = (messageTemplateId) => (
  messageTemplate
) =>
  put(patientPortalMessageTemplateUrl(messageTemplateId), { messageTemplate })
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response.data))
