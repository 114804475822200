import React, { ReactElement } from "react"
import SelectFilter from "components/SelectFilter"
import {
  ClinicalFacility,
  GetIncomingOrdersClinicalFacilitiesQuery,
} from "graphql/__generated__/graphql"
import { ApolloQueryResult } from "@apollo/client"

interface Props {
  clinicalFacilities: ClinicalFacility[]
  searchClinicalFacilities(
    searchTerm: string
  ): Promise<ApolloQueryResult<GetIncomingOrdersClinicalFacilitiesQuery>>
  initialClinicalFacilityIds: string[]
  onChange: (values: { clinicalFacilityIds: string[] }) => void
}

function ClinicalFacilitySelect({
  clinicalFacilities,
  searchClinicalFacilities,
  initialClinicalFacilityIds,
  onChange,
}: Props): ReactElement<Props> {
  const options = clinicalFacilities?.map((clinicalFacility) => {
    return { label: clinicalFacility.name, value: clinicalFacility.id }
  })

  return (
    <SelectFilter
      name="clinicalFacilityIds"
      label="Facilities"
      initialValues={initialClinicalFacilityIds}
      options={options}
      fetchOptions={async (term) => {
        const { data } = await searchClinicalFacilities(term)

        return data?.incomingOrders.clinicalFacilities.map(
          (clinicalFacility) => ({
            label: clinicalFacility.name,
            value: clinicalFacility.id,
          })
        )
      }}
      onChange={onChange}
      minLength={0}
      enableReinitialize
    />
  )
}

export default ClinicalFacilitySelect
