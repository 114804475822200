import React, { useCallback, useState } from "react"
import Actions from "./components/Actions"
import Swiper from "./components/Swiper"
import Preview from "./components/Preview"
import { Document, Zoom } from "./sharedTypes"
import debounce from "debounce"
import {
  getActiveImageIndex,
  getNextIndex,
  getPreviousIndex,
} from "./utilities/preview"

type Props = {
  documents: Document[]
  handleImageRotated?(
    documentId: string,
    imageIndex: number,
    rotationDegrees: number
  ): void
  handleArchive?(document: Document): void
  onDownload?(): void
  className?: string
}

const DocumentsBrowser = ({
  documents,
  handleImageRotated,
  handleArchive,
  onDownload,
  className,
}: Props) => {
  const [index, setIndex] = useState<number>(0)
  const [zoom, setZoom] = useState<number>(Zoom.Min)

  const zoomIn = () => {
    if (zoom < Zoom.Max) {
      setZoom(zoom + Zoom.Delta)
    }
  }

  const zoomOut = () => {
    if (zoom > Zoom.Min) {
      setZoom(zoom - Zoom.Delta)
    }
  }

  const [isRotateHovered, setRotateHover] = useState<boolean>(false)
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0)

  const previous = () => setIndex(getPreviousIndex(index, documents.length))
  const next = () => setIndex(getNextIndex(index, documents.length))

  const onRotateMouseEnter = () => setRotateHover(true)
  const onRotateMouseLeave = () => setRotateHover(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll: (node) => void = useCallback(
    debounce((node) => {
      const index = getActiveImageIndex(node)
      setActiveImageIndex(index)
    }, 100),
    []
  )

  const rotate =
    handleImageRotated &&
    (() => {
      handleImageRotated(documents[index].id, activeImageIndex, 90)
    })

  return (
    <>
      <Actions
        documents={documents}
        index={index}
        archive={handleArchive}
        previous={previous}
        next={next}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        rotate={rotate}
        onRotateMouseEnter={onRotateMouseEnter}
        onRotateMouseLeave={onRotateMouseLeave}
        onDownload={onDownload}
      />
      <Swiper id="sidebar-documents" onScroll={onScroll} className={className}>
        <Preview
          document={documents[index]}
          zoom={zoom}
          activeImageIndex={activeImageIndex}
          isRotateHovered={isRotateHovered}
        />
      </Swiper>
    </>
  )
}

export default DocumentsBrowser
