import { AppBar, Toolbar } from "@material-ui/core"
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined"
import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"
import classNames from "classnames"
import { DonePath, ReviewPath, SignPath } from "../routes"
import { useStyles } from "themes/theme"
import * as styles from "./header.module.scss"
import { Tab } from "../sharedTypes"
import { EventActions, trackEvent } from "../utilities/tracking"
import Link from "components/Link"
import { Badge } from "@material-ui/core"
import * as tokens from "@parachutehealth/canopy-tokens-color"
import { useDoctorContext } from "../hooks/useDoctorContext"

interface Props {
  activeTab: Tab
  actionableSignatureRequestsStatus: { review: boolean; sign: boolean }
  clearPatientSearchQuery: () => void
}

const badgeStyles = {
  dot: {
    transform: "translate(0.5rem, -0.075rem)",
  },
  customBadgeColor: {
    backgroundColor: tokens.canopyColorBackgroundAttention,
  },
}

const Header = ({
  activeTab,
  actionableSignatureRequestsStatus,
  clearPatientSearchQuery,
}: Props) => {
  const { doctor } = useDoctorContext()
  const muiClasses = useStyles(badgeStyles)
  const history = useHistory()

  const handleOnClick = useCallback(
    (event: EventActions, toPath) => {
      trackEvent(event)
      history.push(toPath)
    },
    [history]
  )

  const signTabText = doctor.signOnScreenEnabled
    ? "Sign"
    : "Ready for Signature"

  return (
    <AppBar position="static" className={styles.toolbar} elevation={6}>
      <Toolbar>
        <h3 className={styles.header}>
          <BorderColorOutlinedIcon
            className={styles.headerIcon}
            fontSize="inherit"
          />
          Signature Requests
        </h3>
        <>
          <Link
            className={classNames(styles.tabButton, {
              [styles.tabButtonActive]: activeTab === "review",
            })}
            onClick={() => {
              handleOnClick(
                EventActions.ReviewerReviewTab,
                ReviewPath(doctor.id)
              )
              clearPatientSearchQuery()
            }}
          >
            {actionableSignatureRequestsStatus.review ? (
              <Badge
                classes={{
                  dot: muiClasses.dot,
                  badge: muiClasses.customBadgeColor,
                }}
                variant="dot"
                data-testid="review-notification-badge"
              >
                Review
              </Badge>
            ) : (
              "Review"
            )}
          </Link>
          <Link
            className={classNames(styles.tabButton, {
              [styles.tabButtonActive]: activeTab === "sign",
            })}
            onClick={() => {
              handleOnClick(
                doctor.signOnScreenEnabled
                  ? EventActions.ReviewerSignTab
                  : EventActions.ReviewerReadyForSigTab,
                SignPath(doctor.id)
              )
              clearPatientSearchQuery()
            }}
          >
            {actionableSignatureRequestsStatus.sign ? (
              <Badge
                classes={{
                  dot: muiClasses.dot,
                  badge: muiClasses.customBadgeColor,
                }}
                variant="dot"
                data-testid="sign-notification-badge"
              >
                {signTabText}
              </Badge>
            ) : (
              signTabText
            )}
          </Link>
          <Link
            className={classNames(styles.tabButton, {
              [styles.tabButtonActive]: activeTab === "done",
            })}
            onClick={() => {
              handleOnClick(EventActions.ReviewerDoneTab, DonePath(doctor.id))
              clearPatientSearchQuery()
            }}
          >
            Done
          </Link>
          <div
            className={classNames(
              "bg-lightest-gray canopy-my-4x canopy-ml-auto canopy-px-0 d-flex flex-column align-items-end",
              styles.providerContainer
            )}
          >
            <span
              aria-label="provider name"
              className="bold canopy-px-4x canopy-pt-2x canopy-typography-body-small"
            >
              {doctor.name}
            </span>
            <span
              aria-label="provider npi"
              className="canopy-px-4x canopy-pb-2x canopy-typography-body-xsmall"
            >
              NPI: {doctor.npi}
            </span>
          </div>
        </>
      </Toolbar>
    </AppBar>
  )
}

export default Header
