export type Image = {
  name: string
  createdAt: string
  pageNumber: string
  url: string
  rotation: number
}

export type Document = {
  id: string
  files: Image[]
  permissions: {
    archive: boolean
  }
  downloadUrl: string
}

export enum Zoom {
  Min = 100,
  Max = 200,
  Delta = 25,
}
