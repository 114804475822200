import { Failure, Result, Success } from "components/AsyncButton"
import * as React from "react"
import { Button } from "components/form"
import * as api from "applications/ReassignSalesReps/api"
import { useEffect, useState, Dispatch, SetStateAction } from "react"
import { SalesRepAssignment } from "../ReassignSalesRepsTable"
import TransferSalesRepsEditMessage from "../TransferSalesRepsEditMessage"
import { EventCategory, trackEvent } from "utilities/tracking"
import { Color } from "@material-ui/lab"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { Formik, Field as FormikField } from "formik"

type Props = {
  givingSalesRep: OptionItem
  handleSuccess(result): Promise<Success>
  onInputChange: (newValue: string) => Promise<void>
  onRecipientInputChange: (newValue: string) => Promise<void>
  recipientUserOptions: OptionItem[]
  salesRepAssignments: SalesRepAssignment[]
  setGivingSalesRep: Dispatch<SetStateAction<OptionItem>>
  setToast(message: string, severity: Color): void
  userOptions: OptionItem[]
  userOptionsLoading: boolean
}

const placeholderText = "Select sales rep"
const toLabelText = "Select a sales rep to transfer assignments to"
const fromLabelText = "Select a sales rep to transfer assignments from"
const noOptionsAvailableMessageText = "Search by name or email"
const noOptionsFoundMessageText = "No users found"

const NULL_SALES_REP = "NULL_SALES_REP"

const TransferSalesRepsForm: React.FC<Props> = ({
  givingSalesRep,
  handleSuccess,
  onInputChange,
  onRecipientInputChange,
  recipientUserOptions,
  salesRepAssignments,
  setGivingSalesRep,
  setToast,
  userOptions,
  userOptionsLoading,
}: Props) => {
  const [
    recipientSalesRepAssignments,
    setRecipientSalesRepAssignments,
  ] = useState<SalesRepAssignment[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [recipientSalesRep, setRecipientSalesRep] = useState<OptionItem>({
    label: "",
    value: "",
  })

  const { value: givingSalesRepId = "", label: givingSalesRepName = "" } =
    givingSalesRep || {}
  const { value: recipientSalesRepId = "", label: recipientSalesRepName = "" } =
    recipientSalesRep || {}

  useEffect(() => {
    setRecipientSalesRepAssignments(
      salesRepAssignments.filter(
        (assignment) => assignment.salesRepId === givingSalesRepId
      )
    )
  }, [
    recipientSalesRepId,
    recipientUserOptions,
    salesRepAssignments,
    givingSalesRepId,
    userOptions,
  ])

  const transferAssignments = async (): Promise<Result> => {
    try {
      setLoading(true)
      const result = await api.transferAssignments(
        givingSalesRepId,
        recipientSalesRepId
      )
      setLoading(false)
      setRecipientSalesRep({ label: "", value: "" })
      return await handleSuccess(result)
    } catch {
      setToast("Unable to transfer sales reps", "error")
      setLoading(false)
      return new Failure("Unable to transfer sales reps")
    }
  }

  const addNullSalesRepOption = (userOptions: OptionItem[]): OptionItem[] => {
    return [{ label: "None", value: NULL_SALES_REP }, ...userOptions]
  }

  const handleGivingRepChange = async (newValue, setFieldValue) => {
    await setFieldValue("givingSalesRep", newValue)
    setGivingSalesRep(newValue)
  }

  const handleRecipientRepChange = async (newValue, setFieldValue) => {
    await setFieldValue("recipientSalesRep", newValue)
    setRecipientSalesRep(newValue)
  }

  const onSubmit = () => {
    void trackEvent(
      EventCategory.TransferSalesRep,
      "transfer_sales_rep_transfer_update_clicked"
    )

    void transferAssignments()
  }

  return (
    <Formik
      initialValues={{
        givingSalesRep: givingSalesRep,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldTouched, setFieldValue, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormikField
              as={CanopyComboboxField}
              className="form-group"
              label={fromLabelText}
              placeholder={placeholderText}
              loading={userOptionsLoading}
              options={userOptions}
              onInputChange={onInputChange}
              onFocus={() => {
                void trackEvent(
                  EventCategory.TransferSalesRep,
                  "transfer_sales_rep_transfer_from_clicked"
                )
              }}
              onChange={(newValue) => {
                void (async (newValue) => {
                  handleGivingRepChange(newValue, setFieldValue)
                })(newValue)
              }}
              onBlur={() => {
                void (async () => {
                  setFieldTouched("givingSalesRep")
                })()
              }}
              noOptionsAvailableMessage={noOptionsAvailableMessageText}
              noOptionsFoundMessage={noOptionsFoundMessageText}
              value={values["givingSalesRep"]}
            />
            <FormikField
              as={CanopyComboboxField}
              className="form-group"
              label={toLabelText}
              placeholder={placeholderText}
              loading={userOptionsLoading}
              options={addNullSalesRepOption(recipientUserOptions)}
              onInputChange={onRecipientInputChange}
              onFocus={() => {
                void trackEvent(
                  EventCategory.TransferSalesRep,
                  "transfer_sales_rep_transfer_to_clicked"
                )
              }}
              onBlur={() => {
                void (async () => {
                  setFieldTouched("recipientSalesRep")
                })()
              }}
              onChange={(newValue) => {
                void (async (newValue) => {
                  handleRecipientRepChange(newValue, setFieldValue)
                })(newValue)
              }}
              noOptionsAvailableMessage={noOptionsAvailableMessageText}
              noOptionsFoundMessage={noOptionsFoundMessageText}
              value={values["recipientSalesRep"]}
            />
            <TransferSalesRepsEditMessage
              recipientSalesRepName={recipientSalesRepName}
              givingSalesRepName={givingSalesRepName}
              salesRepAssignments={recipientSalesRepAssignments}
            />
            <Button
              loading={loading}
              loadingText="Update Assignments"
              className="btn btn-brand"
              type="submit"
              disabled={!(recipientSalesRepId && givingSalesRepId)}
            >
              Update Assignments
            </Button>
          </form>
        )
      }}
    </Formik>
  )
}

export default TransferSalesRepsForm
