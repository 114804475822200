import React from "react"
import FacilityDetails from "../FacilityDetails"
import { Facility } from "../../../sharedTypes"
import { formatAddress } from "utilities/address"

interface Props {
  facility: Facility
}

const FacilityPreview = ({ facility }: Props) => {
  const { name } = facility

  return (
    <div className="well">
      <h3 className="mb-2">
        <strong>{name}</strong>
      </h3>
      <p className="mb-0">{formatAddress(facility)}</p>
      <FacilityDetails facility={facility} />
    </div>
  )
}
export default FacilityPreview
