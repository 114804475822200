import flatten from "lodash/flatten"
import compact from "lodash/compact"
import {
  CLINICAL_STEP,
  DELIVERY_STEP,
  isSimpleFacilitySteps,
  PAYMENT_STEP,
  PRODUCTS_STEP,
  productsWarningDependents,
  REVIEW_STEP,
  SIGNATURE_STEP,
  StepContext,
  StepId,
} from "./steps"

export const warningsByStep = (
  context: StepContext
): { key: string; step: StepId; dependents: StepId[] }[] =>
  compact([
    {
      key: "primary_insurance_policy",
      step: PAYMENT_STEP,
      dependents: [REVIEW_STEP],
    },
    {
      key: "secondary_insurance_policy",
      step: PAYMENT_STEP,
      dependents: [REVIEW_STEP],
    },
    {
      key: "tertiary_insurance_policy",
      step: PAYMENT_STEP,
      dependents: [REVIEW_STEP],
    },
    {
      key: "carrier_authorization_number",
      step: PAYMENT_STEP,
      dependents: [REVIEW_STEP],
    },
    {
      key: "products",
      step: PRODUCTS_STEP,
      dependents: productsWarningDependents(context),
    },
    {
      key: "product_requirements",
      step: PRODUCTS_STEP,
      dependents: [REVIEW_STEP, DELIVERY_STEP, CLINICAL_STEP, SIGNATURE_STEP],
    },
    {
      key: "patient_preference",
      step: PRODUCTS_STEP,
      dependents: [REVIEW_STEP, DELIVERY_STEP, CLINICAL_STEP, SIGNATURE_STEP],
    },
    {
      key: "prescription",
      step: PRODUCTS_STEP,
      dependents: [REVIEW_STEP, DELIVERY_STEP, CLINICAL_STEP],
    },
    { key: "rx_details", step: PRODUCTS_STEP, dependents: [REVIEW_STEP] },
    { key: "billing_address", step: DELIVERY_STEP, dependents: [REVIEW_STEP] },
    {
      key: "billing_phone_number",
      step: DELIVERY_STEP,
      dependents: [REVIEW_STEP],
    },
    isSimpleFacilitySteps(context) && {
      key: "discharge_date",
      step: DELIVERY_STEP,
      dependents: [REVIEW_STEP],
    },
    {
      key: "delivery_phone_number",
      step: DELIVERY_STEP,
      dependents: [REVIEW_STEP],
    },
    { key: "diagnoses", step: CLINICAL_STEP, dependents: [REVIEW_STEP] },
    !isSimpleFacilitySteps(context) && {
      key: "discharge_date",
      step: CLINICAL_STEP,
      dependents: [REVIEW_STEP],
    },
    {
      key: "signature_ready_documentation",
      step: CLINICAL_STEP,
      dependents: [REVIEW_STEP],
    },
    { key: "ordering_doctor", step: SIGNATURE_STEP, dependents: [REVIEW_STEP] },
    {
      key: "signature_request_method",
      step: SIGNATURE_STEP,
      dependents: [REVIEW_STEP],
    },
    {
      key: "rejected_supplier",
      step: PRODUCTS_STEP,
      dependents: [REVIEW_STEP],
    },
  ])

export const isStepValid = (
  step: StepId,
  warnings,
  context: StepContext,
  withDependents = true
): boolean => {
  const errors = warnings.map(({ key }) => key)
  const stepsWithErrors = warningsByStep(context)
    .filter((warning) => errors.includes(warning.key))
    .map(({ step, dependents }) =>
      withDependents ? [step].concat(dependents) : [step]
    )
  return !flatten(stepsWithErrors).includes(step)
}
