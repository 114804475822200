// @team @clinical-intelligence
import React, { useRef, useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import ReactJson from "react-json-view"

interface Props {
  title: string
  questionnaire: object
}

const ExternalQuestionnaireModalButton = (props: Props) => {
  const [open, setOpen] = useState(false)
  const activatorRef = useRef<HTMLAnchorElement>(null)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      {props.questionnaire && (
        <CanopyButton size="small" ref={activatorRef} onClick={openModal}>
          View
        </CanopyButton>
      )}
      <CanopyDialogModal
        activatorElementRef={activatorRef}
        header={props.title}
        onClose={closeModal}
        open={open}
        size="large"
        primaryFooterButton={
          <CanopyButton onClick={closeModal}>Close</CanopyButton>
        }
      >
        <ReactJson name={null} src={props.questionnaire} />
      </CanopyDialogModal>
    </>
  )
}

export default ExternalQuestionnaireModalButton
