import React, { useState } from "react"
import AsyncSelect from "react-select/async"
import classNames from "classnames"
import { highlight } from "../../utilities/select"
import { fetchPossibleSalesReps } from "./api"
import { useDebounce } from "../../hooks/useDebounce"

type SalesRep = {
  id: number
  name: string
  email: string
}

type Props = {
  currentSalesRep: SalesRep
}

const AsyncSalesRepSelect = (props: Props) => {
  const convertToOption = (user) => {
    if (user && user.id) {
      return {
        value: user.id,
        label: `${user.name} (${user.email})`,
        ...user,
      }
    } else {
      return {
        value: "",
        label: "Select...",
      }
    }
  }

  const ALL_SEARCH = ""
  const renderSuggestion = (suggestion, { query, isSelected }) => (
    <div className={classNames({ highlight: isSelected })}>
      {highlight(suggestion.label, query)}
    </div>
  )

  const [selectedSalesRep] = useState(convertToOption(props.currentSalesRep))
  const [query, setQuery] = useState(ALL_SEARCH)

  const debouncedFetchPossibleSalesReps = useDebounce(fetchPossibleSalesReps)
  const promiseOptions = () =>
    new Promise((resolve) => {
      resolve(
        debouncedFetchPossibleSalesReps(query).then(({ data }) => {
          return data.map(convertToOption)
        })
      )
    })

  return (
    <div className="form-group">
      <label className="col-form-label">External Sales User</label>
      <AsyncSelect
        id="sales_rep_select"
        isSearchable
        defaultOptions
        cacheOptions
        isClearable
        loadOptions={promiseOptions}
        onInputChange={(value) => setQuery(value)}
        defaultValue={selectedSalesRep}
        name="fulfillment_agreement[external_sales_user_id]"
        placeholder="Select..."
        className="select2-selection select2-selection--multiple"
        renderOption={renderSuggestion}
      />
    </div>
  )
}

export default AsyncSalesRepSelect
