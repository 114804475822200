import React from "react"
import Icon from "components/Icon"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import { datadogRum } from "@datadog/browser-rum"

type Props = {
  currentPage: number
  totalPages: number
  goToPage(page: number): void
}

const instrumentPreviousPageAction = (
  currentPage: number,
  action: () => void
): void => {
  datadogRum.addAction("Page back", {
    fromPage: currentPage,
    toPage: currentPage - 1,
  })
  action()
}

const instrumentNextPageAction = (
  currentPage: number,
  action: () => void
): void => {
  datadogRum.addAction("Page forward", {
    fromPage: currentPage,
    toPage: currentPage + 1,
  })
  action()
}

const Pagination: React.FC<Props> = ({ currentPage, goToPage, totalPages }) => {
  const previousButton = () => {
    const disabled = currentPage <= 1

    return (
      <button
        type="button"
        className="btn btn-brand-o"
        onClick={() =>
          instrumentPreviousPageAction(currentPage, () =>
            goToPage(currentPage - 1)
          )
        }
        disabled={disabled}
      >
        <Icon type="angle-double-left" className="pr-2" />
        Prev
      </button>
    )
  }

  const nextButton = () => {
    const disabled = currentPage >= totalPages

    return (
      <button
        type="button"
        className="btn btn-brand-o"
        onClick={() =>
          instrumentNextPageAction(currentPage, () => goToPage(currentPage + 1))
        }
        disabled={disabled}
      >
        Next
        <Icon type="angle-double-right" className="pl-2" />
      </button>
    )
  }

  return (
    <div className={classNames(styles.pagination, "pt-5")}>
      {previousButton()}
      <span className="px-4">
        {currentPage} / {totalPages}
      </span>
      {nextButton()}
    </div>
  )
}

export default Pagination
