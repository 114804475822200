import React from "react"
import Modal from "components/Modal"
import goDigitalImage from "go_digital.png"
import { EmailField, Form, PhoneField } from "components/form"
import Overlay from "components/Overlay"
import { Doctor } from "../sharedTypes"
import { isEmailValid, EMAIL_VALIDATION_MESSAGE } from "utilities/email"
import { isPhoneValid, PHONE_VALIDATION_MESSAGE } from "utilities/phone"
import Link from "components/Link"

type Props = {
  onClose(): void
  updateContacts?(values): Promise<any>
  showIcon?: boolean
  updateProfile(values): Promise<any>
  doctor: Doctor
}

const GoDigitalModal = ({
  onClose,
  updateProfile,
  showIcon,
  doctor,
}: Props) => {
  const initialValues = {
    mobileNumber: doctor.mobileNumber || "",
    email: doctor.email || "",
  }
  const validate = ({ mobileNumber, email }) => {
    const errors: { all?: string; email?: string; mobileNumber?: string } = {}
    let emptyFields = 0
    if (!email) {
      emptyFields++
    } else if (!isEmailValid(email)) {
      errors.email = EMAIL_VALIDATION_MESSAGE
    }
    if (!mobileNumber) {
      emptyFields++
    } else if (!isPhoneValid(mobileNumber)) {
      errors.mobileNumber = PHONE_VALIDATION_MESSAGE
    }

    if (emptyFields > 1) {
      errors.all = "can't be blank"
    }
    return errors
  }

  const onSubmit = (values, { setErrors, setSubmitting }) => {
    updateProfile(values)
      .then(() => {
        onClose()
      })
      .catch((errors) => {
        setErrors(errors)
        setSubmitting(false)
      })
  }

  const renderHeader = () => {
    if (showIcon) {
      return (
        <>
          <h3 className="text-center">Your dashboard is clear!</h3>
          <div className="text-center my-3">
            <img src={goDigitalImage} />
          </div>
          <p className="paragraph">
            How simple was that? Next time, skip the fax and get signature
            requests directly to your email or phone.
          </p>
          <p className="paragraph">
            Add your contact info, and you can start signing any time, any
            place, with just a few clicks.
          </p>
        </>
      )
    }
    return (
      <>
        <p className="paragraph">
          Add your contact info, and you can start signing any time, any place
          after verifying your info.
        </p>
      </>
    )
  }

  const Disclaimer: React.FC = () => {
    return (
      <p className="canopy-typography-body-small">
        By adding your phone number for notifications, you agree to receive
        digital signature requests from Parachute Health by text. Message and
        data rates may apply. Mobile Carriers are not liable for delayed or
        undelivered messages.{" "}
        <Link
          href="https://www.parachutehealth.com/privacy-policy"
          target="_blank"
        >
          View Privacy Policy.
        </Link>
      </p>
    )
  }

  return (
    <Modal size="md" title="Go Digital!" show cancel={onClose}>
      <Modal.Body>
        {renderHeader()}
        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid }) => {
            return (
              <Overlay active={isSubmitting} showSpinner>
                <EmailField label="Email" name="email" />
                <PhoneField label="Smartphone Number" name="mobileNumber" />
                <Disclaimer />
                <button
                  type="submit"
                  disabled={!isValid}
                  className="btn btn-brand btn-block"
                >
                  Sign Up
                </button>
              </Overlay>
            )
          }}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default GoDigitalModal
