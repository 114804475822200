import React from "react"
import SupplierAccessForm from "./SupplierAccessForm"
import { ToastProvider } from "components/Toaster"
import * as styles from "./index.module.scss"

interface Props {
  clinicalFacilityId: string
  oneTimeOrderEnabled: boolean
  searchByProductEnabled: boolean
  yourOrganizationsSuppliersOnlyDefault: boolean
}

const ManageSupplierAccess: React.FC<Props> = (props) => {
  return (
    <ToastProvider>
      <div className="mx-5 my-4">
        <h1 className={styles.header}>Manage Supplier Access</h1>
        <div className="color-dark-gray font-s mb-4">
          Select the supplier access level for your organization.
        </div>
        <SupplierAccessForm {...props} />
      </div>
    </ToastProvider>
  )
}

export default ManageSupplierAccess
