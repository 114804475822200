// @ts-strict-ignore
import React, { useEffect } from "react"
import Icon from "components/Icon"
import Cobranding from "components/Cobranding"
import { DmeOrderInvitation } from "../../sharedTypes"
import { trackInvitationEvent } from "../../utilities"

type Props = {
  invitation?: DmeOrderInvitation
  name: string
  onClickContinue(): void
}

const WelcomePage = (props: Props) => {
  const { invitation, name, onClickContinue } = props

  useEffect(() => {
    trackInvitationEvent("welcome-page-viewed")
  }, [])

  return (
    <>
      <Cobranding
        supplierName={invitation.supplierName}
        supplierLogoUrl={invitation.supplierLogoUrl}
      />
      <div className="onboarding-intro">
        <Icon type="comment-dots" />
        <p>
          <strong>Welcome, {name}!</strong>
        </p>
        <p>This note is included with your order:</p>
      </div>
      <div className="onboarding-instructions alert alert-info alert-rounded alert-borderless">
        <strong>{invitation.instructions}</strong>
        <p className="mt-2">
          - {invitation.invitingUserName}, {invitation.supplierName}
        </p>
      </div>

      <button onClick={onClickContinue} className="btn btn-brand px-4">
        Continue
      </button>
    </>
  )
}

export default WelcomePage
