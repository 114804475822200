import React from "react"
import Thumbnail from "components/Thumbnail"
import classNames from "classnames"

type Props = {
  lineItemGroup: {
    packageName: string
    imageUrl: string
    isConsignmentCloset: boolean
  }
  thumbnailClassName?: string
}

function LineItemGroupThumbnail({ lineItemGroup, thumbnailClassName }: Props) {
  return (
    <div className="line-item-group-thumbnail">
      <Thumbnail
        className={classNames("float-left mr-3", thumbnailClassName)}
        src={lineItemGroup.imageUrl}
        size="small"
      />
      <div className="line-item-group-thumbnail-name">
        {lineItemGroup.isConsignmentCloset && "Consignment - "}
        {lineItemGroup.packageName}
      </div>
    </div>
  )
}

export default LineItemGroupThumbnail
