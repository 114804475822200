import React from "react"
import classNames from "classnames"

type Props = {
  label: string
  value?: React.ReactNode
  bold?: boolean
  status?: string
  className?: string
}
const DataRow: React.FC<Props> = ({
  bold,
  label,
  value,
  status,
  className,
  children,
}) => {
  const colorClass = status === "error" ? "color-danger" : "color-black"

  return (
    <div className={classNames("row mt-3", className, { bold })}>
      <div className="col-md-4 color-dark-gray">{label}</div>
      <div className={classNames("col-md-8", colorClass)}>
        {value || children || "-"}
      </div>
    </div>
  )
}
export default DataRow
