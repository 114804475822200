import React, { useState } from "react"
import { SelectedRecipient, SendBackRecipient } from "./sharedTypes"
import Suggestion from "components/form/Select/Suggestion"
import classNames from "classnames"
import Icon from "components/Icon"
import { Field } from "formik"
import { EmailField, PhoneField, TextField, Select } from "components/form"
import FollowableSelect from "./FollowableSelect"
import { GroupedSendBackRecipients } from "applications/Workflow/containers/Review/components/SupplierSendBack/SupplierSendBackForm"

type Recipient = {
  channel: string
  destination: string
}

type ChannelOption = {
  value: string
  label: string
  fieldType: string
}

type Props = {
  recipient: Recipient
  index: number
  length: number
  remove(index: number): void
  channelOptions: ChannelOption[]
  defaultSendBackRecipient: SendBackRecipient
  searchSendBackRecipients(
    query: string
  ): Promise<SendBackRecipient[] | GroupedSendBackRecipients[]>
  setFieldValue(name, destination): void
  showChannelSelector: boolean
  setDefaultSupplierFollowables?: () => void
  setSearchRecipientRecommendations: (recommendationIds: string[]) => void
  selectedRecipients: SelectedRecipient[]
}

const INVITE_CHANNELS = ["fax", "email", "sms"]

function SendBackRecipientSelector({
  recipient,
  index,
  length,
  remove,
  setFieldValue,
  searchSendBackRecipients,
  channelOptions,
  showChannelSelector,
  setDefaultSupplierFollowables,
  defaultSendBackRecipient,
  setSearchRecipientRecommendations,
  selectedRecipients,
}: Props) {
  const isFirst = index === 0
  const isLast = index === length - 1
  const initialChannelOption = channelOptions.find(
    (option) => option.value === recipient.channel
  )
  const [fieldType, setFieldType] = useState(initialChannelOption?.fieldType)

  const destinationFieldName = `recipients.${index}.destination`
  const setDestination = (destination) => {
    setFieldValue(destinationFieldName, destination?.value || "")
  }

  const renderDestination = () => {
    if (recipient.channel === "followable") {
      return (
        <>
          <FollowableSelect
            defaultSendBackRecipient={defaultSendBackRecipient}
            searchSendBackRecipients={searchSendBackRecipients}
            name={destinationFieldName}
            focusOnCreate={isLast && !isFirst && !recipient.destination}
            placeholder="Select or search for a facility user"
            setSearchRecipientRecommendations={
              setSearchRecipientRecommendations
            }
            selectedRecipients={selectedRecipients}
          />
        </>
      )
    }

    return destinationWithName(recipient.channel)
  }

  const destinationWithName = (channel) => {
    return (
      <div className="row">
        <div className="col-6 pr-2">
          {(channel === "fax" || channel === "sms") && (
            <PhoneField name={destinationFieldName} validateField unwrapped />
          )}
          {channel === "email" && (
            <EmailField name={destinationFieldName} validateField unwrapped />
          )}
        </div>
        <div className="col-6 pl-0">
          <TextField
            name={`recipients.${index}.name`}
            placeholder="Name/ATTN (Optional)"
            unwrapped
          />
        </div>
      </div>
    )
  }

  const channelFieldName = `recipients.${index}.channel`

  const onChannelChange = (newValue) => {
    const newFieldType = channelOptions.find((c) => c.value === newValue)
      ?.fieldType
    if (fieldType === newFieldType) {
      return
    }
    setFieldType(newFieldType)

    setDestination("")

    if (setDefaultSupplierFollowables && INVITE_CHANNELS.includes(newValue)) {
      setDefaultSupplierFollowables()
    }
  }

  const getIcon = (value) => {
    switch (value) {
      case "fax":
        return "fax"
      case "email":
        return "envelope"
      case "followable":
        return "user"
      case "sms":
        return "phone"
      default:
        return "user"
    }
  }

  const destinationWidth =
    12 - (showChannelSelector ? 3 : 0) - (length > 1 ? 1 : 0)
  return (
    <div
      className={classNames("row gutter-10", {
        "mb-2": recipient.channel !== "followable",
      })}
      key={index}
    >
      {showChannelSelector ? (
        <div className="col-3">
          <Select
            name={channelFieldName}
            options={channelOptions}
            unwrapped
            onChange={onChannelChange}
            renderOption={({ label, value }) => (
              <Suggestion>
                <Icon className="mr-1 color-dark-gray" type={getIcon(value)} />{" "}
                {label}
              </Suggestion>
            )}
            renderSelection={({ label, value }) => (
              <>
                <Icon className="mr-1 color-dark-gray" type={getIcon(value)} />{" "}
                {label}
              </>
            )}
          />
        </div>
      ) : (
        <Field name={channelFieldName} value="followable" type="hidden" />
      )}
      <div className={"col-" + destinationWidth}>{renderDestination()}</div>
      {length > 1 && (
        <div className="col-1 text-center">
          <a onClick={() => remove(index)}>
            <Icon type="trash" className="mt-2" clickable ariaLabel="Remove" />
          </a>
        </div>
      )}
    </div>
  )
}

export default SendBackRecipientSelector
