import React from "react"
import { Autocomplete } from "@material-ui/lab"
import { TextField } from "@material-ui/core"
import { Supplier } from "applications/Cms/types/sharedTypes"

export type Props = {
  onChange?: (event: React.ChangeEvent<{}>, newValue: Supplier | null) => void
  suppliers: Supplier[]
  selectedValue?: Supplier
  id?: string
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
}

const SupplierAutocomplete: React.FC<Props> = (props: Props) => {
  return (
    <Autocomplete
      id={props.id}
      disabled={props.disabled}
      className={props.className}
      data-testid="supplier-filter"
      options={props.suppliers}
      onChange={props.onChange}
      fullWidth={true}
      value={props.selectedValue || null}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(supplier) => supplier.name}
      style={props.style}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="All suppliers"
          label="Suppliers"
          variant="outlined"
        />
      )}
    />
  )
}

export default SupplierAutocomplete
