import React from "react"
import classNames from "classnames"
import cx from "classnames"
import Icon from "components/Icon"
import Tooltip from "components/Tooltip"
import { useGlobalContext } from "hooks"
import { inOrderNavReplacement } from "applications/Workflow/utilities/utilityNav"
import { Tab } from "./index"
import { canopyColorPrimitivesMagenta40 } from "@parachutehealth/canopy-tokens-color"
import { OrderStatus } from "sharedTypes"
import { getEmployerType } from "utilities/url"

interface Props {
  tabs: Tab[]
  onClick(tab: Tab): void
  onClose(): void
  currentTab: string
  fixed?: boolean
  orderStatus: OrderStatus
}

const RightSidebar: React.FC<Props> = ({
  tabs,
  onClick,
  onClose,
  currentTab,
  fixed,
  orderStatus,
}) => {
  const context = useGlobalContext()
  const showTabs = !inOrderNavReplacement(context)
  const tab = tabs.find((tab) => tab.sidebarTab === currentTab)

  const unreadAlert = (tab: Tab) => (
    <div
      className="position-absolute"
      style={{ right: "12px" }}
      id={`unread-alert-${tab.tabName}`}
      data-testid={`test-alert-${tab.tabName}`}
    >
      <svg
        width="7"
        height="7"
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="3.5"
          cy="3.5"
          r="3.5"
          fill={canopyColorPrimitivesMagenta40}
        />
      </svg>
    </div>
  )

  const renderTab = (tab: Tab) => {
    return (
      <button
        key={tab.sidebarTab}
        className="btn btn-brand-o btn-default btn-full-xs"
        type="button"
        onClick={() => onClick(tab)}
        data-analytics={tab.analytics?.closed}
      >
        {tab.tabName}
      </button>
    )
  }

  const renderButton = (tab: Tab) => {
    return (
      <div key={tab.sidebarTab}>
        {tab.alertDot && unreadAlert(tab)}
        <button
          type="button"
          onClick={() => onClick(tab)}
          data-analytics={tab.analytics?.closed}
          className={classNames({
            active: tab.sidebarTab === currentTab,
          })}
          aria-label={tab.tooltip}
        >
          <Tooltip
            trigger={["hover"]}
            overlay={tab.tooltip}
            placement={Tooltip.Placement.Left}
            width={Tooltip.Width.Unlimited}
          >
            {tab.icon}
          </Tooltip>
        </button>
        <hr />
      </div>
    )
  }
  const employerType = getEmployerType()
  const shouldActivityBarBeWider =
    orderStatus === OrderStatus.SupplierReview ||
    orderStatus === OrderStatus.Accepted ||
    (employerType === "Supplier" &&
      orderStatus === OrderStatus.FacilityInProgress)
  const containerClass = () => {
    if (shouldActivityBarBeWider) return "col-md-5"
    return "col-md-4"
  }
  return (
    <div
      className={classNames("p-0 right-sidebar", {
        fixed: fixed,
        [containerClass()]: tab,
      })}
      data-analytics-context="Sidebar"
    >
      {showTabs && !tab && (
        <div className="mobile-buttons">{tabs.map(renderTab)}</div>
      )}
      {showTabs && (
        <div className="sidebar-square-buttons">{tabs.map(renderButton)}</div>
      )}
      {tab && (
        <div className="right-sidebar-body pt-3 bl bb bc-p4">
          <div className="clearfix">
            <div className={cx("col-8", "px-0", "float-left")}>
              <h3>{tab.title}</h3>
              {tab.subtitle}
            </div>
            <span className="float-right">
              {tab.headerButton}
              <div
                className="float-right icon clickable"
                onClick={onClose}
                role="button"
                data-analytics="close-sidebar"
                aria-label="close sidebar"
              >
                <Icon type="times" />
              </div>
            </span>
          </div>
          <div className="content-wrapper">{tab.content}</div>
        </div>
      )}
    </div>
  )
}

export default RightSidebar
