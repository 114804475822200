// @ts-strict-ignore
import React from "react"
import { Field, FormikValues } from "formik"
import asQuestion from "./asQuestion"
import TextLengthCheck from "./TextLengthCheck"
import Checkbox from "../form/Checkbox"
import { isAnswerLengthValid } from "../utilities/validation"
import { isTabOrEnter } from "utilities/browser/event"
import TextArea from "components/TextArea"
import {
  SurveyQuestion,
  SurveyAnswerValue,
  SurveyAnswerType,
} from "sharedTypes"
import { Values } from "../sharedTypes"

import { isNullOrUndefined } from "../../../../../utilities/isNullOrUndefined"

type Props = {
  question: SurveyQuestion
  values: FormikValues
  inputId: string
}

const QuestionInputText = React.forwardRef<HTMLInputElement, Props>(
  ({ question, values, inputId }, ref) => {
    const minimumLength = question.minimumLength
    const maximumLength = question.maximumLength
    const isExistingRecord = !isNullOrUndefined(question.answerValue)
    return (
      <>
        <Field name="answerValue">
          {({ form, field }) => (
            <div>
              <TextArea
                ref={ref}
                {...field}
                id={inputId}
                className="form-control textarea"
                disabled={form.values.na || form.isSubmitting}
                onKeyDown={(event) => {
                  if (isTabOrEnter(event)) {
                    form.handleSubmit()
                  }
                }}
              />
              {(minimumLength || maximumLength) && (
                <TextLengthCheck question={question} answer={field.value} />
              )}
            </div>
          )}
        </Field>
        {isExistingRecord &&
          !values.answerValue &&
          !values.na &&
          !minimumLength && (
            <div className="alert alert-danger mb-3">
              This field is required. Please enter a response
            </div>
          )}
        {question.naEnabled && (
          <Field name="na">
            {({ form, field }) => (
              <Checkbox
                {...field}
                id={`na-${question.questionId}`}
                checked={!!form.values.na}
                label={question.naLabel}
                onChange={(checked) => {
                  form.setFieldValue("answerValue", "")
                  form.setFieldValue("na", checked)
                }}
              />
            )}
          </Field>
        )}
      </>
    )
  }
)
const config = {
  mapValuesToQuestion: (values: Values) => ({
    answerType: values.na ? SurveyAnswerType.Na : SurveyAnswerType.Value,
    answerValue: values.na ? null : values.answerValue,
  }),
  mapQuestionToValues: (question: SurveyQuestion) => {
    let answerValue: SurveyAnswerValue = ""
    let na
    if (question.answerType === SurveyAnswerType.Na) {
      na = true
    } else {
      answerValue = question.answerValue || ""
    }
    return { answerValue, na }
  },
  validate: (values, question) => {
    if (isAnswerLengthValid(question, values.answerValue) || values.na) {
      return {}
    }
    return { answerValue: "is not valid" }
  },
  isButtonVisible: ({ dirty }) => dirty,
}
export default asQuestion(QuestionInputText, config)
