import { get, post, put } from "services/api"
import {
  salesRepFulfillmentAgreementUrl,
  salesRepTransferAssignmentsUrl,
} from "applications/ReassignSalesReps/urls"
import { GridRowId } from "@mui/x-data-grid-pro"
import { SalesRepAssignment } from "applications/ReassignSalesReps/ReassignSalesRepsTable"
import { AxiosResponse } from "axios"

interface UpdatedSalesRepAssignmentData {
  currentFulfillmentAgreements: string[]
}

interface SalesRepAssignmentData {
  settingsPath: string
  salesRepAssignments: SalesRepAssignment[]
  featureFlags: {
    salesRepTerritories: boolean
  }
}

export interface SalesRepSearchResult {
  name: string
  email: string
  externalId: string
}

export const updateAssignments = async (
  salesRepId: string,
  initialAgreements: GridRowId[],
  selectedAgreements: GridRowId[]
): Promise<{ data: UpdatedSalesRepAssignmentData }> => {
  return put(salesRepFulfillmentAgreementUrl(salesRepId), {
    initialAgreements: initialAgreements,
    selectedAgreements: selectedAgreements,
  })
}

export const getAssignments = async (
  salesRepId: string | null
): Promise<{ data: SalesRepAssignmentData }> => {
  return get(salesRepFulfillmentAgreementUrl(salesRepId))
}

export const transferAssignments = async (
  selectedSalesRepId: string,
  recipientSalesRepId: string
): Promise<AxiosResponse> => {
  return put(salesRepTransferAssignmentsUrl(), {
    selectedSalesRepId: selectedSalesRepId,
    recipientSalesRepId: recipientSalesRepId,
  })
}
export const createTerritory = async (path, values): Promise<AxiosResponse> => {
  return post(path, { ...values })
}

export const updateTerritory = async (path, values): Promise<AxiosResponse> => {
  return put(path, {
    ...values,
  })
}
export const getSalesReps = async (
  term: string,
  path: string
): Promise<SalesRepSearchResult[]> => {
  return get(path, { term: term, allPotentialSalesReps: true }).then(
    (res) => res.data
  )
}
