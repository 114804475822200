import React from "react"
import * as styles from "./index.module.scss"
import { formatPhone } from "utilities/phone"

interface Props {
  supplierPhoneNumber: string | undefined
}

const Subhead: React.FC<Props> = ({ supplierPhoneNumber }) => {
  const phoneNumberContent =
    supplierPhoneNumber && `Phone: ${formatPhone(supplierPhoneNumber)}`

  return <h4 className={`${styles.subhead} mb-3`}>{phoneNumberContent}</h4>
}

export default Subhead
