import React from "react"
import Icon from "components/Icon"

function SaveChangesButton({ onClick }) {
  return (
    <a className="link link-understated font-xs ml-3" onClick={onClick}>
      <Icon className="mr-1" prefix="fas" type="save" />
      Save Changes
    </a>
  )
}

export default SaveChangesButton
