// @ts-strict-ignore
import React from "react"
import { DmeOrder, DmeOrderWarning, Employer, EmployerType } from "sharedTypes"
import { Permissions, SignatureRequest, Submission } from "../../sharedTypes"
import { FormData } from "applications/FulfillmentEditor/components/FulfillmentForm"
import FacilityActions from "./FacilityActions"
import SupplierActions from "./SupplierActions"

type Props = {
  warnings: DmeOrderWarning[]
  trackConversionEvents: boolean
  dmeOrder: DmeOrder
  permissions: Permissions
  pullBack(): void
  setUserEmail(): void
  signatureRequests: SignatureRequest[]
  sendSignatureRequest(params: {}): Promise<void>
  updateSignatureStatus(): Promise<void>
  supplierEdit(): Promise<void>
  supplierUnaccept(): Promise<void>
  submission: Submission
  submit(): Promise<void>
  selfSign: boolean
  currentEmployer: Employer
  submissionPending: boolean
  canSubmit: boolean
  showSubmit: boolean
  showReorder: boolean
  updateFulfillment(id: string, values: FormData): Promise<void>
  extraAttestationRequested: boolean
  isExtraAttestationChecked: boolean
  setExtraAttestationChecked(boolean): void
  userEmail?: string
  dmeOrderSpecificUser?: boolean
  archive(): Promise<void>
}

const Actions = (props: Props) => {
  const {
    dmeOrder,
    permissions,
    pullBack,
    setUserEmail,
    submission: { status, errorMessage, splitOrder },
    warnings,
    trackConversionEvents,
    supplierEdit,
    supplierUnaccept,
    sendSignatureRequest,
    updateSignatureStatus,
    submissionPending,
    currentEmployer,
    showReorder,
    selfSign,
    canSubmit,
    showSubmit,
    updateFulfillment,
    submit,
    extraAttestationRequested,
    isExtraAttestationChecked,
    setExtraAttestationChecked,
    userEmail,
    dmeOrderSpecificUser,
    archive,
  } = props

  const actionTitleProps = {
    dmeOrder,
    warnings,
    employer: currentEmployer,
    showSubmit,
    updateFulfillment,
    submit,
    permissions,
    canSubmit,
    selfSign,
    submissionPending,
  }
  const orderAlertProps = {
    permissions,
    submission: { status, errorMessage, splitOrder },
    trackConversionEvents,
    warnings,
    currentEmployer,
    submissionPending,
    dmeOrderSpecificUser,
    setUserEmail,
    userEmail,
  }
  if (currentEmployer.employerType === EmployerType.ClinicalFacility) {
    return (
      <FacilityActions
        actionTitleProps={actionTitleProps}
        orderAlertsProps={orderAlertProps}
        dmeOrder={dmeOrder}
        warnings={warnings}
        currentEmployer={currentEmployer}
        showReorder={showReorder}
        pullBack={pullBack}
        permissions={permissions}
        showSubmit={showSubmit}
        canSubmit={canSubmit}
        submit={submit}
        submissionPending={submissionPending}
        selfSign={selfSign}
        extraAttestationRequested={extraAttestationRequested}
        isExtraAttestationChecked={isExtraAttestationChecked}
        setExtraAttestationChecked={setExtraAttestationChecked}
        sendSignatureRequest={sendSignatureRequest}
        archive={archive}
      />
    )
  } else if (currentEmployer.employerType === EmployerType.Supplier) {
    return (
      <SupplierActions
        actionTitleProps={actionTitleProps}
        orderAlertsProps={orderAlertProps}
        dmeOrder={dmeOrder}
        currentEmployer={currentEmployer}
        showReorder={showReorder}
        showSubmit={showSubmit}
        canSubmit={canSubmit}
        permissions={permissions}
        sendSignatureRequest={sendSignatureRequest}
        updateSignatureStatus={updateSignatureStatus}
        supplierEdit={supplierEdit}
        supplierUnaccept={supplierUnaccept}
        submit={submit}
        submissionPending={submissionPending}
      />
    )
  } else {
    return null
  }
}

export default Actions
