// @team @destroythefax

import React from "react"
import BackArrowIcon from "components/BackArrowIcon"
import { trackClick } from "applications/Workflow/containers/Review/components/Warnings/analytics"
import { incomingOrdersUrl } from "applications/Workflow/urls"
import NonProdHeader from "applications/Workflow/containers/Navigation/components/TopNavigation/TopLeftNavButton/NonProdHeader/NonProdHeader"

interface Props {
  nonprodHeaderDetails?: string
}

const IncomingOrdersLink: React.FC<Props> = ({ nonprodHeaderDetails }) => {
  return (
    <>
      <a
        className="btn btn-ghost px-2"
        href={incomingOrdersUrl}
        onClick={() => trackClick("Incoming Orders")}
      >
        <BackArrowIcon />
        Incoming Orders
      </a>
      <NonProdHeader nonprodHeaderDetails={nonprodHeaderDetails} />
    </>
  )
}

export default IncomingOrdersLink
