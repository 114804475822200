import React from "react"
import { Form, Select, TextField } from "components/form"
import Button from "components/form/Button"
import Bulletin from "components/Bulletin"
import Icon from "components/Icon"
import { User } from "../../../shared/sharedTypes"
import { getTitleOptions, OtherOption } from "../../../shared/utilities"

interface Props {
  user: User
  updateUser(params): Promise<void>
  titles: string[]
}

function CreateAccount({ user, updateUser, titles }: Props) {
  const onSubmit = (params, { setErrors }) => {
    const { firstName, lastName, defaultTitle } = params
    return updateUser({ firstName, lastName, defaultTitle }).catch(
      ({ errors }) => {
        if (errors.user) {
          setErrors(errors.user)
        }
      }
    )
  }

  const validate = ({ firstName, lastName, defaultTitle }) => {
    const errors: {
      firstName?: string
      lastName?: string
      defaultTitle?: string
    } = {}

    if (!firstName) {
      errors.firstName = "Enter first name"
    }
    if (!lastName) {
      errors.lastName = "Enter last name"
    }
    if (!defaultTitle) {
      errors.defaultTitle = "Select title"
    }
    return errors
  }

  const titleOptions = getTitleOptions(titles)

  const handleSubmit = (params, { setErrors }) => {
    if (params.defaultTitle === OtherOption.value) {
      params.defaultTitle = null
    }
    return onSubmit(params, { setErrors })
  }

  const initialValues = { ...user }

  return (
    <>
      <h1 className="mb-5">Create Your Account</h1>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        <TextField label="Email address" name="email" disabled unwrapped />
        <Bulletin status="success">
          <Icon type="check-circle" /> Email verified
        </Bulletin>
        <div className="row">
          <div className="col-md-6">
            <TextField label="First name" name="firstName" />
          </div>
          <div className="col-md-6">
            <TextField label="Last name" name="lastName" />
          </div>
        </div>
        <Select
          label="Job title"
          placeholder="Select your title"
          name="defaultTitle"
          options={titleOptions}
        />
        <Button className="btn-brand btn-block" type="submit">
          Continue
        </Button>
      </Form>
    </>
  )
}

export default CreateAccount
