import { Event, EventAction } from "sharedTypes"
import { Chat, Message } from "services/patientPortal"

export const transformMessage = (
  message: Message,
  chat: Chat,
  insuranceUploadUrl: string
): Event => {
  let action, userId, userName, userEmployerName, userEmployerType
  switch (message.type) {
    case "supplier":
      userName = `${message.userAuthor!.firstName} ${
        message.userAuthor!.lastName
      }`
      action = EventAction.SupplierComment
      userId = message.userAuthor!.parachuteId
      userEmployerName = message.supplier.name
      userEmployerType = "supplier"
      break
    case "patient":
      userName = `${chat.patient.firstName} ${chat.patient.lastName}`
      action = EventAction.Comment
      userId = ""
      userEmployerName = userName
      userEmployerType = "none"
      break
    default:
      userName = `${chat.patient.firstName} ${chat.patient.lastName}`
      action = EventAction.PatientPortalAutomated
      userId = ""
      userEmployerName = "Patient"
      userEmployerType = "none"
      break
  }

  return {
    action: action,
    userId: userId,
    userName: userName,
    userEmail: "",
    userEmployerName: userEmployerName,
    userEmployerType: userEmployerType,
    showContactInfo: false,
    superAdmin: false,
    content: "",
    rawContent:
      message.type === "insurance_uploaded"
        ? createInsuranceUploadContent(message, insuranceUploadUrl)
        : message.content,
    data: {},
    skipNotifications: false,
    eventTimestamp: message.insertedAt,
    externalLinks: [],
    sidebarLinks: [],
    readAt: message?.readAt,
  }
}

const createInsuranceUploadContent = (
  message: Message,
  insuranceUploadUrl: string
): string => {
  return `Patient submitted photos of their ${message.insuranceRank} insurance card.

^[View Insurance Card](${insuranceUploadUrl})`
}
