import React from "react"
import classNames from "classnames"

type Option = {
  label: string
  value: boolean
}

type Props = {
  name: string
  options: Option[]
  initialValue?: boolean
  dirty?: boolean
  onChange(values: { [key: string]: boolean | undefined }): Promise<void>
}

const ToggleFilter = ({
  name,
  options,
  initialValue,
  dirty,
  onChange,
}: Props) => {
  const toggleValue = (value) => {
    const newValue = value === initialValue ? undefined : value
    onChange({ [name]: newValue })
  }

  return (
    <span className="btn-group v-align-top mr-2 mb-3">
      {options.map((option) => {
        const active = option.value === initialValue

        return (
          <button
            key={option.label}
            onClick={() => toggleValue(option.value)}
            className={classNames("btn btn-pill btn-sm", {
              active,
              warning: dirty,
            })}
            type="button"
            tabIndex={0}
          >
            {option.label}
          </button>
        )
      })}
    </span>
  )
}

export default ToggleFilter
