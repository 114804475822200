// @ts-strict-ignore
import React from "react"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import ConfirmButton from "./ConfirmButton"
import ChartNoteHelperAlert from "./ChartNoteHelperAlert"
import ProgressDonut from "components/ProgressDonut"
import Survey from "applications/Workflow/components/Survey"
import { Link } from "react-router-dom"
import * as routes from "applications/Workflow/routes"
import { SurveyQuestion, SurveyAnswerType } from "sharedTypes"
import { ChartNote, ChartNoteHelperType } from "../sharedTypes"
import { LEGAL_WARNING } from "applications/Workflow/containers/ClinicalFacilityClinical/utilities"
import WorkflowHeader from "applications/Workflow/components/WorkflowHeader"
import cx from "classnames"

type Props = {
  answerQuestion(
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue: string
  ): Promise<void>
  confirmPrefilledAnswers(): Promise<void>
  faceToFace: ChartNote
  questions: SurveyQuestion[]
  requiresConfirmation: boolean
  disqualifiedMessage?: string
  qualificationStatus: string
  percentageComplete: number
  chartNoteHelperType: ChartNoteHelperType
  hasDefaultChoices: boolean
  nextPagePath?: string
}

const InternalChartNotes: React.SFC<Props> = ({
  answerQuestion,
  confirmPrefilledAnswers,
  faceToFace,
  questions,
  requiresConfirmation,
  qualificationStatus,
  disqualifiedMessage,
  percentageComplete,
  hasDefaultChoices,
  chartNoteHelperType,
  nextPagePath,
}) => {
  const isDisqualified = qualificationStatus === "disqualified"
  const isQualified = qualificationStatus === "qualified"
  const isComplete = isDisqualified || isQualified

  return (
    <div className={cx("wide-fixed-row")}>
      <WorkflowHeader title={`${faceToFace.name} Documentation`} />
      {!!questions.length && (
        <>
          <ChartNoteHelperAlert
            type={chartNoteHelperType}
            hasDefaultChoices={hasDefaultChoices}
          />
          <div className="well clearfix">
            <Survey
              questions={questions}
              answerQuestion={answerQuestion}
              showMostCommon={
                chartNoteHelperType === ChartNoteHelperType.Common
              }
            />
            {!isComplete && (
              <div className="float-right">
                <ProgressDonut percentage={percentageComplete} />
              </div>
            )}
            <ConfirmButton
              requiresConfirmation={requiresConfirmation}
              confirmPrefilledAnswers={confirmPrefilledAnswers}
            />
          </div>
        </>
      )}
      {!!isQualified && (
        <div className="alert alert-success well-wide">
          <strong>Documentation completed.</strong>
          <br />
          <br />
          {LEGAL_WARNING}
        </div>
      )}
      {!!isDisqualified && (
        <div
          className="alert alert-danger well-wide"
          style={{ whiteSpace: "pre-line" }}
        >
          <strong>Documentation not supported.</strong> {disqualifiedMessage}
          <br />
          <br />
          {LEGAL_WARNING}
        </div>
      )}
      <div className="well-wide">
        <div className="row">
          <div className="col-md-6 col-12 mb-4 order-md-6">
            <div className="text-right">
              <ContinueButton disabled={!nextPagePath} path={nextPagePath} />
            </div>
          </div>
          <div className="col-md-6 col-12">
            {!!isComplete && (
              <Link
                className="btn btn-brand-o btn-xs-block"
                to={routes.clinicalPath()}
              >
                Back To Clinical Info
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InternalChartNotes
