import React from "react"
import DataRow from "components/DataRow"
import PrescriptionDetails from "./PrescriptionDetails"
import { LineItemGroup, RxDetailsOutput } from "sharedTypes"
import {
  formatItemQuantity,
  formatSkuDescription,
} from "applications/Workflow/containers/Review/utilities"
import { formatPaymentMethod, isDefaultPaymentMethod } from "utilities/payment"

type Props = {
  lineItemGroup: LineItemGroup
  rxDetailsOutputs: RxDetailsOutput[]
}

const EquipmentLineItemGroup = ({ lineItemGroup, rxDetailsOutputs }: Props) => {
  return (
    <div key={lineItemGroup.id} className="well well-expand">
      <div className="content">
        <p className="bold font-xs">{lineItemGroup.packageName}</p>
        <ul className="pl-3 font-xs">
          {lineItemGroup.lineItems.map((lineItem) => (
            <li key={lineItem.id} className="mb-1">
              <p className="font-xs mb-0">
                {formatSkuDescription(lineItem.skuDescription)}
                {lineItem.hcpcs && ` - ${lineItem.hcpcs}`}
              </p>
            </li>
          ))}
        </ul>
        <hr className="mt-3 mb-0" />
        <DataRow
          className="font-xs mb-0"
          label="Quantity"
          value={formatItemQuantity(lineItemGroup)}
        />
        {!!rxDetailsOutputs.length && (
          <PrescriptionDetails rxDetailsOutputs={rxDetailsOutputs} />
        )}
        {!isDefaultPaymentMethod(lineItemGroup.paymentMethod) && (
          <DataRow
            className="font-xs mb-0"
            label="Payment Method"
            value={formatPaymentMethod(lineItemGroup.paymentMethod)}
          />
        )}
        {lineItemGroup.catalogId && (
          <DataRow
            className="font-xs mb-0"
            label="Catalog ID"
            value={lineItemGroup.catalogId}
          />
        )}
      </div>
    </div>
  )
}

export default EquipmentLineItemGroup
