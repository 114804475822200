import React from "react"
import {
  DocumentationRequirement,
  DocumentationRequirementEntryMechanism,
  DocumentationRequirementSatisfyMethod,
} from "sharedTypes"
import { titleWithCategory } from "applications/Workflow/utilities/documentationRequirements"
import ClinicalInformationRow from "../ClinicalInformationRow"
import { DownloadUploadDetails } from "./DownloadUploadDetails"
import { DocumentationRowProps } from "./documentation-row-props"
import { ManualUploadDetails } from "./ManualUploadDetails"
import { DocumentationRequirementStatus } from "./DocumentationRequirementStatus"
import { GenerateDetails } from "./GenerateDetails"
import { FillablePdfDetails } from "./FillablePdfDetails"

function documentIsParachuteGenerated(
  documentationRequirement: DocumentationRequirement
) {
  return (
    documentationRequirement.satisfyMethod ===
    DocumentationRequirementSatisfyMethod.Generate
  )
}

enum RowType {
  "Generate" = 0,
  "ManualUpload" = 1,
  "DownloadUpload" = 2,
  "FillablePdf" = 3,
}

type RowTypeProps = Pick<DocumentationRowProps, "documentationRequirement">
const rowType = (props: RowTypeProps) => {
  if (
    props.documentationRequirement.entryMechanism ===
    DocumentationRequirementEntryMechanism.FillablePdf
  ) {
    return RowType.FillablePdf
  }
  if (
    props.documentationRequirement.entryMechanism ===
    DocumentationRequirementEntryMechanism.Survey
  ) {
    return RowType.Generate
  }
  const documentKey = props.documentationRequirement.definitionIdentifierName
  const allowDocumentDownload =
    typeof documentKey === "string" &&
    props.documentationRequirement.entryMechanism ===
      DocumentationRequirementEntryMechanism.DownloadUpload
  if (allowDocumentDownload) return RowType.DownloadUpload
  return RowType.ManualUpload
}

const DetailSwitcher = (props: DocumentationRowProps) => {
  const row = rowType(props)
  switch (row) {
    case RowType.Generate:
      return <GenerateDetails {...props} />
    case RowType.FillablePdf:
      return <FillablePdfDetails {...props} />
    case RowType.DownloadUpload:
      return <DownloadUploadDetails {...props} />
    case RowType.ManualUpload:
      return <ManualUploadDetails {...props} />
    default:
      return <ManualUploadDetails {...props} />
  }
}

export default (props: DocumentationRowProps) => {
  return (
    <ClinicalInformationRow
      title={titleWithCategory(props.documentationRequirement)}
      isDocumentParachuteGenerated={documentIsParachuteGenerated(
        props.documentationRequirement
      )}
      showDetails={props.detailed}
      status={
        <DocumentationRequirementStatus
          satisfyStatus={props.documentationRequirement.satisfyStatus}
        />
      }
      details={<DetailSwitcher {...props} />}
    />
  )
}
