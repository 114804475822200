import * as React from "react"
import { MaterialThemeProvider } from "themes/theme"
import { DataGridPro, GridColDef, GridRowId } from "@mui/x-data-grid-pro"
import { isTest } from "utilities/environment"
import { useEffect, useState } from "react"
import ReassignSalesRepsEditMessage from "../ReassignSalesRepsEditMessage"
import { Button } from "components/form"
import { EventCategory, trackEvent } from "utilities/tracking"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"

const columns: GridColDef[] = [
  {
    field: "facilityName",
    flex: 1,
    headerName: "Facility",
    minWidth: 150,
    sortable: true,
  },
  {
    field: "salesRepName",
    flex: 1,
    headerName: "Assigned Sales Rep",
    minWidth: 150,
    sortable: true,
  },
  {
    field: "facilityAddress",
    flex: 1,
    headerName: "Facility Address",
    minWidth: 150,
    sortable: true,
  },
  {
    field: "facilityCity",
    flex: 1,
    headerName: "Facility City",
    minWidth: 150,
    sortable: true,
  },
  {
    field: "facilityState",
    flex: 1,
    headerName: "Facility State",
    minWidth: 150,
    sortable: true,
  },
  {
    field: "facilityZip",
    flex: 1,
    headerName: "Facility Zip",
    minWidth: 150,
    sortable: true,
  },
]

export type SalesRepAssignment = {
  facilityName: string
  salesRepName: string
  fulfillmentAgreementId: string
  salesRepId: string
  facilityAddress: string
  facilityCity: string
  facilityState: string
  facilityZip: string
}

type Props = {
  salesRepAssignments: SalesRepAssignment[]
  selectedSalesRep: OptionItem
  updateAssignments: (
    selectedSalesRepId: string,
    initiallySelectedRows: GridRowId[],
    selectedRows: GridRowId[]
  ) => Promise<void>
}

const ReassignSalesRepsTable: React.FC<Props> = ({
  salesRepAssignments,
  selectedSalesRep,
  updateAssignments,
}: Props) => {
  const [selectedRows, setSelectedRows] = useState([] as GridRowId[])
  const [initiallySelectedRows, setInitiallySelectedRows] = useState(
    [] as GridRowId[]
  )
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [initiallySortedRows, setInitiallySortedRows] = useState(
    [] as SalesRepAssignment[]
  )
  const [muiTableKey, setMuiTableKey] = useState(0)
  const {
    value: selectedSalesRepId = "",
    label: selectedSalesRepName = "",
  } = selectedSalesRep

  async function handleSubmit(): Promise<void> {
    void trackEvent(
      EventCategory.ReassignSalesRep,
      "reassign_sales_rep_update_clicked"
    )

    await updateAssignments(
      selectedSalesRepId,
      initiallySelectedRows,
      selectedRows
    )
  }

  const trackSorting = () => {
    trackEvent(
      EventCategory.ReassignSalesRep,
      "reassign_sales_rep_sort_clicked"
    )
  }

  const trackFilterClick = () => {
    trackEvent(
      EventCategory.ReassignSalesRep,
      "reassign_sales_rep_filter_clicked"
    )
  }

  const trackHeader = () => {
    trackEvent(
      EventCategory.ReassignSalesRep,
      "reassign_sales_rep_header_clicked"
    )
  }

  const trackHide = () => {
    trackEvent(
      EventCategory.ReassignSalesRep,
      "reassign_sales_rep_hide_clicked"
    )
  }

  useEffect(() => {
    const selectedRowsUnchanged =
      JSON.stringify(selectedRows.sort()) ===
      JSON.stringify(initiallySelectedRows.sort())
    setSubmitButtonDisabled(selectedRowsUnchanged)
  }, [selectedRows, initiallySelectedRows])

  useEffect(() => {
    const initiallySelectedRows = salesRepAssignments
      .filter((assignment) => assignment.salesRepId === selectedSalesRepId)
      .map((assignment) => assignment.fulfillmentAgreementId)

    setSelectedRows(initiallySelectedRows)
    setInitiallySelectedRows(initiallySelectedRows)

    const sortSalesRepToTop = (
      a: SalesRepAssignment,
      b: SalesRepAssignment
    ): number => {
      if (
        a.salesRepId === selectedSalesRepId &&
        b.salesRepId !== selectedSalesRepId
      ) {
        return -1
      } else if (
        a.salesRepId !== selectedSalesRepId &&
        b.salesRepId === selectedSalesRepId
      ) {
        return 1
      } else return 0
    }

    setInitiallySortedRows([...salesRepAssignments].sort(sortSalesRepToTop))
    setMuiTableKey((key) => key + 1)
  }, [salesRepAssignments, selectedSalesRepId])

  return (
    <MaterialThemeProvider>
      <div
        style={{
          height: "calc(100vh - 440px)",
          width: "98.5vw",
        }}
      >
        <DataGridPro
          key={muiTableKey}
          columns={columns}
          rows={initiallySortedRows}
          getRowId={(row) => row.fulfillmentAgreementId}
          loading={false}
          rowHeight={38}
          disableVirtualization={isTest()}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={setSelectedRows}
          selectionModel={selectedRows}
          onSortModelChange={trackSorting}
          onColumnHeaderClick={trackHeader}
          onFilterModelChange={trackFilterClick}
          onColumnVisibilityChange={trackHide}
        />
      </div>
      <div className="text-left">
        <ReassignSalesRepsEditMessage
          selectedSalesRepName={selectedSalesRepName}
          selectedRows={selectedRows}
          initiallySelectedRows={initiallySelectedRows}
        />
        <Button
          type="submit"
          className="btn-brand"
          disabled={submitButtonDisabled}
          onClick={handleSubmit}
        >
          Update Assignments
        </Button>
      </div>
    </MaterialThemeProvider>
  )
}

export default ReassignSalesRepsTable
