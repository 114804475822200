import React from "react"
import * as styles from "./index.module.scss"
import SupplierCard from "../index"
import { Supplier } from "../../sharedTypes"
import Tooltip from "components/Tooltip"
import Icon from "components/Icon"
import NetworkCoverage from "./NetworkCoverage"
import CardTitle from "../CardTitle"
import CardOverline from "../CardOverline"
import { supplierOverlineText } from "../utilities/index"
import { ServiceTier } from "sharedTypes"

interface Props {
  supplier: Supplier
  isOtherSuppliersSection: boolean
}

const ParachuteSupplierCard: React.FC<Props> = ({
  supplier,
  isOtherSuppliersSection,
}) => {
  const overLineText = isOtherSuppliersSection
    ? supplierOverlineText(supplier)
    : undefined

  const EFaxDisclaimer = () => (
    <div className={styles.efaxContainer}>
      <div className="mr-2">
        <Icon type="exclamation-triangle" className={styles.cautionIcon} />
      </div>
      <div className={styles.disclaimerContainer}>
        <p className={styles.efaxDisclaimer}>
          <span className={styles.boldEfaxDisclaimer}>eFax Supplier -</span>{" "}
          Requires follow-up outside of Parachute and may have an incomplete
          catalog.
        </p>
      </div>
    </div>
  )

  return (
    <SupplierCard supplier={supplier}>
      <div>
        <div className={styles.header}>
          <div>
            {overLineText && <CardOverline text={overLineText} />}
            <CardTitle title={supplier.name} />
          </div>
          {supplier.pinned && (
            <Tooltip overlay="Pinned by your organization" trigger="hover">
              <Icon type="thumbtack" className={styles.pinnedIcon} />
            </Tooltip>
          )}
        </div>
        {supplier.serviceTier === ServiceTier.Basic && <EFaxDisclaimer />}
      </div>
      <NetworkCoverage networkCoverage={supplier.networkCoverage} />
    </SupplierCard>
  )
}

export default ParachuteSupplierCard
