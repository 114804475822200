// @ts-strict-ignore
import React, { useEffect } from "react"
import { Field, FormikValues } from "formik"
import asQuestion from "./asQuestion"
import TextLengthCheck from "./TextLengthCheck"
import MostCommonCallout from "./MostCommonCallout"
import RadioButtons from "components/form/RadioButtons"
import { SurveyOption, SurveyQuestion, SurveyAnswerType } from "sharedTypes"
import { Values } from "../sharedTypes"
import { isTab, isEnter } from "utilities/browser/event"
import { isAnswerLengthValid } from "../utilities/validation"

const OTHER_VALUE = "OTHER"
const NA_VALUE = "na"

function isDefaultChoice(choice, question) {
  return (
    question.defaultChoices && question.defaultChoices.includes(choice.code)
  )
}

function getOptions(question, answer, showMostCommon, dirty) {
  const options: SurveyOption[] = question.choices.map((choice) => {
    const option: SurveyOption = {
      label: choice.label,
      value: choice.code,
    }
    if (showMostCommon && isDefaultChoice(choice, question)) {
      option.callout = <MostCommonCallout />
    }
    return option
  })
  if (question.other) {
    options.push({
      label: question.other,
      value: OTHER_VALUE,
      addOnPosition: "inside",
      addOn: ({ form, field }) => {
        if (field.value === OTHER_VALUE) {
          return (
            <div className="mt-2">
              <Field
                className="form-control"
                type="text"
                name="otherAnswer"
                disabled={form.isSubmitting}
                onKeyDown={(event) =>
                  dirty &&
                  (isTab(event) || isEnter(event)) &&
                  form.handleSubmit()
                }
              />
              {question.maximumLength && (
                <TextLengthCheck
                  question={question}
                  answer={form.values.otherAnswer}
                />
              )}
            </div>
          )
        }
      },
    })
  }
  if (question.naEnabled) {
    const option: SurveyOption = {
      label: question.naLabel || "None of these",
      value: NA_VALUE,
    }
    if (showMostCommon && question.defaultChoices?.includes("na")) {
      option.callout = <MostCommonCallout />
    }
    options.push(option)
  }
  return options
}

type Props = {
  question: SurveyQuestion
  setFieldValue(field: string, value, shouldValidate?: boolean): void
  submitForm(): Promise<void>
  isSubmitting: boolean
  inputId: string
  showMostCommon?: boolean
  values: FormikValues
  initialValues: FormikValues
  dirty: boolean
  requiresConfirmation: boolean
}

const QuestionSelectOne: React.FC<Props> = ({
  dirty,
  initialValues,
  question,
  showMostCommon,
  submitForm,
  values,
  ...props
}) => {
  useEffect(() => {
    if (
      values !== initialValues &&
      values.answerValue &&
      values.answerValue !== OTHER_VALUE
    ) {
      submitForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.answerValue])

  const options = getOptions(
    question,
    question.answerValue || "",
    showMostCommon,
    dirty
  )

  return (
    <RadioButtons
      id={props.inputId}
      name="answerValue"
      block
      disabled={props.isSubmitting}
      options={options}
      onChange={(event) => {
        if (event.target.value !== OTHER_VALUE) {
          props.setFieldValue("otherAnswer", "")
        }
      }}
    />
  )
}
const config = {
  mapValuesToQuestion: ({ answerValue, otherAnswer }) => {
    let value = answerValue
    let type = SurveyAnswerType.Value
    if (answerValue === NA_VALUE) {
      type = SurveyAnswerType.Na
      value = null
    } else if (answerValue === OTHER_VALUE) {
      value = otherAnswer
    }
    return { answerValue: value, answerType: type }
  },
  mapQuestionToValues: (question: SurveyQuestion) => {
    const staticChoices = question.choices.map(({ code }) => code)
    const answer = (question.answerValue as string) || ""
    let answerValue = answer
    let otherAnswer = ""
    if (question.answerType === SurveyAnswerType.Na) {
      answerValue = NA_VALUE
    } else if (answer && !staticChoices.includes(answer)) {
      answerValue = OTHER_VALUE
      otherAnswer = answer
    }
    return { answerValue, otherAnswer }
  },
  validate: (values: Values, question: SurveyQuestion) => {
    const { answerValue, otherAnswer } = values
    if (answerValue === OTHER_VALUE && !otherAnswer) {
      return { otherAnswer: "is required" }
    } else if (
      answerValue === OTHER_VALUE &&
      !isAnswerLengthValid(question, otherAnswer)
    ) {
      return { otherAnswer: "is not valid" }
    }
    return {}
  },
  isButtonVisible: ({ values, dirty }) => {
    const { otherAnswer } = values

    return dirty && otherAnswer
  },
}

export default asQuestion(QuestionSelectOne, config)
