import React from "react"
import ClinicalFacilitySuggestion from "./ClinicalFacilitySuggestion"
import { ClinicalFacility } from "../sharedTypes"
import Select from "components/form/Select"
import Suggestion from "components/form/Select/Suggestion"
import { Form } from "components/form"

type Props = {
  existingFacilities: ClinicalFacility[]
  searchFacilities(value: string): Promise<ClinicalFacility[]>
  addFacility(facilityId): void
  requestFacility(): void
}

const ClinicalFacilitySearch = ({
  existingFacilities,
  searchFacilities,
  addFacility,
  requestFacility,
}: Props) => {
  const existingFacilityIds = existingFacilities.map(
    ({ externalId }) => externalId
  )
  const select = (facilityId) => {
    if (existingFacilityIds.includes(facilityId)) {
      return
    }

    addFacility(facilityId)
  }

  const fetchOptions = (term) => {
    return searchFacilities(term).then((facilities: ClinicalFacility[]) => {
      return facilities.map((facility) => {
        return { value: facility.externalId, label: facility.name, ...facility }
      })
    })
  }

  return (
    <div className="well well-expand">
      <h4>Search Facilities</h4>
      <Form>
        <Select
          name="clinicalFacilitySearch"
          placeholder="Type facility's name or NPI"
          fetchOptions={fetchOptions}
          isSearchable
          onChange={select}
          closeMenuOnSelect
          minLength={2}
          clearOnSelect
          renderOption={(facility, { query }) => (
            <ClinicalFacilitySuggestion
              facility={facility}
              query={query}
              inNetwork={existingFacilityIds.includes(facility.externalId)}
            />
          )}
          renderEmpty={() => (
            <Suggestion onClick={requestFacility}>
              <a className="link">+ Create a New Facility</a>
            </Suggestion>
          )}
        />
      </Form>
    </div>
  )
}

export default ClinicalFacilitySearch
