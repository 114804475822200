import React from "react"
import Warnings from "../Warnings"
import Alert from "components/Alert"
import { DmeOrderWarning, Employer } from "sharedTypes"
import { Permissions, Submission } from "../../sharedTypes"
import FollowTheLinkBanner from "../FollowTheLinkBanner"
import { trackInvitationEvent } from "applications/AccessCodeLogin/utilities"

export type Props = {
  permissions: Permissions
  submission: Submission
  warnings: DmeOrderWarning[]
  trackConversionEvents: boolean
  currentEmployer: Employer
  submissionPending: boolean
  setUserEmail(): void
  userEmail?: string
  dmeOrderSpecificUser?: boolean
}

const OrderAlerts = (props: Props) => {
  const {
    permissions,
    submission: { status, errorMessage },
    trackConversionEvents,
    warnings,
    currentEmployer,
    submissionPending,
    dmeOrderSpecificUser,
    setUserEmail,
    userEmail,
  } = props
  return (
    <>
      <Warnings
        warnings={warnings}
        permissions={permissions}
        currentEmployer={currentEmployer}
        trackEvents={trackConversionEvents}
        submissionErrorMessage={
          !submissionPending && status === "error" ? errorMessage : undefined
        }
      />
      {permissions.setUserEmail && !permissions.submit && (
        <Alert
          leftIcon
          rightIcon="fa-chevron-right"
          status="success"
          onClick={() => {
            trackInvitationEvent("registration-banner-clicked")
            setUserEmail()
          }}
        >
          <strong>Submitted! </strong>
          Sign up to place and track orders with Parachute Health.
        </Alert>
      )}
      {dmeOrderSpecificUser && userEmail && (
        <FollowTheLinkBanner userEmail={userEmail} />
      )}
    </>
  )
}

export default OrderAlerts
