import React, { useRef } from "react"
import {
  SelectedRecipient,
  SendBackFormKeys,
  SendBackRecipient,
} from "./sharedTypes"
import { FieldArray } from "formik"
import SendBackRecipientSelector from "./SendBackRecipientSelector"
import { GroupedSendBackRecipients } from "applications/Workflow/containers/Review/components/SupplierSendBack/SupplierSendBackForm"

type Props = {
  setFieldValue(field: string, value, shouldValidate?: boolean): void
  showChannelSelector?: boolean
  defaultFacilityRecipient: SendBackRecipient
  setDefaultSupplierFollowables?: () => void
  hasFacilityRecipients: boolean
  searchFacilityRecipients(
    term: string
  ): Promise<SendBackRecipient[] | GroupedSendBackRecipients[]>
  setSearchRecipientRecommendations: (recommendationIds: string[]) => void
  selectedRecipients: SelectedRecipient[]
}

const SendBackRecipientsSelector = ({
  setFieldValue,
  showChannelSelector,
  setDefaultSupplierFollowables,
  hasFacilityRecipients,
  defaultFacilityRecipient,
  searchFacilityRecipients,
  setSearchRecipientRecommendations,
  selectedRecipients,
}: Props) => {
  const lastHasFacilityRecipients = useRef<boolean>(hasFacilityRecipients)

  const channelOptions = (() => {
    const options = [
      { label: "Fax", value: "fax", fieldType: "phone" },
      { label: "Email", value: "email", fieldType: "email" },
      { label: "SMS", value: "sms", fieldType: "phone" },
    ]
    if (hasFacilityRecipients) {
      options.unshift({
        label: "User",
        value: "followable",
        fieldType: "select",
      })
    }
    return options
  })()

  const addRecipientText = () => {
    if (showChannelSelector) {
      if (hasFacilityRecipients) {
        return "+ Add another user, fax, email, or SMS"
      } else {
        return "+ Add another fax, email, or SMS"
      }
    }
    return "+ Add another user"
  }

  const blankRecipient = {
    channel: showChannelSelector ? channelOptions[0].value : "followable",
    destination: "",
  }

  return (
    <FieldArray
      name={SendBackFormKeys.recipients}
      render={({ form, remove, push }) => {
        if (lastHasFacilityRecipients.current !== hasFacilityRecipients) {
          lastHasFacilityRecipients.current = hasFacilityRecipients
          form.setFieldValue("recipients", [blankRecipient])
        }
        if (form.values.recipients.length === 0) {
          form.values.recipients.push(blankRecipient)
        }
        return (
          <div className="form-group">
            <label className="mb-2">Push order to...</label>
            {form.values.recipients.map((recipient, index) => (
              <SendBackRecipientSelector
                key={`recipient-channel-${index}`}
                recipient={recipient}
                index={index}
                length={form.values.recipients.length}
                remove={remove}
                channelOptions={channelOptions}
                showChannelSelector={showChannelSelector as boolean}
                setFieldValue={setFieldValue}
                defaultSendBackRecipient={defaultFacilityRecipient}
                searchSendBackRecipients={searchFacilityRecipients}
                setDefaultSupplierFollowables={setDefaultSupplierFollowables}
                setSearchRecipientRecommendations={
                  setSearchRecipientRecommendations
                }
                selectedRecipients={selectedRecipients}
              />
            ))}
            <a onClick={() => push(blankRecipient)} className="link">
              {addRecipientText()}
            </a>
          </div>
        )
      }}
    />
  )
}

export default SendBackRecipientsSelector
