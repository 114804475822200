import React from "react"
import Modal from "components/Modal"
import Followers from "./Followers"
import { DmeOrder } from "sharedTypes"
import { UpdateFollowersParams } from "../sharedTypes"

type Props = {
  show: boolean
  closeModal(): void
  editable: boolean
  updateFollowers(params: UpdateFollowersParams): Promise<void>
  dmeOrder: DmeOrder
}

function FollowersModal({
  show,
  closeModal,
  editable,
  dmeOrder,
  updateFollowers,
}: Props) {
  return (
    <Modal
      title={editable ? "Edit followers" : "Followers"}
      show={show}
      cancel={closeModal}
      size={editable ? "md" : "sm"}
    >
      <Modal.Body>
        <Followers
          editable={editable}
          dmeOrder={dmeOrder}
          updateFollowers={updateFollowers}
          onChange={closeModal}
        />
      </Modal.Body>
    </Modal>
  )
}

export default FollowersModal
