import React from "react"
import Icon from "components/Icon"

interface Props {
  onClick(): void
  isDefaultView?: boolean
}

function SaveButton({ onClick, isDefaultView }: Props) {
  const text = isDefaultView ? "Save Current View" : "Save as New"
  return (
    <a className="link link-understated font-xs ml-3" onClick={onClick}>
      <Icon className="mr-1" type="star" />
      {text}
    </a>
  )
}

export default SaveButton
