import React from "react"
import { asForm, TextArea, RadioButtons } from "components/form"
import { SelectOption } from "components/form/sharedTypes"

type Props = {
  isSubmitting: boolean
  close(): void
  denialReasons: SelectOption[]
  isValid: boolean
}

const DenyOrderForm: React.FunctionComponent<Props> = ({
  isSubmitting,
  close,
  isValid,
  denialReasons,
}) => {
  return (
    <>
      <div className="modal-body">
        <RadioButtons
          block
          label="Reason"
          name="reason"
          options={denialReasons}
        />
        <TextArea label="(Optional) Description" name="description" rows={2} />
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-primary mr-3"
          disabled={!isValid || isSubmitting}
        >
          Submit
        </button>
        <button type="button" className="btn btn-secondary" onClick={close}>
          Close
        </button>
      </div>
    </>
  )
}
export default asForm(DenyOrderForm)
