// @ts-strict-ignore
import alertFavicon from "logo-alert.png"
import memoize from "lodash.memoize"

type Favicon = "default" | "alert"

const getDefaultFavicon = memoize(() => {
  return document.querySelector("link[rel*='icon']").getAttribute("href")
})

export const setFavicon = (type: Favicon) => {
  const defaultFavicon = getDefaultFavicon()
  const href = type === "alert" ? alertFavicon : defaultFavicon
  document.querySelector('link[rel="shortcut icon"]').setAttribute("href", href)
}
