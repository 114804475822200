import React from "react"
import Icon from "components/Icon"
import { formatAddress } from "utilities/address"
import { Address } from "sharedTypes"

type Props = {
  address: Address
  editAddress(address: Address): void
  label?: string
  children?: React.ReactNode
}

function AddressSection({ address, label, editAddress, children }: Props) {
  return (
    <div className="ml-3 pl-1">
      {label && <p className="mb-0 mt-2 bold">{label}</p>}
      <p className="mb-2 color-dark-gray">
        {formatAddress(address)}
        <a
          className="link link-primary ml-1 nowrap"
          onClick={() => editAddress(address)}
        >
          Edit
          <Icon type="pencil-alt" className="ml-1" />
        </a>
      </p>
      {children}
    </div>
  )
}

export default AddressSection
