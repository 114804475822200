import { gql, TypedDocumentNode } from "@apollo/client"
import {
  GetPayorUsersQuery,
  GetPayorUsersQueryVariables,
} from "../../../../graphql/__generated__/graphql"
import { PageInfoFieldsFragment } from "../../../../graphql/fragments/fragments"

export const GetPayorUsers: TypedDocumentNode<
  GetPayorUsersQuery,
  GetPayorUsersQueryVariables
> = gql`
  ${PageInfoFieldsFragment}
  query GetPayorUsers(
    $sort: String
    $sortDirection: SortDirectionEnum
    $searchString: String
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    users(
      sort: $sort
      sortDirection: $sortDirection
      searchString: $searchString
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        ...PageInfoFields
      }
      nodes {
        id
        firstName
        lastName
        email
        currentEmployment {
          id
          role
        }
      }
    }
  }
`
