import React from "react"
import { DmeOrderWarning, SignatureWarningMetadata } from "sharedTypes"
import Warning from "./Warning"
import { reportError } from "utilities/error"
import SignaturePendingWarning from "./SignaturePendingWarning"

type Props = {
  warning: DmeOrderWarning
  trackEvents: boolean
}

const render = (
  warning: DmeOrderWarning,
  title: string,
  trackEvents: boolean
) => {
  return (
    <Warning
      warningType={warning.key}
      severity={warning.severity}
      title={title}
      text={warning.text}
      trackEvents={trackEvents}
    />
  )
}

const REVIEW_SIGNATURE = "Review Signature"
const SIGNATURE_NEEDED = "Signature Needed"

const SignatureWarning = ({ warning, trackEvents }: Props) => {
  const {
    doctor,
    signatureRequests,
    type,
    nextExpectedDate,
  } = warning.metadata as SignatureWarningMetadata
  switch (type) {
    case "pending":
      return (
        <SignaturePendingWarning
          doctor={doctor}
          signatureRequests={signatureRequests}
          warningType={warning.key}
          trackEvents={trackEvents}
          nextExpectedDate={nextExpectedDate}
        />
      )
    case "manual":
      return render(warning, REVIEW_SIGNATURE, trackEvents)
    case "needed":
    case "invalid":
      return render(warning, SIGNATURE_NEEDED, trackEvents)
    default:
      reportError(new Error(`Unknown supplier signature type: ${type}`))
      return render(warning, REVIEW_SIGNATURE, trackEvents)
  }
}

export default SignatureWarning
