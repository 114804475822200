import React from "react"

type Props = {
  sortingBy?: string
  results?: string
}

function TableOverviewPresentation({ sortingBy, results }: Props) {
  return (
    <div className="pl-3 my-2 h-50px bt bc-g">
      {results && (
        <span className="font-subparagraph">
          <span className="color-dark-gray">Results:</span> {results}
        </span>
      )}
      {sortingBy && (
        <span className="font-subparagraph ml-4">
          <span className="color-dark-gray">Sorting by:</span> {sortingBy}{" "}
        </span>
      )}
    </div>
  )
}

export default TableOverviewPresentation
