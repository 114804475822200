import React from "react"
import InputText from "./InputText"

type Props = {
  content: string | React.ReactElement
}

const SubText = ({ content }: Props) => {
  return <InputText className="mt-2" content={content} />
}

export default SubText
