import React, { useState } from "react"
import Button from "components/form/Button"
import RequestSupplierModal from "applications/ManageSuppliers/components/RequestSupplierModal"
import { useToaster } from "components/Toaster"
import classNames from "classnames"

interface Props {
  clearFilters: () => void
  filtersApplied: boolean
}

const NoResults: React.FC<Props> = ({ clearFilters, filtersApplied }) => {
  const { persistentAlert } = useToaster()
  const [showModal, toggleModal] = useState<boolean>(false)
  const openModal = () => toggleModal(true)
  const closeModal = () => toggleModal(false)
  const afterSubmit = (message) => {
    closeModal()
    persistentAlert({
      message,
      severity: "info",
      position: { vertical: "top", horizontal: "right" },
    })
    return Promise.resolve()
  }

  return (
    <div>
      <div className={classNames("text-center py-5 w-100-p")}>
        <h2 className="mb-2">No results</h2>
        <p className="color-dark-gray mb-0">
          Try adjusting your search, or clearing any filters.
        </p>
        {filtersApplied && (
          <Button
            className="btn-brand mt-4"
            onClick={() => {
              clearFilters()
            }}
          >
            Clear active filters
          </Button>
        )}
      </div>
      <div className={classNames("text-center py-5 w-100-p bg-white")}>
        <h2 className="mb-2">Can't find the supplier you're looking for?</h2>
        <p className="mb-0">
          <Button className="btn-secondary-o mr-2" onClick={openModal}>
            Request an outside supplier
          </Button>
        </p>

        <RequestSupplierModal
          showModal={showModal}
          onCancel={closeModal}
          afterSubmit={afterSubmit}
        />
      </div>
    </div>
  )
}

export default NoResults
