import React from "react"
import { Notice as NoticeType } from "sharedTypes"

type Props = {
  notice: NoticeType
}

const Notice = ({ notice }: Props) => {
  return (
    <>
      <div className="message-notice clearfix d-none d-xl-block">
        <div className="col-xl-9 px-0 float-left color-dark-gray font-notice">
          {notice.content}
        </div>
        <div className="col-xl-2 px-0 float-right pr-5 bold color-dark-gray font-notice caps">
          {notice.type}
        </div>
      </div>
      <div className="message-notice clearfix d-block d-xl-none">
        <div className="col-xl px-0 bold color-dark-gray font-notice caps">
          {notice.type}
        </div>
      </div>
      <div className="col-12 d-block d-xl-none px-0 mt-2 mb-4 color-dark-gray font-notice">
        {notice.content}
      </div>
    </>
  )
}

export default Notice
