import React from "react"
import { LIST_SUPPLIERS } from "../../../Product"
import { Link } from "react-router-dom"
import * as routes from "applications/Workflow/routes"
import { Supplier } from "sharedTypes"
import * as styles from "./index.module.scss"
import classNames from "classnames"
import BackArrowIcon from "../../../../../../components/BackArrowIcon"

interface Props {
  dmeOrder: {
    supplier?: Supplier
  }
}

const SelectSupplierLink: React.FC<Props> = ({ dmeOrder }) => {
  const supplierNotChangeableOnOrder = dmeOrder.supplier
  if (supplierNotChangeableOnOrder) {
    return null
  }

  return (
    <Link
      className={classNames("mb-1 link", styles.backLink)}
      to={routes.productsPath(LIST_SUPPLIERS)}
    >
      <BackArrowIcon />
      Select Supplier
    </Link>
  )
}

export default SelectSupplierLink
