import React from "react"
import Logo from "components/Logo"

type Props = {
  supplierName: string
  supplierLogoUrl?: string
}

const Cobranding = ({ supplierName, supplierLogoUrl }: Props) => {
  if (supplierLogoUrl) {
    return (
      <div className="cobranding">
        <img src={supplierLogoUrl} alt={supplierName} />
        <span className="cobranding-plus">+</span>
        <Logo />
      </div>
    )
  } else {
    return (
      <div className="notice">
        <p className="color-dark-gray caps font-notice mb-1">{supplierName}</p>
        <p className="color-mid-gray font-notice">
          Powered by Parachute Health
        </p>
      </div>
    )
  }
}

export default Cobranding
