import React, { useEffect, useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import * as styles from "./index.module.scss"
import { Supplier } from "../../../../../../sharedTypes"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { Form, RadioButtons } from "../../../../../../components/form"
import { EventCategory, trackEvent } from "../../../../../../utilities/tracking"

interface Props {
  isOpen: boolean
  onClose(): void
  onSubmit(supplierId: string): void
  currentSupplier: Supplier | undefined
  preferredSuppliers: Supplier[]
}

const PreferredSupplierAlertModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  currentSupplier,
  preferredSuppliers,
}) => {
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [error, setError] = useState<string>()
  const hasOnePreferredSupplier = preferredSuppliers.length === 1
  const prefSupplierOptions = preferredSuppliers
    .map((p) => ({
      label: p.name,
      value: p.externalId,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1))
  const headerTitle = `${
    currentSupplier ? currentSupplier.name : "The selected supplier"
  } may be unable to fulfill your
            order for this product.`
  const learnMoreLink = (
    <a
      href="/Selecting Health Plan Preferred Supplier 4 2025.pdf"
      target="_blank"
      aria-label="Learn more about preferred suppliers"
    >
      Learn more
    </a>
  )

  const modalBody = () => {
    return hasOnePreferredSupplier ? (
      <div>
        <p>
          <strong>{selectedSupplier?.name}</strong> is the{" "}
          <strong>preferred supplier</strong> of your patient&apos;s health
          plan.
        </p>
        <p>
          Preferred suppliers are likely to accept and fulfill the order faster.{" "}
          {learnMoreLink}
        </p>
      </div>
    ) : (
      <div>
        <p>
          Your patient&apos;s health plan has{" "}
          <strong>preferred suppliers</strong>, who are likely to accept and
          fulfill the order faster. {learnMoreLink}
        </p>
        <div>
          <p className="canopy-typography-font-weight-bold">
            Select a preferred supplier to redirect your order:
          </p>
          <Form initialValues={{}}>
            <RadioButtons
              name="supplierId"
              options={prefSupplierOptions}
              unwrapped={true}
              onChange={(e) => {
                const supplier = preferredSuppliers.find(
                  (p) => p.externalId === e.target.value
                )
                setSelectedSupplier(supplier)
              }}
            />
          </Form>
          {error && !selectedSupplier && (
            <span className="color-danger canopy-typography-body-small">
              {error}
            </span>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (hasOnePreferredSupplier) {
      setSelectedSupplier(preferredSuppliers[0])
    }
  }, [preferredSuppliers, setSelectedSupplier, hasOnePreferredSupplier])

  return (
    <CanopyDialogModal
      open={isOpen}
      unclosable
      size="small"
      className={styles.alertModal}
      headerOverride={
        <div className="d-flex flex-column justify-content-center align-content-center text-center canopy-ml-20x">
          <p>
            <CanopyIcon
              name="shield-slash"
              size="large"
              fill="canopyColorPrimitivesYellow62"
            />
          </p>
          <h3>
            <strong>{headerTitle}</strong>
          </h3>
        </div>
      }
      footerOverride={
        <>
          <CanopyButton
            fullWidth
            variant="warning"
            onClick={() => {
              if (!selectedSupplier) {
                setError("Please select a supplier")
                return
              }
              void trackEvent(
                EventCategory.SupplierSelection,
                "modal-redirect-to-preferred-supplier-clicked"
              )
              setIsRedirecting(true)
              onSubmit(selectedSupplier?.externalId || "")
            }}
            loading={isRedirecting}
            loadingText="Redirecting..."
          >
            Redirect to Preferred Supplier
          </CanopyButton>

          <CanopyButton
            fullWidth
            variant="tertiary"
            className={styles.tertiaryButton}
            onClick={() => {
              void trackEvent(
                EventCategory.SupplierSelection,
                "continue-with-supplier-clicked"
              )
              onClose()
            }}
          >
            {`Continue with ${currentSupplier?.name || "Current Supplier"}`}
          </CanopyButton>
        </>
      }
    >
      {modalBody()}
    </CanopyDialogModal>
  )
}

export default PreferredSupplierAlertModal
