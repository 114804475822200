import { get, post, put } from "services/api"
import {
  clinicalFacilityDashboardResultsUrl,
  createAdditionalContactResponsesUrl,
  searchDoctorsUrl,
  searchFollowersUrl,
  searchSuppliersUrl,
  updateUiPreferencesUrl,
} from "./urls"
import { Doctor, Follower, Supplier } from "sharedTypes"
import { AxiosPromise } from "axios"

export function getClinicalFacilityDashboardResults(values = {}) {
  return get(clinicalFacilityDashboardResultsUrl(), values).then(
    ({ data }) => data
  )
}

export function searchFollowers(term: string): Promise<Follower[]> {
  return get(searchFollowersUrl(), { term }).then(({ data }) => data)
}

export function searchSuppliers(term: string): Promise<Supplier[]> {
  return get(searchSuppliersUrl(), { term }).then(({ data }) => data)
}

export function searchDoctors(term: string): Promise<Doctor[]> {
  return get(searchDoctorsUrl(), { term }).then(({ data }) => data)
}

export function updateSidebarExpandedPreference(
  sidebarExpanded: boolean
): AxiosPromise {
  return put(updateUiPreferencesUrl(), {
    uiPreferences: {
      clinicalFacilityLeftSidebarExpanded: sidebarExpanded,
    },
  })
}

export function createAdditionalContactResponses(values: any): Promise<any> {
  return post(createAdditionalContactResponsesUrl(), values).then(
    ({ data }) => data
  )
}
