import React from "react"

interface Props {
  onClick(): void
}

function ClearAllLink({ onClick }: Props) {
  return (
    <>
      <a
        className="link link-understated link-sm nowrap ml-1 mt-1"
        onClick={onClick}
      >
        <small> Clear All Filters </small>
      </a>
    </>
  )
}

export default ClearAllLink
