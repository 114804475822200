import React from "react"
import { Tabs } from "components/Tabs"
import { SupplierFilter } from "../../sharedTypes"
import HistoryIcon from "@material-ui/icons/History"
import ViewModuleIcon from "@material-ui/icons/ViewModule"
import SupplierTab from "./SupplierTab"
import * as styles from "./index.module.scss"
import classNames from "classnames"

interface Props {
  supplierFilter: SupplierFilter
  setSupplierFilter(supplierFilter: SupplierFilter): void
}

const SuppliersTabs = ({ supplierFilter, setSupplierFilter }: Props) => (
  <Tabs className={classNames("mb-4", styles.tabs)}>
    <SupplierTab
      value={SupplierFilter.Active}
      text="Your Organization's Suppliers"
      Icon={ViewModuleIcon}
      supplierFilter={supplierFilter}
      setSupplierFilter={setSupplierFilter}
    />
    <SupplierTab
      value={SupplierFilter.History}
      text="Supplier History"
      Icon={HistoryIcon}
      supplierFilter={supplierFilter}
      setSupplierFilter={setSupplierFilter}
    />
  </Tabs>
)

export default SuppliersTabs
