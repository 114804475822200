import React from "react"
import classNames from "classnames"
import { ChartNoteQuestion } from "sharedTypes"

type Props = {
  questions: ChartNoteQuestion[]
}

const ReadOnlyQuestionList = ({ questions }: Props) => {
  return (
    <ul className="list-unstyled">
      {questions.map((question) => (
        <div key={question.text || question.title} className="well mb-3">
          <li>
            {question.title && <h3>{question.title}</h3>}
            {question.questionId && <h3>{question.questionId}</h3>}
            {question.text && <h3 className="h4">{question.text}</h3>}
          </li>
          <hr />
          <li>
            {question.answers.length ? (
              question.answers.map((answer) => (
                <ul key={answer.value} className="list-check">
                  <li
                    className={classNames("bold", { checked: answer.selected })}
                  >
                    {answer.value}
                  </li>
                </ul>
              ))
            ) : (
              <span className="ml-2 bold">{question.answer.answerValue}</span>
            )}
          </li>
        </div>
      ))}
    </ul>
  )
}

export default ReadOnlyQuestionList
