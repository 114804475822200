import React from "react"
import { EmergencyContact } from "sharedTypes"
import { formatPhone } from "utilities/phone"
import { capitalize } from "utilities/string"
import Icon from "components/Icon"
import cx from "classnames"

type Props = {
  otherContact: EmergencyContact
  editOtherContact(otherContact: EmergencyContact): void
}

export default ({ otherContact, editOtherContact }: Props) => {
  const name = [otherContact.firstName, otherContact.lastName]
    .filter(Boolean)
    .join(" ")
  const title = [
    name,
    otherContact.relationship && `(${capitalize(otherContact.relationship)})`,
  ]
    .filter(Boolean)
    .join(" ")

  return (
    <div className={cx("wide-fixed-row", "well")}>
      <h4>
        {title}
        <a
          className="link link-primary float-right"
          onClick={() => editOtherContact(otherContact)}
        >
          Edit
          <Icon className="ml-2" type="pencil-alt" />
        </a>
      </h4>
      <p>
        {formatPhone(otherContact.phoneNumber)}
        {otherContact.preferredLanguage && (
          <>
            <br />
            {`${otherContact.preferredLanguage} (Preferred Language)`}
          </>
        )}
        {otherContact.email && (
          <>
            <br />
            {otherContact.email}
          </>
        )}
      </p>
    </div>
  )
}
