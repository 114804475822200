import React, { useState, useContext } from "react"
import { Employer, EmployerType } from "sharedTypes"
import withInitialData from "components/withInitialData"
import ClinicalFacilityPatientPortal from "./ClinicalFacilityPatientPortal"
import SupplierPatientPortal from "./SupplierPatientPortal"
import { currentOrderId } from "applications/Workflow/urls"
import PatientPortalContext from "context/PatientPortal"
import { PatientPortalData } from "services/patientPortal"

type Props = {
  employer: Employer
  globalFacilityEnabled: boolean
  globalSupplierEnabled: boolean
  orderStatus: string
  initialData: PatientPortalData
  latestPatientPortalEventTimestamp: string
}

const DataLoadedPatientPortal: React.FC<Props> = (props) => {
  const {
    initialData,
    globalSupplierEnabled,
    globalFacilityEnabled,
    employer,
    orderStatus,
  } = props

  const [portalData, updatePortalData] = useState(initialData)
  const { endpoint } = useContext(PatientPortalContext)

  if (!portalData || !portalData.phoneNumber) {
    return null
  }

  const latestMessageScheduledAt = !!portalData.latestMessageScheduledAt
    ? new Date(portalData.latestMessageScheduledAt)
    : null

  const lastPatientLoginAt = !!portalData.lastPatientLoginAt
    ? new Date(portalData.lastPatientLoginAt)
    : null

  const lastFailedLoginAt = !!portalData.lastFailedLoginAt
    ? new Date(portalData.lastFailedLoginAt)
    : null

  const updateFacilityEnabled = (facilityEnabled: boolean) => {
    endpoint.updateOrder(currentOrderId(), facilityEnabled).then(
      (res) => {
        res && updatePortalData(res.data)
      },
      (_) => {}
    )
  }

  const resendFacilityInvite = () => {
    endpoint.resendFacilityInvite(currentOrderId()).then(
      (res) => {
        res && updatePortalData(res.data)
      },
      (_) => {}
    )
  }

  if (
    employer.employerType === EmployerType.ClinicalFacility &&
    (latestMessageScheduledAt || globalFacilityEnabled)
  ) {
    return (
      <ClinicalFacilityPatientPortal
        facilityEnabled={portalData.facilityEnabled}
        phoneNumber={portalData.phoneNumber}
        phoneCanReceiveSms={portalData.phoneCanReceiveSms}
        latestMessageScheduledAt={latestMessageScheduledAt}
        globalFacilityEnabled={globalFacilityEnabled}
        lastAccessEventType={portalData.lastAccessEventType}
        orderStatus={orderStatus}
        updateFacilityEnabled={updateFacilityEnabled}
        resendFacilityInvite={resendFacilityInvite}
        confirmViewImgUrl={endpoint.confirmFacilityViewImgUrl(currentOrderId())}
        inviteSmsCopy={portalData.inviteSmsCopy}
        lastPatientLoginAt={lastPatientLoginAt}
        lastFailedLoginAt={lastFailedLoginAt}
        phoneNumberRejectedAt={portalData.phoneNumberRejectedAt}
        optedOutAt={portalData.optedOutAt}
        canForceInviteResend={portalData.canForceInviteResend}
        facilityInviteResentAt={portalData.facilityInviteResentAt}
      />
    )
  } else if (employer.employerType === EmployerType.Supplier) {
    return (
      <SupplierPatientPortal
        phoneNumber={portalData.phoneNumber}
        phoneCanReceiveSms={portalData.phoneCanReceiveSms}
        latestMessageScheduledAt={latestMessageScheduledAt}
        globalSupplierEnabled={globalSupplierEnabled}
        globalFacilityEnabled={globalFacilityEnabled}
        facilityEnabled={portalData.facilityEnabled}
        lastAccessEventType={portalData.lastAccessEventType}
        orderStatus={orderStatus}
        supplierControlPanelUrl={`${portalData.supplierControlPanelUrl}`}
        lastPatientLoginAt={lastPatientLoginAt}
        lastFailedLoginAt={lastFailedLoginAt}
        phoneNumberRejectedAt={portalData.phoneNumberRejectedAt}
        optedOutAt={portalData.optedOutAt}
      />
    )
  } else {
    return null
  }
}

const PatientPortal: React.FC<Omit<Props, "initialData">> = (props) => {
  const { endpoint } = useContext(PatientPortalContext)
  const fetchInitialData = (args: Omit<Props, "initialData">) => {
    const orderId = currentOrderId()
    return endpoint.fetchOrder({
      orderId: orderId,
      globalFacilityEnabled: args.globalFacilityEnabled,
      globalSupplierEnabled: args.globalSupplierEnabled,
      latestPatientPortalEventTimestamp: args.latestPatientPortalEventTimestamp,
    })
  }

  const DataLoaded = withInitialData(fetchInitialData)(DataLoadedPatientPortal)

  return <DataLoaded {...props} />
}

export default PatientPortal
