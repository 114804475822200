import { clinicalPath } from "../../routes"
import { RouteComponentProps } from "react-router"

export const LEGAL_WARNING =
  "You are responsible for ensuring that any patient information submitted by you is accurate and correct. Parachute does not provide recommendations related to the appropriateness of any order or plan of care."

export type FillablePdfPathRouteProps = RouteComponentProps<{
  documentationRequirementExternalId
}>
export const fillablePdfPathMatcher = (): string =>
  `${clinicalPath()}/pdfs/:documentationRequirementExternalId?`
export const fillablePdfPath = (
  documentationRequirementExternalId?: string
): string => `${clinicalPath()}/pdfs/${documentationRequirementExternalId}`
