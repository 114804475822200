import React from "react"
import { LineItemGroup, RxDetailsOutput } from "sharedTypes"
import LineItemDescription from "components/LineItemDescription"
import PrescriptionDetails from "./PrescriptionDetails"

type Props = {
  lineItemGroup: LineItemGroup
  rxDetailsOutputs: RxDetailsOutput[]
}

const DocumentLineItemGroup = ({ lineItemGroup, rxDetailsOutputs }: Props) => {
  return (
    <div key={lineItemGroup.id} className="well well-expand">
      <div className="content">
        <p className="bold font-xs">{lineItemGroup.packageName}</p>
        <ul className="pl-3 font-xs">
          {lineItemGroup.lineItems.map((lineItem) => (
            <li key={lineItem.id} className="mb-1">
              <LineItemDescription lineItem={lineItem} />
            </li>
          ))}
          <hr className="mt-3 mb-0" />
        </ul>
        {!!rxDetailsOutputs.length && (
          <PrescriptionDetails rxDetailsOutputs={rxDetailsOutputs} />
        )}
      </div>
    </div>
  )
}

export default DocumentLineItemGroup
