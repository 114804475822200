import React from "react"
import Icon from "components/Icon"
import * as style from "./index.module.scss"

export default function FacilityDetails({ facility }) {
  const { npi, supplierSystemId, facilityId } = facility

  const hasDetails = !!npi || !!supplierSystemId || !!facilityId

  if (!hasDetails) {
    return null
  }

  return (
    <div>
      {npi && <span className={style.detail}>NPI {npi}</span>}
      {(supplierSystemId || facilityId) && (
        <span className={style.detail}>
          {!!facilityId ? (
            <div className="logo logo-sm v-align-text-bottom">
              <div className="image"></div>
            </div>
          ) : (
            <Icon className={style.icon} type="clinic-medical" />
          )}
          {supplierSystemId && (
            <span className="ml-1">External ID: {supplierSystemId}</span>
          )}
        </span>
      )}
    </div>
  )
}
