import React from "react"
import Icon from "components/Icon"

type Props = {
  loading?: boolean
  onChange(value: string): void
  value: string
  placeholder: string
}

function SearchFilter({ loading, onChange, value, placeholder }: Props) {
  return (
    <span className="has-feedback d-inline-block d-xs-block d-sm-block w-lg-250 w-md-250 v-align-top mr-2 mb-3">
      <input
        onChange={(event) => onChange(event.target.value)}
        value={value}
        className="form-control input-sm input-rounded d-inline-block mb-0"
        placeholder={placeholder}
      />
      <Icon
        className="form-control-feedback"
        spin={loading}
        type={loading ? "spinner" : "search"}
      />
    </span>
  )
}

SearchFilter.defaultProps = {
  value: "",
}

export default SearchFilter
