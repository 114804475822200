import React, { useEffect } from "react"
import ErrorMessage from "../ErrorMessage"
import { DmeOrderInvitation } from "../../sharedTypes"
import { Field } from "formik"
import { Form } from "components/form"
import BigInput from "components/BigInput"
import Icon from "components/Icon"
import { toSentence } from "utilities/array"
import { trackInvitationEvent } from "../../utilities"
import Cobranding from "components/Cobranding"
import Legalese from "../Legalese"

type Props = {
  invitation: DmeOrderInvitation
  onSubmit({
    firstName,
    lastName,
  }: {
    firstName: string
    lastName: string
  }): Promise<void>
}

const InvitationIdentificationForm = ({ invitation, onSubmit }: Props) => {
  useEffect(() => {
    trackInvitationEvent("onSubmit-form-viewed")
  }, [])
  const { patientName, packageNames } = invitation

  const initialValues = {
    firstName: invitation.firstName || "",
    lastName: invitation.lastName || "",
  }
  const validate = ({ firstName, lastName }) => {
    const errors: {
      firstName?: string
      lastName?: string
    } = {}

    if (!firstName) {
      errors.firstName = "is required"
    }

    if (!lastName) {
      errors.lastName = "is required"
    }

    return errors
  }

  const getErrorText = (errors) => {
    if (errors.firstName && errors.lastName) {
      return "Please enter both your first and last name"
    } else if (errors.firstName) {
      return "Please enter your first name"
    } else if (errors.lastName) {
      return "Please enter your last name"
    }
    return null
  }

  return (
    <>
      <Cobranding
        supplierName={invitation.supplierName}
        supplierLogoUrl={invitation.supplierLogoUrl}
      />
      <div className="onboarding-intro">
        <Icon type="comment-dots" />
        <p className="px-3">
          <strong className="mb-2 d-block">
            Your order needs some additional information
          </strong>
          {toSentence(packageNames)} for {patientName}
        </p>
        <h1 className="my-5">First, what is your name?</h1>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
        >
          {({ errors, isSubmitting }) => (
            <>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Field name="firstName">
                    {({ field }) => (
                      <BigInput
                        id={`${invitation.dmeOrderId}-first-name`}
                        label="First Name"
                        placeholder="First Name"
                        autoFocus
                        {...field}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-12 col-lg-6">
                  <Field name="lastName">
                    {({ field }) => (
                      <BigInput
                        id={`${invitation.dmeOrderId}-last-name`}
                        label="Last Name"
                        placeholder="Last Name"
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
              {getErrorText(errors) && (
                <ErrorMessage>{getErrorText(errors)}</ErrorMessage>
              )}
              <div className="text-center pt-5 pb-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-brand px-4"
                >
                  Continue
                </button>
              </div>
            </>
          )}
        </Form>
        <Legalese entityName={invitation.clinicalFacilityName} />
      </div>
    </>
  )
}
export default InvitationIdentificationForm
