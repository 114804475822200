import React from "react"
import OrderCard from "./OrderCard"
import { ClinicalFacility } from "sharedTypes"
import { DashboardDmeOrder } from "../../sharedTypes"

type Props = {
  dmeOrders: DashboardDmeOrder[]
  limit: number
  clinicalFacility: ClinicalFacility
  showProgress?: boolean
  showSwatch?: boolean
  showMissingDocument?: boolean
  unreadEventCounts: object
}

function DashboardWarning({ count, limit }) {
  if (count <= limit) {
    return null
  }
  return (
    <div className="alert alert-warning font-xs p-3 mb-3">
      Some orders hidden, use filters
    </div>
  )
}
function DashboardColumn({
  dmeOrders,
  limit,
  clinicalFacility,
  unreadEventCounts,
  showProgress,
  showSwatch,
  showMissingDocument,
}: Props) {
  const count = dmeOrders.length
  return (
    <>
      <DashboardWarning count={count} limit={limit} />
      {dmeOrders.length > 0 && (
        <>
          {dmeOrders.map((dmeOrder) => {
            return (
              <OrderCard
                key={dmeOrder.id}
                clinicalFacility={clinicalFacility}
                dmeOrder={dmeOrder}
                unreadEventCount={unreadEventCounts[dmeOrder.id]}
                showProgress={showProgress}
                showSwatch={showSwatch}
                showMissingDocument={showMissingDocument}
              />
            )
          })}
        </>
      )}
      <DashboardWarning count={count} limit={limit} />
    </>
  )
}

export default DashboardColumn
