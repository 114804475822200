import React, { useContext, useCallback } from "react"
import { instrumentFeatureFlagEvaluation } from "services/clientsideInstrumentation"

import { FeatureFlagName, FeatureFlagInput } from "sharedTypes"

const FeatureFlagContext = React.createContext<FeatureFlagInput>({})

export const FeatureFlagProvider = (props: {
  flags?: FeatureFlagInput
  children: any
}) => {
  return (
    <FeatureFlagContext.Provider value={props.flags || {}}>
      {props.children}
    </FeatureFlagContext.Provider>
  )
}

const featureFlagEvaluationFunction = (
  flagName: FeatureFlagName,
  flagSettings: FeatureFlagInput
): boolean => {
  const evaluatedValue = flagSettings[flagName] || false

  instrumentFeatureFlagEvaluation(flagName, evaluatedValue)

  return evaluatedValue
}

export const useFeatureFlags = (): {
  isFeatureEnabled: (flagName: FeatureFlagName) => boolean
} => {
  const flagSettings = useContext(FeatureFlagContext)

  const isFeatureEnabled = useCallback(
    (flagName: FeatureFlagName) =>
      featureFlagEvaluationFunction(flagName, flagSettings),
    [flagSettings]
  )

  return { isFeatureEnabled }
}
