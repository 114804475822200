import React from "react"
import {
  FailureReason,
  UploadResult,
} from "applications/DocumentationOrders/api"
import * as styles from "applications/Uploads/UploadsTable/UploadsSummaryModal/upload-errors.module.scss"
import Icon from "components/Icon"
import pluralize from "pluralize"

interface Props {
  numberOfRowsWithErrors: number
  failedUploadResults: UploadResult[]
}

function UploadErrors({
  numberOfRowsWithErrors,
  failedUploadResults,
}: Props): React.ReactElement {
  const duplicateCount = failedUploadResults
    ? failedUploadResults.filter(
        (result) => result.failureReason === FailureReason.DUPLICATE
      ).length
    : 0
  const otherErrorCount = failedUploadResults
    ? failedUploadResults.filter(
        (result) => result.failureReason === FailureReason.OTHER
      ).length
    : 0

  return (
    <>
      <div>
        <div>
          <Icon type="exclamation-triangle" className="color-warning p-2" />
          <p className="d-inline-block mb-0">
            {numberOfRowsWithErrors} Requests Not Created
          </p>
        </div>
      </div>
      <div className={styles.errorList}>
        <ul>
          {otherErrorCount > 0 && (
            <li>{`${otherErrorCount} ${pluralize(
              "Row",
              otherErrorCount
            )} Failed to Upload`}</li>
          )}
          {duplicateCount > 0 && (
            <li>{`${duplicateCount} ${pluralize(
              "Duplicate",
              duplicateCount
            )}`}</li>
          )}
        </ul>
      </div>
    </>
  )
}

export default UploadErrors
