import React, { useState } from "react"
import classNames from "classnames"
import Icon from "components/Icon"
import Overlay from "components/Overlay"
import Modal from "components/Modal"
import { DocumentationRequirement } from "sharedTypes"
import {
  SendBackRecipient,
  SendBackFormValues,
  RejectFormValues,
  SelectedRecipient,
  SupplierFaxBackDocument,
} from "./sharedTypes"
import SupplierSendBackFormSection from "./SupplierSendBackFormSection"
import SupplierRejectFormSection from "./SupplierRejectFormSection"
import { DmeOrderCreatedVia } from "./index"
import { FacilitySelectProps } from "../../../../../FacilitySelectForm/components/FacilitySelect"

type Props = {
  allowReject: boolean
  cancel(): void
  documentationRequirements: DocumentationRequirement[]
  facilityEmail: string
  searchFacilityRecipients(
    term: string
  ): Promise<SendBackRecipient[] | GroupedSendBackRecipients[]>
  searchSupplierFollowables(term: string): Promise<SendBackRecipient[]>
  supplierFaxBackDocuments: SupplierFaxBackDocument[]
  supplierSendBack(
    values: SendBackFormValues,
    setErrors: (errors: string) => void,
    setShowOverlay: (show: boolean) => void
  ): void
  supplierReject(
    values: RejectFormValues,
    setErrors: (errors: string) => void,
    setShowOverlay: (show: boolean) => void
  ): void
  faxNumbers: string[]
  defaultFacilityRecipient: SendBackRecipient
  defaultSupplierRecipient: SendBackRecipient
  defaultSupplierTeamRecipients: SendBackRecipient[]
  hasFacilityRecipients: boolean
  isNonParachuteFacility: boolean
  selectedFacilityName: string
  setSearchRecipientRecommendations: (recommendationIds: string[]) => void
  selectedRecipients: SelectedRecipient[]
  createdVia: DmeOrderCreatedVia
  displayClinicalFacilitySearch: boolean
  facilitySelectProps: FacilitySelectProps
}

export enum SendBackAction {
  PushBack = "pushback",
  Reject = "reject",
}

export type GroupedSendBackRecipients = {
  label: string | React.ReactNode
  options: SendBackRecipient[]
}

const ACTION_OPTIONS = [
  {
    title: "Request Revisions",
    icon: "pencil-alt",
    value: SendBackAction.PushBack,
  },
  {
    title: "Reject Order",
    icon: "trash",
    value: SendBackAction.Reject,
  },
]

function SupplierSendBackForm(props: Props) {
  const {
    allowReject,
    cancel,
    documentationRequirements,
    facilityEmail,
    searchFacilityRecipients,
    searchSupplierFollowables,
    faxNumbers,
    defaultFacilityRecipient,
    defaultSupplierRecipient,
    defaultSupplierTeamRecipients,
    hasFacilityRecipients,
    isNonParachuteFacility,
    selectedFacilityName,
    setSearchRecipientRecommendations,
    selectedRecipients,
    supplierFaxBackDocuments,
    createdVia,
    facilitySelectProps,
    displayClinicalFacilitySearch,
  } = props
  const [action, setAction] = useState(SendBackAction.PushBack)
  const [showOverlay, setShowOverlay] = useState(false)

  const supplierSendBack = (values: SendBackFormValues, setErrors) => {
    setShowOverlay(true)
    return props.supplierSendBack(values, setErrors, setShowOverlay)
  }

  const supplierReject = (values: RejectFormValues, setErrors) => {
    setShowOverlay(true)
    return props.supplierReject(values, setErrors, setShowOverlay)
  }

  const renderTab = ({ title, icon, value }) => {
    return (
      <div
        key={value}
        className={classNames("tab", { selected: value === action })}
        onClick={() => setAction(value)}
      >
        <Icon type={icon} className="mr-2" />
        {title}
      </div>
    )
  }

  function getClassName(): string | undefined {
    if (allowReject) {
      return "has-tabs"
    }
  }

  return (
    <Overlay active={showOverlay} showSpinner>
      <Modal.Header className={getClassName()}>
        <button className="close" type="button" onClick={cancel}>
          ×
        </button>
        <h2 className="modal-title">Push to Facility</h2>
        {allowReject && (
          <div className="tabs">
            {ACTION_OPTIONS.map((option) => renderTab(option))}
          </div>
        )}
      </Modal.Header>
      <Modal.Body>
        {action === SendBackAction.PushBack && (
          <SupplierSendBackFormSection
            cancel={cancel}
            documentationRequirements={documentationRequirements}
            facilityEmail={facilityEmail}
            supplierSendBack={supplierSendBack}
            faxNumbers={faxNumbers}
            defaultFacilityRecipient={defaultFacilityRecipient}
            defaultSupplierRecipient={defaultSupplierRecipient}
            defaultSupplierTeamRecipients={defaultSupplierTeamRecipients}
            hasFacilityRecipients={hasFacilityRecipients}
            isNonParachuteFacility={isNonParachuteFacility}
            selectedFacilityName={selectedFacilityName}
            searchFacilityRecipients={searchFacilityRecipients}
            searchSupplierFollowables={searchSupplierFollowables}
            setSearchRecipientRecommendations={
              setSearchRecipientRecommendations
            }
            selectedRecipients={selectedRecipients}
            supplierFaxBackDocuments={supplierFaxBackDocuments}
            createdVia={createdVia}
            facilitySelectProps={facilitySelectProps}
            displayClinicalFacilitySearch={displayClinicalFacilitySearch}
          />
        )}
        {action === SendBackAction.Reject && (
          <SupplierRejectFormSection
            cancel={cancel}
            defaultFacilityRecipient={defaultFacilityRecipient}
            hasFacilityRecipients={hasFacilityRecipients}
            searchFacilityRecipients={searchFacilityRecipients}
            supplierReject={supplierReject}
            setSearchRecipientRecommendations={
              setSearchRecipientRecommendations
            }
            selectedRecipients={selectedRecipients}
          />
        )}
      </Modal.Body>
    </Overlay>
  )
}

export default SupplierSendBackForm
