import React from "react"
import { useDoctorContext } from "../../hooks/useDoctorContext"
import CanopyBackedFeedbackButton from "components/FeedbackModalButton/CanopyBackedFeedbackButton"

enum ReviewerDashboardFeedbackTopics {
  SignatureRequests = "Signature Requests",
  SettingsAndPreferences = "Settings And Preferences",
  InitialOrders = "Initial Orders",
  Other = "Other",
}

const ReviewerFeedbackModalButton = () => {
  const { doctor } = useDoctorContext()
  return (
    <CanopyBackedFeedbackButton
      employerId={doctor.id}
      employerType="Doctor"
      topics={Object.values(ReviewerDashboardFeedbackTopics)}
    />
  )
}

export default ReviewerFeedbackModalButton
