import React, { useState } from "react"
import Popover from "components/Popover"
import Icon from "components/Icon"
import SalesRepForm from "./Form"
import { handleError } from "utilities/error"
import { SalesRep } from "../../sharedTypes"

type Props = {
  salesRep: SalesRep
  setSalesRep({ salesRepId }): Promise<SalesRep>
  getSalesReps(term: string): Promise<SalesRep[]>
}

const SalesRepPopover = ({ salesRep, setSalesRep, getSalesReps }: Props) => {
  const [currentSalesRep, setCurrentSalesRep] = useState(salesRep)
  const [isSubmitting, setSubmitting] = useState(false)
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen((open) => !open)
  const hide = () => setOpen(false)
  const close = () => {
    hide()
    setSubmitting(false)
  }

  const onAssign = ({ salesRepId }) => {
    setSubmitting(true)
    setSalesRep({ salesRepId })
      .then((salesRep) => {
        setCurrentSalesRep(salesRep)
        close()
      })
      .catch((response) => {
        handleError(response)
        close()
      })
  }

  return (
    <Popover
      open={open}
      onHide={hide}
      onClick={toggle}
      label={
        <a title="Show popover" role="button" className="link link-teal">
          <span className="mr-1">{currentSalesRep?.name || "Unassigned"}</span>
          <Icon type="chevron-down" className="position-absolute" />
        </a>
      }
      allowOverflow={true}
    >
      <SalesRepForm
        salesRep={currentSalesRep}
        setSalesRep={onAssign}
        isSubmitting={isSubmitting}
        getSalesReps={getSalesReps}
      />
    </Popover>
  )
}

export default SalesRepPopover
