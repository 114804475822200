import React from "react"
import {
  DmeOrder,
  DmeOrderConnection,
} from "../../../../../../graphql/__generated__/graphql"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { Card, CardContent, Grid } from "@material-ui/core"
import DmeOrderCard from "../DmeOrderCard/DmeOrderCard"
import DmeOrdersTableFooter from "./DmeOrdersTableFooter"
import DmeOrdersTableHeader from "./DmeOrdersTableHeader"

interface DmeOrderTableProps {
  dmeOrdersData: DmeOrderConnection
  loading: boolean
  fetchMore: any
}

const DmeOrdersTable: React.FC<DmeOrderTableProps> = ({
  dmeOrdersData,
  loading,
  fetchMore,
}) => {
  const { nodes: orders, totalCount, pageInfo } = dmeOrdersData || {}
  const patient = orders?.[0]?.patient
  const verifiedMemberId = () => {
    for (const order of orders || []) {
      for (const policy of order?.insurancePolicies || []) {
        if (policy?.rank === "primary" && policy?.verifiedMemberId) {
          return policy?.verifiedMemberId
        }
      }
    }
  }

  const memberId = () => {
    for (const order of orders || []) {
      for (const policy of order?.insurancePolicies || []) {
        if (policy?.rank === "primary" && policy?.memberNumber) {
          return policy?.memberNumber
        }
      }
    }
  }

  return (
    <>
      <hr />
      {loading && (
        <div className="w-100-p text-center">
          <CanopyIcon
            name="spinner"
            className="fa-spin canopy-mt-16x"
            size="medium"
            fill="canopyColorPrimitivesGray60"
            title="Loading DME orders"
          />
        </div>
      )}

      {!loading && totalCount === 0 && (
        <Card>
          <CardContent className="canopy-p-12x text-center">
            <p className="canopy-typography-body-medium">
              No orders found for this member ID. Please check ID number and try
              again.
            </p>
            <p className="canopy-typography-body-medium italic canopy-mb-0">
              Orders placed more than 90 days ago will not appear.
            </p>
          </CardContent>
        </Card>
      )}

      {!loading && totalCount > 0 && patient && (
        <>
          <DmeOrdersTableHeader
            patient={patient}
            memberId={verifiedMemberId() || memberId() || ""}
          />
          <Grid
            container
            spacing={3}
            wrap="nowrap"
            direction="column"
            className="canopy-mb-10x"
          >
            {orders?.map((order) => (
              <DmeOrderCard
                dmeOrder={order as DmeOrder}
                key={(order as DmeOrder).id}
              />
            ))}
          </Grid>
          <DmeOrdersTableFooter
            currentCount={orders?.length || 0}
            totalCount={totalCount}
            pageInfo={pageInfo}
            fetchMore={fetchMore}
          />
        </>
      )}
    </>
  )
}

export default DmeOrdersTable
