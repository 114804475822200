import React from "react"
import letter from "letter-success.png"
import Button from "components/form/Button"

interface Props {
  email: string
  onClick(): void
}

const InvitationSuccess = ({ email, onClick }: Props) => {
  return (
    <div className="text-center">
      <div className="my-5">
        <img src={letter} width="220" />
      </div>
      <div className="text-xs-left mb-5">
        <h1>Sign up invitation sent!</h1>
        <p>
          An email invitation to create a Parachute Health account has been sent
          to {email}.
        </p>
      </div>
      <form>
        <Button className="btn btn-brand btn-block" onClick={onClick}>
          Invite Another Referral
        </Button>
      </form>
    </div>
  )
}
export default InvitationSuccess
