import React, { useState } from "react"
import { DocumentationOrder } from "../fetchOrders"
import Popover from "components/Popover"
import Link from "components/Link"

const PatientActivityPopover: React.FC<{ order: DocumentationOrder }> = ({
  order,
}) => {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen((open) => !open)
  const hide = () => setOpen(false)

  const renderSelectedDoctorInfo = () => {
    if (order.patientSelectedDoctor) {
      return (
        <>
          <div>Patient submitted clinician:</div>
          <div>
            {order.patientSelectedDoctor.firstName}{" "}
            {order.patientSelectedDoctor.lastName}
          </div>
          <div>{order.patientSelectedDoctor.npi}</div>
          <div>
            {order.patientSelectedDoctor.city},{" "}
            {order.patientSelectedDoctor.state}{" "}
            {order.patientSelectedDoctor.zip}
          </div>
        </>
      )
    } else {
      return (
        <p>
          Patient did not submit a new clinician. Reach out to the patient for
          more information.
        </p>
      )
    }
  }

  return (
    <>
      {order.patientActivity === "Wrong Clinician" ? (
        <Popover
          open={open}
          onHide={hide}
          onClick={toggle}
          label={<Link>{order.patientActivity}</Link>}
        >
          {renderSelectedDoctorInfo()}
        </Popover>
      ) : (
        order.patientActivity
      )}
    </>
  )
}

export default PatientActivityPopover
