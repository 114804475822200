import React from "react"
import { LineItem } from "sharedTypes"

type Props = {
  lineItem: LineItem
}

const LineItemDescription = ({ lineItem }: Props) => (
  <p className="font-xs mb-0">
    <strong>{lineItem.skuDescription}</strong>{" "}
    {lineItem.hcpcs && `- ${lineItem.hcpcs}`}
  </p>
)

export default LineItemDescription
