import React from "react"
import { Doctor } from "sharedTypes"
import Modal from "components/Modal"
import ContactInfoForm from "./ContactInfoForm"
import { isEmailValid, EMAIL_VALIDATION_MESSAGE } from "utilities/email"
import { isPhoneValid, PHONE_VALIDATION_MESSAGE } from "utilities/phone"
import { doctorNameWithCredential } from "utilities/doctor"

type Props = {
  doctor: Doctor
  addDoctor(doctor: Doctor, params): Promise<void>
  show: boolean
  close(): void
}

const AddDoctorModal = ({ doctor, addDoctor, show, close }: Props) => {
  if (!doctor) {
    return null
  }

  const create = (values, { setErrors }) => {
    addDoctor(doctor, values)
      .then(close)
      .catch((errors) => setErrors(errors))
  }

  const validate = ({ email, mobileNumber }) => {
    const errors: { email?: string; mobileNumber?: string } = {}
    if (!doctor.maskedEmail && email !== "" && !isEmailValid(email)) {
      errors.email = EMAIL_VALIDATION_MESSAGE
    }
    if (
      !doctor.maskedMobileNumber &&
      mobileNumber !== "" &&
      !isPhoneValid(mobileNumber)
    ) {
      errors.mobileNumber = PHONE_VALIDATION_MESSAGE
    }

    if (!email && !mobileNumber) {
      errors.email = errors.mobileNumber =
        "Must enter at least one contact method"
    }

    return errors
  }

  const anyDigitalMethods = doctor.maskedEmail || doctor.maskedMobileNumber
  const modalTitle = anyDigitalMethods
    ? "Great! This clinician is already registered with us."
    : "Set this clinician up with Parachute!"
  const signedCount = Object.values(doctor.cacheSignaturesCounts).reduce(
    (a, b) => a + b,
    0
  )
  const initialValues = {
    email: doctor.maskedEmail || "",
    mobileNumber: doctor.maskedMobileNumber || "",
  }

  return (
    <Modal show={show} cancel={close} title={modalTitle} size="md">
      <Modal.Body>
        <h4 className="text-center color-dark-gray">
          {doctorNameWithCredential(doctor)} - {doctor.npi}
        </h4>
        <h6 className="text-center color-dark-gray mb-4">{`${signedCount} orders signed`}</h6>
        {!anyDigitalMethods && (
          <p className="text-center m-4">
            Let this clinician know that due to Medicare changes, your supplier
            is moving to a digital system.
          </p>
        )}

        <ContactInfoForm
          initialValues={initialValues}
          emailDisabled={!!doctor.maskedEmail}
          mobileNumberDisabled={!!doctor.maskedMobileNumber}
          onSubmit={create}
          validate={validate}
        />
      </Modal.Body>
    </Modal>
  )
}

export default AddDoctorModal
