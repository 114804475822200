// @ts-strict-ignore
import React from "react"
import ReviewTitle from "./ReviewTitle"
import {
  DmeOrder,
  DmeOrderWarning,
  Employer,
  EmployerType,
  OrderStatus,
} from "sharedTypes"
import { Permissions } from "../sharedTypes"
import FulfillmentEditor from "./FulfillmentEditor"
import { FormData } from "applications/FulfillmentEditor/components/FulfillmentForm"
import FulfillmentStatuses from "./FulfillmentStatuses"

export type Props = {
  dmeOrder: DmeOrder
  warnings: DmeOrderWarning[]
  employer: Employer
  showSubmit: boolean
  canSubmit: boolean
  selfSign: boolean
  updateFulfillment(id: string, values: FormData): Promise<void>
  submissionPending: boolean
  submit(): Promise<void>
  permissions: Permissions
}

const ActionsTitleSection = (props: Props) => {
  const { dmeOrder, warnings, employer, updateFulfillment } = props
  const isFacility = employer.employerType === EmployerType.ClinicalFacility

  const titleRightChildContent = () => {
    if (
      dmeOrder.permissions.updateDeliveryData &&
      dmeOrder.lineItemGroups.length > 0
    ) {
      return (
        <FulfillmentEditor
          dmeOrder={dmeOrder}
          updateFulfillment={updateFulfillment}
          linkClassNames="float-right mt-3"
        />
      )
    }

    if (dmeOrder.orderStatus === OrderStatus.SupplierReview && isFacility) {
      return (
        <div className="text-right">
          <div className="pill pill-success mt-3 text-left">
            <i className="fas fa-check-circle" /> Order Submitted
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <ReviewTitle
        orderStatus={dmeOrder.orderStatus}
        warnings={warnings}
        employer={employer}
        rightChild={titleRightChildContent()}
        supplier={dmeOrder.supplier}
      />
      {dmeOrder.orderStatus === OrderStatus.Accepted && (
        <div className="pl-3">
          <FulfillmentStatuses dmeOrder={dmeOrder} />
        </div>
      )}
    </>
  )
}

export default ActionsTitleSection
