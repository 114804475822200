import React, { useState } from "react"
import { Formik, FormikValues } from "formik"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { getTimeZoneOptions } from "../../../../components/form/TimezoneSelect"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { canopyColorInteractiveLinkDefault } from "@parachutehealth/canopy-tokens-color"
import { titleize } from "../../../../utilities/string"
import { CanopySelectField } from "@parachutehealth/canopy-select-field"

export interface MutationStatus {
  status: "success" | "error"
  message: string
}

export interface UserFormFields {
  firstName: string
  lastName: string
  email: string
  timeZone?: {
    label: string
    value: string
  } | null
  role?: string
}

interface UserFormErrors {
  firstName?: string
  lastName?: string
  email?: string
}

export enum UserFormAction {
  New = "new",
  Edit = "edit",
}

export interface UserFormProps {
  handleFormSubmit(values: FormikValues): void

  loading: boolean
  initialFormValues: UserFormFields
  formAction: UserFormAction

  onCancel?(): void
}

const roleOptions: OptionItem[] = ["view_only", "admin"].map((role) => {
  return {
    label: titleize(role),
    value: role,
  }
})

export const UserForm = ({
  onCancel,
  handleFormSubmit,
  loading,
  initialFormValues,
  formAction,
}: UserFormProps) => {
  const [errors, setErrors] = useState<UserFormErrors>({})

  const onSubmit = (values: FormikValues) => {
    handleFormSubmit(values)
  }

  const onValidate = (values: FormikValues) => {
    setErrors({})
    if (!values.firstName) {
      setErrors((prev) => ({
        ...prev,
        firstName: "Please provide a first name",
      }))
    }
    if (!values.lastName) {
      setErrors((prev) => ({
        ...prev,
        lastName: "Please provide a last name",
      }))
    }

    if (!values.email) {
      setErrors((prev) => ({
        ...prev,
        email: "Please provide an email",
      }))
    }

    return errors
  }

  const primaryButtonText =
    formAction === UserFormAction.New ? "Add user" : "Save"

  return (
    <Formik
      initialValues={initialFormValues}
      validate={onValidate}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        setFieldTouched,
      }) => (
        <CanopyForm onSubmit={handleSubmit}>
          <CanopyTextInputField
            onChange={handleChange}
            label="First name"
            name="firstName"
            required
            value={values.firstName}
            feedbackMessageStatus={errors.firstName ? "error" : undefined}
            feedbackMessage={errors.firstName}
          />
          <CanopyTextInputField
            onChange={handleChange}
            label="Last name"
            name="lastName"
            required
            value={values.lastName}
            feedbackMessageStatus={errors.lastName ? "error" : undefined}
            feedbackMessage={errors.lastName}
          />
          {initialFormValues.timeZone && (
            <CanopyComboboxField
              label="Time zone"
              id="timeZone"
              placeholder="Select a Timezone..."
              value={values.timeZone?.value}
              onBlur={() => {
                void (async () => {
                  await setFieldTouched("timeZone")
                })()
              }}
              onChange={(newValue) => {
                void (async (newValue) => {
                  await setFieldValue("timeZone", newValue)
                })(newValue)
              }}
              options={getTimeZoneOptions()}
            />
          )}
          <CanopyTextInputField
            onChange={handleChange}
            label="Email"
            name="email"
            required
            value={values.email}
            feedbackMessageStatus={errors.email ? "error" : undefined}
            feedbackMessage={errors.email}
          />
          {initialFormValues.role && (
            <CanopySelectField
              label="Role"
              required
              id="role"
              options={roleOptions}
              value={values.role}
              onChange={handleChange}
            />
          )}
          <div className="text-left canopy-mx-3x canopy-my-12x">
            <CanopyButton
              id="user-form-submit-btn"
              type="submit"
              className="mr-2"
              loading={loading}
            >
              {primaryButtonText}
            </CanopyButton>
            {onCancel && (
              <CanopyButton
                variant="tertiary"
                onClick={() => onCancel()}
                style={{ color: canopyColorInteractiveLinkDefault }}
              >
                Cancel
              </CanopyButton>
            )}
          </div>
        </CanopyForm>
      )}
    </Formik>
  )
}
