import {
  ConsignmentCloset,
  LineItemGroup,
  OfferingType,
  PaymentMethod,
  RxDetailsOutput,
  Supplier,
  SurveyQuestion,
} from "sharedTypes"

export enum PackageConfigurationStep {
  RequirementsStep = "requirements",
  ConfigurationStep = "configuration",
  RxDetailsStep = "rx_details",
  DetailsStep = "details",
  SummaryStep = "summary",
}

export type CustomAttributeWithOptions = {
  id: number
  name: string
  options: {
    id: number
    description: string
  }[]
  attribute_auto_selection_enabled: boolean
}

export type ProductWithCustomAttributes = {
  id: number
  name: string
  customAttributes: CustomAttributeWithOptions[]
}

type PackageProduct = {
  productId: string
  productName: string
}

export enum DeliveryMethod {
  Delivery = "delivery",
  ConsignmentCloset = "consignment_closet",
}

export type Preferences = {
  useFeetForHeight: boolean
}

export type ProductCustomAttributeOption = {
  catalogProductId: number
  catalogCustomAttributeId: number
  catalogCustomAttributeOptionId: number
  dmeOrderPackageConfigurationId: string
}

export type PackageConfiguration = {
  id: string
  configured: boolean
  fulfillable: boolean
  heightInches: number
  lengthOfNeedMonths: number
  numberOfWounds: number
  packageId: number
  packageName: string
  paymentMethod: PaymentMethod
  rxDetails: RxDetails
  supportedPaymentMethods: PaymentMethod[]
  quantity: number
  requiresHeight: boolean
  requiresWeight: boolean
  requiresWoundCount: boolean
  requiresLengthOfNeed: boolean
  simplePackage: boolean
  accessoryRequirementsSatisfied: boolean
  weightLbs: number
  warnings: string[]
  catalogCustomAttributeOptionIds: number[]
  productCustomAttributeOptions: ProductCustomAttributeOption[]
  selectedOptionalProductIds: string[]
  offeringType: OfferingType
  supplier: Supplier
  dmeOrderLineItemGroup: LineItemGroup
  availableSuppliers: Supplier[]
  requiredProductCustomAttributes:
    | CustomAttributeWithOptions[]
    | ProductWithCustomAttributes[]
  optionalProductCustomAttributes:
    | CustomAttributeWithOptions[]
    | ProductWithCustomAttributes[]
  optionalProducts: PackageProduct[]
  deliveryMethod: DeliveryMethod
  availableDeliveryMethods: DeliveryMethod[]
  consignmentCloset: ConsignmentCloset
  packageRelationshipsEnabled?: boolean
  searchWorkflow?: string
}

export type RxDetails = {
  questions: SurveyQuestion[]
  isComplete: boolean
  isDisqualified: boolean
  hasQuestions: boolean
  disqualifiedMessage: string
  outputs: RxDetailsOutput[]
}
