// @ts-strict-ignore
import React from "react"
import { SortDirection } from "sharedTypes"
import Icon from "./Icon"
import classNames from "classnames"

type Props = {
  sortColumn?: string
  sortDirection?: SortDirection
  onSortChange(values: {
    sort: string
    direction: SortDirection
  }): Promise<void>
  columns: { title: string; value?: string; className?: string }[]
  stickyColumns: number
}

const DefaultSortDirection = SortDirection.Asc

const SortableTableHeaders = ({
  sortColumn,
  sortDirection,
  onSortChange,
  columns,
  stickyColumns,
}: Props) => {
  const sortBy = (value) => {
    const values = { sort: sortColumn, direction: sortDirection }
    if (sortColumn === value) {
      values.direction =
        sortDirection === SortDirection.Asc
          ? SortDirection.Desc
          : SortDirection.Asc
    } else {
      values.sort = value
      values.direction = DefaultSortDirection
    }
    onSortChange(values)
  }

  const renderHeader = (
    {
      title,
      value,
      className,
    }: { title: string; value?: string; className?: string },
    index
  ) => {
    const iconTypeName = (value) => {
      if (isActive(value)) {
        return sortDirection === "asc" ? "sort-down" : "sort-up"
      } else {
        return "sort-down"
      }
    }

    const isActive = (value) => {
      return sortColumn && sortColumn === value
    }

    const isSticky = index < stickyColumns

    if (!value) {
      return (
        <th
          key={title}
          className={classNames("non-sortable-header", className, {
            "table-cell-sticky": isSticky,
          })}
        >
          <div>{title}</div>
        </th>
      )
    }
    return (
      <th
        key={title}
        className={classNames(className, { "table-cell-sticky": isSticky })}
        onClick={() => sortBy(value)}
      >
        <a
          className={classNames("position-relative", { bold: isActive(value) })}
        >
          {title}
          <Icon
            className={classNames("ml-1 position-absolute", {
              "color-mid-gray": !isActive(value),
            })}
            type={iconTypeName(value)}
          />
        </a>
      </th>
    )
  }

  return (
    <thead className="table-sticky-header">
      <tr>{columns.map((column, index) => renderHeader(column, index))}</tr>
    </thead>
  )
}

export default SortableTableHeaders

SortableTableHeaders.defaultProps = {
  stickyColumns: 0,
}
