// @ts-strict-ignore
import { DmeOrder } from "sharedTypes"
import { formatHeight } from "../../../../../utilities/conversion"
import React from "react"

type Props = {
  dmeOrder: DmeOrder
}

const Patient = (props: Props) => {
  const { dmeOrder } = props
  const {
    patient,
    clinicalFacility,
    doctor,
    patientSnapshot,
    useFeetForHeight,
  } = dmeOrder
  const { dateOfBirth } = patient
  const { heightInches, weightLbs } = patientSnapshot
  const height = heightInches && formatHeight(useFeetForHeight, heightInches)
  const weight = weightLbs && `${weightLbs} lbs`

  return (
    <div>
      <span className="bold color-black">Date of Birth</span>
      <p className="ml-2"> {dateOfBirth} </p>
      <span className="bold color-black">Height / Weight</span>
      <p className="ml-2"> {[height, weight].filter(Boolean).join(", ")} </p>
      <span className="bold color-black">Facility</span>
      <p className="ml-2"> {clinicalFacility.name} </p>
      <span className="bold color-black">Doctor</span>
      <p className="ml-2"> {`${doctor.firstName} ${doctor.lastName}`} </p>
    </div>
  )
}

export default Patient
