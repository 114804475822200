import React, { useEffect, useState } from "react"
import { MaterialThemeProvider } from "themes/theme"
import { DataGridPro, GridColDef, GridSortModel } from "@mui/x-data-grid-pro"
import { isTest } from "utilities/environment"
import SalesRepTerritoriesTableRemoveModal from "./components/SalesRepTerritoriesTableRemoveModal"
import isEqual from "lodash.isequal"
import {
  getGridSortModelFromCookie,
  setGridSortModelInCookie,
} from "./gridSortModelCookieHelper"

type Props = {
  salesRepTerritories: SalesRepTerritory[]
  destroy: () => void
}

export type SalesRepTerritory = {
  territoryId: string
  territoryName: string
  salesRepEmail?: string | null
  territoryCount: number
  territoryPath: string
}

const SalesRepTerritoriesTable: React.FC<Props> = ({
  salesRepTerritories,
}: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [territory, setTerritory] = useState<SalesRepTerritory | null>(null)
  const gridSortModelCookie = getGridSortModelFromCookie("gridSortModel")
  const [gridSortModel, setGridSortModel] = useState<GridSortModel>(
    gridSortModelCookie ? gridSortModelCookie : []
  )

  const openModal = (territory: SalesRepTerritory) => {
    setShowModal(true)
    setTerritory(territory)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    if (!isEqual(newSortModel, gridSortModel)) {
      setGridSortModel(newSortModel)
    }
  }

  useEffect(() => {
    const eightHoursInDays = 1 / 3
    setGridSortModelInCookie(
      "gridSortModel",
      JSON.stringify(gridSortModel),
      eightHoursInDays
    )
  }, [gridSortModel])

  const columns: GridColDef[] = [
    {
      field: "territoryName",
      flex: 1,
      headerName: "Territory Name",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "salesRepEmail",
      flex: 1,
      headerName: "Sales Rep Email Address",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "territoryCount",
      flex: 1,
      headerName: "# of Territory Zip Codes",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "action",
      flex: 1,
      headerName: "Action",
      minWidth: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <a id="editLink" href={params.row.territoryPath}>
              Edit
            </a>
            <button
              onClick={() => openModal(params.row as SalesRepTerritory)}
              className="link canopy-ml-4x"
            >
              Remove
            </button>
          </>
        )
      },
    },
  ]

  return (
    <MaterialThemeProvider>
      <DataGridPro
        columns={columns}
        rows={salesRepTerritories}
        pagination={true}
        disableVirtualization={isTest()}
        getRowId={(row) => row.territoryId}
        autoHeight
        hideFooterSelectedRowCount
        sortModel={gridSortModel}
        onSortModelChange={(newModel) => handleSortModelChange(newModel)}
      />
      {territory && (
        <SalesRepTerritoriesTableRemoveModal
          closeModal={closeModal}
          shouldShow={showModal}
          territory={territory}
        />
      )}
    </MaterialThemeProvider>
  )
}

export default SalesRepTerritoriesTable
