import React from "react"
import withInitialData from "components/withInitialData"
import InternalClinical from "./components/InternalClinical"
import * as api from "./api"
import * as workflowApi from "applications/Workflow/api"
import { DiagnosisHook, useDiagnosis } from "../Icd10Codes/hooks/useDiagnosis"
import { getDmeOrderDiagnosis } from "../Icd10Codes/api"
import { handleError } from "utilities/error"
import {
  DmeOrder,
  DmeOrderPermissions,
  Diagnosis,
  DmeOrderWarning,
  DocumentationRequirementSatisfyStatus,
} from "sharedTypes"
import { Redirect, Route, Switch } from "react-router-dom"
import { fillablePdfPathMatcher, FillablePdfPathRouteProps } from "./utilities"
import { FillablePdfDocumentationRequirement } from "./components/FillablePdfDocumentationRequirement"
import * as routes from "applications/Workflow/routes"
import { useFeatureFlags } from "components/FeatureFlagContext"

type Props = {
  dmeOrder: DmeOrder
  isOptumFlow: boolean
  initialData: Diagnosis
  permissions: DmeOrderPermissions
  nextPagePath: string | undefined
  refreshDmeOrder(): Promise<void>
  setDmeOrder(dmeOrder: DmeOrder): Promise<void>
  isPageChecked?: boolean
  warnings: DmeOrderWarning[]
}

const Clinical = (props: Props) => {
  const {
    dmeOrder,
    isOptumFlow,
    initialData,
    permissions,
    nextPagePath,
    isPageChecked,
    refreshDmeOrder,
    setDmeOrder,
    warnings,
  } = props
  const diagnosisHook: DiagnosisHook = useDiagnosis(
    initialData,
    refreshDmeOrder
  )
  const { isFeatureEnabled } = useFeatureFlags()

  const uploadDocuments = (
    documentationRequirementIds,
    files,
    signed,
    otherAttachmentTitle
  ) =>
    workflowApi.uploadDocuments(
      documentationRequirementIds,
      files,
      signed,
      otherAttachmentTitle
    )

  const updateSatisfyMethod = (documentationRequirement, satisfyMethod) => {
    return api
      .updateSatisfyMethod(documentationRequirement, satisfyMethod)
      .then((response) => {
        setDmeOrder(response.data)
      })
  }

  const updateDocumentationRequirementsSatisfyStatus = (values: {
    manualSatisfyStatus: DocumentationRequirementSatisfyStatus
    documentationRequirementIds: string[]
  }) => {
    return workflowApi
      .updateDocumentationRequirementsSatisfyStatus(values)
      .then((dmeOrder) => setDmeOrder(dmeOrder))
      .catch(handleError)
  }

  return (
    <Switch>
      <Route
        path={fillablePdfPathMatcher()}
        render={({ match }: FillablePdfPathRouteProps) => {
          const documentationRequirement = dmeOrder.documentationRequirements.find(
            ({ externalId }) =>
              externalId === match.params.documentationRequirementExternalId
          )
          if (
            !isFeatureEnabled(
              "jediPendingBrowserFillablePdfsForDocumentationRequirements"
            ) ||
            !documentationRequirement
          ) {
            return <Redirect to={routes.clinicalPath()} />
          }
          return (
            <FillablePdfDocumentationRequirement
              documentationRequirement={documentationRequirement}
              refreshDmeOrder={refreshDmeOrder}
              nextPagePath={nextPagePath}
            />
          )
        }}
      />
      <Route>
        <InternalClinical
          updateSatisfyMethod={updateSatisfyMethod}
          uploadDocuments={uploadDocuments}
          diagnosisHook={diagnosisHook}
          permissions={permissions}
          dmeOrder={dmeOrder}
          nextPagePath={nextPagePath}
          isPageChecked={isPageChecked}
          warnings={warnings}
          updateDocumentationRequirementsSatisfyStatus={
            updateDocumentationRequirementsSatisfyStatus
          }
          isOptumFlow={isOptumFlow}
        />
      </Route>
    </Switch>
  )
}

const fetchInitialData = () => getDmeOrderDiagnosis().then(({ data }) => data)
export default withInitialData(fetchInitialData)(Clinical)
