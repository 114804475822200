import React from "react"
import QuestionInputText from "./questions/QuestionInputText"
import QuestionOptional from "./questions/QuestionOptional"
import QuestionInputNumber from "./questions/QuestionInputNumber"
import QuestionSelectMulti from "./questions/QuestionSelectMulti"
import QuestionSelectOne from "./questions/QuestionSelectOne"
import QuestionInputDate from "./questions/QuestionInputDate"
import * as t from "sharedTypes"

export const INPUT_DATE_QUESTION_TYPE = "date"
export const INPUT_NUMBER_QUESTION_TYPE = "numeric"
export const INPUT_TEXT_QUESTION_TYPE = "text"
export const SELECT_MANY_QUESTION_TYPE = "select_multi"
export const SELECT_ONE_QUESTION_TYPE = "select_one"
export const SELECT_YES_NO_QUESTION_TYPE = "yes_no"
export const OPTIONAL_TYPE = "optional"

const F2FMap = {
  [INPUT_DATE_QUESTION_TYPE]: QuestionInputDate,
  [INPUT_NUMBER_QUESTION_TYPE]: QuestionInputNumber,
  [INPUT_TEXT_QUESTION_TYPE]: QuestionInputText,
  [SELECT_MANY_QUESTION_TYPE]: QuestionSelectMulti,
  [SELECT_ONE_QUESTION_TYPE]: QuestionSelectOne,
  [SELECT_YES_NO_QUESTION_TYPE]: QuestionSelectOne,
  [OPTIONAL_TYPE]: QuestionOptional,
}

type Props = {
  answerQuestion(
    question: t.SurveyQuestion,
    answerType: t.SurveyAnswerType,
    answerValue: string
  ): Promise<void>
  question: t.SurveyQuestion
  showMostCommon: boolean
  setFieldSaving(boolean): void
  requiresConfirmation: boolean
}

const SurveyQuestion = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      question,
      answerQuestion,
      showMostCommon,
      setFieldSaving,
      requiresConfirmation,
    },
    ref
  ) => {
    const QuestionOfType = F2FMap[question.type]
    const wrappedAnswerQuestion = (answerType, answerValue) =>
      answerQuestion(question, answerType, answerValue)

    return (
      <div className="mb-40" style={{ whiteSpace: "pre-line" }}>
        <QuestionOfType
          question={question}
          onAnswer={wrappedAnswerQuestion}
          showMostCommon={showMostCommon}
          setFieldSaving={setFieldSaving}
          requiresConfirmation={requiresConfirmation}
          ref={ref}
        />
      </div>
    )
  }
)
export default SurveyQuestion
