// @ts-strict-ignore
import React, { useState, useContext, ChangeEvent } from "react"
import {
  dashboardPath,
  quickOrderPath,
  sendDocumentsUrl,
} from "applications/Workflow/urls"
import { uploadDocuments } from "./api"
import Icon from "components/Icon"
import DownloadFaxCoverSheetLink from "components/DownloadFaxCoverSheetLink"
import { pluralize } from "utilities/string"
import { meta } from "utilities/meta"
import { handleError } from "utilities/error"
import GlobalContext from "context/Global"
import { DmeOrder } from "sharedTypes"
import { formatPhone } from "utilities/phone"
import { navigate } from "utilities/navigation"

type Props = {
  dmeOrder: DmeOrder
}

const QuickOrder: React.FunctionComponent<Props> = ({ dmeOrder }) => {
  const countOfDocuments = dmeOrder.documentIds.length
  const hasDocuments = countOfDocuments > 0
  const [tab, setTab] = useState(hasDocuments ? "upload" : "")
  const [isUploading, setUploading] = useState(false)
  const { companyFaxNumber } = useContext(GlobalContext)
  const { supplier } = dmeOrder
  const faxNumber = supplier
    ? formatPhone(supplier.intakeFaxNumber)
    : companyFaxNumber
  const upload = (event: ChangeEvent<HTMLInputElement>) => {
    setUploading(true)

    const files = Array.from(event.target.files)

    uploadDocuments(dmeOrder.id, files, "quick_order_page")
      .then(() => {
        navigate(quickOrderPath())
      })
      .catch((error) => {
        setUploading(false)
        event.target.value = null
        handleError(
          error,
          "Could not upload document. Please confirm the document is a valid PDF and try again."
        )
        throw error
      })
  }
  return (
    <div>
      <div className="my-4">
        <a
          className="btn btn-secondary w-200 mr-2"
          onClick={() => setTab("upload")}
        >
          Upload Documents
        </a>
        <a className="btn btn-secondary w-200" onClick={() => setTab("fax")}>
          Fax Documents
        </a>
      </div>
      {tab === "upload" && (
        <div className="well">
          <h4>Please upload all documents in PDF format</h4>
          {hasDocuments && (
            <p>
              Uploaded {countOfDocuments}{" "}
              {pluralize("document", countOfDocuments)}
            </p>
          )}
          <div className="row">
            <div className="col-md-6">
              <label className="btn btn-block btn-primary-o">
                <Icon
                  type={isUploading ? "spinner" : "cloud-upload-alt"}
                  spin={isUploading}
                  className="mr-2"
                />
                {hasDocuments ? "Upload Another" : "Upload Document"}
                <input
                  accept=".pdf"
                  type="file"
                  onChange={upload}
                  disabled={isUploading}
                  className="hide"
                  name="quick_order_page_upload"
                />
              </label>
            </div>
            {hasDocuments && (
              <div className="col-md-6">
                <form method="post" action={sendDocumentsUrl()}>
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={meta("csrf-token")}
                  />
                  <button type="submit" className="btn btn-block btn-primary-o">
                    I&apos;ve uploaded all files
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
      {tab === "fax" && (
        <div className="well">
          <h4>Step 1</h4>
          <DownloadFaxCoverSheetLink
            className="btn btn-default btn-long"
            text="Print cover sheet"
          />
          <h4 className="mt-4">Step 2</h4>
          <p>Fax order and cover sheet to {faxNumber}</p>
          <p>
            The supplier will process the order as soon as the documents are
            received. You can leave this page and return any time.
          </p>
          <div className="mt-3">
            <a className="btn btn-primary" href={dashboardPath()}>
              Done
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default QuickOrder
