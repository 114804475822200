// @ts-strict-ignore
import React, { useState } from "react"
import Accordion from "components/Accordion"
import Modal from "components/Modal"
import ModalBody from "components/ModalBody"
import Icon from "components/Icon"
import { quote } from "utilities/string"

type Tooltip = {
  subject: string
  body: string
}

type Props = {
  questionText?: string
  questionTitle?: string
  questionId?: string
  tooltips?: Tooltip[]
}

function QuestionTitle({
  questionTitle,
  questionText,
  questionId,
  tooltips,
}: Props) {
  const [showTooltips, setShowTooltips] = useState(false)

  const openTooltips = () => {
    setShowTooltips(true)
  }

  const closeTooltips = () => {
    setShowTooltips(false)
  }

  const hasTooltips = tooltips && tooltips.length > 0

  const getModalTitle = function (questionTitle, questionText) {
    const text = [questionTitle, questionText].filter(Boolean).join(" - ")
    return quote(text)
  }

  return (
    <>
      <label
        className="col-form-label"
        htmlFor={questionId}
        style={{ whiteSpace: "pre-line" }}
      >
        {questionTitle && (
          <strong>
            {questionTitle}
            <br />
          </strong>
        )}
        {questionText}
        {hasTooltips && (
          <a onClick={openTooltips} className="color-black pl-1">
            <Icon type="question-circle" />
          </a>
        )}
      </label>

      {hasTooltips && (
        <Modal
          size="md"
          title={getModalTitle(questionTitle, questionText)}
          show={showTooltips}
          cancel={closeTooltips}
        >
          <ModalBody>
            <Accordion
              groups={tooltips.map((tooltip) => ({
                title: tooltip.subject,
                body: tooltip.body,
              }))}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default QuestionTitle
