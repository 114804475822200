// @ts-strict-ignore
import { Paper } from "@material-ui/core"
import Table from "components/Table"
import React, {
  useEffect,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  useCallback,
} from "react"
import { Route } from "react-router-dom"
import {
  DoctorSignatureRequest,
  signatureStatus,
  fetchSignatureRequests,
} from "../../api"
import { SignPath } from "../../routes"
import SignatureRequestDetailView from "../SignatureRequestDetailView"
import SignSignatureRequestsOnScreenButton from "./components/SignSignatureRequestsOnScreenButton"
import * as styles from "./index.module.scss"
import tableColumnDefinitions from "./tableColumnDefinitions"
import FacilitySignup from "../FacilitySignup"
import { NoSignatureRequests } from "../NoSignatureRequests"
import { EventCategory, trackEvent } from "utilities/tracking"
import ReviewSignatureRequests from "./components/ReviewSignatureRequests"
import PatientSearchField from "../PatientSearchField"
import LoadMoreLink from "components/LoadMoreLink"
import {
  tableHeightCalculation,
  heightCalculation,
} from "../../utilities/heightCalculation"
import { useFlashContext } from "../../hooks/useFlashContext"
import { useStyles } from "themes/theme"
import { useDoctorContext } from "../../hooks/useDoctorContext"
import { useDebounce } from "hooks/useDebounce"
import classNames from "classnames"
import RemoveSignatureRequestReviewsButton from "./components/RemoveSignatureRequestReviewsButton"
import { pluralize } from "utilities/string"
import * as tokens from "@parachutehealth/canopy-tokens-color"

const searchBarHeight = 72

interface Props {
  currentUserEmail: string
  currentUserEligibleForFacilitySignup: boolean
  redirectToFacilitySignupPage: boolean
  notificationBadgeStatusChecker: (doctorId: string) => void
  patientSearchQuery: string
  setPatientSearchQuery: Dispatch<SetStateAction<string>>
  actionableSignatureRequestsStatus: { review: boolean; sign: boolean }
}

const paperHeightStyles = (flash) => ({
  paper: {
    height: `${heightCalculation(flash)}`,
  },
  spinner: {
    height: `calc(100% - ${searchBarHeight}px)`,
  },
})

const SelectedCount = ({ count }: { count: number }) => (
  <span
    style={{
      color:
        count > 0
          ? tokens.canopyColorInteractiveLinkDefault
          : tokens.canopyColorTextDisabled,
    }}
    className="canopy-mb-0 canopy-mr-12x"
  >
    {count} {pluralize("order", count)} selected
  </span>
)

const SignatureRequestSignTab = ({
  currentUserEmail,
  currentUserEligibleForFacilitySignup,
  redirectToFacilitySignupPage,
  notificationBadgeStatusChecker,
  patientSearchQuery,
  setPatientSearchQuery,
  actionableSignatureRequestsStatus,
}: Props) => {
  const { doctor, updatingDoctor } = useDoctorContext()
  const debouncedFetch = useDebounce(fetchSignatureRequests)

  const [signatureRequests, setSignatureRequests] = useState<
    DoctorSignatureRequest[]
  >([])
  const [loadingSignatureRequests, setLoadingSignatureRequests] = useState(true)
  const [
    selectedSignatureRequestsGids,
    setSelectedSignatureRequestsGids,
  ] = useState<string[]>([])

  const debouncedFetchSignatureRequests = useCallback(() => {
    debouncedFetch({
      doctorId: doctor.id,
      searchParameters: {
        status: signatureStatus.REVIEWED,
        searchKeywords: patientSearchQuery,
      },
    }).then(({ results }) => {
      setSignatureRequests(results)
      setSelectedSignatureRequestsGids([])
      setLoadingSignatureRequests(false)
    })
  }, [debouncedFetch, doctor.id, patientSearchQuery])

  useEffect(() => {
    setLoadingSignatureRequests(true)
    debouncedFetchSignatureRequests()

    return () => {
      setSignatureRequests([])
      setSelectedSignatureRequestsGids([])
      setLoadingSignatureRequests(false)
    }
  }, [patientSearchQuery, doctor.id, debouncedFetchSignatureRequests])

  const tableColumns = tableColumnDefinitions(doctor.id)
  const flash = useFlashContext()
  const muiStyles = useMemo(() => paperHeightStyles(flash), [flash])
  const muiClasses = useStyles(muiStyles)

  const tableRecords = signatureRequests.map((r) => ({
    ...r,
    key: r.gid,
  }))

  const noSignatureRequests =
    signatureRequests.length === 0 &&
    !patientSearchQuery &&
    !loadingSignatureRequests

  if (noSignatureRequests) {
    const allRequestsSignedText = `All requests for ${doctor.name} have been signed`
    if (actionableSignatureRequestsStatus.review) {
      return (
        <Paper
          className={classNames(styles.container, muiClasses.paper)}
          elevation={0}
        >
          <ReviewSignatureRequests />
        </Paper>
      )
    } else if (currentUserEligibleForFacilitySignup) {
      const resupplyPromoSeenEvent = "promo_seen_resupply"
      void trackEvent(EventCategory.ResupplySignUp, resupplyPromoSeenEvent)
      return (
        <FacilitySignup
          currentUserEmail={currentUserEmail}
          headingTitle={allRequestsSignedText}
          redirectToFacilitySignupPage={redirectToFacilitySignupPage}
        />
      )
    } else {
      return (
        <Paper className={muiClasses.paper} elevation={0}>
          <NoSignatureRequests message={allRequestsSignedText} />
        </Paper>
      )
    }
  }

  return (
    <Route
      path={`${SignPath(doctor.id)}/:previewSignatureRequestGid?`}
      render={({ match }) => {
        const previewSignatureRequest = signatureRequests.find(
          ({ gid }) => gid === match.params.previewSignatureRequestGid
        )

        if (previewSignatureRequest) {
          return (
            <SignatureRequestDetailView
              linkBackTo={SignPath(doctor.id)}
              doctorId={previewSignatureRequest.doctorId}
              signatureRequest={previewSignatureRequest}
            />
          )
        } else {
          const showSpinner = updatingDoctor || loadingSignatureRequests
          return (
            <Paper
              className={classNames(styles.container, muiClasses.paper)}
              elevation={0}
            >
              <fieldset
                className={`d-flex flex-row align-items-center justify-content-between ${styles.actionBar}`}
              >
                <div>
                  <PatientSearchField
                    onSearch={(searchKeywords) => {
                      setLoadingSignatureRequests(true)
                      setPatientSearchQuery(searchKeywords)
                    }}
                    value={patientSearchQuery}
                    className={styles.searchField}
                    autoFocus={!!patientSearchQuery}
                    type="text"
                  />
                </div>
                {doctor.signOnScreenEnabled && (
                  <div>
                    <SelectedCount
                      count={selectedSignatureRequestsGids.length}
                    />
                    <RemoveSignatureRequestReviewsButton
                      selectedSignatureRequestsGids={
                        selectedSignatureRequestsGids
                      }
                      pendingSignatureRequests={signatureRequests}
                      doctor={doctor}
                      onRemoveReviews={debouncedFetchSignatureRequests}
                      notificationBadgeStatusChecker={() =>
                        notificationBadgeStatusChecker(doctor.id)
                      }
                      loading={loadingSignatureRequests}
                      setLoading={setLoadingSignatureRequests}
                    />
                    <SignSignatureRequestsOnScreenButton
                      selectedSignatureRequestsGids={
                        selectedSignatureRequestsGids
                      }
                      pendingSignatureRequests={signatureRequests}
                      doctor={doctor}
                      onSign={debouncedFetchSignatureRequests}
                      notificationBadgeStatusChecker={() =>
                        notificationBadgeStatusChecker(doctor.id)
                      }
                      loading={loadingSignatureRequests}
                      setLoading={setLoadingSignatureRequests}
                    />
                  </div>
                )}
              </fieldset>
              {showSpinner ? (
                <div
                  className={classNames(
                    "d-flex justify-content-center align-items-center",
                    muiClasses.spinner
                  )}
                >
                  <div
                    className="fas fa-spinner fa-spin color-primary"
                    style={{ fontSize: "64px" }}
                  />
                </div>
              ) : (
                <div
                  className={styles.tableContainer}
                  aria-label="signature request sign table"
                >
                  <Table
                    records={tableRecords}
                    tableColumns={tableColumns}
                    selectable={doctor.signOnScreenEnabled}
                    selectedRecordKeys={selectedSignatureRequestsGids}
                    onSelect={setSelectedSignatureRequestsGids}
                    maxHeight={tableHeightCalculation(flash)}
                  />
                  {signatureRequests.length === 0 && patientSearchQuery && (
                    <div className="mt-4 text-center">
                      <LoadMoreLink
                        clearFilters={() => {
                          setSelectedSignatureRequestsGids([])
                          setLoadingSignatureRequests(true)
                          setPatientSearchQuery("")
                        }}
                        loading={loadingSignatureRequests}
                        count={0}
                        totalCount={0}
                        onClick={undefined}
                      />
                    </div>
                  )}
                </div>
              )}
            </Paper>
          )
        }
      }}
    />
  )
}

export default SignatureRequestSignTab
