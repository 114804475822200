import React, { useState, useEffect, Dispatch, SetStateAction } from "react"
import MessageForm from "./components/MessageForm"
import Messages from "./components/Messages"
import ChatUnavailable from "./components/ChatUnavailable"
import { camelizeKeys } from "humps"
import {
  ChatWithMessages,
  Message,
  PatientPortalEndpoint,
} from "services/patientPortal"
import withInitialData from "components/withInitialData"
import { fetchSupplierChatTemplates } from "./api"
import { Socket } from "phoenix"
import { SupplierChatTemplate } from "./types"
import { interpolateTemplates } from "./utilities/templateInterpolator"

type ChatDataWithTemplates = ChatWithMessages & {
  supplierChatTemplates: SupplierChatTemplate[]
}

type Props = {
  dmeOrderId: string
  chatId: string
  chatAvailable: boolean
  patientPortalEndpoint: PatientPortalEndpoint
  setUnreadPatientMessages: Dispatch<SetStateAction<boolean>>
  supplierName: string
  initialData: ChatDataWithTemplates
}

const PatientPortal: React.FC<Props> = ({
  chatId,
  chatAvailable,
  patientPortalEndpoint,
  setUnreadPatientMessages,
  supplierName,
  initialData,
}) => {
  const [messages, setMessages] = useState<Message[]>(initialData.messages)
  const chat = (({ patient }) => ({ patient }))(initialData)
  const variables = {
    supplierName,
    patientFirstName: chat.patient.firstName,
    patientLastName: chat.patient.lastName,
  }
  const chatTemplates = interpolateTemplates(
    initialData.supplierChatTemplates,
    variables
  )

  useEffect(() => {
    if (!initialData) {
      return
    }

    setUnreadPatientMessages(false)

    const socket = new Socket(`${window.patientPortalWsUrl}/ws/parachute`, {
      params: { token: initialData.token },
    })
    socket.connect()
    const channel = socket.channel(`chat:${chatId}`, {})

    channel.join()
    channel.on("new_message", (msg) => {
      setMessages((messages) => [camelizeKeys(msg), ...messages])
      channel.push("read_message", {
        external_id: msg.external_id,
        type: msg.type,
      })
    })

    channel.on("updated_messages", ({ updated_messages }) => {
      setMessages((messages) =>
        messages.map((message) => {
          const updatedMessage = updated_messages.find(
            (updatedMessage) =>
              message.externalId === updatedMessage.external_id
          )
          if (updatedMessage) {
            return camelizeKeys(updatedMessage)
          } else {
            return message
          }
        })
      )
    })

    return () => {
      channel.leave()
      socket.disconnect()
    }
  }, [chatId, initialData, setUnreadPatientMessages])

  const onSubmit = (params, { resetForm }) => {
    return patientPortalEndpoint.sendMessage(params).then(() => {
      resetForm()
    })
  }

  if (!initialData) {
    return null
  }

  return chatAvailable ? (
    <>
      <MessageForm
        chatId={chatId}
        chatTemplates={chatTemplates}
        patientMessagingConsentedAt={chat.patient.messagingConsentedAt}
        onSubmit={onSubmit}
      />
      <Messages
        messages={messages}
        chat={chat}
        insuranceUploadUrl={patientPortalEndpoint.insuranceUploadUrl}
      />
    </>
  ) : (
    <ChatUnavailable />
  )
}

const fetchInitialData = (
  args: Omit<Props, "initialData">
): Promise<ChatDataWithTemplates> => {
  return Promise.all([
    args.patientPortalEndpoint.fetchChat(args.chatId).then(({ data }) => data),
    fetchSupplierChatTemplates(args.dmeOrderId).then((data) => data),
  ]).then((values) => {
    const [chatData, supplierChatTemplates] = values
    return { ...chatData, supplierChatTemplates }
  })
}

export default withInitialData(fetchInitialData)(PatientPortal)
