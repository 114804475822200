// @team @clinical-intelligence

import React, { ReactElement } from "react"
import ExternalQuestionnaireModalButton from "./ExternalQuestionnaireModalButton"
import * as styles from "./index.module.scss"

type Props = {
  title: string
  questionnaire?: object
  statusPill: ReactElement
  key: string
}
function MedicalNecessitySummaryRow({
  title,
  questionnaire,
  statusPill,
  key,
}: Props) {
  return (
    <span className={`${styles.rowWrapper}`} key={key}>
      <span className="flex-1">{title}</span>
      {questionnaire && (
        <span className={styles.floatRight}>
          <ExternalQuestionnaireModalButton
            title={title}
            questionnaire={questionnaire}
          />
        </span>
      )}
      {statusPill}
    </span>
  )
}

export default MedicalNecessitySummaryRow
