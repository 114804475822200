import { DATE_FORMAT, format } from "utilities/date"
import Icon from "components/Icon"
import React from "react"
import cx from "classnames"

interface Props {
  signProofOfDelivery(): void
  deliveryTicketSignedAt: string
}

const DeliveryReceiptLinks = ({
  signProofOfDelivery,
  deliveryTicketSignedAt,
}: Props) => {
  const formattedDeliverySignatureDate = format(
    deliveryTicketSignedAt,
    DATE_FORMAT
  )

  const signedOnDeliverySignatureDate = () => {
    if (deliveryTicketSignedAt) {
      return <div>(signed on {formattedDeliverySignatureDate})</div>
    }
  }

  return (
    <div className={cx("wide-fixed-row", "clearfix")}>
      <ul className="list-unstyled">
        <a className="link" onClick={signProofOfDelivery}>
          <Icon type="edit" className="mr-2" />
          Sign Proof of Delivery
        </a>
        <br />
        {signedOnDeliverySignatureDate()}
      </ul>
    </div>
  )
}

export default DeliveryReceiptLinks
