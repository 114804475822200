import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "./index.module.scss"
import NotificationDropdownMenu from "applications/NotificationDropdown/NotificationDropdownMenu"

interface Props {
  events: {
    href: string
    message: string
    timestamp: string
  }[]
}

const NotificationDropdown: React.FC<Props> = ({ events }) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const badgeContent = events.length > 99 ? "99+" : events.length

  return (
    <div style={{ position: "relative" }} className="dropdown">
      <CanopyButton
        accessibleText="Notifications"
        data-analytics="notification_dropdown"
        data-analytics-replay-event
        data-toggle="dropdown"
        iconStart="bell"
        onClick={() => setShowDropdown(!showDropdown)}
        size="small"
        type="button"
        variant="tertiary"
      />
      {events.length > 0 && (
        <span className={styles.badge}>{badgeContent}</span>
      )}
      <NotificationDropdownMenu events={events} show={showDropdown} />
    </div>
  )
}

export default NotificationDropdown
