import { get } from "services/api"
import { makeUrlProvider } from "./urls"
import debounceFn from "awesome-debounce-promise"
import { EmployerType, getEmployerId, getEmployerType } from "utilities/url"

export const makeApi = (employerType: EmployerType, employerId) => {
  const urls = makeUrlProvider(
    getEmployerType(employerType),
    getEmployerId(employerId)
  )
  return {
    fetchDoctors: (term) => {
      return get(urls.doctorsSearchUrl, { term })
        .then(({ data }) => data.results)
        .catch(({ response }) => Promise.reject(response.data))
    },

    fetchFacilities: debounceFn((term) => {
      return get(urls.facilitiesSearchUrl, { term })
        .then(({ data }) => data.results)
        .catch(({ response }) => Promise.reject(response.data))
    }, 200),

    fetchLegalEntities: (term) => {
      return get(urls.legalEntitiesSearchUrl, { term })
        .then(({ data }) => data.supplierLegalEntities)
        .catch(({ response }) => Promise.reject(response.data))
    },

    fetchExternalSystemBranches: (term) => {
      return get(urls.getExternalSystemBranchesUrl, { term })
        .then(({ data }) => data.results)
        .catch(({ response }) => Promise.reject(response.data))
    },

    fetchEmployees: (term) => {
      return get(urls.employeesSearchUrl, { term })
        .then(({ data }) => data.results)
        .catch(({ response }) => Promise.reject(response.data))
    },

    getInitialValues: () => {
      return get(urls.initialValuesUrl)
        .then(({ data }) => data)
        .catch(({ response }) => Promise.reject(response.data))
    },

    getDefaultValues: ({ clinicalFacilityId, doctorId }) => {
      return get(urls.defaultValuesUrl, { clinicalFacilityId, doctorId })
        .then(({ data }) => data)
        .catch(({ response }) => Promise.reject(response.data))
    },

    fetchRecommendedFacilities: (doctorId: string) => {
      return get(urls.recommendedFacilitiesUrl, { doctorId })
        .then(({ data }) => data)
        .catch(({ response }) => Promise.reject(response.data))
    },
  }
}
