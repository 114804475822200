// @ts-strict-ignore
import React from "react"
import { EmrCachedPatient } from "sharedTypes"
import Section from "components/Section"
import CreateOrderButton from "./CreateOrderButton"
import { format } from "utilities/date"
import { capitalize, dash } from "utilities/string"
import { formatPhone } from "utilities/phone"
import SubText from "components/SubText"

type Props = {
  emrCachedPatient: EmrCachedPatient
  isPrimary: boolean
  createDmeOrder(patientId: string, cachedEncounterId?: string): Promise<void>
  canCreateOrder: boolean
}

export default (props: Props) => {
  const { emrCachedPatient, isPrimary, createDmeOrder, canCreateOrder } = props

  const encounter = emrCachedPatient.encounter

  const sectionTitle = () => {
    const syncedAt = encounter ? encounter.syncedAt : emrCachedPatient.syncedAt
    const syncedAtLabel = encounter
      ? `Visit ${encounter.visitNumber} synced`
      : "Synced"

    return (
      <div className="d-inline-block font-xs mb-0">
        <div className="bold">MRN: {emrCachedPatient.mrn}</div>
        {(syncedAt && (
          <div className="color-dark-gray mt-1">
            {syncedAtLabel} on {format(syncedAt)} at {format(syncedAt, "h:mmA")}
          </div>
        )) || <div className="color-dark-gray mt-1">&nbsp;</div>}
      </div>
    )
  }

  const detail = (title, content) =>
    content && (
      <div
        className="col-6 col-md-4 mt-2"
        aria-labelledby={`${dash(title)}`}
        key={title}
      >
        <strong id={`${dash(title)}`}>{title}</strong>
        <p className="mt-1 preline">{content}</p>
      </div>
    )

  const observationDetail = (title, content, date) =>
    content && (
      <div
        className="col-6 col-md-4 mt-2"
        aria-labelledby={`${dash(title)}`}
        key={title}
      >
        <strong id={`${dash(title)}`}>{title}</strong>
        <p className="mt-1 preline">{content}</p>
        {date && <SubText content={"Date Observed: " + format(date)} />}
      </div>
    )

  const sectionHeader = (title) => (
    <div className="row">
      <div className="col-md-12 color-dark-gray">{title}</div>
    </div>
  )

  const dataSection = (title, children, first = false) =>
    children.props.children.filter((detail) => !!detail).length > 0 && (
      <div className={`${first ? "" : "bt bc-p5"} py-3`}>
        {sectionHeader(title)}
        <div className="row">{children}</div>
      </div>
    )

  const formatAddress = (address) => {
    if (!address.line1 && !address.city && !address.state && !address.zip) {
      return
    }

    const formattedAddress = [address.line1]
    address.line2 && formattedAddress.push(address.line2)
    formattedAddress.push(`${address.city}, ${address.state} ${address.zip}`)

    return formattedAddress.join("\n")
  }

  const patientInformation = () => {
    const formatEmergencyContact = (
      firstName,
      lastName,
      phoneNumber,
      relationship
    ) => {
      const emergencyContact = []

      firstName && lastName && emergencyContact.push(`${firstName} ${lastName}`)
      phoneNumber && emergencyContact.push(phoneNumber)
      relationship && emergencyContact.push(relationship)

      if (emergencyContact.length > 0) {
        return emergencyContact.join("\n")
      }
    }

    const details = (
      <>
        {detail("Patient Address", formatAddress(emrCachedPatient.address))}
        {detail(
          "Emergency Contact",
          formatEmergencyContact(
            emrCachedPatient.emergencyContactFirstName,
            emrCachedPatient.emergencyContactLastName,
            emrCachedPatient.emergencyContactPhoneNumber,
            emrCachedPatient.emergencyContactRelationship
          )
        )}
        {detail("Billing Phone Number", emrCachedPatient.billingPhoneNumber)}
        {emrCachedPatient.alternateBillingPhoneNumber &&
          detail(
            "Alternate Billing Phone Number",
            formatPhone(emrCachedPatient.alternateBillingPhoneNumber)
          )}
        {detail("Preferred Language", emrCachedPatient.primaryLanguage)}
        {detail("Email", emrCachedPatient.email)}
      </>
    )

    return dataSection("Patient Information", details, true)
  }

  const insurance = () => {
    const formatInsurance = (insuranceName, memberNumber) => {
      const ins = []
      insuranceName && ins.push(insuranceName)
      memberNumber && ins.push(`ID: ${memberNumber}`)

      if (ins.length > 0) {
        return ins.join("\n")
      }
    }

    const details = (
      <>
        {detail(
          "Primary Insurance",
          formatInsurance(
            emrCachedPatient.primaryInsuranceName,
            emrCachedPatient.primaryInsuranceMemberNumber
          )
        )}
        {detail(
          "Secondary Insurance",
          formatInsurance(
            emrCachedPatient.secondaryInsuranceName,
            emrCachedPatient.secondaryInsuranceMemberNumber
          )
        )}
        {detail(
          "Tertiary Insurance",
          formatInsurance(
            emrCachedPatient.tertiaryInsuranceName,
            emrCachedPatient.tertiaryInsuranceMemberNumber
          )
        )}
      </>
    )

    return dataSection("Insurance", details)
  }

  const lastEncounter = () => {
    if (!encounter) {
      return
    }

    const details = (
      <>
        {detail("Visit Number", encounter.visitNumber)}
        {detail("Admission Date", encounter.admittedAt)}
        {detail("Discharge Date", encounter.dischargedAt)}
        {detail("Patient Status", capitalize(encounter.accountStatus))}
        {detail("Room Number", encounter.room)}
        {detail("Assigned Patient Location", encounter.assignedPatientLocation)}
      </>
    )

    const visitDxCodes = encounter.displayableDiagnoses
    const visitDx = () => {
      return (
        <>
          <strong className="d-inline-block mb-1" id="visit-dx">
            Encounter ICD10 Codes
          </strong>
          {icd10Codes(visitDxCodes)}
        </>
      )
    }

    return (
      <div className="bt bc-p5 py-3">
        {sectionHeader("Last Encounter")}
        <div className="row">{details}</div>
        {visitDxCodes.length > 0 && (
          <div className="row">
            <div className="col-md-12 mt-2" aria-labelledby="visit-dx">
              {visitDx()}
            </div>
          </div>
        )}
      </div>
    )
  }

  const icd10Codes = (codes) => (
    <ul>
      {codes.map((code) => {
        const sliceIndex = code.indexOf(" ")
        const icd10 = code.slice(0, sliceIndex)
        const description = code.slice(sliceIndex)
        return (
          <li key={icd10}>
            <span>
              <strong>{icd10}</strong>
              <span> - {description}</span>
            </span>
          </li>
        )
      })}
    </ul>
  )

  const clinicalData = () => {
    const problemList = () => {
      return (
        <>
          <strong className="d-inline-block mb-1" id="problem-list">
            Problem List ICD10 Codes
          </strong>
          {icd10Codes(problemListCodes)}
        </>
      )
    }

    const details = (
      <>
        {detail("Weight (lbs)", emrCachedPatient.weightLbs)}
        {detail("Height (inches)", emrCachedPatient.heightInches)}
        {emrCachedPatient.observations &&
          emrCachedPatient.observations.map((observation) => {
            return observationDetail(
              observation.name,
              observation.value,
              observation.effectiveAt
            )
          })}
      </>
    )

    const notes = emrCachedPatient.notes

    const problemListCodes = emrCachedPatient.displayableDiagnoses

    if (
      !emrCachedPatient.weightLbs &&
      !emrCachedPatient.heightInches &&
      emrCachedPatient.observations.length < 1 &&
      !notes &&
      problemListCodes.length < 1
    ) {
      return
    }
    return (
      <div className="bt bc-p5 py-3">
        {sectionHeader("Clinical Data")}
        <div className="row">{details}</div>
        {problemListCodes.length > 0 && (
          <div className="row">
            <div className="col-md-12 mt-2" aria-labelledby="problem-list">
              {problemList()}
            </div>
          </div>
        )}
        {notes && (
          <div className="row">
            <div className="col-md-12 mt-2" aria-labelledby="notes">
              <strong id="notes">Notes:</strong>
              <p>{notes}</p>
            </div>
          </div>
        )}
      </div>
    )
  }

  const careProviders = () => {
    if (!emrCachedPatient.doctors.length) {
      return null
    }

    const doctors = (
      <>
        {emrCachedPatient.doctors.map((doctor) => {
          let nameAndRelationship = doctor.name
          if (doctor.relationship) {
            nameAndRelationship += ` (${capitalize(doctor.relationship)})`
          }
          return detail(nameAndRelationship, `NPI: ${doctor.npi}`)
        })}
      </>
    )
    return (
      <div className="bt bc-p5 py-3">
        {sectionHeader("Care Providers")}
        <div className="row">{doctors}</div>
      </div>
    )
  }

  return (
    <Section
      className="well-tall"
      title={sectionTitle()}
      fullWidth
      actions={
        canCreateOrder && (
          <CreateOrderButton
            isPrimary={isPrimary}
            isBrand={false}
            createDmeOrder={() =>
              createDmeOrder(emrCachedPatient.patientId, encounter?.id)
            }
          />
        )
      }
    >
      {patientInformation()}
      {insurance()}
      {lastEncounter()}
      {clinicalData()}
      {careProviders()}
    </Section>
  )
}
