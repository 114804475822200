// @ts-strict-ignore
import React, { ReactNode } from "react"
import { Field, FieldValidator } from "formik"
import { blurOnEnter } from "utilities/browser/event"
import TextInput from "components/TextInput"
import SuperText from "components/SuperText"
import SubText from "components/SubText"
import { handleChange, handleBlur } from "../event-handlers"
import { TextFieldType } from "../sharedTypes"
import classNames from "classnames"
import { getFieldError } from "../utilities"
import ErrorText from "components/form/ErrorText"
import * as styles from "./index.module.scss"

type Props = {
  id?: string
  name: string
  label?: string | React.ReactElement
  supertext?: string | React.ReactElement
  subtext?: string | React.ReactElement
  submitOnBlur?: boolean
  placeholder?: string
  autoComplete?: string
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  mask?: (string | RegExp)[]
  inline?: boolean
  numeric?: boolean
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void
  onBlur?(event: React.FormEvent): void
  onPaste?(event: React.ChangeEvent<HTMLInputElement>): void
  onConfirm?(event: React.FormEvent): void
  onClear?(): void
  buttonId?: string
  disabled?: boolean
  type?: TextFieldType
  className?: string
  unwrapped?: boolean
  validate?: FieldValidator
  autoFocus?: boolean
  isClearable?: boolean
  renderCustomError?: (error) => ReactNode
  isRequired?: boolean
}

function TextField({
  disabled,
  label,
  supertext,
  subtext,
  id,
  name,
  className,
  placeholder,
  autoComplete,
  prefix,
  suffix,
  mask,
  inline,
  submitOnBlur,
  numeric,
  onChange,
  onBlur,
  onPaste,
  onConfirm,
  onClear,
  buttonId,
  type,
  unwrapped,
  validate,
  autoFocus,
  isClearable,
  renderCustomError,
  isRequired,
}: Props) {
  const renderError = (error) => <ErrorText error={error} />
  const renderInputGroup = (input) => {
    if (!prefix && !suffix) {
      return (
        <>
          <SuperText content={supertext} />
          {input}
          <SubText content={subtext} />
        </>
      )
    }
    return (
      <>
        <SuperText content={supertext} />
        <div className="input-group">
          {prefix && (
            <span className="input-group-text input-group-prepend">
              {prefix}
            </span>
          )}
          {input}
          {suffix && (
            <span className="input-group-text input-group-append">
              {suffix}
            </span>
          )}
        </div>
        <SubText content={subtext} />
      </>
    )
  }
  const renderRegularInput = ({ input, error }, isRequired) => {
    return (
      <div
        className={classNames({ "form-group": !unwrapped, "has-error": error })}
      >
        {label && renderLabel("col-form-label", isRequired)}
        {renderInputGroup(input)}

        {renderCustomError ? renderCustomError(error) : renderError(error)}
      </div>
    )
  }
  const renderInlineInput = ({ input, error }, isRequired) => {
    return (
      <div
        className={classNames("row gutter-0", {
          "form-group": !unwrapped,
          "has-error": error,
        })}
      >
        {renderLabel("col-3 col-form-label inline", isRequired)}
        <div className="col-9">
          {renderInputGroup(input)}
          {renderError(error)}
        </div>
      </div>
    )
  }

  const renderLabel = (classNames, isRequired) => {
    if (isRequired) {
      classNames += ` ${styles.required}`
    }
    return (
      <label className={classNames} htmlFor={name}>
        {label}
      </label>
    )
  }

  return (
    <Field name={name} validate={validate}>
      {({ field, form }) => {
        const { errors, touched } = form
        const error = getFieldError(errors, touched, name)
        const handleClear = () => {
          form.setFieldValue(name, "")
          onClear && onClear()
        }

        let inputProps = {
          ...field,
          placeholder,
          autoComplete,
          disabled,
          type: type ? type : "text",
          id: id || name,
          className: classNames("form-control", className, {
            "mb-0": subtext || error,
          }),
          onKeyUp: blurOnEnter,
          onChange: handleChange({ field, onChange }),
          onBlur: handleBlur({ form, field, onBlur, submitOnBlur }),
          onPaste,
          mask,
          onConfirm,
          buttonId,
          autoFocus,
          onClear: isClearable && handleClear,
        }

        if (numeric) {
          inputProps = { ...inputProps, inputMode: "numeric" }
        }

        const input = <TextInput {...inputProps} />
        return inline
          ? renderInlineInput({ input, error }, isRequired)
          : renderRegularInput({ input, error }, isRequired)
      }}
    </Field>
  )
}

export default TextField
