import { CanopyButton } from "@parachutehealth/canopy-button"
import React from "react"

interface Props {
  href?: string
  onClick: () => void
}

const NewOrderButton: React.FC<Props> = ({ href, onClick }) => (
  <>
    <CanopyButton
      accessibleText="New order"
      className="d-flex d-md-none"
      href={href}
      onClick={onClick}
      iconStart="plus"
      size="small"
      variant="primary"
    />
    <CanopyButton
      className="d-none d-md-flex"
      href={href}
      onClick={onClick}
      iconStart="plus"
      size="small"
      variant="primary"
    >
      New order
    </CanopyButton>
  </>
)

export default NewOrderButton
