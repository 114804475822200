// @ts-strict-ignore
import { EmploymentMention } from "../../sharedTypes"
import { tokenize } from "utilities/commentLexer"
import React from "react"
import { splitLines, truncate } from "../../utilities/string"
import { Mention } from "./Mention"
import { Link } from "./Link"

function findMentionById(employmentMentions, value) {
  return employmentMentions.find(({ id }) => value === id)
}

const UNKNOWN_RECORD = {
  email: "",
  name: "Unknown",
}

type Props = {
  event: {
    rawContent: string
    data: { employmentMentions?: EmploymentMention[] }
  }
  maxLength?: number
  disableTooltip?: boolean
  colorCoded?: boolean
}

export const PlainTextEventContent = ({ text }: { text: string }) => {
  const lines = splitLines(text)
  return (
    <span className="message-text">
      {lines.map((line, index) => {
        return (
          <span key={`${line}-${index}`}>
            {index > 0 && <br />}
            {line}
          </span>
        )
      })}
    </span>
  )
}

export const EventRawContent = ({
  event,
  maxLength,
  disableTooltip = false,
  colorCoded = true,
}: Props) => {
  const { rawContent, data } = event
  let totalCharacters = 0
  return (
    <>
      {tokenize(rawContent).map((node, index) => {
        const key = node.type + index
        if (node.type === "mention") {
          const employmentMention =
            findMentionById(data.employmentMentions, node.value) ||
            UNKNOWN_RECORD
          const textLength = employmentMention.name.length + 1
          totalCharacters += textLength
          return !maxLength || totalCharacters <= maxLength ? (
            <span key={key}>
              {" "}
              <Mention
                {...employmentMention}
                eventInformation={event}
                disableTooltip={disableTooltip}
                colorCoded={colorCoded}
              />
            </span>
          ) : null
        }
        if (node.type === "link") {
          const key = node.type + index
          const [title, url] = node.value.split("|")
          const textLength = title.length + 1
          totalCharacters += textLength
          return !maxLength || totalCharacters <= maxLength ? (
            <span key={key}>
              <Link url={url} title={title} />
            </span>
          ) : null
        }
        if (!maxLength)
          return (
            <span key={key}>
              <PlainTextEventContent text={node.value} />
            </span>
          )

        const remainingCharacters = maxLength - totalCharacters
        const truncatedContent = truncate(node.value, remainingCharacters)
        totalCharacters += node.value.length
        return remainingCharacters > 0 ? (
          <span key={key}>
            <PlainTextEventContent text={truncatedContent} />
          </span>
        ) : null
      })}
    </>
  )
}
