import { CatalogSearchType } from "sharedTypes"
import React from "react"
import { PackageSku } from "../sharedTypes"
import SidebarAwareContainer from "./SideBarAwareContainer"
import SkuQuickAdd from "./SkuQuickAdd"
import PackageFilter from "./PackageFilter"
import QuickListButton from "./QuickListButton"

interface Props {
  canFilterByFormulary: boolean
  catalogSearchType: CatalogSearchType
  filterQuery: string
  formularyPriceEnabled: boolean
  supplierIdParam: string
  searchPackagesWithSku(query: string): Promise<{ data: PackageSku[] }>
  selectPackageWithSku(s: PackageSku): void
  updateFilterQuery(query: string): void
}

const SingleSearch: React.FC<Props> = (props) => {
  const {
    canFilterByFormulary,
    catalogSearchType,
    filterQuery,
    formularyPriceEnabled,
    supplierIdParam,
    searchPackagesWithSku,
    selectPackageWithSku,
    updateFilterQuery,
  } = props

  return (
    <div className="mb-3">
      <SidebarAwareContainer role="searchLabel">
        <label>Search</label>
      </SidebarAwareContainer>
      {catalogSearchType === CatalogSearchType.SkuQuickAdd ? (
        <SkuQuickAdd
          searchPackagesWithSku={searchPackagesWithSku}
          selectPackageWithSku={selectPackageWithSku}
          formularyPriceEnabled={formularyPriceEnabled}
        />
      ) : catalogSearchType === CatalogSearchType.PackageFilter ? (
        <PackageFilter
          updateFilterQuery={updateFilterQuery}
          filterQuery={filterQuery}
        />
      ) : null}
      <QuickListButton
        canFilterByFormulary={canFilterByFormulary}
        supplierIdParam={supplierIdParam}
      />
    </div>
  )
}

export default SingleSearch
