/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react"
import DocumentDashboard from "components/DocumentDashboard/DocumentDashboard"
import LeftSidebar, {
  Option,
} from "components/SupplierOrganizationDashboard/LeftSidebar"
import {
  Supplier,
  SupplierList,
} from "components/SupplierOrganizationDashboard/SupplierList"
import classNames from "classnames"
import { getIdsOfSuppliersWithUnworkedDocuments } from "./api"
import { withBrowserRouter } from "routers/BrowserRouter"
import { useHistory, Route } from "react-router-dom"
import { employerPrefix } from "../../utilities/url"

type Props = {
  supplierOrganizationId: string
  suppliers: Supplier[]
  initialSupplierId: string
  suppliersWithUnworkedIds: string[]
}

const SupplierOrganizationDocumentDashboard = ({
  supplierOrganizationId,
  suppliers,
  initialSupplierId,
  suppliersWithUnworkedIds,
}: Props) => {
  const history = useHistory()
  const [highlightedSupplierIds, setHighlightedSupplierIds] = useState(
    suppliersWithUnworkedIds
  )

  const showSupplierList = suppliers.length > 1

  const inboxUrlBase = `${employerPrefix(
    "SupplierOrganization",
    supplierOrganizationId
  )}/inbox`

  const selectSupplier = (supplierId) => {
    history.push(`${inboxUrlBase}/${supplierId}`)
  }

  useEffect(() => {
    if (initialSupplierId) selectSupplier(initialSupplierId)
  }, [initialSupplierId])

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        getIdsOfSuppliersWithUnworkedDocuments(supplierOrganizationId).then(
          setHighlightedSupplierIds
        ),
      110000
    )
    return () => clearInterval(intervalId)
  }, [])

  return (
    <Route
      path={`${inboxUrlBase}/:supplierId?`}
      render={({ match }) => {
        return (
          <div className="position-relative">
            <LeftSidebar active={Option.InboundDocuments} />
            <div className="row mt-n3">
              <div className="mt-4 col-12">
                <h3>
                  {" "}
                  <span className="bold">Inbound Documents</span>
                </h3>
                <div className="row mt-3">
                  {showSupplierList && (
                    <div className="col-3 col-lg-2">
                      <SupplierList
                        suppliers={suppliers}
                        selectedSupplierId={match.params.supplierId}
                        selectSupplier={selectSupplier}
                        highlightedSupplierIds={highlightedSupplierIds}
                      />
                    </div>
                  )}
                  {match.params.supplierId && (
                    <div
                      className={classNames({
                        "col-9 col-lg-10": showSupplierList,
                        "col-12 col-lg-12": !showSupplierList,
                      })}
                    >
                      <DocumentDashboard
                        key={match.params.supplierId}
                        supplierId={match.params.supplierId}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default withBrowserRouter(SupplierOrganizationDocumentDashboard, {})
