import React from "react"
import { EventAction } from "sharedTypes"

type Props = {
  action: EventAction
  onClick(): void
}

const NewCommentMessage = ({ action, onClick }: Props) => {
  const text =
    action === EventAction.InternalComment
      ? "New Internal Comment"
      : "New Global Comment"
  return (
    <div className="text-right">
      <a onClick={onClick} className="link link-accent">
        <small>
          <span className="mr-1">•</span>
          {text}
        </small>
      </a>
    </div>
  )
}

export default NewCommentMessage
