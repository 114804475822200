// Client that loads the SDK library and instantiates the AdobeDC View object.
// Ensures the library is only loaded once and wraps the window.AdobeDC.View object.
// Based on https://github.com/adobe/pdf-embed-api-samples, specifically:
// https://github.com/adobe/pdf-embed-api-samples/blob/master/More%20Samples/Angular%20Samples/src/app/samples/view-sdk.service.ts
// https://github.com/adobe/pdf-embed-api-samples/blob/master/More%20Samples/React%20Samples/src/samples/ViewSDKClient.js

export type SaveDocumentCallback = (data: ArrayBuffer) => Promise<void>

export class viewSDKClient {
  readyPromise: Promise<any> = new Promise<void>((resolve, reject) => {
    // @ts-ignore
    if (window.AdobeDC) {
      resolve()
    } else {
      const loadScript = () => {
        const SCRIPT_SRC =
          "https://acrobatservices.adobe.com/view-sdk/viewer.js"
        return new Promise(function (resolve, reject) {
          const script = document.createElement("script")
          script.src = SCRIPT_SRC
          script.onload = resolve
          script.onerror = reject
          script.id = "adobe-pdf-viewer-sdk"
          document.head.appendChild(script)
        })
      }

      /* Wait for Adobe Acrobat Services PDF Embed API to be ready */
      loadScript()
        .then(() => {
          document.addEventListener("adobe_dc_view_sdk.ready", () => {
            resolve()
          })
        })
        .catch(reject)
    }
  })
  adobeDCView: any

  ready() {
    return this.readyPromise
  }

  previewFile({
    divId,
    url,
    saveDocument,
  }: {
    divId: string
    url: string
    saveDocument: SaveDocumentCallback
  }): Promise<void> {
    const viewConfig = {
      clientId: window.adobeApiKey,
      divId: divId,
      sendAutoPDFAnalytics: false,
    }
    // https://developer.adobe.com/document-services/docs/overview/pdf-embed-api/howtos_ui/#menu-and-tool-options
    const viewOptions = {
      embedMode: "FULL_WINDOW",
      defaultViewMode: "FIT_WIDTH",
      enableFormFilling: true,
      showDownloadPDF: false,
      showZoomControl: true,
      showAnnotationTools: false,
      showPrintPdf: false,
      showThumbnails: false,
      showBookmarks: false,
      showFullScreenViewButton: false,
      showFullScreen: false,
      focusOnRendering: true,
      showDisabledSaveButton: false,
    }

    // @ts-ignore
    this.adobeDCView = new window.AdobeDC.View(viewConfig)
    // @ts-ignore
    const viewEnum = window.AdobeDC.View.Enum

    const saveOptions = {
      autoSaveFrequency: 1,
      enableFocusPolling: true,
      showSaveButton: false,
    }
    this.adobeDCView.registerCallback(
      viewEnum.CallbackType.SAVE_API,
      function (_metaData, content, _options) {
        return saveDocument(content.buffer)
          .then(() => {
            return {
              code: viewEnum.ApiResponseCode.SUCCESS,
              data: {
                metadata: {},
              },
            }
          })
          .catch(() => {
            return {
              code: viewEnum.ApiResponseCode.FAIL,
              data: {
                metaData: {},
              },
            }
          })
      },
      saveOptions
    )

    const aStringWithASpaceBecauseEmptyStringOrMissingParameterMakesTheSdkErrorOut =
      " "
    return this.adobeDCView.previewFile(
      {
        content: { location: { url: url } },
        metaData: {
          fileName: aStringWithASpaceBecauseEmptyStringOrMissingParameterMakesTheSdkErrorOut,
        },
      },
      viewOptions
    )
  }
}
