import React from "react"
import PatientChatIcon from "./PatientChatIcon"

const ChatUnavailable: React.FC = () => (
  <div className="h-75 px-4 d-flex flex-column justify-content-center align-items-center">
    <PatientChatIcon classes="d-block" fontSize="large" />
    <h2 className="pt-4">Chat Unavailable</h2>
    <p className="text-center">
      Patient Chat becomes available when the patient&apos;s primary phone
      number is set to a valid mobile number.
    </p>
  </div>
)

export default ChatUnavailable
