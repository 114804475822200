import React from "react"
import ClearAllLink from "components/ClinicalFilters/ClearAllLink"
import PatientSelect from "components/ClinicalFilters/PatientSelect"
import FollowerSelect from "components/ClinicalFilters/FollowerSelect"
import SupplierSelect from "components/ClinicalFilters/SupplierSelect"
import ClinicianSelect from "components/ClinicalFilters/ClinicianSelect"
import DateRangeFilter from "components/ClinicalFilters/DateRangeFilter"
import SortBySelect from "./SortBySelect"
import { OriginatorToggle } from "./OriginatorToggle"
import { Doctor, Follower } from "sharedTypes"
import { Supplier } from "components/ClinicalFilters/sharedTypes"
import { isEmpty } from "utilities/object"
import { EventCategory, trackEvent } from "utilities/tracking"

interface Props {
  loading?: boolean
  initialQuery: string
  setInitialQuery(query: string): void
  followers: Follower[]
  searchFollowers(term: string): Promise<Follower[]>
  suppliers: Supplier[]
  searchSuppliers(term: string): Promise<Supplier[]>
  initialSupplierIds: string[]
  setInitialSupplierIds(supplierIds: string[]): void
  initialOriginator: string
  setInitialOriginator(originator: string): void
  initialFollowerIds: string[]
  setInitialFollowerIds(followerIds: string[]): void
  initialDeliveryDateStart?: string
  setInitialDeliveryDateStart(string): void
  initialDeliveryDateEnd?: string
  setInitialDeliveryDateEnd(string): void
  initialSort: string
  setInitialSort(sort: string): void
  doctors: { doctorId: string; firstName: string; lastName: string }[]
  searchDoctors(term: string): Promise<Doctor[]>
  initialDoctorIds: string[]
  setInitialDoctorIds(doctorIds: string[]): void
  onClear(): void
}

function Filters({
  loading,
  initialQuery,
  setInitialQuery,
  followers,
  searchFollowers,
  suppliers,
  searchSuppliers,
  initialSupplierIds,
  setInitialSupplierIds,
  initialOriginator,
  setInitialOriginator,
  initialSort,
  setInitialSort,
  initialFollowerIds,
  setInitialFollowerIds,
  initialDeliveryDateStart,
  setInitialDeliveryDateStart,
  initialDeliveryDateEnd,
  setInitialDeliveryDateEnd,
  doctors,
  searchDoctors,
  initialDoctorIds,
  setInitialDoctorIds,
  onClear,
}: Props) {
  const showClearAllLink =
    initialQuery ||
    initialOriginator ||
    initialDeliveryDateStart ||
    initialDeliveryDateEnd ||
    !isEmpty(initialFollowerIds) ||
    !isEmpty(initialSupplierIds) ||
    !isEmpty(initialDoctorIds)

  const fetchFollowers = async (
    term: string
  ): Promise<{ label: string; value: string }[]> => {
    const followers = await searchFollowers(term)
    return followers.map((follower) => ({
      label: follower.name,
      value: follower.id,
    }))
  }

  const handleFollowerFilterChange = (values: { followerIds: string[] }) => {
    const { followerIds } = values
    setInitialFollowerIds(followerIds)
    if (!isEmpty(followerIds))
      trackEvent(
        EventCategory.ClinicalFacilityDashboard,
        "follower_filter_selected"
      )
  }

  const fetchClinicians = async (
    term: string
  ): Promise<{ label: string; value: string }[]> => {
    const doctors = await searchDoctors(term)
    return doctors.map((doctor) => ({
      label: `${doctor.firstName} ${doctor.lastName}`,
      value: doctor.doctorId,
    }))
  }

  const handleClinicianFilterChange = (values: { doctorIds: string[] }) => {
    const { doctorIds } = values
    setInitialDoctorIds(doctorIds)
    if (!isEmpty(doctorIds))
      trackEvent(
        EventCategory.ClinicalFacilityDashboard,
        "clinician_filter_selected"
      )
  }

  function handleDeliveryDateFilterChange(values) {
    const { deliveryDateStartAt, deliveryDateEndAt } = values
    setInitialDeliveryDateStart(deliveryDateStartAt)
    setInitialDeliveryDateEnd(deliveryDateEndAt)
    if (deliveryDateStartAt || deliveryDateEndAt) {
      void trackEvent(
        EventCategory.ClinicalFacilityDashboard,
        "requested_delivery_date_filter_enabled"
      )
    }
  }

  const handleSupplierSelectOnChange = (values) => {
    setInitialSupplierIds(values.supplierIds)
    if (!isEmpty(values.supplierIds))
      trackEvent(
        EventCategory.ClinicalFacilityDashboard,
        "supplier_filter_selected"
      )
  }

  const handleSupplierSelectFetchOptions = async (searchTerm) => {
    const suppliers = await searchSuppliers(searchTerm)
    return suppliers.map((supplier) => ({
      label: supplier.name,
      value: supplier.externalId,
    }))
  }

  const handlePatientSelectChange = (query: string) => {
    setInitialQuery(query)
    if (query) {
      void trackEvent(
        EventCategory.ClinicalFacilityDashboard,
        "patient_select_filter_enabled"
      )
    }
  }

  return (
    <div className="clearfix">
      <div className="float-left">
        <div className="d-inline-block v-align-top">
          <PatientSelect
            loading={loading}
            initialQuery={initialQuery}
            onChange={handlePatientSelectChange}
          />
        </div>
        <div className="d-inline-block v-align-top">
          <ClinicianSelect
            fetchClinicians={fetchClinicians}
            onChange={handleClinicianFilterChange}
            doctors={doctors}
            initialDoctorIds={initialDoctorIds}
          />
        </div>
        <div className="d-inline-block v-align-top ">
          <FollowerSelect
            fetchFollowers={fetchFollowers}
            onChange={handleFollowerFilterChange}
            followers={followers}
            initialFollowerIds={initialFollowerIds}
          />
        </div>
        <div className="d-inline-block v-align-top">
          <SupplierSelect
            fetchOptions={handleSupplierSelectFetchOptions}
            suppliers={suppliers}
            initialSupplierIds={initialSupplierIds}
            onChange={handleSupplierSelectOnChange}
          />
        </div>
        <div className="d-inline-block v-align-top">
          <DateRangeFilter
            initialDeliveryDateStart={initialDeliveryDateStart}
            initialDeliveryDateEnd={initialDeliveryDateEnd}
            onChange={handleDeliveryDateFilterChange}
          />
        </div>
        <div className="d-inline-block v-align-top">
          <OriginatorToggle
            initialOriginator={initialOriginator}
            setInitialOriginator={setInitialOriginator}
          />
        </div>
      </div>
      {showClearAllLink && (
        <div className="d-inline-block v-align-top mx-2 float-left">
          <ClearAllLink onClick={onClear} />
        </div>
      )}
      <div className="d-inline-block v-align-top float-right">
        <SortBySelect
          initialSort={initialSort}
          setInitialSort={setInitialSort}
        />
      </div>
    </div>
  )
}

export default Filters
