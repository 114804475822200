import React from "react"
import networkImage from "network-illustration.png"

const AddPrompt = () => {
  return (
    <div className="well well-expand">
      <div className="text-center">
        <img src={networkImage} />
      </div>
      <div className="text-center">
        <div className="w-400 mx-auto">
          <em>
            Add clinicians your facility commonly works with by searching their
            name above.
          </em>
        </div>
      </div>
    </div>
  )
}

export default AddPrompt
