import React, { ChangeEvent, useState } from "react"
import { Form } from "components/form"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import classNames from "classnames"
import * as styles from "../index.module.scss"

type Props = {
  title: string
  name: string
  disabled: boolean
  onMemberIdChange(event: ChangeEvent<HTMLInputElement>): void
  onSave(): void
  onClear(): void
  errorFromReviewPage: boolean
  value: string
}

function InsuranceMemberNumberInput({
  title,
  name,
  disabled,
  onMemberIdChange,
  onSave,
  onClear,
  errorFromReviewPage,
  value,
}: Props) {
  const [saveButtonIsVisible, setSaveButtonIsVisible] = useState(false)
  const [clearButtonIsVisible, setClearButtonIsVisible] = useState(
    value ? true : false
  )

  const validate = (values) => {
    if (!values[name] || errorFromReviewPage) {
      return { [name]: "Please provide a Member ID" }
    }
  }

  const handleSubmit = () => {
    onSave()
    setSaveButtonIsVisible(false)
    setClearButtonIsVisible(true)
  }

  const handleReset = () => {
    onClear()
    setClearButtonIsVisible(false)
  }

  return (
    <Form
      initialValues={{ [name]: value }}
      validate={validate}
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      {({ errors, touched, handleBlur, handleChange }) => {
        const memberIdError = touched[name] ? errors[name] : undefined

        const handleInputChange = (e) => {
          handleChange(e)
          onMemberIdChange(e)
          setClearButtonIsVisible(false)
          setSaveButtonIsVisible(e.target.value ? true : false)
        }

        const buttonClassNames = classNames("canopy-ml-8x", {
          [styles.buttonCenter]: memberIdError,
        })

        return (
          <div className={styles.insuranceMemberNumberInput}>
            <CanopyTextInputField
              className={styles.textField}
              label={title}
              name={name}
              onChange={handleInputChange}
              onBlur={handleBlur}
              disabled={disabled}
              id={name}
              value={value}
              feedbackMessage={memberIdError}
              feedbackMessageStatus={memberIdError ? "error" : "success"}
            />
            {saveButtonIsVisible && (
              <CanopyButton className={buttonClassNames} type="submit">
                Save
              </CanopyButton>
            )}
            {clearButtonIsVisible && (
              <CanopyButton
                className={buttonClassNames}
                type="reset"
                variant="secondary"
              >
                Clear
              </CanopyButton>
            )}
          </div>
        )
      }}
    </Form>
  )
}

export default InsuranceMemberNumberInput
