// @ts-strict-ignore
import React, { useState } from "react"
import { Doctor } from "sharedTypes"
import Confirm from "components/Confirm"
import { doctorNameWithCredential } from "utilities/doctor"
import EmailModal from "./EmailModal"
import MobileNumberModal from "./MobileNumberModal"
import Icon from "components/Icon"

type Props = {
  doctor: Doctor
  updateDoctor(doctor: Doctor, params): Promise<void>
  removeDoctor(doctor: Doctor): Promise<void>
}

const DoctorCard = ({ doctor, updateDoctor, removeDoctor }: Props) => {
  const [showMobileModal, toggleMobileModal] = useState(false)
  const [showEmailModal, toggleEmailModal] = useState(false)

  const signedCount = Object.values(doctor.cacheSignaturesCounts).reduce(
    (a, b) => a + b,
    0
  )

  return (
    <div className="well">
      <Confirm
        title="Are you sure?"
        confirmText="Yes, remove"
        cancelText="No, cancel"
        render={(confirm) => {
          return (
            <a
              className="float-right link-basic remove-doctor"
              onClick={confirm(() => removeDoctor(doctor))}
            >
              {"\u2715"}
            </a>
          )
        }}
      />
      <h4>{doctorNameWithCredential(doctor)}</h4>
      <div className="color-dark-gray">NPI: {doctor.npi}</div>

      <div className="my-4">
        <div className="row gutter-10 mb-2">
          <div className="col-1 text-center">
            <Icon
              prefix="far"
              type="envelope"
              className="font-m color-mid-gray bold"
            />
          </div>
          <div className="col-11 truncate">
            {doctor.maskedEmail ? (
              doctor.maskedEmail
            ) : (
              <a
                className="link"
                onClick={() => {
                  toggleEmailModal(true)
                }}
              >
                +Add Email Address
              </a>
            )}
          </div>
        </div>
        <div className="row gutter-10">
          <div className="col-1 text-center">
            <Icon type="mobile" className="font-l color-mid-gray bold" />
          </div>
          <div className="col-11 truncate">
            {doctor.maskedMobileNumber ? (
              doctor.maskedMobileNumber
            ) : (
              <a
                className="link"
                onClick={() => {
                  toggleMobileModal(true)
                }}
              >
                +Add Mobile Number
              </a>
            )}
          </div>
        </div>
      </div>
      <div>
        <span className="color-mid-gray">Orders Signed</span>
        <span className="float-right">{signedCount}</span>
      </div>

      <EmailModal
        doctor={doctor}
        updateDoctor={updateDoctor}
        close={() => {
          toggleEmailModal(false)
        }}
        show={showEmailModal}
      />
      <MobileNumberModal
        doctor={doctor}
        updateDoctor={updateDoctor}
        close={() => {
          toggleMobileModal(false)
        }}
        show={showMobileModal}
      />
    </div>
  )
}

export default DoctorCard
