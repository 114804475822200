import React, { useEffect, useState } from "react"
import Modal from "components/Modal"
import Icon from "components/Icon"
import EmailForm from "../PushbackSignUpModal/EmailForm"
import * as api from "../PushbackSignUpModal/api"
import withInitialData from "components/withInitialData"
import * as styles from "./index.module.scss"
import { EventCategory, trackEvent } from "utilities/tracking"
import VerifyEmailModal from "components/VerifyEmailModal"

type InitialData = {
  initialEmail: string
  currentUserEmail: string
}

type Props = {
  show: boolean
  dismiss(): void
  initialData: InitialData
}

const onSubmit = async (email) => {
  api.updateEmail(email)
  trackEvent(
    EventCategory.PushbackSignUp,
    "sign_up_notifications_email_entered_pushback"
  )
}

const EmailEntryPage = ({ initialData, setSubmittedEmail }) => {
  return (
    <div className={styles.modalBody}>
      <Icon type="bell" className="font-xxl" />
      <h1 className={styles.modalTitle}>
        Want to receive order notifications?
      </h1>
      <h2 className={styles.modalSubtitle}>
        Create a Parachute Health account!
      </h2>
      <div className={styles.emailForm}>
        <EmailForm
          setSubmittedEmail={setSubmittedEmail}
          onSubmit={onSubmit}
          initialEmail={initialData.initialEmail}
          submitText="Sign up for free"
        />
      </div>
      <a
        className="link d-block mt-5 color-secondary"
        href="https://www.parachutehealth.com"
        target="_blank"
        rel="noreferrer"
      >
        <p>Learn more about Parachute Health</p>
      </a>
    </div>
  )
}

const NotificationsSignUpModal = ({ show, dismiss, initialData }: Props) => {
  const { currentUserEmail } = initialData

  const [submittedEmail, setSubmittedEmail] = useState<string | null>(
    currentUserEmail
  )

  useEffect(() => {
    if (currentUserEmail) {
      void trackEvent(
        EventCategory.PushbackSignUp,
        "notifications_button_clicked_pushback_invite_resent"
      )

      void api.sendPushbackInvitation()
    }
  }, [currentUserEmail])

  return (
    <>
      {submittedEmail ? (
        <VerifyEmailModal
          email={submittedEmail}
          dismiss={dismiss}
          show={show}
        />
      ) : (
        <Modal closeable size="lg" show={show}>
          <Modal.Body>
            <button
              className="close icon clickable"
              type="button"
              onClick={dismiss}
            >
              ×
            </button>
            <EmailEntryPage
              initialData={initialData}
              setSubmittedEmail={setSubmittedEmail}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

const fetchInitialData = () => api.getUserEmail().then(({ data }) => data)
export default withInitialData(
  fetchInitialData,
  () => null
)(NotificationsSignUpModal)
