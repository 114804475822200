import React, { useState } from "react"
import { Form, TextField, Button, TimezoneSelect } from "components/form"
import { updateContactInformation } from "../../api"
import { handleError } from "utilities/error"

type Props = {
  firstName: string
  lastName: string
  timeZone: string
  onSuccess: () => void
}

type FormValues = {
  firstName: string
  lastName: string
  timeZone: string
}

const NameAndLocationForm = ({
  firstName,
  lastName,
  timeZone,
  onSuccess,
}: Props) => {
  const [initialValues, setInitialValues] = useState({
    firstName,
    lastName,
    timeZone,
  })

  const validate = ({ firstName, lastName }) => {
    const errors = {} as FormValues
    if (!firstName) {
      errors.firstName = "can't be blank"
    }
    if (!lastName) {
      errors.lastName = "can't be blank"
    }
    return errors
  }

  const onSubmit = (values: FormValues) =>
    updateContactInformation(values)
      .then(() => {
        onSuccess()
        setInitialValues(values)
      })
      .catch(handleError)

  return (
    <div className="row mb-5">
      <div className="col-sm-2">
        <h3>Name and Location</h3>
      </div>
      <div className="col-sm-6">
        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ dirty, isValid, isSubmitting }) => (
            <>
              <div className="row">
                <div className="col-sm-6">
                  <TextField name="firstName" label="First Name" />
                </div>
                <div className="col-sm-6">
                  <TextField name="lastName" label="Last Name" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <TimezoneSelect name="timeZone" label="Time Zone" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <Button
                    className="btn-brand"
                    type="submit"
                    disabled={!dirty || !isValid || isSubmitting}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  )
}

export default NameAndLocationForm
