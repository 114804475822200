import React, { useContext } from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import ConsignmentClosetsPage from "./components/ConsignmentClosetsPage"
import ConsignmentClosetDetailsPage from "./components/ConsignmentClosetDetailsPage"
import { withBrowserRouter } from "routers/BrowserRouter"
import { withMaterialTheme } from "themes/theme"
import {
  NoticeContext,
  withNoticeProvider,
} from "applications/Cms/contexts/NoticeContext"

const ConsignmentClosetsWorkflow: React.FC = () => {
  const history = useHistory()
  const { clearNotice } = useContext(NoticeContext)

  React.useEffect(() => {
    clearNotice()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  return (
    <Switch>
      <Route path="/cms/catalog/consignment_closets" exact>
        <ConsignmentClosetsPage />
      </Route>
      <Route
        path="/cms/catalog/consignment_closets/:consignmentClosetsId"
        exact
      >
        <ConsignmentClosetDetailsPage />
      </Route>
    </Switch>
  )
}

export default withBrowserRouter(
  withNoticeProvider(withMaterialTheme(ConsignmentClosetsWorkflow)),
  {}
)
