// @ts-strict-ignore
import React from "react"

export const highlight = (source: string, query: string) => {
  if (!query) {
    return <b>{source}</b>
  }

  const nodes = []
  const lines = source.split("\n")
  lines.forEach((line, lineIndex) => {
    let rest = line
    while (rest.length > 0) {
      const matchIndex = rest.toLowerCase().indexOf(query.toLowerCase())
      if (matchIndex === -1) {
        nodes.push(rest)
        break
      } else {
        nodes.push(rest.substring(0, matchIndex))
        nodes.push(
          <span
            className="regular mark p-0"
            key={`match-${lineIndex + matchIndex}`}
          >
            {rest.substring(matchIndex, matchIndex + query.length)}
          </span>
        )
        rest = rest.substring(matchIndex + query.length)
      }
    }
    if (lineIndex < lines.length - 1) {
      nodes.push(<br key={`br-${lineIndex}`} />)
    }
  })

  return <b>{nodes}</b>
}
