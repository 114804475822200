import React from "react"

interface Props {
  sortingBy: string
}

const SortDescription: React.FC<Props> = ({ sortingBy }) => {
  return (
    <div className="m-3 text-right font-xs">
      <strong>Sorting by: </strong>
      {sortingBy}
    </div>
  )
}

export default SortDescription
