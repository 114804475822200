import React from "react"
import { formatHeight } from "utilities/conversion"
import { formatPaymentMethod } from "utilities/payment"
import LineItemDescription from "components/LineItemDescription"
import QuantityForm from "./QuantityForm"
import { PackageConfiguration } from "../sharedTypes"
import { OfferingType } from "sharedTypes"
import { FormikValues } from "formik"
import classNames from "classnames"
import { trackClick } from "../../Navigation/components/TopNavigation/analytics"
import Confirm from "components/Confirm"
import FormularyUnitPrice from "../../Sidebar/containers/Cart/components/CartLineItemGroup/FormularyUnitPrice"
import { formularyUnitPrice } from "../../../utilities/formularyPrice"
import FormularySubtotal from "../../Sidebar/containers/Cart/components/CartLineItemGroup/FormularySubtotal"

type Props = {
  packageConfiguration: PackageConfiguration
  formularyPriceEnabled: boolean
  onEditDetails(): void
  onEditRequirements(): void
  onEditRxDetails(): void
  onEditConfiguration(): void
  remove(): void
  update(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  returnToBrowse(): void
  useFeetForHeight: boolean
  goToNextPage(values: FormikValues): void
}

const ConfigurationSectionTitles = {
  [OfferingType.Service]: "Services",
  [OfferingType.O2Recert]: "Related Offerings",
  [OfferingType.Equipment]: "Configuration",
}

function Summary(props: Props): React.ReactElement {
  const {
    packageConfiguration,
    formularyPriceEnabled,
    onEditDetails,
    onEditRequirements,
    onEditRxDetails,
    onEditConfiguration,
    returnToBrowse,
    remove,
    update,
    useFeetForHeight,
    goToNextPage,
  } = props
  const {
    dmeOrderLineItemGroup,
    requiresHeight,
    requiresWeight,
    requiresWoundCount,
    supportedPaymentMethods,
    heightInches,
    weightLbs,
    numberOfWounds,
    rxDetails,
    warnings,
  } = packageConfiguration

  const isService = packageConfiguration.offeringType === OfferingType.Service
  const isO2Recert = packageConfiguration.offeringType === OfferingType.O2Recert

  const configurationSectionTitle =
    ConfigurationSectionTitles[packageConfiguration.offeringType] ||
    "Configuration"

  const headerSection = () => {
    const packageNameHeader = (
      <>
        <h2 className="mt-0 mb-2">{packageConfiguration.packageName}</h2>
        {formularyPriceEnabled ? (
          <FormularyUnitPrice
            unitPrice={formularyUnitPrice(dmeOrderLineItemGroup)}
          />
        ) : null}
      </>
    )

    if (!packageConfiguration.simplePackage) {
      return packageNameHeader
    }

    return (
      <div className="row">
        <div className="col-md-10">{packageNameHeader}</div>
        <div className="col-md-2">
          {" "}
          <QuantityForm
            packageConfiguration={packageConfiguration}
            onSubmit={update}
          />
          <div className="mt-n2">
            {formularyPriceEnabled ? (
              <FormularySubtotal
                unitPrice={formularyUnitPrice(dmeOrderLineItemGroup)}
                quantity={dmeOrderLineItemGroup.quantity}
              />
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  const configurationSection = (
    <>
      <hr className="bg-lighter-gray mx-n4 mt-3" />
      <h3>{configurationSectionTitle}</h3>
      <div className="well well-expand">
        <button
          type="button"
          className="float-right ml-3 btn btn-sm btn-brand-o"
          onClick={onEditConfiguration}
        >
          Edit
        </button>
        Provided by {packageConfiguration?.supplier?.name}
        <ul>
          {dmeOrderLineItemGroup.lineItems.map((lineItem) => (
            <li key={lineItem.skuDescription}>
              <LineItemDescription lineItem={lineItem} />
            </li>
          ))}
        </ul>
      </div>
    </>
  )

  const requirementsSection = (
    <>
      <h3>Requirements</h3>
      <div className="well well-expand">
        <button
          type="button"
          className="float-right ml-3 btn btn-sm btn-brand-o"
          onClick={onEditRequirements}
        >
          Edit
        </button>
        <ul className="list-unstyled">
          {requiresHeight && (
            <li>
              Patient height: {formatHeight(useFeetForHeight, heightInches)}
            </li>
          )}
          {requiresWeight && <li>Patient weight: {weightLbs} lbs</li>}
          {requiresWoundCount && <li>Wound count: {numberOfWounds}</li>}
        </ul>
      </div>
    </>
  )
  const rxDetailsSection = isO2Recert ? (
    <div className={classNames("mt-4 ml-2", { "mb-4": !!warnings.length })}>
      <a className="link" onClick={onEditRxDetails}>
        View/Edit Document Details
      </a>
    </div>
  ) : (
    <>
      <h3>{isService ? "Service Details" : "Prescription Details"}</h3>
      <div className="well well-expand">
        <div className="row">
          <div className="col-9">
            {rxDetails.outputs.map((lineItemDetails) => (
              <li key={lineItemDetails.label}>
                {lineItemDetails.label}: {lineItemDetails.value}
              </li>
            ))}
          </div>
          {rxDetails.hasQuestions && (
            <div className="col-3">
              <button
                type="button"
                className="float-right ml-3 btn btn-sm btn-brand-o"
                onClick={onEditRxDetails}
              >
                Edit
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )

  const detailsSection = () => {
    if (isO2Recert) {
      return
    }
    const detailedItems = [
      packageConfiguration.requiresLengthOfNeed && (
        <li key="length-of-need">
          Length of need: {packageConfiguration.lengthOfNeedMonths}&nbsp;mo
        </li>
      ),
      !packageConfiguration.simplePackage && (
        <li key="quantity">Quantity: {packageConfiguration.quantity}</li>
      ),
      supportedPaymentMethods.length > 1 && (
        <li key="payment-method">
          Payment Method:{" "}
          {formatPaymentMethod(packageConfiguration.paymentMethod)}
        </li>
      ),
    ].filter((li) => li)
    if (detailedItems.length === 0) {
      return
    }

    return (
      <>
        <h3>Details</h3>
        <div className="well well-expand">
          <button
            type="button"
            className="float-right btn btn-sm btn-brand-o"
            onClick={onEditDetails}
          >
            Edit
          </button>
          <ul className="list-unstyled">{detailedItems.map((li) => li)}</ul>
        </div>
      </>
    )
  }

  const warningsSection = (
    <>
      <div className="well well-warning well-expand">
        Attention:
        {warnings.map((warning) => (
          <span key={warning}> {warning}</span>
        ))}
      </div>
    </>
  )

  const actionsSection = (
    <div className="mt-3">
      <div className="d-flex flex-column-reverse flex-md-row justify-content-between">
        <Confirm
          title={`Remove ${packageConfiguration.packageName} from order?`}
          cancelText="No, don't remove"
          confirmText="Yes, remove"
          render={(confirm) => (
            <button
              type="button"
              className="btn btn-link color-danger mb-2"
              onClick={confirm(remove)}
            >
              Remove
            </button>
          )}
        />
        <div className="d-flex flex-column-reverse flex-md-row">
          <button
            type="button"
            className="btn btn-brand-o mb-2 ml-2"
            onClick={returnToBrowse}
          >
            Add Items
          </button>
          <button
            className="btn btn-brand mb-2 ml-2"
            onClick={(e) => {
              trackClick("continue-button")
              goToNextPage(e)
            }}
          >
            Continue to Addresses & Contacts
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {headerSection()}
      {configurationSection}
      {(requiresHeight || requiresWeight || requiresWoundCount) &&
        requirementsSection}
      {(rxDetails.hasQuestions || rxDetails.outputs.length > 0) &&
        rxDetailsSection}
      {detailsSection()}
      {!!warnings.length && warningsSection}
      {actionsSection}
    </>
  )
}

export default Summary
