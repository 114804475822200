import React, { useRef, useState } from "react"
import { ReviewerEmployment } from "../../sharedTypes"
import Table, { TableColumnDefinition } from "components/Table"
import { Form, Button } from "components/form"
import Checkbox from "components/form/Checkbox"
import { Checkbox as MuiCheckbox } from "@material-ui/core"
import { updateSignatureRequestPreferences } from "../../api"
import { handleError } from "utilities/error"
import { useStyles } from "themes/theme"
import Tooltip from "components/Tooltip"
import Icon from "components/Icon"
import Alert from "components/Alert"
import { useFormikContext } from "formik"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"
import { useToaster } from "components/Toaster"
import { Link } from "@material-ui/core"
import { canopyTypographyFontWeightBold } from "@parachutehealth/canopy-tokens-typography"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { CanopyButton } from "@parachutehealth/canopy-button"
import useRemoveEmployment from "applications/ReviewerUserPreferences/hooks/useRemoveEmployment"

type Props = {
  reviewerEmployments: ReviewerEmployment[]
  reviewerEmailVerified: boolean
  reviewerCanReceiveEmrMessages: boolean
}

type FormValues = {
  [key: string]: { fax: boolean; email: boolean; emr: boolean }
}

export type TableRowEmployment = {
  key: string
  doctorName: string
  doctorNpi: string
  creationSource: string | undefined
  email: boolean
  fax: boolean
  emr: boolean
}

const muiStyles = () => ({
  checkbox: {
    padding: 0,
  },
  link: {
    fontWeight: canopyTypographyFontWeightBold,
  },
})

const SignatureRequestPreferences = ({
  reviewerEmployments,
  reviewerEmailVerified,
  reviewerCanReceiveEmrMessages,
}: Props) => {
  const [
    employmentIdsWithoutNotifications,
    setEmploymentIdsWithoutNotifications,
  ] = useState<string[] | []>(
    reviewerEmployments
      .filter(
        (employment) =>
          !employment.preferences.fax &&
          !employment.preferences.email &&
          !employment.preferences.emr
      )
      .map((employment) => employment.id)
  )
  const [showModal, setShowModal] = React.useState<boolean>(false)

  const { persistentAlert } = useToaster()

  const onSuccess = () => {
    persistentAlert({
      message: "Signature Request preferences saved.",
      severity: "success",
    })
  }

  const onSubmit = (formValues) => {
    const preferences: {
      employmentId: string
      email?: boolean
      fax?: boolean
      emr?: boolean
    }[] = []
    Object.keys(formValues).forEach((key) => {
      const employmentId = key
      preferences.push({
        employmentId,
        email: formValues[employmentId].email,
        emr: formValues[employmentId].emr,
      })
    })
    return updateSignatureRequestPreferences({ preferences })
      .then(() => {
        onSuccess()
        setInitialValues(formValues)
      })
      .catch(handleError)
  }

  const reviewerEmploymentsToTableData = reviewerEmployments.map(
    (employment): TableRowEmployment => ({
      key: employment.id,
      doctorName: employment.doctorName,
      doctorNpi: employment.doctorNpi,
      creationSource: employment.creationSource,
      email: employment.preferences.email,
      fax: employment.preferences.fax,
      emr: employment.preferences.emr,
    })
  )

  const initializeValues = () => {
    const values: FormValues = {}
    reviewerEmploymentsToTableData.forEach((employment) => {
      values[`${employment.key}`] = {
        email: employment.email,
        fax: employment.fax,
        emr: employment.emr,
      }
    })
    return values
  }

  const [initialValues, setInitialValues] = useState(initializeValues())

  const muiClasses = useStyles(muiStyles)

  const PreferenceCheckbox = ({
    name,
    label,
    disabled,
    value,
    onChange,
  }: {
    name: string
    label: string
    disabled?: boolean
    value: boolean
    onChange: (event) => void
  }) => (
    <>
      <div className="d-none">
        <Checkbox name={name} label={label} />
      </div>
      <MuiCheckbox
        size="small"
        color="primary"
        checked={value}
        className={muiClasses.checkbox}
        onChange={onChange}
        data-testid={name}
        disabled={disabled}
      />
    </>
  )

  const UpdateEmploymentIdsWithoutNotificationsHook = () => {
    const { values }: { values: FormValues } = useFormikContext()

    useEffectThatWontRunOnMount(() => {
      let employmentsToAdd: string[] = []
      Object.keys(values).forEach((employmentId) => {
        if (
          !values[employmentId].email &&
          !values[employmentId].fax &&
          !values[employmentId].emr
        ) {
          employmentsToAdd.push(employmentId)
        }
      })
      setEmploymentIdsWithoutNotifications(employmentsToAdd)

      return () => {
        employmentsToAdd = []
      }
    }, [values])
    return <></>
  }

  const DarkGrayText = ({ text }) => (
    <div className="color-dark-gray">{text}</div>
  )

  const {
    removeEmployment,
    isRemoving,
    setEmploymentIdToRemove,
  } = useRemoveEmployment()
  const openModal = () => setShowModal(true)
  const onClose = () => {
    setEmploymentIdToRemove(undefined)
    setShowModal(false)
  }
  const modalActivatorRef = useRef<HTMLAnchorElement | null>(null)

  return (
    <div className="row canopy-pb-32x">
      <div className="col-sm-2">
        <h3>Signature Requests</h3>
        <p className="font-subparagraph color-dark-gray">
          Signature requests are supplier generated documents that need to be
          reviewed and signed by a clinician.
        </p>
      </div>{" "}
      <div className="col-sm-9">
        <Form initialValues={initialValues} onSubmit={onSubmit}>
          {(form) => (
            <>
              <Table
                truncate={false}
                records={reviewerEmploymentsToTableData}
                overrideHeaderPointerEvents="auto"
                tableColumns={
                  [
                    {
                      title: "Clinician Name",
                      attr: "doctorName",
                      render: (employment) => (
                        <DarkGrayText text={employment.doctorName} />
                      ),
                    },
                    {
                      title: "NPI",
                      attr: "doctorNpi",
                      render: (employment) => (
                        <DarkGrayText text={employment.doctorNpi} />
                      ),
                    },
                    {
                      title: "Added By",
                      attr: "creationSource",
                      render: (employment) => (
                        <DarkGrayText text={employment.creationSource} />
                      ),
                    },
                    {
                      title: "Email",
                      attr: "email",
                      render: (employment) => (
                        <PreferenceCheckbox
                          name={`${employment.key}.email`}
                          label={`Enable emails for ${employment.doctorName}`}
                          value={form.values[`${employment.key}`].email}
                          onChange={(event) => {
                            form.setFieldValue(
                              `${employment.key}.email`,
                              event.target.checked
                            )
                          }}
                          disabled={!reviewerEmailVerified}
                        />
                      ),
                    },
                    reviewerCanReceiveEmrMessages && {
                      title: "EMR",
                      attr: "emr",
                      render: (employment) => (
                        <PreferenceCheckbox
                          name={`${employment.key}.emr`}
                          label={`Enable EMR notifications for ${employment.doctorName}`}
                          value={form.values[`${employment.key}`].emr}
                          onChange={(event) => {
                            form.setFieldValue(
                              `${employment.key}.emr`,
                              event.target.checked
                            )
                          }}
                        />
                      ),
                    },
                    {
                      title: "Fax",
                      attr: "fax",
                      render: (employment) => (
                        <PreferenceCheckbox
                          name={`${employment.key}.fax`}
                          label={`Enable faxes for ${employment.doctorName}`}
                          disabled
                          value={form.values[`${employment.key}`].fax}
                          onChange={(event) => {
                            form.setFieldValue(
                              `${employment.key}.fax`,
                              event.target.checked
                            )
                          }}
                        />
                      ),
                      headerRender: () => (
                        <>
                          {"Fax "}
                          <Tooltip
                            trigger={["click", "hover"]}
                            triggerElementClassName="d-inline-block"
                            placement={Tooltip.Placement.Top}
                            width={Tooltip.Width.Unlimited}
                            overlay="If you'd like to change your fax preferences, please use the Help
                        Center."
                          >
                            <>
                              <Icon
                                className="color-dark-gray"
                                type="info-circle"
                              />
                            </>
                          </Tooltip>
                        </>
                      ),
                    },
                    {
                      title: "Action",
                      attr: "action",
                      render: (employment) => (
                        <>
                          <Link
                            className={muiClasses.link}
                            onClick={() => {
                              const employmentId = employment.key
                              setEmploymentIdToRemove(employmentId)
                              openModal()
                            }}
                            ref={modalActivatorRef}
                          >
                            Remove
                          </Link>
                        </>
                      ),
                    },
                  ].filter(Boolean) as TableColumnDefinition<
                    TableRowEmployment & { key: string }
                  >[]
                }
              />
              {employmentIdsWithoutNotifications.length > 0 && (
                <Alert status="warning">
                  <p>
                    <strong>Warning!</strong>{" "}
                  </p>
                  <p>
                    Orders will remain in your dashboard, but you will no longer
                    receive notifications for:
                  </p>
                  {employmentIdsWithoutNotifications.map(
                    (employmentId: string) => (
                      <li
                        key={employmentId}
                        data-testid={`no-notifications-${employmentId}`}
                      >
                        {
                          reviewerEmploymentsToTableData.find(
                            (employment) => employment.key === employmentId
                          )?.doctorName
                        }
                      </li>
                    )
                  )}
                  <br />
                  <p className="mb-n1">
                    Please select "Remove" in the table above if you'd no longer
                    like to receive orders for this clinician(s).
                  </p>
                </Alert>
              )}
              <Button
                className="btn-brand mt-5"
                type="submit"
                disabled={!form.dirty || form.isSubmitting}
              >
                Save Changes
              </Button>
              <UpdateEmploymentIdsWithoutNotificationsHook />
            </>
          )}
        </Form>
      </div>
      <CanopyDialogModal
        header="Are you sure you want to remove this clinician?"
        activatorElementRef={modalActivatorRef}
        headerOverline="Remove Clinician"
        open={showModal}
        onClose={onClose}
        size="large"
        primaryFooterButton={
          <CanopyButton
            loading={isRemoving}
            loadingText="Removing..."
            onClick={removeEmployment}
            variant="danger"
          >
            Remove
          </CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton variant="secondary" onClick={onClose}>
            Cancel
          </CanopyButton>
        }
      >
        Removing this clinician means you will no longer receive signature
        requests for them. If you&apos;d like to simply stop receiving
        notifications for their orders, please deselect Email and reach out to
        the Help Center to stop receiving faxes.
      </CanopyDialogModal>
    </div>
  )
}

export default SignatureRequestPreferences
