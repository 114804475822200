// @team @catalog-crew

import { get } from "../../../services/api"
import {
  CatalogConsignmentCloset,
  CatalogConsignmentClosetStatus,
  Supplier,
} from "../types/sharedTypes"
import { ServerSideDataGridParams } from "../hooks/useServerSideDataGrid"

export type CatalogConsignmentClosetParams = ServerSideDataGridParams & {
  supplierId?: string
  status?: CatalogConsignmentClosetStatus | "All"
}

export type CatalogConsignmentClosetResponse = {
  catalogConsignmentClosets: CatalogConsignmentCloset[]
  suppliers: Supplier[]
  totalCount: number
  pageSize: number
}
export const getCatalogConsignmentClosets = async (
  params: CatalogConsignmentClosetParams
): Promise<CatalogConsignmentClosetResponse> => {
  return await get(`/cms/catalog/consignment_closets.json`, params).then(
    (response) => {
      return response.data
    }
  )
}
